import { createAction, props } from '@ngrx/store';

import { Participant } from '../participant';
import { DevicesSetupState } from './../media-device/media-devices/devices-setup-state.type';
import {
    ConnectionDetails,
    ReceivedChatMessage,
    SplashScreen
} from './conference.model';
import { SidebarState } from './conference.model';
import { ConnectionFailureError } from './conference.service';
import { DTMFDialogConfig } from './dtmf-dialog/dtmf-dialog.component';
import { FECCDialogConfig } from './fecc-dialog/fecc-dialog.component';
import { LoginError } from './idp-popup-login-dialog/idp-login.service';

export const CONFERENCE_ACTIONS = {
    connectSuccess: createAction(
        '[Conference] Connect Success',
        (payload: ConnectionDetails) => ({ payload })
    ),
    connectFail: createAction(
        '[Conference] Connect Fail',
        props<ConnectionFailureError>()
    ),
    updateMediaTypeAction: createAction(
        '[Conference] Update Media Type',
        (payload: CallType) => ({ payload })
    ),
    escalateAction: createAction(
        '[Conference] Escalate',
        props<{ callType: string }>()
    ),
    disconnect: createAction(
        '[Conference] Disconnect',
        // tslint:disable-next-line:no-inferrable-types
        (payload: boolean = false) => ({ payload })
    ),
    disconnectSuccess: createAction(
        '[Conference] Disconnect Success',
        (payload?: string) => ({ payload })
    ),
    clear: createAction('[Conference] Clear'),
    disconnectAll: createAction('[Conference] Disconnect All'),
    setRemoteMediaStream: createAction(
        '[Conference] Set Remote Media Stream',
        (payload: MediaStream) => ({ payload })
    ),
    updateConference: createAction(
        '[Conference] Update Conference',
        props<{
            guestsMuted: boolean;
            locked: boolean;
            started: boolean;
            liveCaptionsAvailable: boolean;
            isDirectMedia: boolean;
            isBreakoutRoomsEnabled: boolean;
        }>()
    ),
    mutedChanged: createAction(
        '[Conference] Muted Changed',
        (payload: boolean) => ({ payload })
    ),
    lockedChanged: createAction(
        '[Conference] Locked Changed',
        (payload: boolean) => ({ payload })
    ),
    startedChanged: createAction(
        '[Conference] Started Changed',
        (payload: boolean) => ({ payload })
    ),
    muteCamera: createAction('[Conference] Mute Camera'),
    unmuteCamera: createAction('[Conference] Unmute Camera'),
    muteMicrophone: createAction('[Conference] Mute Microphone'),
    unmuteMicrophone: createAction('[Conference] Unmute Microphone'),
    muteVolume: createAction('[Conference] Mute Volume'),
    unmuteVolume: createAction('[Conference] Unmute Volume'),
    buzzOn: createAction('[Conference] Buzz On'),
    buzzOff: createAction('[Conference] Buzz Off', (payload?: string) => ({
        payload
    })),
    buzzOffSomeone: createAction(
        '[Conference] Buzz Off Someone',
        (payload: string) => ({ payload })
    ),
    receiveChatMessage: createAction(
        '[Conference] Receive Chat Message',
        (payload: ReceivedChatMessage) => ({ payload })
    ),
    sendChatMessage: createAction(
        '[Conference] Send Chat Message',
        (payload: string) => ({ payload })
    ),
    newPresentationImgSrc: createAction(
        '[Conference] New Presentation Img Src',
        (payload: string) => ({ payload })
    ),
    stopPresentationVideo: createAction('[Conference] Stop Presentation Video'),
    updatePresentationVideo: createAction(
        '[Conference] Update Presentation Video',
        (payload: MediaStream) => ({ payload })
    ),
    slideShareStarted: createAction(
        '[Conference] Slide share Started',
        (payload: { type: PresentingMode; uuid: string }) => ({ payload })
    ),
    screenShareStarted: createAction(
        '[Conference] Screen share Started',
        (payload: { type: PresentingMode; uuid: string }) => ({ payload })
    ),
    presentingStopped: createAction(
        '[Conference] Presenting Stopped',
        props<{ reason: string; uuid: string }>()
    ),
    addSlide: createAction('[Conference] Add Slide', (payload: string) => ({
        payload
    })),
    updatePresentingSlides: createAction(
        '[Conference] Update Presenting Slides',
        (payload: number[]) => ({ payload })
    ),
    removeAllSlides: createAction('[Conference] Remove All Slides'),
    updateCurrentSlide: createAction(
        '[Conference] Update Current Slide',
        (payload: number) => ({ payload })
    ),
    nextSlide: createAction('[Conference] Next Slide'),
    prevSlide: createAction('[Conference] Prev Slide'),
    updatePresentationWindow: createAction(
        '[Conference] Update presentation video'
    ),
    setSelfUUID: createAction(
        '[Conference] Set Self UUID',
        (payload: string) => ({ payload })
    ),
    transferSuccess: createAction(
        '[Conference] Transfer Success',
        (payload: string) => ({ payload })
    ),
    setChatEnabled: createAction(
        '[Conference] Set Chat Enabled',
        (payload: boolean) => ({ payload })
    ),
    setLiveCaptionsAvailable: createAction(
        '[Conference] Set Live Captions Available',
        (payload: boolean) => ({ payload })
    ),
    setIsDirectMedia: createAction(
        '[Conference] Set Is Direct Media',
        (payload: boolean) => ({ payload })
    ),
    setIsBreakoutRoomsEnabled: createAction(
        '[Conference] Set Is Breakout Rooms Enabled',
        (payload: boolean) => ({ payload })
    ),
    setSidebarAction: createAction(
        '[Conference] Set Sidebar',
        (payload: SidebarState) => ({ payload })
    ),
    devicesSetupNavigateAction: createAction(
        '[Conference] Devices setup state',
        (payload: DevicesSetupState) => ({ payload })
    ),
    newPresentationFrameAction: createAction(
        '[Conference] New Presentation Frame',
        (payload: string) => ({ payload })
    ),
    incomingPresentationChangedAction: createAction(
        '[Conference] Incoming Presentation Changed',
        props<{ isActive: boolean; uuid: string }>()
    ),
    setPinOnTop: createAction(
        '[Conference] Set pin on top',
        (payload: boolean) => ({ payload })
    ),
    setVideoLoaded: createAction(
        '[Conference] Set video loaded',
        (payload: boolean) => ({ payload })
    ),
    setIdle: createAction('[Conference] Set idle', (payload: boolean) => ({
        payload
    })),
    setPresentationMaximized: createAction(
        '[Conference] Set presentation maximized',
        (payload: boolean) => ({ payload })
    ),
    setPresentationPoppedOut: createAction(
        '[Conference] Set presentation popped out',
        (payload: boolean) => ({ payload })
    ),
    startPresenting: createAction(
        '[Conference] Start presenting',
        (payload: PresentingMode) => ({ payload })
    ),
    startSlideUpload: createAction('[Conference] Start slide upload'),
    stopPresenting: createAction('[Conference] Stop presenting'),
    startPresentationVideo: createAction(
        '[Conference] Start presentation video'
    ),
    openDialpad: createAction(
        '[Conference] Open Dialpad',
        props<DTMFDialogConfig>()
    ),
    addDTMFDialog: createAction(
        '[Conference] Add DTMF Dialog',
        props<DTMFDialogConfig>()
    ),
    removeDTMFDialog: createAction(
        '[Conference] Remove DTMF Dialog',
        (payload: string) => ({ payload })
    ),
    openFECCpad: createAction(
        '[Conference] Open FECCpad',
        props<FECCDialogConfig>()
    ),
    addFECCDialog: createAction(
        '[Conference] Add FECC Dialog',
        props<FECCDialogConfig>()
    ),
    removeFECCDialog: createAction(
        '[Conference] Remove FECC Dialog',
        (payload: string) => ({ payload })
    ),
    openHostPinDialog: createAction('[Conference] Open Host Pin Dialog'),
    openIdPSelectionDialog: createAction(
        '[Conference] Open IdP Selection Dialog'
    ),
    openIdpLoginDialog: createAction('[Conference] Open IdP Login Dialog'),
    openStatsDialog: createAction('[Conference] Open Stats Dialog'),
    openAddParticipantDialog: createAction(
        '[Conference] Open Add Participant Dialog',
        props<{
            previousURI?: string;
            isHost: boolean;
        }>()
    ),
    openParticipantInfoPad: createAction(
        '[Conference] Open Participant info pad',
        props<{ dialogId: string; participant: Participant }>()
    ),
    addParticipantInfoDialog: createAction(
        '[Conference] Add Participant info dialog',
        props<{ dialogId: string; participant: Participant }>()
    ),
    removeParticipantInfoDialog: createAction(
        '[Conference] Remove Participant info dialog',
        (payload: string) => ({ payload })
    ),
    lock: createAction('[Conference] Lock'),
    unlock: createAction('[Conference] Unlock'),
    muteAll: createAction('[Conference] Mute All'),
    unmuteAll: createAction('[Conference] Unmute All'),
    filterRosterBy: createAction(
        '[Conference] Filter roster by',
        (payload: string) => ({ payload })
    ),
    startConference: createAction('[Conference] Start conference'),
    spotlightParticipant: createAction(
        '[Conference] Spotlight participant',
        props<{ uuid: string; shouldSpotlight: boolean }>()
    ),
    changeParticipantRole: createAction(
        '[Conference] Change participant role',
        props<{ uuid: string; role: string }>()
    ),
    setParticipantMuted: createAction(
        '[Conference] Set participant muted',
        props<{ uuid: string; shouldMute: boolean }>()
    ),
    setParticipantRxPres: createAction(
        '[Conference] Set participant rx pres',
        props<{ uuid: string; allowRxPres: boolean }>()
    ),
    toggleSidebar: createAction('[Conference] Toggle sidebar'),
    setShowConferenceSidebar: createAction(
        '[Conference] Set Show Conference Sidebar',
        (payload: boolean) => ({ payload })
    ),
    dialOut: createAction(
        '[Conference] Dial out',
        props<{
            alias: string;
            protocol: string;
            role: string;
            cb: (msg: object) => void;
            params: string | object;
        }>()
    ),
    sendRequest: createAction(
        '[Conference] Send request',
        props<{
            request: string;
            params: object;
            cb: (msg: object) => void;
            reqMethod: string;
            retries: number;
        }>()
    ),
    disconnectParticipantDialog: createAction(
        '[Conference] Disconnect participant dialog',
        props<Participant>()
    ),
    disconnectParticipant: createAction(
        '[Conference] Disconnect participant',
        (payload: string) => ({ payload })
    ),
    transferParticipantDialog: createAction(
        '[Conference] Transfer participant dialog',
        props<Participant>()
    ),
    changeConferenceLayout: createAction(
        '[Conference] Change conference layout',
        (payload: object) => ({ payload })
    ),
    transformConferenceLayout: createAction(
        '[Conference] Transform conference layout',
        (payload: object) => ({ payload })
    ),
    localMediaChange: createAction(
        '[Conference] Local media change',
        props<{ payload: MediaStream }>()
    ),
    handleEscalation: createAction(
        '[Conference] Handle escalation',
        (payload: [string, CallType]) => ({ payload })
    ),
    setSidebarExpanded: createAction(
        '[Conference] Set sidebar expanded',
        (payload: boolean) => ({ payload })
    ),
    setRemoteCallType: createAction(
        '[Conference] Set remote call type',
        (payload: 'audio' | 'video-only' | 'video') => ({ payload })
    ),
    setFECCEnabled: createAction(
        '[Conference] Set fecc enabled',
        (payload: boolean) => ({ payload })
    ),
    setGuestsCanPresent: createAction(
        '[Conference] Set guests can present',
        (payload: boolean) => ({ payload })
    ),
    setActive: createAction('[Conference] Set active'),
    setPresentationInMix: createAction(
        '[Conference] Set presentation in mix',
        (payload: boolean) => ({ payload })
    ),
    setPresentationInMixSuccess: createAction(
        '[Conference] Set presentation in mix success',
        (payload: boolean) => ({ payload })
    ),
    openLoginUrl: createAction(
        '[Conference] Open login url',
        (payload: string) => ({ payload })
    ),
    submitSsoToken: createAction(
        '[Conference] Submit SSO token',
        (payload: string) => ({ payload })
    ),
    samlLoginError: createAction(
        '[Conference] SAML Login error',
        (payload: LoginError) => ({ payload })
    ),
    setCurrentPresenter: createAction(
        '[Conference] Set current presenter',
        props<{ isActive: boolean; uuid?: string }>()
    ),
    setLiveCaptionsEnabled: createAction(
        '[Conference] Set Live Captions Enabled',
        (payload: boolean) => ({ payload })
    ),
    setSplashScreen: createAction(
        '[Conference] Set Splash Screen',
        props<SplashScreen>()
    )
};

export type ConferenceActions = ReturnType<
    typeof CONFERENCE_ACTIONS[keyof typeof CONFERENCE_ACTIONS]
>;

export const isPresentationAction = (action: ConferenceActions) =>
    action &&
    ([
        CONFERENCE_ACTIONS.slideShareStarted.type,
        CONFERENCE_ACTIONS.screenShareStarted.type,
        CONFERENCE_ACTIONS.presentingStopped.type,
        CONFERENCE_ACTIONS.incomingPresentationChangedAction.type
    ] as string[]).includes(action.type);
export const isPresentationStartAction = (action: ConferenceActions) =>
    action &&
    (([
        CONFERENCE_ACTIONS.slideShareStarted.type,
        CONFERENCE_ACTIONS.screenShareStarted.type
    ] as string[]).includes(action.type) ||
        (action.type ===
            CONFERENCE_ACTIONS.incomingPresentationChangedAction.type &&
            action.isActive));
export const isPresentationStopAction = (action: ConferenceActions) =>
    action &&
    (([CONFERENCE_ACTIONS.presentingStopped.type] as string[]).includes(
        action.type
    ) ||
        (action.type ===
            CONFERENCE_ACTIONS.incomingPresentationChangedAction.type &&
            !action.isActive));
