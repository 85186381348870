import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, tap, withLatestFrom } from 'rxjs/operators';

import { PlatformService } from '../platform.service';
import { AnalyticsService } from '../services/analytics.service';
import { MEDIA_DEVICE_ACTIONS } from './media-device.actions';
import { MediaDeviceFacade } from './media-device.facade';
import { MediaDeviceService } from './media-device.service';

@Injectable()
export class MediaDeviceEffects {
    constructor(
        private actions$: Actions,
        private mediaDeviceService: MediaDeviceService,
        private mediaDeviceFacade: MediaDeviceFacade,
        private analyticsService: AnalyticsService,
        private platformService: PlatformService
    ) {}

    setMediaInputDevices$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MEDIA_DEVICE_ACTIONS.setMediaInputDevicesAction),
                filter(({ store }) => store),
                withLatestFrom(this.mediaDeviceFacade.mutedByUser$),
                tap(([action, mutedByUser]) => {
                    if (action.video) {
                        this.setLocalStorageItem(
                            'pexStorage-videoInputDevice',
                            action.video
                        );
                    }
                    if (action.audio) {
                        this.setLocalStorageItem(
                            'pexStorage-audioInputDevice',
                            action.audio
                        );
                    }
                    this.setLocalStorageItem(
                        'pexStorage-mutedByUser',
                        mutedByUser
                    );
                })
            ),
        { dispatch: false }
    );

    setVideoInputDevice$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MEDIA_DEVICE_ACTIONS.setVideoInputDeviceAction),
                filter(({ store }) => store),
                withLatestFrom(this.mediaDeviceFacade.mutedByUser$),
                tap(([action, mutedByUser]) => {
                    if (action.device) {
                        this.setLocalStorageItem(
                            'pexStorage-videoInputDevice',
                            action.device
                        );
                    }
                    this.setLocalStorageItem(
                        'pexStorage-mutedByUser',
                        mutedByUser
                    );
                })
            ),
        { dispatch: false }
    );

    setAudioInputDevice$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MEDIA_DEVICE_ACTIONS.setAudioInputDeviceAction),
                filter(({ store }) => store),
                withLatestFrom(this.mediaDeviceFacade.mutedByUser$),
                tap(([action, mutedByUser]) => {
                    if (action.device) {
                        this.setLocalStorageItem(
                            'pexStorage-audioInputDevice',
                            action.device
                        );
                    }
                    this.setLocalStorageItem(
                        'pexStorage-mutedByUser',
                        mutedByUser
                    );
                })
            ),
        { dispatch: false }
    );

    setAudioOutputDevice$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MEDIA_DEVICE_ACTIONS.setAudioOutputDeviceAction),
                tap(action => {
                    localStorage.setItem(
                        'pexStorage-audioOutputDevice',
                        JSON.stringify(action.device)
                    );
                    if (this.platformService.isAndroid()) {
                        window.plugins.ManageAudio.switchAudioRoute(
                            action.device.deviceId.toUpperCase()
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    audioOutputDeviceChanged$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MEDIA_DEVICE_ACTIONS.audioOutputDeviceChangedAction),
                tap(action =>
                    this.setLocalStorageItem(
                        'pexStorage-audioOutputDevice',
                        action.device
                    )
                )
            ),
        { dispatch: false }
    );

    // @Effect({ dispatch: false })
    // setLocalMediaStream$ = this.actions$
    //     .pipe(
    //         ofType(MEDIA_DEVICE_ACTIONS.SET_LOCAL_MEDIA_STREAM),
    //         withLatestFrom(this.mediaDeviceFacade.mediaDeviceState$),
    //         tap(([action, mediaDeviceState]) => {
    //             const newStream = action.payload.stream;
    //             if (
    //                 newStream &&
    //                 newStream !== mediaDeviceState.localMediaStream
    //             ) {
    //                 this.mediaDeviceService.releaseUserMedia(
    //                     mediaDeviceState.localMediaStream
    //                 );
    //             }
    //         })
    //     );

    setMediaTypeRequest$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MEDIA_DEVICE_ACTIONS.setMediaTypeRequest),
                tap(action =>
                    this.analyticsService.recordEvent('conferenceEscalation', {
                        mediaTypeRequest: action.callType
                    })
                )
            ),
        { dispatch: false }
    );

    private setLocalStorageItem(key: string, value: string | object) {
        localStorage.setItem(key, JSON.stringify(value));
    }
}
