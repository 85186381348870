import { createAction } from '@ngrx/store';
import { PresentationSlotCoordinates } from '.';
import { ViewState } from './layout.model';

export const LAYOUT_ACTIONS = {
    updateLayoutAction: createAction(
        '[Layout] Update Layout',
        (payload: PexRTCLayout) => ({ payload })
    ),
    presSlotCoordsChangedAction: createAction(
        '[Layout] Presentation slot coordinates changed',
        (payload: PresentationSlotCoordinates) => ({ payload })
    ),
    viewChangedAction: createAction(
        '[Layout] View changed',
        (payload: ViewState) => ({ payload })
    ),
    requestedLayoutChangedAction: createAction(
        '[Layout] Requested layout changed',
        (payload: LayoutOptions) => ({ payload })
    )
};

export type LayoutActions = ReturnType<
    typeof LAYOUT_ACTIONS[keyof typeof LAYOUT_ACTIONS]
>;
