import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { DevicesSetupState } from '../media-device/media-devices/devices-setup-state.type';
import { HOME_ACTIONS } from './home.actions';
import {
    getCameraMuted,
    getDevicesSetupState,
    getIsHome,
    getMicrophoneMuted,
    getScreenState,
    getSettingsState,
    HomeState
} from './home.reducer';
import { ScreenState } from './offset-overlay/screen-state.type';
import { SettingsState } from './setting-overlay/settings-state.type';

@Injectable({ providedIn: 'root' })
export class HomeFacade {
    constructor(private store: Store<HomeState>) {}

    setScreen(screen: ScreenState) {
        this.store.dispatch(HOME_ACTIONS.setScreenAction({ screen }));
    }

    setSettingsNavigate(settings: SettingsState) {
        this.store.dispatch(
            HOME_ACTIONS.settingsNavigateAction({ screen: settings })
        );
    }

    setDevicesSetup(devicesSetup: DevicesSetupState) {
        this.store.dispatch(
            HOME_ACTIONS.devicesSetupNavigateAction({ screen: devicesSetup })
        );
    }

    setMicrophoneMuted(muted: boolean) {
        this.store.dispatch(HOME_ACTIONS.microphoneMuteChangeAction({ muted }));
    }

    setCameraMuted(muted: boolean) {
        this.store.dispatch(HOME_ACTIONS.cameraMuteChangeAction({ muted }));
    }

    getScreen() {
        return this.store.select(getScreenState);
    }

    isHome() {
        return this.store.select(getIsHome);
    }

    getSettingsScreen() {
        return this.store.select(getSettingsState);
    }

    getDevicesSetup() {
        return this.store.select(getDevicesSetupState);
    }

    getCameraMuted() {
        return this.store.select(getCameraMuted);
    }

    getMicrophoneMuted() {
        return this.store.select(getMicrophoneMuted);
    }
}
