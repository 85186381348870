import { Component } from '@angular/core';

import { BaseDialogContentComponent } from '../../dialog/base-dialog-content.component';

interface ProvisionDetails {
    name?: string;

    registrationHost?: string;
    registrationAlias?: string;
    registrationUsername?: string;
    registrationPassword?: string;

    adfsFederationServiceName?: string;
    adfsResource?: string;
    adfsClientID?: string;
    adfsRedirectURI?: string;

    brandingURL?: string;
}

export function isADFSProvisioning({
    adfsFederationServiceName,
    adfsResource,
    adfsClientID,
    adfsRedirectURI
}: ProvisionDetails) {
    return (
        adfsFederationServiceName &&
        adfsResource &&
        adfsClientID &&
        adfsRedirectURI &&
        true
    );
}

@Component({
    templateUrl: './registration-provision-dialog.component.html',
    styleUrls: ['./registration-provision-dialog.component.scss']
})
export class RegistrationProvisionDialogComponent extends BaseDialogContentComponent<
    boolean,
    ProvisionDetails
> {
    provisionType: string;

    afterInit() {
        if (
            this.safeConfig.registrationUsername &&
            this.safeConfig.registrationPassword
        ) {
            this.provisionType = 'normal';
        } else if (isADFSProvisioning(this.safeConfig)) {
            this.provisionType = 'adfs';
        }
    }
}
