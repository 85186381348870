import { Injectable } from '@angular/core';
import { timer } from 'rxjs';
import { buffer } from 'rxjs/operators';

import { ClipboardService } from './services/clipboard.service';
import { Log, Logger, LogLevel } from './shared/logger';

@Injectable()
export abstract class LoggingService {
    readonly fileSaver = require('file-saver');

    constructor(protected clipboardService: ClipboardService) {
        //tslint:disable-next-line:no-any
        const mapArrayToLength = (arr: any[]) => {
            return Array.isArray(arr) ? arr.length : 0;
        };

        Logger.mappedFields = {
            password: () => '',
            events: {
                orphanedFrames: mapArrayToLength,
                //tslint:disable-next-line:no-any
                presentations: (presentations: any[]) => {
                    return Array.isArray(presentations)
                        ? presentations.map(presentation =>
                              mapArrayToLength(presentation.frames)
                          )
                        : [];
                }
            },
            orphanedFrames: mapArrayToLength,
            //tslint:disable-next-line:no-any
            presentations: (presentations: any[]) => {
                return Array.isArray(presentations)
                    ? presentations.map(presentation =>
                          mapArrayToLength(presentation.frames)
                      )
                    : [];
            },
            conference: {
                presentationImgSrc: (imgStr: string) =>
                    imgStr ? 'image' : 'empty',
                slides: mapArrayToLength
            },
            //tslint:disable-next-line:no-any
            payload: (payload: any) => {
                if (
                    typeof payload === 'string' &&
                    payload.startsWith('data:image')
                ) {
                    return 'image';
                }
                if (
                    typeof payload === 'object' &&
                    payload &&
                    payload.image &&
                    typeof payload.image === 'string' &&
                    payload.image.startsWith('data:image')
                ) {
                    return { ...payload, image: 'image' };
                }
                return payload;
            },
            slides: mapArrayToLength
        };

        const logBufferMS = 500;

        Logger.log$.pipe(buffer(timer(0, logBufferMS))).subscribe(logs => {
            if (this && this.storeLog && logs.length > 0) {
                this.storeLog(
                    logs.reduce(
                        (accLog, currLog) =>
                            `${accLog}\r\n${currLog.toString()}`,
                        ''
                    )
                );
            }
        });
    }

    //tslint:disable-next-line:no-any
    info(message: string, ...objects: any[]) {
        Logger.info(message, ...objects);
    }

    //tslint:disable-next-line:no-any
    warn(message: string, ...objects: any[]) {
        Logger.warn(message, ...objects);
    }

    //tslint:disable-next-line:no-any
    error(message: string, ...objects: any[]) {
        //prompt user to send logs to us?
        Logger.error(message, ...objects);
    }

    //tslint:disable-next-line:no-any
    log(message: string, level: LogLevel, ...objects: any[]) {
        Logger.log(message, level, ...objects);
    }

    //tslint:disable-next-line:no-any
    safeStringify(object: any) {
        let objString: string;
        try {
            objString = JSON.stringify(object, null, 2);
        } catch {
            objString =
                object.longStack ||
                object.stack ||
                object.message ||
                'Could not stringify object';
        }
        return objString;
    }

    downloadMemoryLogs() {
        const data = Logger.recentLogs.join('\r\n');
        const now = new Date();
        const filename =
            'Pexip Infinity Connect Logs ' +
            now.toDateString() +
            ' ' +
            now.toTimeString().split(' ')[0] +
            '.txt';
        this.downloadAsFile(filename, data);
    }

    private downloadAsFile(name: string, data: string) {
        const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
        this.fileSaver.saveAs(blob, name);
    }

    copyFileLogsToClipboard() {
        let logString: string;
        const interval = setInterval(() => {
            if (logString) {
                this.clipboardService.copy(logString);
                clearInterval(interval);
            }
        }, 100); //tslint:disable-line:no-magic-numbers
        this.retrieveLogs().then(logs => {
            logString = logs[0] + logs[1];
        });
    }

    abstract retrieveLogs(): Promise<[string, string]>;
    abstract storeLog(log: string): void;
    abstract resetLast(): void;
}
