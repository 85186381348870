import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, take, timeout } from 'rxjs/operators';

import { PlatformService } from '../../platform.service';
import { __REGISTRATION_CONSTANTS__ } from '../../registration/registration.constants';
import { RegistrationFacade } from '../../registration/registration.facade';
import {
    AuthHeader,
    DirectoryResult,
    TokenResponse
} from './registration.model';

@Injectable({
    providedIn: 'root'
})
export class RegistrationService {
    constructor(
        private http: HttpClient,
        public platformService: PlatformService,
        private registrationFacade: RegistrationFacade
    ) {}

    directoryQuery(query: string): Observable<DirectoryResult> {
        return this.registrationFacade.state$.pipe(
            take(1),
            switchMap(
                ({
                    registered,
                    registeredHost,
                    tokenResponse: { token, directory_enabled }
                }) => {
                    if (
                        registered &&
                        token &&
                        directory_enabled &&
                        query !== ''
                    ) {
                        return this.http.get<DirectoryResult>(
                            `https://${registeredHost}/api/client/v2/registrations?q=${query}`,
                            {
                                headers: new HttpHeaders().append(
                                    'token',
                                    token
                                )
                            }
                        );
                    }
                    return of({ status: 'success', result: [] });
                }
            )
        );
    }

    requestToken(
        host: string,
        alias: string,
        header: AuthHeader,
        time = __REGISTRATION_CONSTANTS__.REQUEST_TOKEN_TIMEOUT
    ) {
        const requestTokenUrl = `https://${host}/api/client/v2/registrations/${encodeURIComponent(
            alias
        )}/request_token`;
        let headers: HttpHeaders;

        headers = new HttpHeaders().append(header.name, header.value);

        return this.http
            .post<{ result: TokenResponse }>(requestTokenUrl, {}, { headers })
            .pipe(
                timeout(time),
                map(response => response.result)
            );
    }

    refreshToken(
        host: string,
        alias: string,
        token: string,
        time = __REGISTRATION_CONSTANTS__.REQUEST_TOKEN_TIMEOUT
    ) {
        const refreshTokenUrl = `https://${host}/api/client/v2/registrations/${encodeURIComponent(
            alias
        )}/refresh_token`;

        return this.http
            .post<{ result: TokenResponse }>(refreshTokenUrl, { token })
            .pipe(
                timeout(time),
                map(response => response.result)
            );
    }

    releaseToken(
        host: string,
        alias: string,
        token: string,
        time = __REGISTRATION_CONSTANTS__.REQUEST_TOKEN_TIMEOUT
    ) {
        const releaseTokenUrl = `https://${host}/api/client/v2/registrations/${encodeURIComponent(
            alias
        )}/release_token?token=${token}`;

        return this.http.post(releaseTokenUrl, null).pipe(
            timeout(time),
            map(response => response['result'])
        );
    }

    getADFSAuthCode(
        adfsFederationServiceName: string,
        adfsClientID: string,
        adfsResource: string,
        adfsRedirectURI: string
    ) {
        const adfsStateToken = (() => {
            let text = '';
            const possible =
                'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const length = 32;
            for (let i = 0; i < length; i++) {
                text += possible.charAt(
                    Math.floor(Math.random() * possible.length)
                );
            }
            return text;
        })();

        this.registrationFacade.setADFSStateToken(adfsStateToken);

        const url = `https://${adfsFederationServiceName}/adfs/oauth2/authorize?response_type=code&client_id=${adfsClientID}&resource=${adfsResource}&redirect_uri=${adfsRedirectURI}&state=${adfsStateToken}`;

        if (this.platformService.platform === 'electron') {
            window.open(url, '_blank');
        } else if (this.platformService.isMobile()) {
            // window.cordova.InAppBrowser.open(url, '_system');
        } else {
            window.open(url, '_blank');
        }
    }

    getADFSTokenFromAuth(
        code: string,
        adfsClientID: string,
        adfsRedirectURI: string,
        adfsFederationServiceName: string
    ) {
        const params = new HttpParams()
            .set('grant_type', 'authorization_code')
            .set('code', code)
            .set('client_id', adfsClientID)
            .set('redirect_uri', adfsRedirectURI);
        return this.getADFSToken(params, adfsFederationServiceName);
    }

    getADFSTokenFromRefresh(
        adfsRefreshToken: string,
        adfsFederationServiceName: string
    ) {
        const params = new HttpParams()
            .set('grant_type', 'refresh_token')
            .set('refresh_token', adfsRefreshToken);
        return this.getADFSToken(params, adfsFederationServiceName);
    }

    private getADFSToken(
        params: HttpParams,
        adfsFederationServiceName: string
    ) {
        return this.http
            .post(
                `https://${adfsFederationServiceName}/adfs/oauth2/token`,
                params.toString(),
                {
                    headers: new HttpHeaders().set(
                        'Content-Type',
                        'application/x-www-form-urlencoded'
                    )
                }
            )
            .toPromise()
            .then(
                (response: {
                    access_token?: string;
                    refresh_token?: string;
                    expires_in?: number;
                }) => {
                    if (response.access_token) {
                        this.registrationFacade.setADFSToken(
                            response.access_token
                        );
                        this.registrationFacade.setADFSTokenExpiry(
                            Date.now() +
                                response.expires_in * __CONSTANTS__.SECOND_MS
                        );
                        if (response.refresh_token) {
                            this.registrationFacade.setADFSRefreshToken(
                                response.refresh_token
                            );
                        }
                        return true;
                    }
                }
            )
            .catch(error => {
                if (
                    error.error === 'invalid_grant' &&
                    params.get('grant_type') === 'refresh_token'
                ) {
                    this.registrationFacade.setADFSRefreshToken(null);
                    return false;
                }
            });
    }
}
