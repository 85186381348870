import { Injectable, Injector } from '@angular/core';

import { URLService } from './services';

export interface TouchItem {
    type: string;
    title: string;
    subtitle?: string;
    iconType?: string;
}

export interface RecentEntry {
    connectionDetails: { alias: string };
    alias: string;
}

@Injectable()
export abstract class LongPressService {
    abstract populateEntries(entries: RecentEntry[]): void;

    protected urlService: URLService;

    constructor(injector: Injector) {
        this.urlService = injector.get(URLService);
    }

    dialRecent(alias: string) {
        this.urlService.open('pexip://' + alias, false);
    }
}
