export enum SettingsStateEnum {
    MAIN = 'MAIN',
    DEVICES_SETUP = 'DEVICES_SETUP',
    REGISTER = 'REGISTER',
    ADVANCED = 'ADVANCED',
    ABOUT = 'ABOUT',
    PLUGIN = 'PLUGIN',
    LANGUAGES = 'LANGUAGES'
}

export type SettingsState =
    | SettingsStateEnum.MAIN
    | SettingsStateEnum.DEVICES_SETUP
    | SettingsStateEnum.REGISTER
    | SettingsStateEnum.ADVANCED
    | SettingsStateEnum.ABOUT
    | SettingsStateEnum.PLUGIN
    | SettingsStateEnum.LANGUAGES;
