<div class="pex-dialog-close">
    <button
        class="circle-close-button"
        type="button"
        (click)="close('CANCEL')"
        [attr.aria-label]="'CLOSE' | translate"
    >
        <svg class="circle-close-button-icon">
            <use xlink:href="icons.svg#x" />
        </svg>
    </button>
</div>

<div
    class="pex-dialog-content-inner action-dialog"
    role="dialog"
    aria-labelledby="pex-action-dialog-title"
    aria-modal="true"
>
    <div
        id="pex-action-dialog-title"
        class="pex-dialog-content-title"
        [ngClass]="this.config?.titleClass && this.config?.titleClass"
        [innerHTML]="this.config?.title | translate: translationParams"
    ></div>
    <div
        class="pex-dialog-content-message"
        *ngIf="!!this.config?.message"
        [innerHTML]="this.config?.message | translate: translationParams"
    ></div>
    <!--
        FIXME: testing button strings to set classes feels a bit nasty
        should probably have attributes to set e.g. "defaultAction" etc. to apply classes
    -->
    <div id="pex-dialog-disconnect-action" class="pex-dialog-content-button">
        <button
            type="button"
            class="dialog-button"
            [class.red-action-button]="
                action == 'DISCONNECT' ||
                action == 'DISCONNECT_ALL.DISCONNECT_ALL' ||
                action == 'DISCONNECT_PARTICIPANT.DISCONNECT'
            "
            [class.green-non-action-button]="
                action == 'DISCONNECTED.REJOIN' ||
                action == 'DISCONNECTED.REDIAL'
            "
            [class.green-action-button]="action == 'JOIN_DIALOG.JOIN'"
            [class.blue-action-button]="action == 'OK'"
            [class.gray-non-action-button]="action == 'CANCEL'"
            (click)="close(action)"
            *ngFor="let action of this.config?.actions"
            #dialogButtons
        >
            {{ action | translate }}
        </button>
    </div>
</div>

<div class="pex-dialog-error-code" *ngIf="!!this.config?.code">
    <a
        *ngIf="this.config?.code.includes('#pex'); else plainText"
        target="_blank"
        [href]="
            'https://docs.pexip.com/end_user/guide_for_admins/error_messages.htm' +
            this.config?.code
        "
        >({{ this.config?.code }})</a
    >
    <ng-template #plainText>({{ this.config?.code }})</ng-template>
</div>
