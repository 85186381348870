import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

interface WillChangeTarget {
    id: string;
    source: Subject<boolean>;
    properties: string;
}
@Injectable()
export class WillChangeService {
    private willChangeTargets: WillChangeTarget[] = [];

    constructor() {}

    addTarget(id: string, properties: string) {
        const target = {
            id,
            source: new Subject<boolean>(),
            properties
        };

        this.willChangeTargets.push(target);
        return target;
    }

    removeTarget(id: string) {
        const existingTarget = this.findTarget(id);
        if (existingTarget) {
            this.willChangeTargets = this.willChangeTargets.filter(
                target => id !== target.id
            );
        }
    }

    getSource(id: string) {
        const target = this.findTarget(id);
        if (target) {
            return target.source;
        }
    }

    private findTarget(id: string) {
        return this.willChangeTargets.find(target => target.id === id);
    }
}
