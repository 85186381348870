import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { GENERAL_ACTIONS } from './general.actions';
import {
    GeneralState,
    getWindowActive,
    getWindowClosed,
    getWindowMinimized
} from './general.reducer';

@Injectable()
export class GeneralFacade {
    private isWindowActive = true;
    constructor(private store: Store<GeneralState>) {
        this.getWindowActive().subscribe(
            active => (this.isWindowActive = active)
        );
    }

    getWindowActive() {
        return this.store.select(getWindowActive);
    }

    setWindowActive(active: boolean) {
        if (active !== this.isWindowActive) {
            this.store.dispatch(
                GENERAL_ACTIONS.setWindowActiveAction({ active })
            );
        }
    }

    getWindowMinimized() {
        return this.store.select(getWindowMinimized);
    }

    setWindowMinimized(minimised: boolean) {
        this.store.dispatch(
            GENERAL_ACTIONS.setWindowMinimizedAction({ minimised })
        );
    }

    getWindowClosed() {
        return this.store.select(getWindowClosed);
    }

    setWindowClosed(closed: boolean) {
        this.store.dispatch(GENERAL_ACTIONS.setWindowClosedAction({ closed }));
    }

    setOnline(online: boolean) {
        this.store.dispatch(GENERAL_ACTIONS.setOnlineAction({ online }));
    }

    initFinished() {
        this.store.dispatch(GENERAL_ACTIONS.initFinished());
    }
}
