import {
    Action,
    createFeatureSelector,
    createReducer,
    createSelector,
    on
} from '@ngrx/store';

import {
    IncomingCallDetails,
    TokenResponse
} from '../services/registration/registration.model';
import { REGISTRATION_ACTIONS } from './registration.actions';

export interface RegistrationState {
    username: string;
    password: string;
    host: string;
    alias: string;

    adfsFederationServiceName: string;
    adfsResource: string;
    adfsClientID: string;
    adfsRedirectURI: string;
    adfsToken: string;
    adfsRefreshToken: string;
    adfsStateToken: string;
    adfsTokenExpiry: number;

    shouldRegister: boolean;
    registering: boolean;
    registered: boolean;
    numFailures: number;
    errorLabel: string;

    resolvedHosts: string[];

    tokenResponse: Partial<TokenResponse>;
    registeredHost: string;

    incomingCalls: { [token: string]: IncomingCallDetails };
    incomingCallDialogs: { [token: string]: string };
    closeSourceFn: () => void;
}

export const initialState: RegistrationState = {
    username: '',
    password: '',
    host: '',
    alias: '',

    adfsFederationServiceName: '',
    adfsResource: '',
    adfsClientID: '',
    adfsRedirectURI: '',
    adfsToken: '',
    adfsRefreshToken: '',
    adfsStateToken: '',
    adfsTokenExpiry: undefined,

    shouldRegister: false,
    registering: false,
    registered: false,
    numFailures: 0,
    errorLabel: '',

    resolvedHosts: [],

    tokenResponse: {},
    registeredHost: '',

    incomingCalls: {},
    incomingCallDialogs: {},
    closeSourceFn: undefined
};

const getStateToStore = (state: Partial<RegistrationState>) => {
    const {
        username,
        host,
        alias,
        shouldRegister,
        adfsFederationServiceName,
        adfsResource,
        adfsClientID,
        adfsRedirectURI,
        adfsToken,
        adfsRefreshToken,
        adfsStateToken,
        adfsTokenExpiry
    } = state;
    return {
        username,
        host,
        alias,
        shouldRegister,
        adfsFederationServiceName,
        adfsResource,
        adfsClientID,
        adfsRedirectURI,
        adfsToken,
        adfsRefreshToken,
        adfsStateToken,
        adfsTokenExpiry
    };
};

export const storedRegistrationKeys = {
    serialize: (state: Partial<RegistrationState>) => {
        state = getStateToStore(state);
        return state;
    },
    deserialize: (state: Partial<RegistrationState>) => {
        state = getStateToStore(state);
        return state;
    }
};

const registrationReducer = createReducer(
    initialState,
    on(REGISTRATION_ACTIONS.setAlias, (state, { alias }) => ({
        ...state,
        alias
    })),
    on(REGISTRATION_ACTIONS.setHost, (state, { host }) => ({ ...state, host })),
    on(REGISTRATION_ACTIONS.setPassword, (state, { password }) => ({
        ...state,
        password
    })),
    on(REGISTRATION_ACTIONS.initPassword, (state, { password }) => ({
        ...state,
        password
    })),
    on(REGISTRATION_ACTIONS.setUsername, (state, { username }) => ({
        ...state,
        username
    })),

    on(REGISTRATION_ACTIONS.register, state => ({
        ...state,
        registering: true
    })),
    on(REGISTRATION_ACTIONS.unregisterFinish, state => ({
        ...state,
        shouldRegister: false,
        registeredHost: '',
        registered: false,
        tokenResponse: {},
        closeSourceFn: undefined
    })),
    on(REGISTRATION_ACTIONS.registerFail, (state, action) => {
        const nextState = {
            ...state
        };
        const error: any = action.error; //tslint:disable-line
        if (!error) {
            nextState.errorLabel = 'REGISTRATION.UNKNOWN_ERROR';
        } else {
            if (state.shouldRegister && error.statusText !== 'Unauthorized') {
                nextState.errorLabel = 'REGISTRATION.REGISTRATION_IS_RETRYING';
            } else {
                let customizedError = error;
                if (error.name === 'TimeoutError') {
                    customizedError = {
                        name: 'TimeoutError',
                        message: 'REGISTRATION.TIMEOUT_ERROR'
                    };
                } else if (error.statusText === 'Unauthorized') {
                    customizedError = {
                        name: 'Unauthorized',
                        message: 'REGISTRATION.UNAUTHORIZED_ERROR'
                    };
                } else if (
                    error.name === 'HttpErrorResponse' &&
                    error.statusText === 'Unknown Error'
                ) {
                    customizedError = {
                        name: 'HttpErrorResponse',
                        message: 'REGISTRATION.HOST_OR_NETWORK_ERROR'
                    };
                } else {
                    customizedError = {
                        name: 'UnknownError',
                        message: 'REGISTRATION.UNKNOWN_ERROR'
                    };
                }
                nextState.errorLabel = customizedError.message;
            }
        }

        if (state.shouldRegister) {
            nextState.numFailures += 1;
        } else {
            nextState.registering = false;
        }

        return nextState;
    }),
    on(REGISTRATION_ACTIONS.registerFinish, (state, { closeSourceFn }) => ({
        ...state,
        registering: false,
        registered: true,
        errorLabel: '',
        closeSourceFn
    })),
    on(REGISTRATION_ACTIONS.cancel, state => ({
        ...state,
        registering: false,
        shouldRegister: false,
        numFailures: 0
    })),

    on(REGISTRATION_ACTIONS.srvLookupFinish, (state, { resolvedHosts }) => ({
        ...state,
        resolvedHosts
    })),

    on(REGISTRATION_ACTIONS.requestTokenStart, (state, { server }) => ({
        ...state,
        resolvedHosts: state.resolvedHosts.filter(host => host !== server)
    })),
    on(
        REGISTRATION_ACTIONS.requestTokenFinish,
        (state, { registeredHost, tokenResponse }) => ({
            ...state,
            tokenResponse,
            registeredHost,
            shouldRegister: true,
            numFailures: 0,
            resolvedHosts: []
        })
    ),

    on(REGISTRATION_ACTIONS.refreshTokenFinish, (state, { tokenResponse }) => ({
        ...state,
        tokenResponse: { ...state.tokenResponse, ...tokenResponse }
    })),
    on(REGISTRATION_ACTIONS.refreshTokenFail, state => ({
        ...state,
        registered: false,
        tokenResponse: {}
    })),
    on(REGISTRATION_ACTIONS.clearDetails, state => ({
        ...state,
        username: '',
        password: '',
        host: '',
        alias: '',
        adfsFederationServiceName: '',
        adfsResource: '',
        adfsClientID: '',
        adfsRedirectURI: '',
        adfsToken: '',
        adfsRefreshToken: ''
    })),
    on(REGISTRATION_ACTIONS.setADFSClientID, (state, { adfsClientID }) => ({
        ...state,
        adfsClientID
    })),
    on(
        REGISTRATION_ACTIONS.setADFSFederationServiceName,
        (state, { adfsFederationServiceName }) => ({
            ...state,
            adfsFederationServiceName
        })
    ),
    on(
        REGISTRATION_ACTIONS.setADFSRedirectURI,
        (state, { adfsRedirectURI }) => ({ ...state, adfsRedirectURI })
    ),
    on(
        REGISTRATION_ACTIONS.setADFSRefreshToken,
        (state, { adfsRefreshToken }) => ({ ...state, adfsRefreshToken })
    ),
    on(REGISTRATION_ACTIONS.setADFSResource, (state, { adfsResource }) => ({
        ...state,
        adfsResource
    })),
    on(REGISTRATION_ACTIONS.setADFSStateToken, (state, { adfsStateToken }) => ({
        ...state,
        adfsStateToken
    })),
    on(REGISTRATION_ACTIONS.setADFSToken, (state, { adfsToken }) => ({
        ...state,
        adfsToken
    })),

    on(REGISTRATION_ACTIONS.incomingCall, (state, { call }) => ({
        ...state,
        incomingCalls: { ...state.incomingCalls, [call.token]: call }
    })),
    on(REGISTRATION_ACTIONS.incomingCallCancelled, (state, { call }) => {
        const { [call.token]: _, ...newCalls } = state.incomingCalls;
        return { ...state, incomingCalls: newCalls };
    }),
    on(
        REGISTRATION_ACTIONS.incomingCallDialogOpened,
        (state, { token, id }) => ({
            ...state,
            incomingCallDialogs: { ...state.incomingCallDialogs, [token]: id }
        })
    ),
    on(REGISTRATION_ACTIONS.incomingCallDialogClosed, (state, { token }) => {
        const { [token]: _, ...newCallDialogs } = state.incomingCallDialogs;
        return { ...state, incomingCallDialogs: newCallDialogs };
    })
);

export function reducer(state: RegistrationState, action: Action) {
    return registrationReducer(state, action);
}

export const getRegistrationState = createFeatureSelector<RegistrationState>(
    'registration'
);
export const getRegistrationDetails = createSelector(
    getRegistrationState,
    ({ host, alias, username, password }) => ({
        host,
        alias,
        username,
        password
    })
);
export const areFormFieldsDisabled = createSelector(
    getRegistrationState,
    ({ registered, registering }) => registered || registering
);

export const getRegistrationType = createSelector(
    getRegistrationState,
    ({
        adfsFederationServiceName,
        adfsResource,
        adfsClientID,
        adfsRedirectURI
    }) =>
        adfsFederationServiceName &&
        adfsResource &&
        adfsClientID &&
        adfsRedirectURI
            ? 'adfs'
            : 'standard'
);

export const isRetrying = createSelector(
    getRegistrationState,
    ({ numFailures }) => numFailures > 0
);

export const getCloseSourceFn = createSelector(
    getRegistrationState,
    ({ closeSourceFn }) => closeSourceFn
);

export const getErrorLabel = createSelector(
    getRegistrationState,
    ({ errorLabel }) => errorLabel
);
