import { ApplicationSettingsModel } from '../storage/settings.model';
import { PexStorage } from './storage';

export class ApplicationSettingsStorage extends PexStorage<ApplicationSettingsModel> {
    readonly storageString = 'pexApplicationSettings';

    constructor(
        factory: new () => ApplicationSettingsModel,
        model: ApplicationSettingsModel
    ) {
        super(new factory(), model);
    }
}
