import {
    ChangeDetectorRef,
    Component,
    NgZone,
    OnDestroy,
    OnInit
} from '@angular/core';
import { filter, tap, withLatestFrom } from 'rxjs/operators';

import {
    TakeUntilDestroy,
    untilDestroyed
} from '../../take-until-destory.decorator';
import { ConferenceFacade } from './../../../conference/conference.facade';
import { MediaDeviceFacade } from './../../../media-device/media-device.facade';
import { MediaDeviceService } from './../../../media-device/media-device.service';

@TakeUntilDestroy()
@Component({
    selector: 'pex-talking-when-muted',
    template: `
        <pex-talking-when-muted-indicator
            [isTalkingWhenMuted]="isTalkingWhenMuted"
        ></pex-talking-when-muted-indicator>
    `
})
export class TalkingWhenMutedComponent implements OnInit, OnDestroy {
    readonly VOLUME_THRESHOLD = 35;
    readonly VISIBILITY_TIMEOUT = 3000;

    isTalkingWhenMuted: boolean;

    private lastMicActivityWhenMuted = 0;
    private lastMicActivityTimeout: number;

    constructor(
        private mediaDeviceFacade: MediaDeviceFacade,
        private mediaDeviceService: MediaDeviceService,
        private ngZone: NgZone,
        private conferenceFacade: ConferenceFacade,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.setupMediaListeners();
    }

    private setupMediaListeners() {
        this.mediaDeviceFacade.localMediaStream$
            .pipe(
                filter(userMediaStream => !!userMediaStream),
                untilDestroyed(this)
            )
            .subscribe(this.handleAudioInputVolume);
    }

    private handleAudioInputVolume = () => {
        if (!this.mediaDeviceService.audioInputVolume$) {
            return;
        }

        this.ngZone.runOutsideAngular(() => {
            this.mediaDeviceService.audioInputVolume$
                .pipe(
                    untilDestroyed(this),
                    withLatestFrom(this.conferenceFacade.isMicrophoneMuted$),
                    tap(([, isMuted]) => {
                        if (!isMuted && this.isTalkingWhenMuted !== false) {
                            this.isTalkingWhenMuted = false;
                            this.changeDetectorRef.detectChanges();
                        }
                    }),
                    filter(
                        ([volume, isMuted]) =>
                            volume > this.VOLUME_THRESHOLD && isMuted
                    )
                )
                .subscribe(() => {
                    if (this.shouldShowMicInfo) {
                        this.lastMicActivityWhenMuted = Date.now();
                        if (this.isTalkingWhenMuted !== true) {
                            this.isTalkingWhenMuted = true;
                            this.changeDetectorRef.detectChanges();
                        }

                        if (this.lastMicActivityTimeout) {
                            clearTimeout(this.lastMicActivityTimeout);
                        }
                        this.lastMicActivityTimeout = window.setTimeout(() => {
                            if (this.isTalkingWhenMuted !== false) {
                                this.isTalkingWhenMuted = false;
                                this.changeDetectorRef.detectChanges();
                            }
                        }, this.VISIBILITY_TIMEOUT);
                    }
                });
        });
    };

    private get shouldShowMicInfo() {
        return (
            Date.now() - this.lastMicActivityWhenMuted > this.VISIBILITY_TIMEOUT
        );
    }

    ngOnDestroy(): void {}
}
