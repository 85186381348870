<div class="pex-dialog-close">
    <button
        type="button"
        class="circle-close-button"
        (click)="close()"
        [attr.aria-label]="'CLOSE' | translate"
    >
        <svg class="circle-close-button-icon">
            <use xlink:href="icons.svg#x" />
        </svg>
    </button>
</div>
<div
    class="device-permission-dialog"
    *ngIf="dialogType === 'blocked' || dialogType === 'android_blocked'"
>
    <div class="pex-dialog-content-inner">
        <div class="pex-dialog-content-title" style="max-width: 260px">
            {{ 'DEVICE_PERMISSION_BLOCKED_DIALOG.TITLE' | translate }}
        </div>

        <div
            class="pex-dialog-content-message"
            style="max-width: 300px; margin: auto auto 40px auto"
            *ngIf="browserType === 'android'"
        >
            {{ 'DEVICE_PERMISSION_BLOCKED_DIALOG.ANDROID_MESSAGE' | translate }}
        </div>

        <div
            class="pex-dialog-content-message"
            style="max-width: 300px; margin: auto auto 40px auto"
            *ngIf="['safari', 'edge'].indexOf(browserType) > -1"
        >
            {{ 'DEVICE_PERMISSION_BLOCKED_DIALOG.MESSAGE_ONE' | translate }}
        </div>

        <div
            class="pex-dialog-content-message"
            style="max-width: 300px; margin: auto"
            *ngIf="['chrome', 'firefox'].indexOf(browserType) > -1"
        >
            {{ 'DEVICE_PERMISSION_BLOCKED_DIALOG.MESSAGE_ONE' | translate }}
        </div>
        <div
            class="pex-dialog-content-message"
            style="max-width: 300px; margin: auto; margin-bottom: 40px"
            *ngIf="['chrome', 'firefox'].indexOf(browserType) > -1"
        >
            {{ 'DEVICE_PERMISSION_BLOCKED_DIALOG.MESSAGE_TWO' | translate }}
            <img
                *ngIf="browserType === 'chrome'"
                src="assets/blocked_chrome.png"
                style="width: 16px; display: inline; margin: 0 4px"
            />
            <svg
                *ngIf="browserType === 'firefox'"
                style="margin: 0 2px; height: 24px; width: 24px"
            >
                <use xlink:href="icons.svg#info" /></svg
            >{{ 'DEVICE_PERMISSION_BLOCKED_DIALOG.MESSAGE_THREE' | translate }}
        </div>

        <div class="pex-dialog-content-button">
            <button (click)="close()" class="dialog-button green-action-button">
                {{ 'DEVICE_PERMISSION_BLOCKED_DIALOG.DISMISS' | translate }}
            </button>
        </div>
    </div>
</div>
<div class="device-permission-dialog" *ngIf="dialogType === 'timeout'">
    <div class="pex-dialog-content-inner">
        <div class="pex-dialog-content-title" style="max-width: 260px">
            {{ 'DEVICE_PERMISSION_TIMEOUT_DIALOG.TITLE' | translate }}
        </div>

        <div
            class="pex-dialog-content-message"
            style="max-width: 300px; margin: auto"
        >
            {{ 'DEVICE_PERMISSION_TIMEOUT_DIALOG.MESSAGE_ONE' | translate }}
        </div>
        <div
            class="pex-dialog-content-message"
            style="max-width: 300px; margin: auto; margin-bottom: 40px"
        >
            {{ 'DEVICE_PERMISSION_TIMEOUT_DIALOG.MESSAGE_TWO' | translate }}
        </div>

        <div class="pex-dialog-content-button">
            <button (click)="close()" class="dialog-button green-action-button">
                {{ 'OK' | translate }}
            </button>
        </div>
    </div>
</div>
<div class="device-permission-dialog" *ngIf="dialogType === 'camera_blocked'">
    <div class="pex-dialog-content-inner">
        <div
            class="pex-dialog-content-title"
            [innerHTML]="
                'DEVICE_PERMISSION_CAMERA_BLOCKED_DIALOG.TITLE' | translate
            "
            style="max-width: 300px"
        ></div>
        <div
            class="pex-dialog-content-message"
            [innerHTML]="
                'DEVICE_PERMISSION_CAMERA_BLOCKED_DIALOG.MESSAGE' | translate
            "
            style="max-width: 300px; margin: auto; margin-bottom: 40px"
        ></div>

        <div class="pex-dialog-content-button">
            <button (click)="close()" class="dialog-button green-action-button">
                {{ 'OK' | translate }}
            </button>
        </div>
    </div>
</div>
<div class="device-permission-dialog" *ngIf="dialogType === 'other'">
    <div class="pex-dialog-content-inner">
        <div
            class="pex-dialog-content-title"
            [innerHTML]="'DEVICE_PERMISSION_OTHER_DIALOG.TITLE' | translate"
            style="max-width: 300px"
        ></div>
        <div
            class="pex-dialog-content-message"
            [innerHTML]="'DEVICE_PERMISSION_OTHER_DIALOG.MESSAGE' | translate"
            style="max-width: 300px; margin: auto; margin-bottom: 40px"
        ></div>

        <div class="pex-dialog-content-button">
            <button (click)="close()" class="dialog-button green-action-button">
                {{ 'OK' | translate }}
            </button>
        </div>
    </div>
</div>
