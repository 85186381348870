import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { MEDIA_DEVICE_ACTIONS } from './media-device.actions';
import { MediaDeviceState } from './media-device.reducer';
import {
    getAudioInputDevice,
    getAudioOutputDevice,
    getCurrentConferenceLocalMediaStream,
    getCurrentConferenceLocalMediaStreamHasVideoTracks,
    getFeccSupported,
    getInputDevices,
    getIsFacingCamera,
    getIsNoneCamera,
    getIsNoneDevice,
    getIsNoneMicrophone,
    getLocalMediaStream,
    getMediaDeviceState,
    getMediaTypeRequest,
    getMutedByUser,
    getSlowGUMCall,
    getStreamConstraints,
    getVideoInputDevice
} from './media-device.selectors';

@Injectable({ providedIn: 'root' })
export class MediaDeviceFacade {
    audioOutputDevice$ = this.store.select(getAudioOutputDevice);
    videoInputDevice$ = this.store.select(getVideoInputDevice);
    audioInputDevice$ = this.store.select(getAudioInputDevice);
    inputDevices$ = this.store.select(getInputDevices);
    isFacingCamera$ = this.store.select(getIsFacingCamera);
    isNoneCamera$ = this.store.select(getIsNoneCamera);
    isNoneMicrophone$ = this.store.select(getIsNoneMicrophone);
    isNoneDevice$ = this.store.select(getIsNoneDevice);
    localMediaStream$ = this.store.select(getLocalMediaStream);
    currentConferenceLocalMediaStream$ = this.store.select(
        getCurrentConferenceLocalMediaStream
    );
    currentConferenceLocalMediaStreamHasVideoTracks$ = this.store.select(
        getCurrentConferenceLocalMediaStreamHasVideoTracks
    );
    mediaDeviceState$ = this.store.select(getMediaDeviceState);
    streamConstraints$ = this.store.select(getStreamConstraints);
    mediaTypeRequest$ = this.store.select(getMediaTypeRequest);
    mutedByUser$ = this.store.select(getMutedByUser);
    feccSupported$ = this.store.select(getFeccSupported);
    slowGUMCall$ = this.store.select(getSlowGUMCall);

    constructor(private store: Store<MediaDeviceState>) {}

    setMediaInputDevices(
        video: MediaDeviceInfo,
        audio: MediaDeviceInfo,
        mutedByUser?: boolean,
        store = true
    ) {
        this.store.dispatch(
            MEDIA_DEVICE_ACTIONS.setMediaInputDevicesAction({
                audio,
                video,
                mutedByUser,
                store
            })
        );
    }

    setVideoInputDevice(
        device: MediaDeviceInfo,
        mutedByUser?: boolean,
        store = true
    ) {
        this.store.dispatch(
            MEDIA_DEVICE_ACTIONS.setVideoInputDeviceAction({
                device,
                mutedByUser,
                store
            })
        );
    }

    setAudioInputDevice(
        device: MediaDeviceInfo,
        mutedByUser?: boolean,
        store = true
    ) {
        this.store.dispatch(
            MEDIA_DEVICE_ACTIONS.setAudioInputDeviceAction({
                device,
                mutedByUser,
                store
            })
        );
    }

    setAudioOutputDevice(device: MediaDeviceInfo) {
        this.store.dispatch(
            MEDIA_DEVICE_ACTIONS.setAudioOutputDeviceAction({ device })
        );
    }

    audioOutputDeviceChanged(device: MediaDeviceInfo) {
        this.store.dispatch(
            MEDIA_DEVICE_ACTIONS.audioOutputDeviceChangedAction({ device })
        );
    }

    setLocalMediaStream(streamAndConstraints: {
        stream: MediaStream;
        audioDeviceID: MediaDeviceInfo;
        videoDeviceID: MediaDeviceInfo;
        streamType: string;
        constraints: string;
    }) {
        this.store.dispatch(
            MEDIA_DEVICE_ACTIONS.setLocalMediaStreamAction(streamAndConstraints)
        );
    }

    setStreamConstraints(constraints: {
        audioDeviceID: MediaDeviceInfo;
        videoDeviceID: MediaDeviceInfo;
        callType: string;
    }) {
        this.store.dispatch(
            MEDIA_DEVICE_ACTIONS.setStreamConstraintsAction(constraints)
        );
    }

    setCurrentConferencelocalMediaStream(stream: MediaStream) {
        this.store.dispatch(
            MEDIA_DEVICE_ACTIONS.setCurrentConferenceLocalMediaStream({
                stream
            })
        );
    }

    setMediaTypeRequest(callType: CallType) {
        this.store.dispatch(
            MEDIA_DEVICE_ACTIONS.setMediaTypeRequest({ callType })
        );
    }

    setFeccSupported(feccSupported: boolean) {
        this.store.dispatch(
            MEDIA_DEVICE_ACTIONS.setFeccSupported({ feccSupported })
        );
    }

    setSlowGUMCall(isSlow: boolean) {
        this.store.dispatch(MEDIA_DEVICE_ACTIONS.setSlowGUMCall({ isSlow }));
    }
}
