import { BehaviorSubject } from 'rxjs';

import { UserSettingsModel } from '../storage/settings.model';
import { PexDefaultStorage } from './storage';

export class UserSettingsStorage extends PexDefaultStorage<UserSettingsModel> {
    callType$: BehaviorSubject<string>;

    constructor(
        userSettingModelFactory: new () => UserSettingsModel,
        values: Partial<UserSettingsModel>,
        defaults: Partial<UserSettingsModel>
    ) {
        super(
            new userSettingModelFactory(),
            values,
            defaults,
            'pexUserSettings'
        );
        this.callType$ = new BehaviorSubject(this.callType);
    }

    get callType() {
        if (this.values.muteMicrophone && this.values.muteCamera) {
            return 'none';
        }
        if (this.values.muteCamera) {
            return 'audioonly';
        }
        return 'video';
    }

    setItem<K extends keyof UserSettingsModel>(
        key: K,
        value: UserSettingsModel[K]
    ) {
        const prevCallType = this.callType;
        super.setItem(key, value);
        const newCallType = this.callType;

        if (newCallType !== prevCallType) {
            this.callType$.next(newCallType);
        }
    }
}
