<form
    class="pex-dialog-content-inner"
    [formGroup]="displayNameForm"
    (submit)="onSubmit()"
>
    <div class="pex-dialog-content-title">
        {{ 'CONFIGURATION.EDIT_DISPLAY_NAME.TITLE' | translate }}
    </div>
    <div class="pex-dialog-content-message pex-dialog-content-message--medium">
        <input
            id="display-name-dialog-input"
            required
            class="pex-text-input pex-text-input--big"
            formControlName="displayName"
            [attr.aria-label]="
                'CONFIGURATION.EDIT_DISPLAY_NAME.PLACEHOLDER' | translate
            "
            [placeholder]="
                'CONFIGURATION.EDIT_DISPLAY_NAME.PLACEHOLDER' | translate
            "
            autofocus
        />
    </div>
    <div class="pex-dialog-content-button">
        <button
            id="display-name-dialog-ok"
            class="dialog-button blue-action-button"
            type="submit"
            [class.gray-action-button]="!displayNameForm.valid"
            [disabled]="!displayNameForm.valid"
        >
            {{ 'OK' | translate }}
        </button>
    </div>
</form>
