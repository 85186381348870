import { Injectable } from '@angular/core';

import { LoggingService } from '../core/logging.service';
import { ClipboardService } from '../core/services';
import { Log, Logger } from '../core/shared/logger';

@Injectable()
export class WebLoggingService extends LoggingService {
    constructor(clipboardService: ClipboardService) {
        super(clipboardService);
    }

    storeLog(_log: string) {}

    resetLast() {}

    retrieveLogs() {
        return Promise.resolve<[string, string]>(['', '']);
    }

    copyFileLogsToClipboard() {
        this.clipboardService.copy(Logger.recentLogs.join('\n'));
    }
}
