export function browserSupportsPtzConstraints(): boolean {
    const browserSupports = navigator.mediaDevices.getSupportedConstraints();
    if (
        'pan' in browserSupports &&
        'tilt' in browserSupports &&
        'zoom' in browserSupports
    ) {
        return true;
    }
    return false;
}

export function addPtzToVideoConstraints(
    supportedContraints: MediaTrackSupportedConstraints,
    videoConstraints: MediaTrackConstraints
) {
    if (
        'pan' in supportedContraints &&
        'tilt' in supportedContraints &&
        'zoom' in supportedContraints
    ) {
        videoConstraints['pan'] = true;
        videoConstraints['tilt'] = true;
        videoConstraints['zoom'] = true;
    }
}

export function getPtzPermissionStatus() {
    return !navigator.permissions
        ? null
        : navigator.permissions
              .query(({
                  name: 'camera',
                  panTiltZoom: true
              } as unknown) as PermissionDescriptor)
              .catch(e => {});
}

export function getVideoTrackCapabilities(
    videoTrack: MediaStreamTrack
): MediaTrackCapabilities {
    if (!videoTrack) {
        return;
    }
    return videoTrack.getCapabilities?.();
}

export function isVideoTrackWithPtzCapabilities(
    mediaStream: MediaStream
): boolean {
    const [videoTrack] = mediaStream.getVideoTracks();
    const capabilities = videoTrack?.getCapabilities?.() ?? {};
    return (
        'pan' in capabilities ||
        'tilt' in capabilities ||
        'zoom' in capabilities
    );
}
