import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';

@Component({
    selector: 'pex-context-menu',
    templateUrl: './context-menu.component.html',
    styleUrls: ['./context-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContextMenuComponent {
    _top: number;
    _left: number;
    _right: number;
    _bottom: number;
    _class: string;
    _isCenter: boolean;
    _elements: HTMLElement[];
    _lastIndex: number;

    @Input() templateRefInput: TemplateRef<unknown>;
    @Input() context: unknown;
    @ViewChild(TemplateRef, { static: false })
    templateRefChild: TemplateRef<unknown>;
    @ViewChild('contextMenuContent', { static: false })
    contextMenuContent: ElementRef;
    @Output() close$ = new EventEmitter<void>();

    get templateRef() {
        return this.templateRefInput || this.templateRefChild;
    }

    @HostListener('window:resize')
    onResize() {
        this.close$.emit();
    }

    constructor(
        private _viewContainer: ViewContainerRef,
        private _changeDetectorRef: ChangeDetectorRef
    ) {}

    onClickOutside(event: Event) {
        event.stopPropagation();
        this.close$.emit();
    }

    public open(element: HTMLElement) {
        this._changeDetectorRef.detectChanges();
        const contextMenuHeight = this.contextMenuContent.nativeElement.getBoundingClientRect()
            .height;
        const elementRect = element.getBoundingClientRect();
        const spaceBelowElement = window.innerHeight - elementRect.bottom;
        const spaceAboveElement = elementRect.top;
        if (spaceBelowElement >= spaceAboveElement) {
            this.openBelow(element, contextMenuHeight, spaceBelowElement);
        } else {
            this.openAbove(element, contextMenuHeight, spaceAboveElement);
        }
        this._changeDetectorRef.detectChanges();
    }

    public openBelow(
        element: HTMLElement,
        contextMenuHeight: number,
        space: number
    ) {
        const elementRect = element.getBoundingClientRect();
        this._top = elementRect.bottom + 2;
        this._left = elementRect.left + elementRect.width / 2;
        this._bottom = null;
        this._class =
            'pex-context-menu pex-context-menu-content--arrow-top-center';
        this._isCenter = true;

        if (space < contextMenuHeight) {
            this._top = null;
            this._bottom = 0;
            this._class = 'pex-context-menu';
        }
    }

    public openBelowLeft(element: HTMLElement, focus = false) {
        const borderOffset = 12;
        const targetBounds = element.getBoundingClientRect();
        this._top = targetBounds.bottom + 2;
        this._left = targetBounds.left + targetBounds.width / 2 - borderOffset;
        this._class =
            'pex-context-menu pex-context-menu-content--arrow-top-left';
        this._isCenter = false;

        if (focus) {
            this._changeDetectorRef.detectChanges();
            this.contextMenuContent.nativeElement.focus();
        }
    }

    public openBelowRight(element: HTMLElement) {
        const borderOffset = 12;
        const targetBounds = element.getBoundingClientRect();
        this._top = targetBounds.bottom + 2;
        this._right =
            window.innerWidth -
            targetBounds.right +
            targetBounds.width / 2 -
            borderOffset;
        this._class =
            'pex-context-menu pex-context-menu-content--arrow-top-right';
        this._isCenter = false;
    }

    public openAbove(
        element: HTMLElement,
        contextMenuHeight: number,
        space: number
    ) {
        const elementRect = element.getBoundingClientRect();
        this._bottom = window.innerHeight - elementRect.top + 2;
        this._left = elementRect.left + elementRect.width / 2;
        this._top = null;
        this._class =
            'pex-context-menu pex-context-menu-content--arrow-bottom-center';
        this._isCenter = true;

        if (space < contextMenuHeight) {
            this._bottom = null;
            this._top = 0;
            this._class = 'pex-context-menu';
        }
    }

    public openAboveRight(element: HTMLElement) {
        const borderOffset = 12;
        const targetBounds = element.getBoundingClientRect();
        this._bottom = window.innerHeight - targetBounds.top + 2;
        this._right =
            window.innerWidth -
            targetBounds.right +
            targetBounds.width / 2 -
            borderOffset;
        this._class =
            'pex-context-menu pex-context-menu-content--arrow-bottom-right';
        this._isCenter = false;
    }

    public close() {
        this._viewContainer.clear();
    }

    getElements(): HTMLElement[] {
        return Array.from(
            this.contextMenuContent.nativeElement.querySelectorAll(
                '.pex-context-menu-item, pex-context-menu-item'
            )
        );
    }

    getLastIndex() {
        return this._elements.findIndex(
            (element: HTMLElement) => element === document.activeElement
        );
    }

    @HostListener('document:keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        const keycode = event.keyCode || event.which;
        const arrowUp = 38;
        const arrowDown = 40;

        if (keycode !== arrowUp && keycode !== arrowDown) return;

        this._changeDetectorRef.detectChanges();
        if (this.contextMenuContent) {
            this._elements = this.getElements();
            this._lastIndex = this.getLastIndex();

            if (keycode === arrowUp) {
                this._lastIndex <= 0 || this._lastIndex === -1
                    ? this._elements[this._elements.length - 1].focus()
                    : this._elements[this._lastIndex - 1].focus();
            } else {
                this._lastIndex >= this._elements.length - 1 ||
                this._lastIndex === -1
                    ? this._elements[0].focus()
                    : this._elements[this._lastIndex + 1].focus();
            }
        }
    }
}
