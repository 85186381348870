import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ContextMenuItemComponent } from './context-menu-item.component';
import { ContextMenuComponent } from './context-menu.component';
import { ContextMenuDirective } from './context-menu.directive';

@NgModule({
    imports: [CommonModule],
    providers: [],
    declarations: [
        ContextMenuComponent,
        ContextMenuItemComponent,
        ContextMenuDirective
    ],
    exports: [
        ContextMenuComponent,
        ContextMenuItemComponent,
        ContextMenuDirective
    ]
})
export class ContextMenuModule {}
