import { Component } from '@angular/core';

import { BaseDialogContainerComponent } from './base-dialog-container.component';
import { BaseDialogContentComponent } from './base-dialog-content.component';

@Component({
    selector: 'pex-floating-dialog-container',
    templateUrl: './floating-dialog-container.component.html',
    styleUrls: ['./floating-dialog-container.component.scss']
})
export class FloatingDialogContainerComponent<
    S extends BaseDialogContentComponent
> extends BaseDialogContainerComponent<S> {
    readonly type = 'floating';
}
