import { Component } from '@angular/core';

import { BaseDialogContentComponent } from '../../dialog/base-dialog-content.component';

interface BrandingDetails {
    isSettingsBranded?: boolean;
    isWatermarkBranded?: boolean;
    isStylesBranded?: boolean;
}

@Component({
    templateUrl: './branding-dialog.component.html',
    styleUrls: ['./branding-dialog.component.scss']
})
export class BrandingApplyDialogComponent extends BaseDialogContentComponent<
    boolean,
    BrandingDetails
> {}
