import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Injector
} from '@angular/core';

import { BaseDialogContainerComponent } from '../base-dialog-container.component';
import { BaseDialogContentComponent } from '../base-dialog-content.component';

@Component({
    selector: 'pex-card-dialog-container',
    templateUrl: './card-dialog-container.component.html',
    styleUrls: ['./card-dialog-container.component.scss']
})
export class CardDialogContainerComponent<S extends BaseDialogContentComponent>
    extends BaseDialogContainerComponent<S>
    implements AfterViewInit {
    readonly type = 'card';
    isOpen = false;
    changeDetectionRef: ChangeDetectorRef;

    constructor(injector: Injector, changeDetectionRef: ChangeDetectorRef) {
        super(injector);
        this.changeDetectionRef = changeDetectionRef;
    }

    ngAfterViewInit() {
        const timeoutMS = 50;
        setTimeout(() => {
            this.isOpen = true;
            this.changeDetectionRef.detectChanges();
        }, timeoutMS);
    }

    transitionEnded() {
        if (!this.isOpen) {
            this.destroy();
        }
    }

    protected doClose() {
        this.isOpen = false;
    }
}
