import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { State } from '../../app.reducer';
import { HOME_ACTIONS } from '../../home/home.actions';
import { MySpaceDatabaseService } from '../../services/my-space.service';
import { BaseDialogContentComponent } from '../base-dialog-content.component';
import { ScreenStateEnum } from './../../home/offset-overlay/screen-state.type';
import { StorageService } from './../../services/storage/storage.service';

export enum CardDialogType {
    INVITATION = 'invitation',
    INCOMING_CALL = 'incoming_call'
}

export interface CardDialogConfig {
    alias?: string;
    type?: CardDialogType;
    oneTimeToken?: string;
}

@Component({
    templateUrl: './card-dialog.component.html',
    styleUrls: ['./card-dialog.component.scss']
})
export class CardDialogComponent
    extends BaseDialogContentComponent<string, CardDialogConfig>
    implements OnInit {
    constructor(
        private storageService: StorageService,
        private store$: Store<State>,
        public mySpaceDatabaseService: MySpaceDatabaseService
    ) {
        super();
    }

    ngOnInit(): void {}

    setMuteCamera(muteCamera: boolean) {
        this.storageService.userSettingsProxy.muteCamera = muteCamera;
    }

    openSpaces() {
        this.mySpaceDatabaseService.isSpacesOpen = true;
        this.store$.dispatch(
            HOME_ACTIONS.setScreenAction({ screen: ScreenStateEnum.SEARCH })
        );
    }

    get muteCamera(): boolean {
        return this.storageService.userSettingsProxy.muteCamera;
    }

    get isIncomingCall() {
        return this.safeConfig.type === CardDialogType.INCOMING_CALL;
    }

    get isInvitation() {
        return !this.isIncomingCall;
    }

    get playRingtone() {
        return this.storageService.userSettingsProxy.playRingtone;
    }
}
