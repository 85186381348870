import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, timeout } from 'rxjs/operators';

import { PlatformService } from '../../platform.service';
import { __REGISTRATION_CONSTANTS__ } from '../../registration/registration.constants';
import { RegistrationFacade } from '../../registration/registration.facade';

@Injectable({
    providedIn: 'root'
})
export class GatewayCallService {
    constructor(
        private ngZone: NgZone,
        public platformService: PlatformService,
        private registrationFacade: RegistrationFacade
    ) {}

    openEventSource(
        host: string,
        alias: string,
        token: string,
        time: number = __REGISTRATION_CONSTANTS__.REQUEST_TOKEN_TIMEOUT
    ) {
        const eventSourceUrl = `https://${host}/api/client/v2/registrations/${alias}/events?token=${token}`;
        return new Observable<EventSource>(observer => {
            const eventSource = new EventSource(eventSourceUrl);

            eventSource.onopen = () => {
                eventSource.onopen = undefined;
                this.ngZone.run(() => {
                    observer.next(eventSource);
                    observer.complete();
                });
            };

            eventSource.onerror = (event: Event) => {
                this.ngZone.run(() => {
                    observer.error(event);
                });
            };
        }).pipe(
            timeout(time), // Timeout for opening the eventsource connection
            switchMap(eventSource => {
                return new Observable<() => void>(observer => {
                    eventSource.onerror = (event: Event) => {
                        this.ngZone.run(() => {
                            observer.error(event);
                        });
                    };

                    eventSource.addEventListener(
                        'incoming',
                        (event: MessageEvent) => {
                            this.ngZone.run(() => {
                                const data = JSON.parse(event.data);
                                this.registrationFacade.incomingCall(data);
                            });
                        }
                    );

                    eventSource.addEventListener(
                        'incoming_cancelled',
                        (event: MessageEvent) => {
                            this.ngZone.run(() => {
                                const token = JSON.parse(event.data).token;
                                this.registrationFacade.incomingCallCancelled(
                                    token
                                );
                            });
                        }
                    );
                    observer.next(() => {
                        eventSource.close();
                    });
                });
            })
        );
    }
}
