import { Injector } from '@angular/core';

/**
 * Allows for retrieving singletons using `AppInjector.get(MyService)` (whereas
 * `ReflectiveInjector.resolveAndCreate(MyService)` would create a new instance
 * of the service).
 */
// tslint:disable-next-line:variable-name
export let AppInjector: Injector;

export function setAppInjector(injector: Injector) {
    if (AppInjector) {
        // Should not happen
        // tslint:disable-next-line:no-console
        console.error('Programming error: AppInjector was already set');
    } else {
        AppInjector = injector;
    }
}
