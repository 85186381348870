import { createFeatureSelector, createSelector } from '@ngrx/store';

import { MediaDeviceState } from './media-device.reducer';

export const getMediaDeviceState = createFeatureSelector<MediaDeviceState>(
    'mediaDevices'
);

export const getLocalMediaStream = createSelector(
    getMediaDeviceState,
    (state: MediaDeviceState) => state.localMediaStream
);

export const getCurrentConferenceLocalMediaStream = createSelector(
    getMediaDeviceState,
    (state: MediaDeviceState) => state.currentConferenceLocalMediaStream
);

export const getStreamConstraints = createSelector(
    getMediaDeviceState,
    (state: MediaDeviceState) => {
        return {
            audioDeviceID: state.streamAudioDeviceID,
            videoDeviceID: state.streamVideoDeviceID,
            callType: state.streamCallType,
            constraints: state.constraints
        };
    }
);

export const getInputDevices = createSelector(
    getMediaDeviceState,
    (state: MediaDeviceState) => state.inputDevices
);

export const getVideoInputDevice = createSelector(
    getMediaDeviceState,
    (state: MediaDeviceState) => state.inputDevices.videoInputDevice
);

export const getAudioInputDevice = createSelector(
    getMediaDeviceState,
    (state: MediaDeviceState) => state.inputDevices.audioInputDevice
);

export const getAudioOutputDevice = createSelector(
    getMediaDeviceState,
    (state: MediaDeviceState) => state.audioOutputDevice
);

export const getIsNoneCamera = createSelector(
    getMediaDeviceState,
    (state: MediaDeviceState) =>
        state.inputDevices.videoInputDevice &&
        state.inputDevices.videoInputDevice.deviceId === 'none'
);

export const getIsNoneMicrophone = createSelector(
    getMediaDeviceState,
    (state: MediaDeviceState) =>
        state.inputDevices.audioInputDevice &&
        state.inputDevices.audioInputDevice.deviceId === 'none'
);

export const getIsNoneDevice = createSelector(
    getIsNoneCamera,
    getIsNoneMicrophone,
    (isNoneCamera, isNoneMicrophone) => isNoneCamera || isNoneMicrophone
);

export const getIsFacingCamera = createSelector(
    getVideoInputDevice,
    (videoInputDevice: MediaDeviceInfo) =>
        videoInputDevice && videoInputDevice.deviceId === 'user'
);

export const getCurrentConferenceLocalMediaStreamHasVideoTracks = createSelector(
    getCurrentConferenceLocalMediaStream,
    (localMediaStream: MediaStream) =>
        localMediaStream && localMediaStream.getVideoTracks().length > 0
);

export const getMediaTypeRequest = createSelector(
    getMediaDeviceState,
    (state: MediaDeviceState) => state.mediaTypeRequest
);

export const getMutedByUser = createSelector(
    getMediaDeviceState,
    (state: MediaDeviceState) => state.mutedByUser
);

export const getFeccSupported = createSelector(
    getMediaDeviceState,
    (state: MediaDeviceState) => state.feccSupported
);

export const getSlowGUMCall = createSelector(
    getMediaDeviceState,
    (state: MediaDeviceState) => state.slowGUMCall
);
