import { ParticipantActions } from 'src/core/participant/participant.actions';
import { ConferenceActions } from '../conference.actions';

export class TimelineEvent {
    xPos = 1;
    hide = false;

    constructor(
        public action: ConferenceActions | ParticipantActions,
        public presentationActive?: boolean,
        public timestamp = Date.now(),
        public maxShowLength = __CONSTANTS__.MAX_MESSAGE_LENGTH
    ) {}
}

export class PexRange {
    constructor(public start = Date.now(), public end?: number) {}

    get unfinished() {
        return this.end === undefined;
    }

    isTimeInside(time: number) {
        return time >= this.start && (!this.end || time <= this.end);
    }

    coincides(range: PexRange) {
        const end1 = this.unfinished ? Number.MAX_VALUE : this.end;
        const end2 =
            range.unfinished || range.unfinished === undefined
                ? Number.MAX_VALUE
                : range.end;
        return this.start <= end2 && end1 >= range.start;
    }

    getLength() {
        if (this.unfinished) {
            return Date.now() - this.start;
        }
        return this.end - this.start;
    }
}

export interface Frame {
    time: number;
    img: string;
}
export class PexPresentation extends PexRange {
    frames: Frame[] = [];

    constructor(
        public presenterUUID?: string,
        public isIncoming = false,
        start?: number,
        end?: number,
        frames?: Frame[]
    ) {
        super(start, end);
        if (frames) {
            this.frames = frames;
        }
    }
}

export class PexLock extends PexRange {}

export const colors = {
    '[Participant] Connect Success': 'color-green',
    '[Participant] Disconnect Success': 'color-red',
    '[Conference] Incoming Presentation Changed': 'color-blue',
    '[Conference] Slide share Started': 'color-blue',
    '[Conference] Screen share Started': 'color-blue',
    '[Conference] Presenting Stopped': 'color-blue',
    '[Conference] Receive Chat Message': 'color-grey',
    '[Conference] Send Chat Message': 'color-grey',
    '[Conference] Muted Changed': 'color-orange',
    '[Conference] Locked Changed': 'color-orange',
    '[Conference] Connect Success': 'color-teal',
    '[Conference] Transfer Success': 'color-teal'
};
