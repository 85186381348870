import {
    Component,
    ComponentFactory,
    EventEmitter,
    Injector,
    Output,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import {
    BaseDialogContentComponent,
    DialogConfigType,
    DialogReturnType
} from './base-dialog-content.component';

@Component({
    template: ''
})
export class BaseDialogContainerComponent<
    S extends BaseDialogContentComponent = BaseDialogContentComponent
> {
    @ViewChild('content', { read: ViewContainerRef, static: true })
    contentViewContainerRef: ViewContainerRef;

    public priority: number;
    public id: string;
    private destroyFunction: () => void;
    private rootNode: HTMLElement;

    contentComponent: S;

    readonly type: string;

    @Output() viewInit = new EventEmitter();

    constructor(private injector: Injector) {}

    init(
        id: string,
        destroyFunction: () => void,
        rootNode: HTMLElement,
        priority = 0
    ) {
        this.id = id;
        this.destroyFunction = destroyFunction;
        this.rootNode = rootNode;
        this.priority = priority;

        document.addEventListener('keydown', this._onKeyDown, true);
        this.afterInit();
    }

    protected afterInit() {}

    addContent(
        contentFactory: ComponentFactory<S>,
        config?: DialogConfigType<S>
    ) {
        this.contentComponent = this.contentViewContainerRef.createComponent(
            contentFactory,
            null,
            this.injector
        ).instance;
        this.contentComponent.init(this.destroyFunction, config);
        this.contentComponent.viewInit
            .pipe(take(1))
            .subscribe(() => this.viewInit.emit());
        this.close$.subscribe(() => {
            this.doClose();
        });
    }

    protected doClose() {
        this.destroy();
    }

    close() {
        this.contentComponent.close();
    }

    destroy() {
        document.removeEventListener('keydown', this._onKeyDown, true);
        this.destroyFunction();
    }

    get close$() {
        return (
            this.contentComponent &&
            (this.contentComponent.close$ as Subject<DialogReturnType<S>>)
        );
    }

    public clickOutside(e: MouseEvent) {
        this.contentComponent.clickOutside(e);
        e.stopPropagation();
    }

    public escapePressed(e: KeyboardEvent) {
        return this.contentComponent.escapePressed(e);
    }

    private _getElements() {
        return this.rootNode.querySelectorAll<HTMLElement>(
            '[aria-label]:not(:disabled), [type="submit"]:not(:disabled), [role=button]:not(:disabled), button:not(:disabled), input:not(:disabled), select:not(:disabled), textarea:not(:disabled)'
        );
    }

    private _onKeyDown = (event: KeyboardEvent) => {
        const elements = this._getElements();

        function handleBackwardTab() {
            if (document.activeElement === elements[0]) {
                event.preventDefault();
                elements[elements.length - 1].focus();
            }
        }
        function handleForwardTab() {
            if (document.activeElement === elements[elements.length - 1]) {
                event.preventDefault();
                elements[0].focus();
            }
        }

        const keyTab = 9;

        switch (event.keyCode) {
            case keyTab:
                if (elements.length === 1) {
                    event.preventDefault();
                    break;
                }

                if (event.shiftKey) {
                    handleBackwardTab();
                } else {
                    handleForwardTab();
                }

                break;
            default:
                break;
        }
    };
}
