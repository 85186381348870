import { AbstractControl, ValidationErrors } from '@angular/forms';

function isEmptyInputValue(value: string): boolean {
    return !value || value.trim().length === 0;
}

export class CustomValidators {
    static required(control: AbstractControl): ValidationErrors | null {
        return isEmptyInputValue(control.value) ? { required: true } : null;
    }
}
