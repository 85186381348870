import { Subject } from 'rxjs';

interface MapField {
    [key: string]: MapField | Function;
}

export type LogLevel = 'Info' | 'Warn' | 'Error';

export class Log {
    message: string;
    objects: string;
    level: LogLevel;
    timestamp: string;

    get style() {
        let logStyle: string;
        switch (this.level) {
            case 'Info':
                logStyle = 'color: #4286f4; font-weight: bold';
                break;

            case 'Warn':
                logStyle = 'color: #e0a12c; font-weight: bold';
                break;

            case 'Error':
                logStyle = 'color: #e02b2b; font-weight: bold';
                break;

            default:
                logStyle = '';
                break;
        }
        return logStyle;
    }

    constructor(
        message: string,
        level: LogLevel,
        objects?: string,
        timestamp?: string
    ) {
        this.message = message;
        this.objects = objects;
        this.level = level;

        if (!timestamp) {
            const now = new Date();
            now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
            const startOfTimeIndex = 11;
            const endOfTimeIndex = 23;
            timestamp = now
                .toISOString()
                .slice(startOfTimeIndex, endOfTimeIndex);
        }
        this.timestamp = timestamp;
    }

    public toString() {
        return `${this.level} ${this.timestamp} : ${this.message} ${this.objects}`;
    }

    public toConsoleString() {
        return `%c${this.level}%c ${this.timestamp} : ${this.message}`;
    }

    // public static fromString(logString: string) {
    //     const endOfTimeIndex = 12;
    //     const startOfMessageIndex = logString.indexOf(': ') + 2;
    //     //Timestamp is fixed length so easy to get
    //     const timestamp = logString.slice(0, endOfTimeIndex);
    //     //Get level from end of timestamp until level/message separator
    //     const level = logString.slice(endOfTimeIndex + 1, startOfMessageIndex - 2) as LogLevel;
    //     //Get message from separator onwards
    //     const message = logString.slice(startOfMessageIndex);
    //     return new Log(message, level, timestamp);
    // }
}

export namespace Logger {
    export const recentLogs: Log[] = [];
    const storeLimit = 1000;
    export const log$ = new Subject<Log>();
    export let mappedFields: MapField = {};
    export let simpleLogs = false;

    //tslint:disable-next-line:no-any
    export function info(message: string, ...objects: any[]) {
        log(message, 'Info', ...objects);
    }

    //tslint:disable-next-line:no-any
    export function warn(message: string, ...objects: any[]) {
        log(message, 'Warn', ...objects);
    }

    //tslint:disable-next-line:no-any
    export function error(message: string, ...objects: any[]) {
        //prompt user to send logs to us?
        log(message, 'Error', ...objects);
    }

    //tslint:disable-next-line:no-any
    export function log(message: string, level: LogLevel, ...objects: any[]) {
        let objectString = '';
        if (objects && objects.length > 0) {
            const strings = objects.map(object => {
                return safeStringify(mapFields(object));
            });
            if (objects.length > 0) {
                objectString = '\n' + strings.join('\n\n');
            }
        }
        sendLog(new Log(message, level, objectString), ...[...objects]);
    }

    //tslint:disable-next-line:no-any
    export function safeStringify(object: { [key: string]: any } | string) {
        let objString = object;
        if (typeof object === 'object') {
            try {
                objString = JSON.stringify(object, null, 2);
            } catch {
                objString =
                    object.longStack ||
                    object.stack ||
                    object.message ||
                    'Could not stringify object';
            }
        }
        return <string>objString;
    }

    function mapFields(object: object, fields = mappedFields) {
        const isRecursive = fields !== mappedFields;

        if (!object) {
            return;
        }
        if (!isRecursive && typeof object !== 'object') {
            return object;
        }

        const copy = { ...object };
        for (const key in fields) {
            if (object.hasOwnProperty(key)) {
                const childFields = fields[key];
                const childObject = object[key];
                if (typeof childFields === 'function') {
                    copy[key] = childFields(childObject);
                } else {
                    copy[key] = mapFields(childObject, childFields);
                }
            }
        }
        return copy;
    }

    //tslint:disable-next-line:no-any
    function sendLog(log: Log, ...objects: any[]) {
        if (simpleLogs) {
            console.log(log.toString()); //tslint:disable-line:no-console
        } else {
            console.log(log.toConsoleString(), log.style, null, ...objects); //tslint:disable-line:no-console
        }
        recentLogs.push(log);
        if (recentLogs.length > storeLimit) recentLogs.shift();
        log$.next(log);
    }
}
