import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    Injector,
    ViewChild
} from '@angular/core';

import { BaseDialogContainerComponent } from '../base-dialog-container.component';
import { BaseDialogContentComponent } from '../base-dialog-content.component';

export class FlexContainerConfig {
    type: 'floating' | 'modal' = 'modal';
    style: 'opaque' | 'transparent' = 'opaque';
    width?: number;
    height?: number;
    startPosition:
        | 'center'
        | 'top-left'
        | 'top-right'
        | 'bottom-left'
        | 'bottom-right' = 'center';
}

@Component({
    selector: 'pex-flex-dialog-container',
    templateUrl: './flex-dialog-container.component.html',
    styleUrls: ['./flex-dialog-container.component.scss']
})
export class FlexDialogContainerComponent<S extends BaseDialogContentComponent>
    extends BaseDialogContainerComponent<S>
    implements AfterViewInit {
    readonly type = 'flex';
    @ViewChild('divElement', { static: false }) divElement: ElementRef;

    constructor(
        injector: Injector,
        @Inject('CONFIG') public config: FlexContainerConfig
    ) {
        super(injector);
    }

    ngAfterViewInit() {
        this.divElement.nativeElement.style.top =
            window.innerHeight / 2 -
            this.divElement.nativeElement.clientHeight / 2 +
            'px';
        if (
            window.innerWidth < 500 ||
            window.innerWidth < this.divElement.nativeElement.clientWidth
        ) {
            this.divElement.nativeElement.style.left = 0;
        } else {
            this.divElement.nativeElement.style.left =
                window.innerWidth / 2 -
                this.divElement.nativeElement.clientWidth / 2 +
                'px';
        }
    }
}
