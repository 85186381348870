import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    Actions,
    createEffect,
    ofType,
    ROOT_EFFECTS_INIT
} from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { EMPTY, from, interval, Observable, of, race } from 'rxjs';
import {
    catchError,
    combineLatest,
    delayWhen,
    filter,
    map,
    switchMap,
    take,
    takeUntil,
    tap,
    withLatestFrom
} from 'rxjs/operators';

import {
    ConnectionTypeEnum,
    ServiceType
} from '../conference/conference.model';
import { CardDialogComponent } from '../dialog/card-dialog/card-dialog.component';
import { DialogService } from '../dialog/dialog.service';
import { IncomingCallDialogComponent } from '../dialog/incoming-call-dialog';
import { LoggingService } from '../logging.service';
import { PlatformService } from '../platform.service';
import { AnalyticsService } from '../services/analytics.service';
import { HistoryDatabaseService } from '../services/history.service';
import { GatewayCallService } from '../services/registration/gateway-call.service';
import { AuthHeader } from '../services/registration/registration.model';
import { RegistrationService } from '../services/registration/registration.service';
import { StorageService } from '../services/storage/storage.service';
import { URLService } from '../services/url.service';
import { GENERAL_ACTIONS } from '../shared/general/general.actions';
import { SRVService } from '../srv.service';
import { REGISTRATION_ACTIONS } from './registration.actions';
import { __REGISTRATION_CONSTANTS__ } from './registration.constants';
import { RegistrationFacade } from './registration.facade';

function encodeUtf8(str: string) {
    return unescape(encodeURIComponent(str));
}

@Injectable()
export class RegistrationEffects {
    constructor(
        private actions$: Actions,
        private srvService: SRVService,
        private registrationService: RegistrationService,
        private registrationFacade: RegistrationFacade,
        private analyticsService: AnalyticsService,
        private platformService: PlatformService,
        private gatewayCallService: GatewayCallService,
        private storageService: StorageService,
        private router: Router,
        private dialogService: DialogService,
        private urlService: URLService,
        private historyDatabaseService: HistoryDatabaseService,
        private loggingService: LoggingService
    ) {}

    init$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROOT_EFFECTS_INIT),
            combineLatest(
                this.actions$.pipe(ofType(GENERAL_ACTIONS.initFinished))
            ),
            take(1),
            withLatestFrom(this.registrationFacade.state$),
            filter(([_, { shouldRegister }]) => shouldRegister),
            tap(() => {
                if (this.platformService.isElectron()) {
                    this.platformService.setUnregisteredTray();
                }
            }),
            map(() => REGISTRATION_ACTIONS.register())
        )
    );

    initPassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROOT_EFFECTS_INIT),
            filter(() => this.platformService.isElectron()),
            switchMap(() => from(window.pexBridge.getRegistrationPassword())),
            map(password => {
                return REGISTRATION_ACTIONS.initPassword({ password });
            })
        )
    );

    setPassword$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(REGISTRATION_ACTIONS.setPassword),
                filter(() => this.platformService.isElectron()),
                map(action => {
                    return window.pexBridge.setRegistrationPassword(
                        action.password
                    );
                })
            ),
        { dispatch: false }
    );

    register$ = createEffect(() =>
        this.actions$.pipe(
            ofType(REGISTRATION_ACTIONS.register),
            withLatestFrom(
                this.registrationFacade.getRegistrationType(),
                this.registrationFacade.state$
            ),
            map(([_, registrationType, { adfsToken, adfsRefreshToken }]) => {
                if (registrationType === 'adfs') {
                    if (!adfsToken) {
                        if (adfsRefreshToken) {
                            return REGISTRATION_ACTIONS.adfsTokenRefresh();
                        } else {
                            return REGISTRATION_ACTIONS.openADFSURL();
                        }
                    }
                }
                return REGISTRATION_ACTIONS.srvLookupStart();
            })
        )
    );

    registerFail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(REGISTRATION_ACTIONS.registerFail),
            tap(action => {
                if (this.platformService.isElectron()) {
                    this.platformService.setUnregisteredTray();
                }
                this.analyticsService.recordEvent(
                    'registrationFailedToRegister',
                    { regError: action.error }
                );
            }),
            withLatestFrom(this.registrationFacade.state$),
            filter(([_, { shouldRegister }]) => shouldRegister),
            switchMap(([_, { numFailures, closeSourceFn }]) => {
                if (closeSourceFn) {
                    closeSourceFn();
                }
                return of(REGISTRATION_ACTIONS.register()).pipe(
                    delayWhen(() =>
                        race(
                            interval(
                                Math.min(
                                    __REGISTRATION_CONSTANTS__.MAX_RETRY_DELAY,
                                    Math.pow(2, numFailures) * 1000
                                )
                            ),
                            this.actions$.pipe(
                                ofType(GENERAL_ACTIONS.setOnlineAction),
                                filter(({ online }) => online)
                            )
                        )
                    ),
                    takeUntil(
                        this.actions$.pipe(ofType(REGISTRATION_ACTIONS.cancel))
                    )
                );
            })
        )
    );

    unregisterStart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(REGISTRATION_ACTIONS.unregisterStart),
            withLatestFrom(this.registrationFacade.state$),
            tap(
                ([
                    _,
                    {
                        closeSourceFn,
                        registeredHost,
                        alias,
                        tokenResponse: { token }
                    }
                ]) => {
                    if (this.platformService.isElectron()) {
                        this.platformService.setDefaultTray();
                    }

                    if (closeSourceFn) {
                        closeSourceFn();
                    }

                    this.registrationService
                        .releaseToken(registeredHost, alias, token)
                        .pipe(catchError(() => EMPTY))
                        .subscribe();
                }
            ),
            map(() => REGISTRATION_ACTIONS.unregisterFinish())
        )
    );

    unregisterFinish$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(REGISTRATION_ACTIONS.unregisterFinish),
                tap(() => {
                    this.analyticsService.recordEvent(
                        'registrationUnRegistered'
                    );
                })
            ),
        { dispatch: false }
    );

    srvLookupStart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(REGISTRATION_ACTIONS.srvLookupStart),
            withLatestFrom(this.registrationFacade.state$),
            switchMap(([_, { host }]) =>
                from(this.srvService.resolvePexApp(host)).pipe(
                    map(resolvedHosts =>
                        REGISTRATION_ACTIONS.srvLookupFinish({ resolvedHosts })
                    ),
                    catchError(error =>
                        of(REGISTRATION_ACTIONS.srvLookupFail({ error }))
                    )
                )
            )
        )
    );

    srvLookupFail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(REGISTRATION_ACTIONS.srvLookupFail),
            map(({ error }) => REGISTRATION_ACTIONS.registerFail({ error }))
        )
    );

    srvLookupFinish$ = createEffect(() =>
        this.actions$.pipe(
            ofType(REGISTRATION_ACTIONS.srvLookupFinish),
            map(action =>
                REGISTRATION_ACTIONS.requestTokenStart({
                    server: action.resolvedHosts[0]
                })
            )
        )
    );

    requestTokenStart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(REGISTRATION_ACTIONS.requestTokenStart),
            withLatestFrom(
                this.registrationFacade.state$,
                this.registrationFacade.getRegistrationType()
            ),
            switchMap(
                ([
                    { server },
                    { username, password, adfsToken, alias },
                    registrationType
                ]) => {
                    let authHeader: AuthHeader;

                    if (registrationType === 'adfs') {
                        authHeader = {
                            name: 'Authorization',
                            value: `x-pexip-token ${adfsToken}`
                        };
                    } else {
                        authHeader = {
                            name: 'X-Pexip-Authorization',
                            value: `x-pexip-basic ${btoa(
                                encodeUtf8(`${username}:${password}`)
                            )}`
                        };
                    }
                    return this.registrationService
                        .requestToken(server, alias, authHeader)
                        .pipe(
                            map(tokenResponse =>
                                REGISTRATION_ACTIONS.requestTokenFinish({
                                    registeredHost: server,
                                    tokenResponse
                                })
                            ),
                            catchError(error => {
                                if (
                                    registrationType === 'adfs' &&
                                    (error?.error?.code ===
                                        'InvalidTokenEndTime' ||
                                        error?.error?.code === 'Unauthorized')
                                ) {
                                    this.registrationFacade.setADFSToken('');
                                    this.registrationFacade.setADFSTokenExpiry(
                                        undefined
                                    );
                                }
                                return of(
                                    REGISTRATION_ACTIONS.requestTokenFail({
                                        error
                                    })
                                );
                            }),
                            takeUntil(
                                this.actions$.pipe(
                                    ofType(REGISTRATION_ACTIONS.cancel)
                                )
                            )
                        );
                }
            )
        )
    );

    requestTokenFail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(REGISTRATION_ACTIONS.requestTokenFail),
            withLatestFrom(this.registrationFacade.state$),
            map(([{ error }, { resolvedHosts }]) => {
                if (error.statusText === 'Unauthorized') {
                    return REGISTRATION_ACTIONS.registerFail({ error });
                }

                if (resolvedHosts.length > 0) {
                    return REGISTRATION_ACTIONS.requestTokenStart({
                        server: resolvedHosts[0]
                    });
                }
                return REGISTRATION_ACTIONS.registerFail({ error });
            })
        )
    );

    requestTokenFinish$ = createEffect(() =>
        this.actions$.pipe(
            ofType(REGISTRATION_ACTIONS.requestTokenFinish),
            map(() => REGISTRATION_ACTIONS.openEventSourceStart())
        )
    );

    registerFinish$ = createEffect(() =>
        this.actions$.pipe(
            ofType(REGISTRATION_ACTIONS.registerFinish),
            tap(() => {
                if (this.platformService.isElectron()) {
                    this.platformService.setRegisteredTray();
                }
                this.analyticsService.recordEvent('registrationRegistered');
            }),
            withLatestFrom(this.registrationFacade.state$),
            switchMap(([_, { tokenResponse: { expires } }]) =>
                interval(1000 * expires * 0.8).pipe(
                    map(() => REGISTRATION_ACTIONS.refreshTokenStart()),
                    takeUntil(
                        this.actions$.pipe(
                            ofType(
                                REGISTRATION_ACTIONS.unregisterStart,
                                REGISTRATION_ACTIONS.registerFail
                            )
                        )
                    )
                )
            )
        )
    );

    refreshTokenStart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(REGISTRATION_ACTIONS.refreshTokenStart),
            withLatestFrom(this.registrationFacade.state$),
            switchMap(
                ([
                    _,
                    {
                        registeredHost,
                        alias,
                        tokenResponse: { token }
                    }
                ]) =>
                    this.registrationService
                        .refreshToken(registeredHost, alias, token)
                        .pipe(
                            map(tokenResponse =>
                                REGISTRATION_ACTIONS.refreshTokenFinish({
                                    tokenResponse
                                })
                            ),
                            catchError(error =>
                                of(
                                    REGISTRATION_ACTIONS.refreshTokenFail({
                                        error
                                    })
                                )
                            )
                        )
            )
        )
    );

    refreshTokenFail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(REGISTRATION_ACTIONS.refreshTokenFail),
            withLatestFrom(this.registrationFacade.isRetrying()),
            filter(([_, isRetrying]) => !isRetrying),
            map(([{ error }]) => REGISTRATION_ACTIONS.registerFail({ error }))
        )
    );

    openADFSURL$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(REGISTRATION_ACTIONS.openADFSURL),
                withLatestFrom(this.registrationFacade.state$),
                tap(
                    ([
                        _,
                        {
                            adfsFederationServiceName,
                            adfsClientID,
                            adfsResource,
                            adfsRedirectURI
                        }
                    ]) =>
                        this.registrationService.getADFSAuthCode(
                            adfsFederationServiceName,
                            adfsClientID,
                            adfsResource,
                            adfsRedirectURI
                        )
                )
            ),
        { dispatch: false }
    );

    adfsTokenRefresh$ = createEffect(() =>
        this.actions$.pipe(
            ofType(REGISTRATION_ACTIONS.adfsTokenRefresh),
            withLatestFrom(this.registrationFacade.state$),
            switchMap(([_, { adfsFederationServiceName, adfsRefreshToken }]) =>
                this.registrationService.getADFSTokenFromRefresh(
                    adfsRefreshToken,
                    adfsFederationServiceName
                )
            ),
            map(success => {
                if (success) {
                    return REGISTRATION_ACTIONS.srvLookupStart();
                }
                return REGISTRATION_ACTIONS.openADFSURL();
            })
        )
    );

    adfsTokenRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(REGISTRATION_ACTIONS.adfsTokenRequest),
            withLatestFrom(this.registrationFacade.state$),
            switchMap(
                ([
                    action,
                    { adfsClientID, adfsFederationServiceName, adfsRedirectURI }
                ]) =>
                    this.registrationService.getADFSTokenFromAuth(
                        action.code,
                        adfsClientID,
                        adfsRedirectURI,
                        adfsFederationServiceName
                    )
            ),
            map(success => {
                if (success) {
                    return REGISTRATION_ACTIONS.srvLookupStart();
                }
                return REGISTRATION_ACTIONS.registerFail({
                    error: new Error('Request ADFS token failed')
                });
            })
        )
    );

    openEventSourceStart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(REGISTRATION_ACTIONS.openEventSourceStart),
            withLatestFrom(this.registrationFacade.state$),
            switchMap(
                ([
                    _,
                    {
                        registeredHost,
                        alias,
                        tokenResponse: { token },
                        shouldRegister
                    }
                ]) =>
                    this.gatewayCallService
                        .openEventSource(registeredHost, alias, token)
                        .pipe(
                            map(closeSourceFn =>
                                REGISTRATION_ACTIONS.registerFinish({
                                    closeSourceFn
                                })
                            ),
                            catchError(error => {
                                this.loggingService.warn(
                                    'Registration error: ',
                                    error
                                );
                                return this.registrationFacade
                                    .isRetrying()
                                    .pipe(
                                        take(1),
                                        map(isRetrying => {
                                            if (isRetrying || !shouldRegister)
                                                return;
                                            return REGISTRATION_ACTIONS.registerFail(
                                                { error }
                                            );
                                        })
                                    );
                            }),
                            filter(action => !!action),
                            takeUntil(
                                this.actions$.pipe(
                                    ofType(REGISTRATION_ACTIONS.unregisterStart)
                                )
                            )
                        )
            )
        )
    );

    incomingCall$ = createEffect(() =>
        this.actions$.pipe(
            ofType(REGISTRATION_ACTIONS.incomingCall),
            withLatestFrom(this.registrationFacade.state$),
            switchMap(([{ call }, { registeredHost, alias }]) => {
                return new Observable<Action>(observer => {
                    const dialogConfig = {
                        ...call,
                        playRingtone: this.storageService.userSettingsProxy
                            .playRingtone
                    };
                    window.pexBridge.incomingCall(call.remote_display_name);

                    if (this.router.url.includes('/conference')) {
                        this.dialogService
                            .open(IncomingCallDialogComponent, dialogConfig)
                            .then(dialog => {
                                observer.next(
                                    REGISTRATION_ACTIONS.incomingCallDialogOpened(
                                        { token: call.token, id: dialog.id }
                                    )
                                );
                                dialog.close$
                                    .pipe(
                                        takeUntil(
                                            this.actions$.pipe(
                                                ofType(
                                                    REGISTRATION_ACTIONS.incomingCallDialogClosed
                                                )
                                            )
                                        )
                                    )
                                    .subscribe(dialogResult => {
                                        observer.next(
                                            REGISTRATION_ACTIONS.incomingCallDialogClosed(
                                                { token: call.token }
                                            )
                                        );
                                        window.pexBridge.incomingCallDone(
                                            call.remote_display_name
                                        );
                                        if (dialogResult === 'ACCEPT') {
                                            const url = `pexip://${call.conference_alias}?oneTimeToken=${call.token}`;
                                            this.urlService.open(url, false);
                                        } else if (
                                            dialogResult === 'CANCELLED'
                                        ) {
                                        } else {
                                            this.historyDatabaseService.addConference(
                                                {
                                                    alias: `${
                                                        call.conference_alias
                                                    }@${this.urlService.getHost(
                                                        registeredHost,
                                                        alias
                                                    )}`,
                                                    type:
                                                        ConnectionTypeEnum.INCOMING_REJECTED
                                                }
                                            );
                                        }
                                        observer.complete();
                                    });
                            });
                    } else {
                        this.router.navigate(['/home'], {
                            queryParams: {
                                conference: call.conference_alias,
                                conferenceDisplayName: call.remote_display_name,
                                oneTimeToken: call.token
                            }
                        });
                        observer.complete();
                    }
                });
            })
        )
    );

    incomingCallCancelled$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(REGISTRATION_ACTIONS.incomingCallCancelled),
                withLatestFrom(this.registrationFacade.state$),
                tap(
                    ([
                        { call },
                        { incomingCallDialogs, registeredHost, alias }
                    ]) => {
                        if (this.router.url.includes('/conference')) {
                            window.pexBridge.incomingCallDone(
                                call.remote_display_name
                            );
                            const dialogId = incomingCallDialogs[call.token];
                            if (dialogId) {
                                this.dialogService.close(dialogId);
                            }
                        } else {
                            const dialog = this.dialogService
                                .getDialogComponents(CardDialogComponent)
                                .find(
                                    dialog =>
                                        dialog.contentComponent.config
                                            .oneTimeToken === call.token
                                );
                            if (dialog) {
                                dialog.close();
                            }

                            this.router.navigate(['/home']);
                        }

                        if (call) {
                            if (call.service_type === ServiceType.conference) {
                                const incomingCallAlias = call.remote_alias.includes(
                                    '@'
                                )
                                    ? call.remote_alias
                                    : `${
                                          call.remote_alias
                                      }@${this.urlService.getHost(
                                          registeredHost,
                                          alias
                                      )}`;

                                this.historyDatabaseService.addConference({
                                    alias: incomingCallAlias,
                                    type: ConnectionTypeEnum.INCOMING_MISSED
                                });
                            } else {
                                this.historyDatabaseService.addConference({
                                    alias: call.remote_display_name,
                                    type: ConnectionTypeEnum.INCOMING_MISSED,
                                    canNotDialBack: true
                                });
                            }
                        }
                    }
                )
            ),
        { dispatch: false }
    );
}
