import { Action, createReducer, on } from '@ngrx/store';

import { MEDIA_DEVICE_ACTIONS } from './media-device.actions';

export interface MediaDeviceState {
    inputDevices: {
        videoInputDevice: MediaDeviceInfo;
        audioInputDevice: MediaDeviceInfo;
    };
    audioOutputDevice: MediaDeviceInfo;
    localMediaStream: MediaStream;
    currentConferenceLocalMediaStream: MediaStream;
    streamType: 'video' | 'audioonly';
    devices: MediaDeviceInfo[];
    streamAudioDeviceID: string | MediaDeviceInfo;
    streamVideoDeviceID: string | MediaDeviceInfo;
    streamCallType: string;
    gotPermission: boolean;
    mediaTypeRequest: CallType;
    mutedByUser: { [x in MediaDeviceKind]?: boolean };
    feccSupported: boolean;
    constraints: string;
    slowGUMCall: boolean;
}

export const initialState: MediaDeviceState = {
    inputDevices: {
        videoInputDevice:
            JSON.parse(localStorage.getItem('pexStorage-videoInputDevice')) ||
            __CONSTANTS__.DEFAULT_VIDEO_INPUT_DEVICE,
        audioInputDevice:
            JSON.parse(localStorage.getItem('pexStorage-audioInputDevice')) ||
            __CONSTANTS__.DEFAULT_AUDIO_INPUT_DEVICE
    },
    audioOutputDevice:
        JSON.parse(localStorage.getItem('pexStorage-audioOutputDevice')) ||
        __CONSTANTS__.DEFAULT_AUDIO_OUTPUT_DEVICE,

    localMediaStream: null,
    currentConferenceLocalMediaStream: null,

    streamType: 'video',

    devices: [],

    streamAudioDeviceID: null,

    streamVideoDeviceID: null,

    streamCallType: null,

    gotPermission: false,

    mediaTypeRequest: null,

    mutedByUser: {
        videoinput: false,
        audioinput: false,
        ...JSON.parse(localStorage.getItem('pexStorage-mutedByUser'))
    },

    feccSupported: false,

    constraints: null,

    slowGUMCall: false
};

const mediaDeviceReducer = createReducer(
    initialState,
    on(
        MEDIA_DEVICE_ACTIONS.setMediaInputDevicesAction,
        (state, { video, audio, mutedByUser }) => ({
            ...state,
            inputDevices: {
                audioInputDevice: audio,
                videoInputDevice: video
            },
            mutedByUser: {
                ...state.mutedByUser,
                videoinput: Boolean(mutedByUser),
                audioinput: Boolean(mutedByUser)
            }
        })
    ),
    on(
        MEDIA_DEVICE_ACTIONS.setVideoInputDeviceAction,
        (state, { device, mutedByUser }) => ({
            ...state,
            inputDevices: {
                ...state.inputDevices,
                videoInputDevice: device
            },
            mutedByUser: {
                ...state.mutedByUser,
                videoinput: Boolean(mutedByUser)
            }
        })
    ),
    on(
        MEDIA_DEVICE_ACTIONS.setAudioInputDeviceAction,
        (state, { device, mutedByUser }) => ({
            ...state,
            inputDevices: {
                ...state.inputDevices,
                audioInputDevice: device
            },
            mutedByUser: {
                ...state.mutedByUser,
                audioinput: Boolean(mutedByUser)
            }
        })
    ),
    on(
        MEDIA_DEVICE_ACTIONS.setAudioOutputDeviceAction,
        (state, { device }) => ({ ...state, audioOutputDevice: device })
    ),
    on(
        MEDIA_DEVICE_ACTIONS.audioOutputDeviceChangedAction,
        (state, { device }) => ({ ...state, audioOutputDevice: device })
    ),
    on(
        MEDIA_DEVICE_ACTIONS.setLocalMediaStreamAction,
        (
            state,
            { stream, audioDeviceID, videoDeviceID, streamType, constraints }
        ) => ({
            ...state,
            localMediaStream: stream,
            streamAudioDeviceID: audioDeviceID,
            streamVideoDeviceID: videoDeviceID,
            streamCallType: streamType,
            constraints
        })
    ),
    on(
        MEDIA_DEVICE_ACTIONS.setStreamConstraintsAction,
        (state, { audioDeviceID, videoDeviceID, callType }) => ({
            ...state,
            streamAudioDeviceID: audioDeviceID,
            streamVideoDeviceID: videoDeviceID,
            streamCallType: callType
        })
    ),
    on(
        MEDIA_DEVICE_ACTIONS.setCurrentConferenceLocalMediaStream,
        (state, { stream }) => ({
            ...state,
            currentConferenceLocalMediaStream: stream
        })
    ),
    on(MEDIA_DEVICE_ACTIONS.setMediaTypeRequest, (state, { callType }) => ({
        ...state,
        mediaTypeRequest: callType
    })),
    on(MEDIA_DEVICE_ACTIONS.setFeccSupported, (state, { feccSupported }) => ({
        ...state,
        feccSupported
    })),
    on(MEDIA_DEVICE_ACTIONS.setSlowGUMCall, (state, { isSlow }) => ({
        ...state,
        slowGUMCall: isSlow
    }))
);

export function reducer(state: MediaDeviceState, action: Action) {
    return mediaDeviceReducer(state, action);
}
