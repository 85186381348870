import {
    Action,
    createFeatureSelector,
    createReducer,
    createSelector,
    on
} from '@ngrx/store';

import { GENERAL_ACTIONS } from './general.actions';
export interface GeneralState {
    windowActive: boolean;
    windowMinimized: boolean;
    windowClosed: boolean;
    online: boolean;
}

export const initialState: GeneralState = {
    windowActive: true,
    windowMinimized: false,
    windowClosed: false,
    online: navigator.onLine
};

const generalReducer = createReducer(
    initialState,
    on(GENERAL_ACTIONS.setWindowActiveAction, (state, { active }) => ({
        ...state,
        windowActive: active
    })),
    on(GENERAL_ACTIONS.setWindowMinimizedAction, (state, { minimised }) => ({
        ...state,
        windowMinimized: minimised
    })),
    on(GENERAL_ACTIONS.setWindowClosedAction, (state, { closed }) => ({
        ...state,
        windowClosed: closed
    })),
    on(GENERAL_ACTIONS.setOnlineAction, (state, { online }) => ({
        ...state,
        online
    }))
);

export function reducer(state: GeneralState, action: Action) {
    return generalReducer(state, action);
}

export const getGeneralState = createFeatureSelector<GeneralState>('general');

export const getWindowActive = createSelector(
    getGeneralState,
    (state: GeneralState) => state.windowActive
);

export const getWindowMinimized = createSelector(
    getGeneralState,
    (state: GeneralState) => state.windowMinimized
);

export const getWindowClosed = createSelector(
    getGeneralState,
    (state: GeneralState) => state.windowClosed
);
