<div class="pex-dialog-close">
    <button
        type="button"
        class="circle-close-button"
        (click)="this.close()"
        [attr.aria-label]="'CLOSE' | translate"
    >
        <svg class="circle-close-button-icon">
            <use xlink:href="icons.svg#x" />
        </svg>
    </button>
</div>

<div
    class="pex-dialog-content-inner"
    role="dialog"
    aria-labelledby="pex-branding-apply-dialog-title"
    aria-modal="true"
>
    <div id="pex-branding-apply-dialog-title" class="pex-dialog-content-title">
        {{ 'BRANDING_APPLY_DIALOG.TITLE' | translate }}
    </div>
    <div class="pex-dialog-content-message">
        {{ 'BRANDING_APPLY_DIALOG.BODY' | translate }}
        <p *ngIf="safeConfig.isSettingsBranded">
            {{ 'BRANDING_APPLY_DIALOG.SETTINGS' | translate }}
        </p>
        <p *ngIf="safeConfig.isStylesBranded">
            {{ 'BRANDING_APPLY_DIALOG.STYLES' | translate }}
        </p>
    </div>
    <div class="button-container">
        <div class="pex-dialog-content-button">
            <button
                type="button"
                class="dialog-button buttons green-action-button"
                (click)="close(true)"
            >
                {{ 'BRANDING_APPLY_DIALOG.APPLY' | translate }}
            </button>
        </div>
        <div class="pex-dialog-content-button">
            <button
                type="button"
                class="dialog-button buttons red-action-button"
                (click)="close(false)"
            >
                {{ 'BRANDING_APPLY_DIALOG.CANCEL' | translate }}
            </button>
        </div>
    </div>
</div>
