import { Component, Input } from '@angular/core';

@Component({
    selector: 'pex-context-menu-item',
    templateUrl: './context-menu-item.component.html',
    styleUrls: ['./context-menu-item.component.scss']
})
export class ContextMenuItemComponent {
    @Input() icon: string;
    @Input() label: string;
}
