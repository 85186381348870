export type CallType =
    | 'presentation'
    | 'screen'
    | 'audioonly'
    | 'recvonly'
    | 'rtmp'
    | 'stream'
    | 'none';
export type PinStatus = 'none' | 'required' | 'optional';
export type ConferenceExtension = 'standard' | 'mssip';
export interface IdentityProvider {
    uuid: string;
    name: string;
    img?: string;
    redirectUrl?: string;
}

export enum ConnectionTypeEnum {
    OUTGOING_SUCCESS = 'OUTGOING_SUCCESS',
    OUTGOING_FAILED = 'OUTGOING_FAILED',
    INCOMING_SUCCESS = 'INCOMING_SUCCESS',
    INCOMING_REJECTED = 'INCOMING_REJECTED',
    INCOMING_MISSED = 'INCOMING_MISSED'
}
export type ConnectionType =
    | ConnectionTypeEnum.OUTGOING_SUCCESS
    | ConnectionTypeEnum.OUTGOING_FAILED
    | ConnectionTypeEnum.INCOMING_SUCCESS
    | ConnectionTypeEnum.INCOMING_REJECTED
    | ConnectionTypeEnum.INCOMING_MISSED;

export enum ServiceType {
    conference = 'conference',
    gateway = 'gateway'
}

export type SidebarState =
    | 'ROSTER'
    | 'EVENTS'
    | 'SETTINGS'
    | 'SETTINGS_DEVICE_SETUP'
    | 'DEVICES_SETUP'
    | 'CAPTION';

export interface ConnectionDetails {
    alias: string;
    host?: string;
    displayName?: string;
    bandwidth?: string;
    callType?: CallType;
    pin?: string;
    extension?: string;
    muteMicrophone?: boolean;
    muteCamera?: boolean;
    registrationToken?: string;
    oneTimeToken?: string;
    // only use for if show 'SlideShareDialog' for api join
    joinRole?: string;
    currentServiceType?: string;
    remoteCallType?: 'audio' | 'video-only' | 'video';
    guestsCanPresent?: boolean;
    type?: ConnectionType;
    canNotDialBack?: boolean;
    serviceType?: string;
    feccEnabled?: boolean;
}

export interface ConferenceDetails {
    locked: boolean;
    started: boolean;
    guestsMuted: boolean;
    chatEnabled: boolean;
    mediaType: string;
}

export interface PresentationDetails {
    presentationImgSrc: string;
    presentationVideo: MediaStream;
    presentingMode: PresentingMode;
    presentingSlides: number[];
    currentSlide: number;
}

export interface Conference {
    id?: number;
    connectionDetails: ConnectionDetails;
    timestamp: number;
}

export interface ReceivedChatMessage {
    origin: string;
    payload: string;
    type: string;
    uuid: string;
}

export interface SplashScreen {
    text: string;
    audio: string;
    image: string;
}
