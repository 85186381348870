<div
    [class]="_class"
    (click)="onClickOutside($event)"
    (window:keydown.Escape)="onClickOutside($event)"
>
    <div
        #contextMenuContent
        id="pex-context-menu-content"
        class="pex-context-menu-content"
        tabindex="-1"
        [ngClass]="{ 'pex-context-menu-content--center': _isCenter }"
        [style.bottom.px]="_bottom"
        [style.top.px]="_top"
        [style.left.px]="_left"
        [style.right.px]="_right"
    >
        <ng-content
            *ngTemplateOutlet="templateRef; context: context"
        ></ng-content>
    </div>
</div>
