<div>
    <div class="card-dialog__info">
        <div class="card-dialog__title">
            {{
                (isIncomingCall
                    ? 'CARD_DIALOG.INCOMING_CALL'
                    : 'CARD_DIALOG.INVITATION'
                ) | translate
            }}
        </div>
        <div class="card-dialog__subtitle">{{ this.config?.alias }}</div>
    </div>
    <div class="card-dialog__actions">
        <div
            class="pex-button--content"
            *ngIf="!isIncomingCall"
            (click)="close('none')"
            pexTabbable
        >
            <div id="incoming-content-call-button">
                <svg><use xlink:href="icons.svg#monitor-light" /></svg>
            </div>
            <span id="incoming-content-call-text">{{
                'CALL_CONTENT' | translate
            }}</span>
        </div>
        <div
            class="pex-button--video"
            (click)="muteCamera ? setMuteCamera(false) : close('video')"
            pexTabbable
        >
            <div id="incoming-video-call-button">
                <svg><use xlink:href="icons.svg#video-fill" /></svg>
            </div>
            <span id="incoming-video-call-text">{{
                'CALL_VIDEO' | translate
            }}</span>
        </div>
        <div class="pex-button--voice" (click)="close('audioonly')" pexTabbable>
            <div id="incoming-voice-call-button">
                <svg><use xlink:href="icons.svg#phone-light" /></svg>
            </div>
            <span id="incoming-voice-call-text">{{
                'CALL_VOICE' | translate
            }}</span>
        </div>
    </div>

    <div
        class="pex-user__space"
        *ngIf="mySpaceDatabaseService.activeSpace"
        (click)="openSpaces()"
        pexTabbable
    >
        <span>{{ 'SPACES.ANSWERING_USING' | translate }} </span>
        <span class="pex-user__space-name">{{
            mySpaceDatabaseService.activeSpaceName
        }}</span>
    </div>

    <audio
        *ngIf="isIncomingCall && playRingtone"
        src="assets/ping.ogg"
        autoplay
        loop
    ></audio>
</div>
