import { Pipe, PipeTransform } from '@angular/core';
import { TruncatePipe } from './truncate.pipe';

@Pipe({
    name: 'truncateDisplayName'
})
export class TruncateDisplayNamePipe implements PipeTransform {
    constructor(private truncatePipe: TruncatePipe) {}

    transform(displayName: string, limit = 20): string {
        if (displayName.length < limit) return displayName;

        if (displayName.includes('@')) {
            const firstPart = displayName.split('@')[0];
            return firstPart.length < limit
                ? firstPart
                : this.truncatePipe.transform(firstPart, limit);
        } else {
            return this.truncatePipe.transform(displayName, limit);
        }
    }
}
