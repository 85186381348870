import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'pex-talking-when-muted-indicator',
    templateUrl: './talking-when-muted-indicator.component.html',
    styleUrls: ['./talking-when-muted-indicator.component.scss']
})
export class TalkingWhenMutedIndicatorComponent implements OnInit {
    @Input() isTalkingWhenMuted: boolean;

    constructor() {}

    ngOnInit(): void {}
}
