import { Component } from '@angular/core';

import { BaseDialogContentComponent } from '../../dialog/base-dialog-content.component';
import { PlatformService } from '../../platform.service';

@Component({
    templateUrl: './device-permission-dialog.component.html',
    styleUrls: ['./device-permission-dialog.component.scss']
})
export class DevicePermissionDialogComponent extends BaseDialogContentComponent<
    boolean,
    string
> {
    browserType: string;
    dialogType: string; //'device blocked' or 'device permission neither allow or deny'

    constructor(private platformService: PlatformService) {
        super();
        if (this.platformService.isWeb()) {
            this.browserType = this.platformService.browserType;
        } else if (this.platformService.isAndroid()) {
            this.browserType = 'android';
        }
    }

    afterInit() {
        this.dialogType = this.config;
    }

    close() {
        super.close(true);
    }
}
