import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { Base64 } from 'js-base64';

import { State } from '../app.reducer';

import { DialogService } from '../dialog/dialog.service';
import { DisplayNameDialogComponent } from '../dialog/display-name-dialog';
import { ConferenceFacade } from './../conference/conference.facade';
import { BrandingService } from './branding.service';
import { StorageService } from './storage';

import { CONFERENCE_ACTIONS } from '../conference/conference.actions';
import { PlatformService } from '../platform.service';
import { RegistrationFacade } from '../registration/registration.facade';

import {
    ErrorCode,
    LoginError
} from '../conference/idp-popup-login-dialog/idp-login.service';
import { PexURL } from '../shared/pexurl';
import {
    isADFSProvisioning,
    RegistrationProvisionDialogComponent
} from '../shared/registration-provision-dialog/registration-provision-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class URLService {
    waitingCall: Function = null;
    getConnectedSubscription: Subscription;

    constructor(
        private dialogService: DialogService,
        private storageService: StorageService,
        private injector: Injector,
        private brandingService: BrandingService,
        private store$: Store<State>,
        private platformService: PlatformService,
        private conferenceFacade: ConferenceFacade,
        private registrationFacade: RegistrationFacade
    ) {}

    getHost(host: string, alias: string) {
        if (
            ['localhost:8080', 'nowebslave1.rd.pexip.com'].indexOf(
                window.location.host
            ) > -1
        ) {
            return (
                host ||
                this.storageService.applicationSettingsProxy.serverAddress ||
                window.location.host
            );
        }

        if (this.platformService.platform === 'web') {
            return (
                this.storageService.applicationSettingsProxy.serverAddress ||
                window.location.host
            );
        }

        if (this.registrationFacade.state.tokenResponse.route_via_registrar) {
            return this.registrationFacade.state.registeredHost;
        }

        if (host) {
            return host;
        }

        if (this.storageService.applicationSettingsProxy.serverAddress) {
            return this.storageService.applicationSettingsProxy.serverAddress;
        }

        const aliasArr = alias.split('@');
        return aliasArr.length > 1 ? aliasArr[1] : '';
    }

    openEncodedURL(url: string, promptToConnect: boolean) {
        this.open(decodeURIComponent(url), promptToConnect);
    }

    async open(url: string, promptToConnect: boolean) {
        // assumes NOT percent encoded from clicks etc
        let urlObject: PexURL;
        try {
            urlObject = new PexURL(url);
        } catch (e) {
            urlObject = new PexURL('unknown:foo@bar.com');
        }
        switch (urlObject.protocol) {
            case 'pexip':
                if (this.injector.get(Router).url.includes('/conference')) {
                    if (promptToConnect) {
                        return this.promptToConnect(
                            '',
                            urlObject.alias,
                            urlObject.params
                        );
                    }

                    return this.disconnectFromConference(
                        urlObject.params,
                        urlObject.alias
                    );
                }

                if (promptToConnect) {
                    return this.navigateToCardDialog(
                        urlObject.alias,
                        urlObject.params
                    );
                }

                this.injector.get(Router).navigate(['/conference'], {
                    queryParams: {
                        ...urlObject.params,
                        conference: decodeURI(urlObject.alias)
                    },
                    queryParamsHandling: 'merge'
                });
                break;
            case 'pexip-provision':
                if (urlObject.alias.indexOf('settings') > -1) {
                    let data = urlObject.params?.data;
                    if (!data) {
                        data = '';
                    }
                    try {
                        data = Base64.decode(data);
                    } catch (e) {
                        data = '';
                    }

                    const params = new URLSearchParams(data);
                    const name = params.get('name');
                    const registrationHost = params.get('registrationHost');
                    const registrationAlias = params.get('registrationAlias');
                    const registrationUsername = params.get(
                        'registrationUsername'
                    );
                    const registrationPassword = params.get(
                        'registrationPassword'
                    );
                    const adfsFederationServiceName = params.get(
                        'adfsFederationServiceName'
                    );
                    const adfsResource = params.get('adfsResource');
                    const adfsClientID = params.get('adfsClientID');
                    const adfsRedirectURI = params.get('adfsRedirectURI');
                    let brandingURL: string;
                    if (
                        this.platformService.isElectron() &&
                        (await window.pexBridge.isTrustedKeySet())
                    ) {
                        brandingURL = params.get('brandingURL');
                    }

                    const provisioningDetails = {
                        name,

                        registrationHost,
                        registrationAlias,
                        registrationUsername,
                        registrationPassword,

                        adfsFederationServiceName,
                        adfsResource,
                        adfsClientID,
                        adfsRedirectURI,

                        brandingURL
                    };
                    (
                        await this.dialogService.open(
                            RegistrationProvisionDialogComponent,
                            provisioningDetails
                        )
                    ).close$
                        .pipe(filter(dialogResult => dialogResult))
                        .subscribe(() => {
                            this.registrationFacade.clearDetails();

                            if (name) {
                                this.storageService.userSettingsProxy.displayName = name;
                                // close aready opened displayname dialog
                                this.dialogService
                                    .getDialogComponents(
                                        DisplayNameDialogComponent
                                    )
                                    .forEach(dialogRef => {
                                        dialogRef.close();
                                    });
                            }

                            if (this.platformService.platform === 'electron') {
                                if (
                                    this.storageService.applicationSettingsProxy
                                        .registrationEnabled
                                ) {
                                    if (
                                        registrationUsername &&
                                        registrationPassword
                                    ) {
                                        this.registrationFacade.setHost(
                                            registrationHost
                                        );
                                        this.registrationFacade.setAlias(
                                            registrationAlias
                                        );
                                        this.registrationFacade.setUsername(
                                            registrationUsername
                                        );
                                        this.registrationFacade.setPassword(
                                            registrationPassword
                                        );
                                        this.registrationFacade.register();
                                    } else if (
                                        isADFSProvisioning(provisioningDetails)
                                    ) {
                                        this.registrationFacade.setHost(
                                            registrationHost
                                        );
                                        this.registrationFacade.setAlias(
                                            registrationAlias
                                        );
                                        this.registrationFacade.setADFSFederationServiceName(
                                            adfsFederationServiceName
                                        );
                                        this.registrationFacade.setADFSResource(
                                            adfsResource
                                        );
                                        this.registrationFacade.setADFSClientID(
                                            adfsClientID
                                        );
                                        this.registrationFacade.setADFSRedirectURI(
                                            adfsRedirectURI
                                        );
                                        this.registrationFacade.setADFSToken(
                                            null
                                        );
                                        this.registrationFacade.setADFSRefreshToken(
                                            null
                                        );
                                        this.registrationFacade.setUsername(
                                            null
                                        );
                                        this.registrationFacade.setPassword(
                                            null
                                        );
                                        this.registrationFacade.register();
                                    }
                                }
                                if (brandingURL) {
                                    this.storageService.brandingProxy.brandingURL = brandingURL;
                                    this.brandingService.getManifest();
                                }
                            }
                        });
                }
                break;
            case 'pexip-auth':
                if (
                    urlObject.alias === 'adfs' &&
                    this.platformService.platform === 'electron' &&
                    this.storageService.applicationSettingsProxy
                        .registrationEnabled
                ) {
                    const state = urlObject.params?.state;
                    if (
                        state !== this.registrationFacade.state.adfsStateToken
                    ) {
                        return;
                    }
                    const code = urlObject.params?.code;
                    this.registrationFacade.adfsTokenRequest(code);
                }
                if (urlObject.alias === 'saml') {
                    if (urlObject.params?.token) {
                        this.conferenceFacade.submitSsoToken(
                            urlObject.params.token
                        );
                    } else if (
                        parseInt(urlObject.params?.code) ===
                        ErrorCode.SERVICE_UNAVAILABLE
                    ) {
                        this.conferenceFacade.samlLoginError(
                            LoginError.MAINTENANCE
                        );
                    } else if (urlObject.params?.code) {
                        this.conferenceFacade.samlLoginError(
                            LoginError.DEFAULT
                        );
                    } else {
                        return;
                    }
                }
                break;
            default:
                this.dialogService.openActionDialog({
                    title: 'Unrecognized URL',
                    message:
                        'The url ' +
                        url +
                        ' is not a valid url to be handled by this application',
                    actions: ['OK']
                });
        }
    }

    private navigateToCardDialog(alias: string, queryParams: {} = {}) {
        return this.injector.get(Router).navigate(['/home'], {
            queryParams: { conference: decodeURI(alias), ...queryParams }
        });
    }

    private disconnectFromConference(queryParams: {} = {}, alias: string) {
        if (this.getConnectedSubscription) {
            this.getConnectedSubscription.unsubscribe();
            this.getConnectedSubscription = null;
        }
        this.getConnectedSubscription = this.conferenceFacade.isConnected$
            .pipe(take(1))
            .subscribe(connected => {
                if (connected) {
                    this.store$.dispatch(CONFERENCE_ACTIONS.disconnect(false));
                } else {
                    // disconnected with a disconnected dialog popup
                    // close dialogs
                    this.dialogService.closeAllDialogs();
                }

                // store the unfinished call and will be triggered asa navigate back to home
                this.waitingCall = () => {
                    if (this.getConnectedSubscription) {
                        this.getConnectedSubscription.unsubscribe();
                        this.getConnectedSubscription = null;
                    }
                    queryParams['conference'] = decodeURI(alias);
                    this.injector.get(Router).navigate(['/home'], {
                        queryParams,
                        queryParamsHandling: 'merge'
                    });
                };
            });
    }

    private async promptToConnect(
        _host: string,
        alias: string,
        queryParams: {} = {}
    ) {
        return (
            await this.dialogService.openActionDialog({
                title: 'JOIN_DIALOG.TITLE',
                titleClass: 'title-green',
                message: 'JOIN_DIALOG.DESC',
                actions: ['JOIN_DIALOG.JOIN'],
                translationParams: {
                    alias: decodeURI(alias)
                }
            })
        ).close$
            .pipe(
                map(dialogResult => dialogResult === 'JOIN_DIALOG.JOIN'),
                filter(result => result)
            )
            .subscribe(() => this.disconnectFromConference(queryParams, alias));
    }
}
