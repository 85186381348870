import { Pipe, PipeTransform } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'sanitizeStyle'
})
export class SanitizeStylePipe implements PipeTransform {
    constructor(private _sanitizer: DomSanitizer) {}

    transform(v: string) {
        return this._sanitizer.bypassSecurityTrustStyle(v);
    }
}
