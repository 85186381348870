import { Injectable } from '@angular/core';

import { InitService } from '../core';
import { LoggingService } from '../core/logging.service';
import { BrandingService } from '../core/services/branding.service';
import { StorageService } from '../core/services/storage/storage.service';
import { GeneralFacade } from '../core/shared/general/general.facade';

@Injectable({ providedIn: 'root' })
export class WebInitService extends InitService {
    constructor(
        generalFacade: GeneralFacade,
        storageService: StorageService,
        loggingService: LoggingService,
        brandingService: BrandingService
    ) {
        super(generalFacade, storageService, loggingService, brandingService);
    }

    initImpl() {}
}
