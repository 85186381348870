export enum ScreenStateEnum {
    HOME = 'HOME',
    SETTINGS = 'SETTINGS',
    SEARCH = 'SEARCH',
    WELCOME = 'WELCOME'
}

export type ScreenState =
    | ScreenStateEnum.HOME
    | ScreenStateEnum.SETTINGS
    | ScreenStateEnum.SEARCH
    | ScreenStateEnum.WELCOME;
