import { createAction, props } from '@ngrx/store';
import { DevicesSetupState } from './../media-device/media-devices/devices-setup-state.type';
import { ScreenState } from './offset-overlay/screen-state.type';
import { SettingsState } from './setting-overlay/settings-state.type';

export const HOME_ACTIONS = {
    setScreenAction: createAction(
        '[Home] Screen state',
        props<{ screen: ScreenState }>()
    ),
    settingsNavigateAction: createAction(
        '[Home] Settings state',
        props<{ screen: SettingsState }>()
    ),
    devicesSetupNavigateAction: createAction(
        '[Home] Devices setup state',
        props<{ screen: DevicesSetupState }>()
    ),
    cameraMuteChangeAction: createAction(
        '[Home] Camera mute change',
        props<{ muted: boolean }>()
    ),
    microphoneMuteChangeAction: createAction(
        '[Home] Microphone mute change',
        props<{ muted: boolean }>()
    )
};
