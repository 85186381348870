import { Component } from '@angular/core';
import { BackgroundEffectsService } from 'src/core/media-device/background-effects/background-effects.service';
import { PlatformService } from 'src/core/platform.service';

@Component({
    selector: 'pex-background-effect-indicator',
    templateUrl: './background-effect-indicator.component.html',
    styleUrls: ['./background-effect-indicator.component.scss']
})
export class BackgroundEffectIndicatorComponent {
    constructor(
        public platformService: PlatformService,
        public backgroundEffectsService: BackgroundEffectsService
    ) {}

    get areBackgroundEffectsSupported() {
        return this.backgroundEffectsService.areBackgroundEffectsSupported;
    }

    get isBackgroundEffectEnabled() {
        return this.backgroundEffectsService.isBackgroundEffectEnabled;
    }

    get isBlurActiveEffect() {
        return this.backgroundEffectsService.isBlurActiveEffect;
    }

    setEnableBackgroundEffects(event: MouseEvent, enable: boolean) {
        event.stopPropagation();
        this.backgroundEffectsService.setEnableBackgroundEffects(enable);
    }
}
