import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { LoggingService } from '../../logging.service';
import { PlatformService } from '../../platform.service';
import {
    ApplicationSettingsModel,
    BrandedSettingsModel,
    MobileUserSettingsModel,
    PluginSettingsModel,
    UserSettingsModel
} from '../storage/settings.model';
import { ApplicationSettingsStorage } from './application-settings.storage';
import { BrandingModel, BrandingStorage } from './branding.storage';
import { FavoritesModel, FavoritesStorage } from './favorites.storage';
import { HistoryModel, HistoryStorage } from './history.storage';
import { MySpacesStorage } from './my-spaces.storage';
import { PexStorageBase } from './storage';
import { UserSettingsStorage } from './user-settings.storage';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    brandingStorage: BrandingStorage;
    userSettingsStorage: UserSettingsStorage;
    applicationSettingsStorage: ApplicationSettingsStorage;
    historyStorage: HistoryStorage;
    favoritesStorage: FavoritesStorage;
    spacesStorage: MySpacesStorage;
    private pluginStorage: PluginSettingsModel[];

    brandingProxy: BrandingModel;
    userSettingsProxy: UserSettingsModel;
    applicationSettingsProxy: ApplicationSettingsModel;
    historyProxy: HistoryModel;
    favoritesProxy: FavoritesModel;
    pluginsProxy: PluginSettingsModel[];

    loaded$: Subject<void> = new Subject();
    reset$: Subject<void> = new Subject();
    update$: Subject<void> = new Subject();

    constructor(
        private loggingService: LoggingService,
        private platformService: PlatformService,
        @Inject('UserSettingsModelFactory')
        private userSettingsModelFactory: new () => UserSettingsModel,
        @Inject('ApplicationSettingsModelFactory')
        private applicationSettingsModelFactory: new () => ApplicationSettingsModel
    ) {}

    init() {
        this.loggingService.info('Storage init start');

        this.brandingStorage = new BrandingStorage(
            this.safeParse('pexBranding')
        );
        this.userSettingsStorage = new UserSettingsStorage(
            this.platformService.isMobileBrowser()
                ? MobileUserSettingsModel
                : this.userSettingsModelFactory,
            this.safeParse('pexUserSettingsValues'),
            this.safeParse('pexUserSettingsDefaults')
        );
        this.applicationSettingsStorage = new ApplicationSettingsStorage(
            this.applicationSettingsModelFactory,
            this.safeParse('pexApplicationSettings')
        );
        this.historyStorage = new HistoryStorage(this.safeParse('pexHistory'));
        this.favoritesStorage = new FavoritesStorage(
            this.safeParse('pexFavorites')
        );
        this.spacesStorage = new MySpacesStorage(this.safeParse('pexSpaces'));
        this.pluginStorage = this.safeParse('pexPlugins');
        if (!this.pluginStorage) {
            this.pluginStorage = [];
        } else {
            this.pluginStorage = [...this.pluginStorage];
        }

        this.applicationSettingsProxy = this.proxyGenerator(
            this.applicationSettingsStorage
        );
        this.userSettingsProxy = this.proxyGenerator(this.userSettingsStorage);
        this.brandingProxy = this.proxyGenerator(this.brandingStorage);
        this.historyProxy = this.proxyGenerator(this.historyStorage);
        this.favoritesProxy = this.proxyGenerator(this.favoritesStorage);
        this.pluginsProxy = this.pluginProxyGenerator();
        if (
            !this.applicationSettingsProxy.backgroundEffects &&
            this.userSettingsProxy.enableBackgroundEffects
        ) {
            this.userSettingsProxy.enableBackgroundEffects = false;
        }
    }

    private pluginProxyGenerator() {
        return new Proxy(this.pluginStorage, {
            set: (target, property, value) => {
                if (isNaN(Number(property))) {
                    return Reflect.set(target, property, value);
                }
                let success: boolean;

                const pluginProxy = new Proxy(value, {
                    set: (target, property, value) => {
                        const success = Reflect.set(target, property, value);
                        if (success) {
                            localStorage.setItem(
                                'pexPlugins',
                                JSON.stringify(this.pluginStorage)
                            );
                        }
                        return success;
                    }
                });

                success = Reflect.set(target, property, pluginProxy);

                if (success) {
                    localStorage.setItem('pexPlugins', JSON.stringify(target));
                }
                return success;
            }
        });
    }

    private proxyGenerator<T extends object>(storage: PexStorageBase<T>): T {
        return new Proxy(storage.values, this.proxyHandlerGenerator(storage));
    }

    private proxyHandlerGenerator<T extends object>(
        storage: PexStorageBase<T>
    ): ProxyHandler<T> {
        return {
            set: (_target, property, value) => {
                if (value === null) {
                    storage.deleteItem(<keyof T>String(property));
                    return true;
                }

                storage.setItem(<keyof T>String(property), value);
                return true;
            },

            get: (_target, property, _receiver) => {
                return storage.values[property];
            }
        };
    }

    private safeParse(item: string) {
        try {
            return JSON.parse(localStorage.getItem(item));
        } catch (e) {
            this.loggingService.warn(`Corrupt localStorage settings: ${item}`);
            return null;
        }
    }

    update(settingsModel: BrandedSettingsModel) {
        this.loggingService.info('storage service: updating settings');
        this.applicationSettingsStorage.reset();
        Object.assign(
            this.applicationSettingsProxy,
            settingsModel.applicationSettings
        );
        this.userSettingsStorage.updateDefaults(
            settingsModel.defaultUserSettings
        );
        this.userSettingsStorage.resetValues(settingsModel.defaultUserSettings);
        this.resetPlugins();
        if (settingsModel.plugins) {
            this.pluginsProxy.push(...settingsModel.plugins);
            localStorage.setItem(
                'pexPluginDefaults',
                JSON.stringify(settingsModel.plugins)
            );
        }
        this.update$.next();
    }

    resetPlugins() {
        localStorage.removeItem('pexPluginDefaults');
        localStorage.removeItem('pexPlugins');
        this.pluginStorage = [];
        this.pluginsProxy = this.pluginProxyGenerator();
    }

    resetStorage() {
        this.loggingService.info('storage-service: resetting storage');
        this.brandingStorage.reset();

        this.historyStorage.reset();
        this.spacesStorage.reset();
        localStorage.removeItem('pexRegistration');
        this.favoritesStorage.reset();
        this.pluginStorage.splice(0, this.pluginStorage.length);
        localStorage.removeItem('pexPlugins');
        if (
            this.platformService.platform === 'web' ||
            this.platformService.preBaked
        ) {
            this.userSettingsStorage.resetValues();
            const plugins = JSON.parse(
                localStorage.getItem('pexPluginDefaults')
            );
            if (plugins) {
                this.pluginsProxy.push(...plugins);
            }
        } else {
            this.userSettingsStorage.resetAll();
            this.applicationSettingsStorage.reset();
        }
        this.reset$.next();
    }

    resetLanguages() {
        const applicationSettingsModel = new this.applicationSettingsModelFactory();
        this.applicationSettingsStorage.reset({
            languages: applicationSettingsModel.languages
        });
        this.userSettingsStorage.resetValues({
            language: ''
        });
        this.brandingProxy.cachedLanguages = {};
    }
}
