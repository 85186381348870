import {
    AfterViewInit,
    Component,
    ElementRef,
    QueryList,
    ViewChildren
} from '@angular/core';
import { BaseDialogContentComponent } from './base-dialog-content.component';

export interface ActionsDialogConfig {
    escapeAction?: string;
    translationParams?: Translation;
    titleClass?: string;
    title?: string;
    message?: string;
    actions?: string[];
    code?: string;
}

@Component({
    templateUrl: './actions-dialog.component.html',
    styleUrls: ['./actions-dialog.component.scss']
})
export class ActionsDialogComponent
    extends BaseDialogContentComponent<string, ActionsDialogConfig>
    implements AfterViewInit {
    translationParams: Translation;

    @ViewChildren('dialogButtons') dialogButtons: QueryList<ElementRef>;

    ngAfterViewInit() {
        super.ngAfterViewInit();
        if (
            this.dialogButtons &&
            this.dialogButtons.first &&
            this.dialogButtons.first.nativeElement
        ) {
            this.dialogButtons.first.nativeElement.focus();
        }
    }

    afterInit() {
        this.translationParams = this.stripTranslationParams(
            this.config.translationParams
        );
    }

    stripTranslationParams(translationParams: Translation) {
        for (const key in translationParams) {
            if (translationParams.hasOwnProperty(key)) {
                translationParams[key] = translationParams[key]
                    .replace(/&/g, '&amp;')
                    .replace(/</g, '&lt;')
                    .replace(/>/g, '&gt;');
            }
        }

        return translationParams;
    }

    escapePressed(_e: KeyboardEvent) {
        this.close(this.safeConfig.escapeAction);
    }
}
