import { Component, OnDestroy, OnInit } from '@angular/core';

import {
    TakeUntilDestroy,
    untilDestroyed
} from '../../shared/take-until-destory.decorator';
import { ToastMessage } from './toast-message';
import { ToastService } from './toast.service';

@TakeUntilDestroy()
@Component({
    selector: 'pex-toast-overlay',
    templateUrl: './toast-overlay.component.html',
    styleUrls: ['./toast.scss']
})
export class ToastOverlayComponent implements OnInit, OnDestroy {
    toasts: ToastMessage[] = [];

    constructor(private _toastService: ToastService) {}

    ngOnInit() {
        this._toastService.addToast
            .pipe(untilDestroyed(this))
            .subscribe((toast: ToastMessage) => this.onAddToast(toast));

        this._toastService.removeToast
            .pipe(untilDestroyed(this))
            .subscribe((toast: ToastMessage) => this.onRemoveToast(toast));
    }

    onAddToast(toast: ToastMessage) {
        const toastAlreadyToasting = this.toasts.find(t => t.id === toast.id);
        if (!toastAlreadyToasting) {
            this.toasts.push(toast);
            if (toast.timeout) {
                setTimeout(() => this.onRemoveToast(toast), toast.timeout);
            }
        }
    }

    onRemoveToast(toast: ToastMessage) {
        const targetToast = this.toasts.find(t => t.id === toast.id);
        if (targetToast) {
            targetToast.exitClass = 'animate__zoomOut';
            setTimeout(() => {
                this.toasts = this.toasts.filter(t => t.id !== toast.id);
                targetToast.exitClass = '';
            }, 100);
        }
    }

    ngOnDestroy() {}
}
