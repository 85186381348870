import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    EventEmitter,
    OnDestroy,
    Output,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';

import {
    TakeUntilDestroy,
    untilDestroyed
} from '../take-until-destory.decorator';
import { ContextMenuComponent } from './context-menu.component';

@TakeUntilDestroy()
@Directive({
    selector: '[pexContextMenu]',
    exportAs: 'pexContextMenu'
})
export class ContextMenuDirective implements OnDestroy {
    @Output()
    opened = new EventEmitter();

    private componentRef: ComponentRef<ContextMenuComponent>;
    private activeElement: HTMLElement;

    constructor(
        private templateRef: TemplateRef<unknown>,
        private viewContainerRef: ViewContainerRef,
        private resolver: ComponentFactoryResolver,
        private applicationRef: ApplicationRef
    ) {}

    ngOnDestroy() {}

    create(context?: unknown) {
        this.close();
        this.activeElement = document.activeElement as HTMLElement;
        const rootComponent = this.applicationRef.components[0].instance;
        const viewContainerRef =
            rootComponent.viewContainerRef || this.viewContainerRef;
        const factory = this.resolver.resolveComponentFactory(
            ContextMenuComponent
        );
        this.componentRef = viewContainerRef.createComponent(factory);
        this.componentRef.instance.context = context;
        this.componentRef.instance.templateRefInput = this.templateRef;
        this.componentRef.instance.close$
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.close();
            });
    }

    open(element: HTMLElement, context?: unknown) {
        this.create(context);
        this.componentRef.instance.open(element);
        this.opened.emit();
    }

    openAboveRight(element: HTMLElement, context?: unknown) {
        this.create(context);
        this.componentRef.instance.openAboveRight(element);
        this.opened.emit();
    }

    openBelowLeft(element: HTMLElement, focus = false, context?: unknown) {
        this.create(context);
        this.componentRef.instance.openBelowLeft(element, focus);
        this.opened.emit();
    }

    openBelowRight(element: HTMLElement, context?: unknown) {
        this.create(context);
        this.componentRef.instance.openBelowRight(element);
        this.opened.emit();
    }

    close() {
        this.activeElement?.focus?.();
        if (this.componentRef) {
            this.componentRef.destroy();
            this.componentRef = null;
        }
    }
}
