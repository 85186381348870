import { Injectable } from '@angular/core';

import { PlatformService } from '../core/platform.service';
import { PexConstantsWeb } from '../core/shared/constants';

@Injectable()
export class WebPlatformService extends PlatformService {
    readonly platform = 'web';
    browserType: string;
    browserMajorVersion: number;
    browserFullVersion: string;
    mobileBrowser: boolean;
    hostPlatform: string;

    get constants() {
        return __CONSTANTS__ as PexConstantsWeb;
    }

    constructor() {
        super();
        if (
            navigator &&
            /mobi|android|tablet|ipad|iphone/.test(
                navigator.userAgent.toLowerCase()
            )
        ) {
            this.mobileBrowser = true;
        }

        if (navigator.platform) {
            this.hostPlatform = navigator.platform.split(' ')[0];
        }

        if (navigator && navigator.userAgent.indexOf('Edge') > -1) {
            this.browserType = 'edge';
        } else if (navigator && navigator.userAgent.indexOf('Chrome') > -1) {
            this.browserType = 'chrome';
            this.browserMajorVersion = parseInt(
                navigator.userAgent.match(/Chrome\/(\d+)\./)[1],
                10
            );
        } else if (navigator && navigator.userAgent.indexOf('Firefox') > -1) {
            this.browserType = 'firefox';
            this.browserMajorVersion = parseInt(
                navigator.userAgent.match(/Firefox\/(\d+)\./)[1],
                10
            );
        } else if (navigator && navigator.userAgent.indexOf('Safari') > -1) {
            this.browserType = 'safari';
            if (/Version/.test(navigator.userAgent)) {
                this.browserMajorVersion = parseInt(
                    navigator.userAgent.match(/Version\/(\d+)\./)[1],
                    10
                );
            }
        }
    }
}
