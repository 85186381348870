import * as angularCommon from '@angular/common';
import * as angularHttp from '@angular/common/http';
import * as angularCore from '@angular/core';
import * as angularForms from '@angular/forms';
import * as rxjs from 'rxjs';
import * as rxjsOperators from 'rxjs/operators';

import { enableProdMode } from '@angular/core';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app.module';

if (ENV === 'production') {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
