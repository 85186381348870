<div
    class="pex-dialog"
    (click)="clickOutside($event)"
    (window:keydown.Escape)="escapePressed($event)"
    [style.z-index]="priority + 1000"
>
    <!-- <div tabindex="0" style="position: absolute"></div> -->
    <div
        role="dialog"
        class="pex-dialog-content"
        (click)="$event.stopPropagation()"
        aria-modal="true"
    >
        <ng-template #content></ng-template>
    </div>
    <!-- <div tabindex="0" style="position: absolute"></div> -->
</div>
