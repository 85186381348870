import { HttpClient } from '@angular/common/http';
import { ComponentFactory, Injectable, NgZone } from '@angular/core';
import { SafeStyle, SafeUrl } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import * as JSZip from 'jszip';
import { file } from 'jszip';
import { from, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { BrandingApplyDialogComponent } from '../dialog/branding-dialog/branding-dialog.component';
import { DialogService } from '../dialog/dialog.service';

import { LoggingService } from '../logging.service';
import { PlatformService } from '../platform.service';
import { SanitizeStylePipe } from './../shared/sanitize-style.pipe';
import { SanitizeUrlPipe } from './../shared/sanitize-url.pipe';
import { PluginService } from './plugin/plugin.service';
import { BrandingManifest } from './storage/branding.storage';
import {
    BrandedSettingsModel,
    LanguageModel,
    PluginSettingsModel,
    SettingsModel
} from './storage/settings.model';
import { StorageService } from './storage/storage.service';

class SafeCachedImage {
    constructor(
        public image: string,
        private sanitizeUrlPipe: SanitizeUrlPipe,
        private sanitizeStylePipe: SanitizeStylePipe
    ) {}

    private _safeImageURL: SafeUrl;
    get safeImageURL() {
        if (!this._safeImageURL)
            this._safeImageURL = this.sanitizeUrlPipe.transform(this.image);
        return this._safeImageURL;
    }

    private _safeImageURLStyle: SafeStyle;
    get safeImageURLStyle() {
        if (!this._safeImageURLStyle)
            this._safeImageURLStyle = this.sanitizeStylePipe.transform(
                `url("${this.image}")`
            );
        return this._safeImageURLStyle;
    }
}

@Injectable({
    providedIn: 'root'
})
export class BrandingService {
    private loadedStyle: HTMLStyleElement;
    public images: { [id: string]: SafeCachedImage } = {};
    public manifest: BrandingManifest;
    public isCustomHeaderAdded = false;
    public customHeaderComponent: ComponentFactory<unknown>;
    public brandingPackage: JSZip;

    get brandingURL() {
        return this.storageService.brandingProxy.brandingURL;
    }

    constructor(
        private httpClient: HttpClient,
        private storageService: StorageService,
        private platformService: PlatformService,
        private loggingService: LoggingService,
        private translate: TranslateService,
        private titleService: Title,
        private pluginService: PluginService,
        private ngZone: NgZone,
        private sanitizeStylePipe: SanitizeStylePipe,
        private sanitizeUrlPipe: SanitizeUrlPipe,
        private dialogService: DialogService
    ) {}

    init() {
        this.loggingService.info('Branding init start');
        const cachedStyle = this.storageService.brandingProxy.cachedStyle;
        const cachedImages = this.storageService.brandingProxy.cachedImages;

        if (cachedStyle) {
            const node = document.createElement('style');
            node.innerHTML = cachedStyle;
            document.body.appendChild(node);
            this.loadedStyle = node;
        }
        if (cachedImages) {
            for (const prop in cachedImages) {
                if (cachedImages.hasOwnProperty(prop)) {
                    this.images[prop] = new SafeCachedImage(
                        cachedImages[prop],
                        this.sanitizeUrlPipe,
                        this.sanitizeStylePipe
                    );
                }
            }
        }

        this.translate.setDefaultLang('configuration/languages/en');
    }

    private async calculateSHA256(buffer: ArrayBuffer) {
        const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
            .map(b => b.toString(16).padStart(2, '0'))
            .join('');
        return hashHex;
    }

    private getSHA256FromSignature(signature: string) {
        const arr = signature.split('.');
        if (arr.length > 1) {
            try {
                const payload = JSON.parse(atob(arr[1]));
                if (payload && payload.sha256) {
                    return payload.sha256;
                }
            } catch (e) {
                this.loggingService.warn(
                    'Branding - SHA256 could not be retrieved from signature',
                    e
                );
                return;
            }
        }
        return;
    }

    async getBranding() {
        try {
            await this.getManifest();
            // if (timeout) {
            //     this.loggingService.warn('Init: branding timeout');
            //     this.storageService.resetLanguages();
            // }

            if (this.storageService.applicationSettingsProxy.defaultToMuted) {
                this.storageService.userSettingsProxy.muteMicrophone = true;
            }
            this.storageService.loaded$.next();
            this.storageService.loaded$.complete();
        } catch (e) {
            return this.loggingService.error('Init: branding error', e);
        }
    }

    async getManifest(brandingURL = this.brandingURL) {
        this.loggingService.info('Branding getManifest start');
        if (brandingURL && brandingURL !== this.brandingURL) {
            this.storageService.brandingProxy.brandingURL = brandingURL;
        }
        if (this.platformService.isElectron()) {
            const [signature, zipBuffer] = await this.getBrandingPackage(
                brandingURL
            ).toPromise();
            if (!signature || !zipBuffer) return;
            if (!(await window.pexBridge.verifySignature(signature))) {
                this.loggingService.warn(
                    'Branding - could not verify branding signature'
                );
                return;
            }
            const sha256 = await this.calculateSHA256(zipBuffer);
            if (sha256 !== this.getSHA256FromSignature(signature)) {
                this.loggingService.warn(
                    'Branding - SHA256 of branding package did not match signature'
                );
                return;
            }
            this.brandingPackage = new JSZip();
            try {
                await this.brandingPackage.loadAsync(zipBuffer);
            } catch (e) {
                this.brandingPackage = undefined;
                this.loggingService.warn(
                    'Branding - failed to read branding zip',
                    e
                );
                return;
            }
        }
        return this.getResource(brandingURL, 'manifest.json')
            .toPromise()
            .then(async (manifest: BrandingManifest) => {
                if (!manifest) {
                    //TODO: darius-branding Failed to load manifest because of 404 or JSON error
                    this.storageService.resetLanguages();
                    return;
                }

                if (
                    manifest.brandingID === undefined &&
                    manifest['brandingTimestamp'] !== undefined
                ) {
                    manifest.brandingID = manifest['brandingTimestamp'];
                }
                const cachedBrandingID = this.storageService.brandingProxy
                    .cachedManifest?.brandingID;
                if (
                    this.platformService.isElectron() &&
                    manifest.brandingID !== cachedBrandingID &&
                    (manifest.isCustomBackgroundBranded ||
                        manifest.isSettingsBranded ||
                        manifest.isStylesBranded)
                ) {
                    await (
                        await this.dialogService.open(
                            BrandingApplyDialogComponent,
                            manifest
                        )
                    ).close$
                        .pipe(filter(apply => apply))
                        .toPromise();
                }
                return this.handleManifest(manifest, brandingURL);
            });
    }

    async handleManifest(manifest: BrandingManifest, brandingURL: string) {
        this.manifest = manifest;

        const cachedBrandingID = this.storageService.brandingProxy
            .cachedManifest?.brandingID;
        if (
            this.manifest.brandingID === undefined &&
            this.manifest['brandingTimestamp'] !== undefined
        ) {
            this.manifest.brandingID = this.manifest['brandingTimestamp'];
        }

        if (this.manifest.brandingID !== cachedBrandingID) {
            return this.updateBranding(brandingURL);
        }

        if (!this.manifest.isSettingsBranded) {
            this.clearSettings();
        }

        if (!this.manifest.isStylesBranded) {
            this.clearStyles();
        }
        if (!this.manifest.isWatermarkBranded) {
            this.clearFile('watermark');
        }
        if (!this.manifest.isCustomBackgroundBranded) {
            this.clearFile('background');
        }

        this.setLanguage();
    }

    async loadPluginResources() {
        if (this.brandingPackage) {
            const pluginDirPath = 'webapp2/plugins/';
            const pluginDir = this.brandingPackage.files[pluginDirPath];
            if (pluginDir?.dir) {
                // Loop through all files in plugin dir
                await Promise.all(
                    Object.entries(this.brandingPackage.files)
                        .filter(
                            ([path, { dir }]) =>
                                path.startsWith('webapp2/plugins/') && !dir
                        )
                        .map(async ([path, file]) => {
                            const relativePath = path
                                .split('/')
                                .splice(1)
                                .join('/');
                            const fileContents = await file.async('string');
                            this.pluginService.addPluginResource(
                                relativePath,
                                fileContents
                            );
                        })
                );
            }
        }
    }

    async updateBranding(brandingURL = this.brandingURL) {
        this.loggingService.info('Branding updateBranding start');
        const falsePromise = Promise.resolve<false>(false);
        const manifestPromises: [
            Promise<RecursivePartial<SettingsModel> | false>,
            Promise<string | false>,
            Promise<Blob | false>,
            Promise<Blob | false>
        ] = [falsePromise, falsePromise, falsePromise, falsePromise];
        if (this.platformService.isElectron()) {
            await this.loadPluginResources();
        }

        if (this.manifest.isSettingsBranded) {
            manifestPromises[0] = this.getResource(
                brandingURL,
                'settings.json'
            ).toPromise();
        }

        if (this.platformService.platform !== 'web') {
            if (this.manifest.isStylesBranded) {
                manifestPromises[1] = this.getTextResource(
                    brandingURL,
                    'themes/styles.css'
                ).toPromise();
            }
            if (this.manifest.isWatermarkBranded) {
                manifestPromises[2] = this.getBlobResource(
                    brandingURL,
                    'watermark_icon.png'
                ).toPromise();
            }
            if (this.manifest.isCustomBackgroundBranded) {
                manifestPromises[3] = this.getBlobResource(
                    brandingURL,
                    'background.jpg'
                ).toPromise();
            }
        }

        return Promise.all(manifestPromises).then(results => {
            if (results.some(result => result === null)) {
                this.loggingService.warn(
                    'Branding: One or more manifest items that should have loaded failed to load'
                );
                //TODO: darius-branding Failed to load one or more manifest items that should have been loaded
                return;
            }
            this.loggingService.info('Branding: resources loaded');

            const settingsResult = results[0];
            const stylesResult = results[1];
            const watermarkResult = results[2];
            const backgroundResult = results[3];

            if (settingsResult) {
                this.loggingService.info(
                    'Branding: handling settings json',
                    settingsResult
                );
                if (
                    settingsResult.applicationSettings &&
                    settingsResult.applicationSettings.languages !== undefined
                ) {
                    settingsResult.applicationSettings.languages = this.languagesFormatNormalization(
                        settingsResult.applicationSettings.languages
                    );

                    if (this.platformService.platform !== 'web') {
                        this.cacheLanguages(
                            settingsResult.applicationSettings
                                .languages as LanguageModel[],
                            brandingURL
                        );
                    }
                }
                if (settingsResult.plugins !== undefined) {
                    settingsResult.plugins = this.pluginsFormatNormalization(
                        settingsResult.plugins
                    ) as PluginSettingsModel[];
                }

                this.applySettings(settingsResult as BrandedSettingsModel);
            } else {
                this.clearSettings();
            }

            if (stylesResult) {
                this.applyStyles(stylesResult);
            } else {
                this.clearStyles();
            }

            if (watermarkResult) {
                this.applyFile(watermarkResult);
            } else {
                this.clearFile('watermark');
            }

            if (backgroundResult) {
                this.applyFile(backgroundResult, 'background');
            } else {
                this.clearFile('background');
            }

            this.storageService.brandingProxy.cachedManifest = this.manifest;
            this.setLanguage(brandingURL);
        });
    }

    applySettings(settings: BrandedSettingsModel) {
        this.loggingService.info('Branding applySettings start');
        this.pluginService.resetPlugins();
        this.storageService.update(settings);
        this.pluginService.init();
        this.loggingService.info('Branding applySettings end');
    }

    private clearSettings() {
        this.storageService.resetLanguages();
        this.pluginService.resetPlugins();
        this.storageService.resetPlugins();
        this.storageService.applicationSettingsStorage.reset();
        this.storageService.userSettingsStorage.resetDefaults();
    }

    private languagesFormatNormalization(
        languages: (string | number | boolean | Partial<LanguageModel>)[]
    ): LanguageModel[] {
        if (!Array.isArray(languages)) {
            return [];
        }

        return languages.reduce((acc, l) => {
            if (typeof l === 'string') {
                return [...acc, { id: `custom_${l}`, locale: l, label: l }];
            }

            if (typeof l === 'number' || typeof l === 'boolean') {
                return acc;
            }

            if (!l.locale || !l.label) {
                return acc;
            }

            return [...acc, l];
        }, []);
    }

    private pluginsFormatNormalization(
        plugins: Partial<PluginSettingsModel>[]
    ): PluginSettingsModel[] {
        if (!Array.isArray(plugins)) {
            return [];
        }

        return plugins.reduce((acc, p) => {
            if (
                typeof p === 'string' ||
                typeof p === 'number' ||
                typeof p === 'boolean'
            ) {
                return acc;
            }

            if (!p.id || !p.srcURL || p.enabled === undefined) {
                return acc;
            }

            return [...acc, p];
        }, []);
    }

    private applyStyles(styles: string) {
        this.loggingService.info('Branding applyStyles start');
        if (this.platformService.platform === 'web') {
            return;
        }

        const node = document.createElement('style');
        node.innerHTML = <string>styles;
        document.body.appendChild(node);
        if (this.loadedStyle) {
            document.body.removeChild(this.loadedStyle);
        }
        this.loadedStyle = node;
        this.storageService.brandingProxy.cachedStyle = styles;
    }

    private clearStyles() {
        this.storageService.brandingProxy.cachedStyle = null;
        if (this.loadedStyle) {
            document.body.removeChild(this.loadedStyle);
            this.loadedStyle = null;
        }
    }

    private applyFile(imageFile: Blob, fileName = 'watermark') {
        this.loggingService.info(`Branding apply${fileName} start`);
        this.ngZone.runOutsideAngular(() => {
            const reader = new FileReader();
            reader.onload = () => {
                this.images = {
                    ...this.images,
                    [fileName]: new SafeCachedImage(
                        reader.result as string,
                        this.sanitizeUrlPipe,
                        this.sanitizeStylePipe
                    )
                };
                this.storageService.brandingProxy.cachedImages = {
                    ...this.storageService.brandingProxy.cachedImages,
                    [fileName]: reader.result as string
                };
            };
            reader.readAsDataURL(imageFile);
        });
    }

    private clearFile(fileName: string) {
        if (
            this.storageService.brandingProxy.cachedImages &&
            this.storageService.brandingProxy.cachedImages[fileName]
        ) {
            this.storageService.brandingProxy.cachedImages[
                fileName
            ] = undefined;
        }

        if (this.images && this.images[fileName]) {
            this.images[fileName] = undefined;
        }
    }

    resetBranding() {
        this.loggingService.info('Branding resetBranding start');
        this.storageService.brandingProxy.cachedManifest = null;
        this.storageService.brandingProxy.cachedStyle = null;
        this.storageService.brandingProxy.cachedImages = null;
        if (this.loadedStyle) {
            document.body.removeChild(this.loadedStyle);
            this.loadedStyle = null;
            this.images = {};
        }
        this.images = {};
        this.manifest = {};

        if (this.platformService.isElectron()) {
            window.pexBridge.clearTrustedKey();
        }
    }

    setLanguage(brandingURL = this.brandingURL) {
        this.loggingService.info('Branding - setLanguage start');
        if (!this.storageService.userSettingsStorage.currentValues.language) {
            // there's no store used preference for language, what does browser report ?
            this.loggingService.info('Branding - no current language set');
            const cultureLanguage = this.translate.getBrowserCultureLang();
            const baseLanguage = this.translate.getBrowserLang();
            this.loggingService.info(
                `Branding - browser is reporting culture language as ${cultureLanguage}, base language as ${baseLanguage}`
            );
            const languages = this.storageService.applicationSettingsProxy.languages.map(
                language => language.locale
            );
            this.loggingService.info(
                `Branding - languages currently available ${languages}`
            );
            if (
                cultureLanguage &&
                languages.includes(cultureLanguage.toLowerCase())
            ) {
                this.loggingService.info(
                    `Branding - language set with prefix "${cultureLanguage}"`
                );
                this.storageService.userSettingsProxy.language = cultureLanguage.toLowerCase();
            } else if (
                baseLanguage &&
                languages.includes(baseLanguage.toLowerCase())
            ) {
                this.loggingService.info(
                    `Branding - language set with prefix from base language "${baseLanguage}"`
                );
                this.storageService.userSettingsProxy.language = baseLanguage.toLowerCase();
            } else {
                this.loggingService.info(
                    `Branding - no matching language for "${cultureLanguage}"`
                );
            }
        } else {
            // user has stored a language preference
            this.loggingService.info(
                `Branding - current language set to ${this.storageService.userSettingsStorage.currentValues.language}`
            );
        }

        this.loggingService.info(
            `Branding - checking for ${this.storageService.userSettingsProxy.language} in brandedLanguages ${this.brandedLanguages}`
        );

        if (
            Object.keys(
                this.storageService.brandingProxy.cachedLanguages
            ).includes(this.storageService.userSettingsProxy.language) &&
            this.platformService.platform !== 'web'
        ) {
            this.loggingService.info(
                `Branding - (!web) cached language found for ${this.storageService.userSettingsProxy.language}`
            );
            this.translate.setTranslation(
                this.storageService.userSettingsProxy.language,
                this.storageService.brandingProxy.cachedLanguages[
                    this.storageService.userSettingsProxy.language
                ],
                true
            );
            this.translate.use(this.storageService.userSettingsProxy.language);
        } else if (
            this.brandedLanguages.includes(
                this.storageService.userSettingsProxy.language
            )
        ) {
            this.loggingService.info(
                `Branding - branded language "${this.storageService.userSettingsProxy.language}" available`
            );
            try {
                const langPath = `languages/${this.storageService.userSettingsProxy.language}`;
                const langName = `${brandingURL}/${langPath}`;
                this.loggingService.info(
                    `Branding - attempting to fetch language file for "${langName}"`
                );
                this.getResource(brandingURL, `${langPath}.json`)
                    .toPromise()
                    .then((lang: { [key: string]: string }) => {
                        if (!lang) {
                            this.loggingService.info(
                                'Branding - failed to fetch lang from file'
                            );
                            return;
                        }
                        this.translate.setTranslation(langName, lang, true);
                        this.translate.use(langName);
                        this.translate
                            .get('APPLICATION.TITLE')
                            .subscribe(title =>
                                this.titleService.setTitle(title)
                            );
                    });
            } catch (e) {
                this.loggingService.info(
                    `Branding - failed to apply language ${e}`
                );
            }
        } else {
            this.loggingService.info(
                `Branding - attempting to use stored language for ${this.storageService.userSettingsProxy.language}`
            );
            try {
                const langPath = `languages/${this.storageService.userSettingsProxy.language}`;
                const langName = `configuration/${langPath}`;
                this.loggingService.info(
                    `Branding - attempting to fetch stored language file for "${langName}"`
                );
                this.getResource('configuration', `${langPath}.json`)
                    .toPromise()
                    .then((lang: { [key: string]: string }) => {
                        if (!lang) {
                            this.loggingService.info(
                                'Branding - failed to fetch stored lang from file'
                            );
                            return;
                        }
                        this.translate.setTranslation(langName, lang, true);
                        this.translate.use(langName);
                        this.translate
                            .get('APPLICATION.TITLE')
                            .subscribe(title =>
                                this.titleService.setTitle(title)
                            );
                    });
            } catch (e) {
                this.loggingService.warn(
                    'Branding - cant use stored language, falling back to en'
                );
                this.translate.use('configuration/languages/en');
            }
            this.translate
                .get('APPLICATION.TITLE')
                .subscribe(title => this.titleService.setTitle(title));
        }
    }

    private get brandedLanguages() {
        if (
            !this.storageService.applicationSettingsStorage.currentValues
                .languages
        )
            return [];

        return this.storageService.applicationSettingsStorage.currentValues.languages.map(
            language => language.locale
        );
    }

    private getBlobResource(url: string, path: string) {
        return this.getResource(url, path, { responseType: 'blob' });
    }

    private getTextResource(url: string, path: string) {
        return this.getResource(url, path, { responseType: 'text' });
    }

    private getBrandingPackage(
        brandingURL: string
    ): Observable<[string, ArrayBuffer]> {
        const brandingSig$ = this.httpClient
            .get(`${brandingURL}/branding.zip.sig?${Date.now()}`, {
                responseType: 'text'
            })
            .pipe(
                catchError(error => {
                    this.loggingService.warn(
                        'Branding - could not find a signature file',
                        error
                    );
                    return of<null>(null);
                })
            );
        return brandingSig$.pipe(
            switchMap(sig => {
                if (!sig) return of([null, null]);
                return this.httpClient
                    .get(`${brandingURL}/branding.zip?${Date.now()}`, {
                        responseType: 'arraybuffer'
                    })
                    .pipe(
                        map(zip => [sig, zip]),
                        catchError(error => {
                            this.loggingService.warn(
                                'Branding - could not find a branding package',
                                error
                            );
                            return [null, null];
                        })
                    );
            })
        );
    }

    private getResource(
        url: string,
        path: string,
        options?: any // tslint:disable-line:no-any
        // tslint:disable-next-line:no-any
    ): Observable<any> {
        this.loggingService.info(`Branding - getResource: ${url}/${path}`);
        if (this.platformService.isElectron()) {
            if (this.brandingPackage) {
                const file = this.brandingPackage.file(`webapp2/${path}`);
                if (!file) return of(null);
                if (options?.responseType === 'blob') {
                    return from(file.async('blob'));
                }
                if (options?.responseType === 'text') {
                    return from(file.async('string'));
                }
                return from(
                    file.async('string').then(fileText => {
                        try {
                            return JSON.parse(fileText);
                        } catch (e) {
                            this.loggingService.warn(
                                'Branding - getResource, failed to parse JSON',
                                e
                            );
                            return null;
                        }
                    })
                );
            } else {
                return of(null);
            }
        } else {
            return this.httpClient
                .get(`${url}/${path}?${Date.now()}`, options)
                .pipe(
                    catchError(error => {
                        this.loggingService.warn(
                            'Branding - ' + `${url} not available: error was`,
                            error
                        );
                        return of(null);
                    })
                );
        }
    }

    private cacheLanguages(
        settingslanguages: LanguageModel[],
        brandingURL: string
    ) {
        const languagePromises = settingslanguages.reduce(
            (languages, { locale }) => {
                languages[locale] = this.getResource(
                    brandingURL,
                    `languages/${locale}.json`
                ).toPromise();
                return languages;
            },
            {} as { [language: string]: Promise<string> }
        );

        if (languagePromises) {
            Promise.all(Object.values(languagePromises)).then(languages => {
                const cachedLanguages: { [id: string]: string } = {};
                Object.keys(languagePromises).forEach((l, i) => {
                    cachedLanguages[l] = languages[i];
                });
                this.storageService.brandingProxy.cachedLanguages = cachedLanguages;
            });
        }
    }

    getWatermarkURL() {
        if (this.images.watermark) {
            return this.images.watermark.safeImageURL;
        }

        if (this.manifest && this.manifest.isWatermarkBranded) {
            return this.brandingURL + '/watermark_icon.png';
        }

        return './configuration/watermark_icon.png';
    }

    getBackgroundURL() {
        if (this.images.background) {
            return this.images.background.safeImageURLStyle;
        }

        if (this.manifest && this.manifest.isCustomBackgroundBranded) {
            return this.sanitizeStylePipe.transform(
                `url("${this.brandingURL + '/background.jpg'}")`
            );
        }
    }
}
