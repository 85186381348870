import { createAction, props } from '@ngrx/store';

import { TimelineEvent } from './event.model';

export const EVENTS_ACTIONS = {
    clear: createAction('[Events] Clear'),
    setTime0Action: createAction(
        '[Events] Set time0',
        props<{ time: number }>()
    ),
    setSelectedEvent: createAction(
        '[Events] Set selected event',
        props<{ payload: TimelineEvent }>()
    )
};

export type EventsActions = ReturnType<
    typeof EVENTS_ACTIONS[keyof typeof EVENTS_ACTIONS]
>;
