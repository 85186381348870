import { DatePipe } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
    APP_INITIALIZER,
    ErrorHandler,
    Injectable,
    NgModule
} from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { metaReducers, reducer } from './app.reducer';

import { ErrorDialogComponent } from './dialog/error-dialog';
import { IncomingCallDialogComponent } from './dialog/incoming-call-dialog';

import { DisplayNameDialogComponent } from './dialog/display-name-dialog';
import { OnboardingDialogComponent } from './dialog/onboarding-dialog/onboarding-dialog.component';

import {
    AnalyticsService,
    BrandingService,
    PluginService,
    StorageService
} from './services';

import { SharedModule } from './shared';

import { MediaDeviceEffects } from './media-device/media-device.effects';

import { DevicePermissionDialogComponent } from './media-device/device-permission-dialog';

import { DialogService } from './dialog/dialog.service';

import { extModules } from 'build/devStore';
import { BrandingApplyDialogComponent } from './dialog/branding-dialog/branding-dialog.component';
import { DialogModule } from './dialog/dialog.module';
import { LoggingService } from './logging.service';
import { MediaDeviceService } from './media-device';
import { RegistrationEffects } from './registration/registration.effects';
import { GeneralEffects } from './shared/general/general.effects';
import { Logger } from './shared/logger';

@Injectable()
export class PexErrorHandler implements ErrorHandler {
    errors: number[] = [];
    exceptions: string[] = [
        'NetworkError: A network error occurred.',
        "Error: Failed to execute 'send' on 'XMLHttpRequest'",
        "TypeError: Cannot read properties of null (reading 'context')"
    ];

    constructor(
        private dialogService: DialogService,
        private analyticsService: AnalyticsService,
        private loggingService: LoggingService,
        public storageService: StorageService
    ) {}

    private generateHash(theThing: string) {
        let hash = 0,
            i,
            chr;
        if (theThing.length === 0) return hash;
        for (i = 0; i < theThing.length; i++) {
            chr = theThing.charCodeAt(i);
            hash = (hash << 5) - hash + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }

    handleError(error: Error) {
        const errorString = error.toString();
        // check for things we know have already been dealt with
        // i.e. network errors triggering pexrtc disconnects
        if (
            this.exceptions.some((exceptionString: string) =>
                errorString.includes(exceptionString)
            )
        ) {
            this.analyticsService.recordEvent('applicationErrorIgnored', {
                errorText: errorString
            });
            return;
        }
        const errorHash = this.generateHash(errorString);
        if (this.errors.indexOf(errorHash) > -1) {
            return;
        } else {
            this.errors.push(errorHash);
            this.loggingService.error('App Error:', error);
            this.analyticsService.recordEvent('applicationError', {
                errorText: errorString
            });
            this.dialogService.open(ErrorDialogComponent, errorString);
        }
    }
}

export class TranslateHttpLoader implements TranslateLoader {
    constructor(private http: HttpClient, public suffix = '.json') {}
    public getTranslation(lang: string) {
        return this.http.get(`${lang}${this.suffix}`);
    }
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export function InitializeFactory(
    storageService: StorageService,
    brandingService: BrandingService,
    loggingService: LoggingService,
    pluginService: PluginService,
    mediaDeviceService: MediaDeviceService
) {
    return async () => {
        Logger.simpleLogs = !!localStorage.getItem('simpleLogs');
        loggingService.info('Init Factory start');
        storageService.init();
        brandingService.init();
        pluginService.init();
        mediaDeviceService.init();
        await brandingService.getBranding();
        loggingService.info('Init Factory end');
    };
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        DialogModule,

        StoreModule.forRoot(reducer, {
            metaReducers,
            runtimeChecks: {
                strictActionImmutability: false,
                strictStateImmutability: false
            }
        }),
        extModules,
        EffectsModule.forRoot([RegistrationEffects]),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),

        SharedModule,

        EffectsModule.forFeature([MediaDeviceEffects]),
        EffectsModule.forFeature([GeneralEffects])
    ],
    declarations: [
        ErrorDialogComponent,
        IncomingCallDialogComponent,
        DevicePermissionDialogComponent,
        DisplayNameDialogComponent,
        OnboardingDialogComponent,
        BrandingApplyDialogComponent
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: InitializeFactory,
            deps: [
                StorageService,
                BrandingService,
                LoggingService,
                PluginService,
                MediaDeviceService
            ],
            multi: true
        },
        DatePipe,
        Title,
        { provide: ErrorHandler, useClass: PexErrorHandler },
        DialogService
    ],
    exports: [BrowserModule, SharedModule]
})
export class CoreModule {}
