import { Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import {
    BehaviorSubject,
    combineLatest,
    interval,
    Observable,
    race,
    Subject,
    Subscriber,
    Subscription,
    timer
} from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    map,
    share,
    skip,
    take,
    withLatestFrom
} from 'rxjs/operators';
import { DeviceList } from './media-devices/device-list.interface';

import { DialogService } from '../dialog/dialog.service';
import { LoggingService } from '../logging.service';
import { PlatformService } from '../platform.service';
import { DevicePermissionDialogComponent } from './device-permission-dialog';
import { MediaDeviceKindEnum } from './media-device.model';

import { HomeFacade } from '../home/home.facade';
import { StorageService } from '../services/storage/storage.service';
import { PexConstantsMobile } from '../shared/constants';
import { BackgroundEffectsService } from './background-effects/background-effects.service';
import {
    addPtzToVideoConstraints,
    getPtzPermissionStatus,
    isVideoTrackWithPtzCapabilities
} from './fecc/fecc.util';
import { MediaDeviceFacade } from './media-device.facade';
import { MediaDeviceState } from './media-device.reducer';
import { VideoProcessing } from './video-processing/video-processing';

import { CitrixInit } from '@pexip/pex-citrix';
import { Logger } from '../shared/logger';

@Injectable({
    providedIn: 'root'
})
export class MediaDeviceService {
    readonly GET_USER_MEDIA_TIMEOUT = 10000;

    getUserMediaSubscription: Subscription;

    inputDevices$: Observable<{
        audioInputDevice: MediaDeviceInfo;
        videoInputDevice: MediaDeviceInfo;
    }>;
    videoInputDevice$: Observable<MediaDeviceInfo>;
    audioInputDevice$: Observable<MediaDeviceInfo>;
    audioOutputDevice$: Observable<MediaDeviceInfo>;
    startMedia$ = new BehaviorSubject<boolean>(false);

    electronWaitingToClose$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        null
    );

    deviceList: { [x: string]: DeviceList };
    currentDeviceList: string;

    mediaDevices$: Subject<MediaDeviceInfo[]> = new BehaviorSubject<
        MediaDeviceInfo[]
    >([]);
    private mediaDevices: MediaDeviceInfo[] = [];
    videoInputDevices$ = this.mediaDevices$.pipe(
        map(devices => devices.filter(device => device.kind === 'videoinput'))
    );
    audioInputDevices$ = this.mediaDevices$.pipe(
        map(devices => devices.filter(device => device.kind === 'audioinput'))
    );
    audioOutputDevices$ = this.mediaDevices$.pipe(
        map(devices => devices.filter(device => device.kind === 'audiooutput'))
    );

    pexrtcUserMediaStream: MediaStream;

    userMediaStream: MediaStream;
    userMediaStream$: Subject<MediaStream>;
    streamConstraints$: MediaDeviceFacade['streamConstraints$'];
    audioInputVolume$: Observable<number>;
    lastVolume = 0;
    volumeSubscriber: Subscriber<number>;
    shouldPlaySound = false;
    shouldGetMediaStream = true;

    gettingUserMedia = false;
    private latestMediaConstraints: MediaStreamConstraints;
    private previousMediaConstraints: MediaStreamConstraints;
    private previousBandwidth: string;
    private prevWebBlur: boolean;
    private prevFeccSetting: boolean;

    failedMediaConstraints: MediaStreamConstraints;

    audioContext: AudioContext;
    analyser: AnalyserNode;
    private mediaStreamAudio: MediaStreamAudioDestinationNode;
    private mediaStreamAudioSource: MediaStreamAudioSourceNode;
    rawMediaStream: MediaStream;

    bandwidthLabelsSelected: { [value: string]: string };
    bandwidthLabelsOptions: { [value: string]: string };

    showPermissionDialog = true; // ensure only one permission dialog open at a time

    currentIOSAudioOutputDevice: MediaDeviceInfo;

    homeCallType: string;

    noneEscalate$ = new Subject<CallType>();
    trackEnded$ = new Subject<void>();
    trackMuted$ = new Subject<void>();
    gumError$ = new Subject<string>();

    private webBlur: VideoProcessing;

    private gumTimeout: number;

    // from home page
    get callType(): string {
        return this.storageService.userSettingsStorage.callType;
    }

    get bandwidth(): string {
        return this.storageService.userSettingsProxy.bandwidth;
    }

    get bandwidths(): string[] {
        return this.storageService.applicationSettingsProxy.bandwidths;
    }

    constructor(
        private ngZone: NgZone,
        private injector: Injector,
        private platformService: PlatformService,
        private translate: TranslateService,
        private storageService: StorageService,
        private dialogService: DialogService,
        private loggingService: LoggingService,
        private mediaDeviceFacade: MediaDeviceFacade,
        private homeFacade: HomeFacade,
        private backgroundEffectsService: BackgroundEffectsService
    ) {}

    init() {
        if (this.platformService.isCitrixApp()) {
            CitrixInit(
                window.pexBridge.getWindowHandle,
                // This function should check the registry to see if CitrixHDX is enabled, I am assuming it is if we are running in Citrix
                () => Promise.resolve(1),
                {
                    refreshTimerMS: 500,
                    useCitrixScreenshare: true,
                    showMasking: false
                }
            );
        }

        this.inputDevices$ = this.mediaDeviceFacade.inputDevices$;
        this.videoInputDevice$ = this.mediaDeviceFacade.videoInputDevice$;
        this.audioInputDevice$ = this.mediaDeviceFacade.audioInputDevice$;
        this.audioOutputDevice$ = this.mediaDeviceFacade.audioOutputDevice$;
        this.streamConstraints$ = this.mediaDeviceFacade.streamConstraints$;

        this.previousBandwidth = this.storageService.userSettingsProxy.bandwidth;

        this.bandwidthLabelsSelected = {
            ['']: 'SETTINGS_DIALOG.BANDWIDTH_AUTO_PREFIX',
            ['256']: 'SETTINGS_DIALOG.BANDWIDTH_VERY_LOW',
            ['576']: 'SETTINGS_DIALOG.BANDWIDTH_LOW',
            ['1264']: 'SETTINGS_DIALOG.BANDWIDTH_MEDIUM',
            ['2464']: 'SETTINGS_DIALOG.BANDWIDTH_HIGH',
            ['6144']: 'SETTINGS_DIALOG.BANDWIDTH_VERY_HIGH'
        };
        this.bandwidthLabelsOptions = {
            ['']: 'SETTINGS_DIALOG.BANDWIDTH_AUTO',
            ['256']: 'SETTINGS_DIALOG.BANDWIDTH_VERY_LOW_WITH_NUMBER',
            ['576']: 'SETTINGS_DIALOG.BANDWIDTH_LOW_WITH_NUMBER',
            ['1264']: 'SETTINGS_DIALOG.BANDWIDTH_MEDIUM_WITH_NUMBER',
            ['2464']: 'SETTINGS_DIALOG.BANDWIDTH_HIGH_WITH_NUMBER',
            ['6144']: 'SETTINGS_DIALOG.BANDWIDTH_VERY_HIGH_WITH_NUMBER'
        };

        if (navigator.mediaDevices) {
            navigator.mediaDevices.ondevicechange = () => {
                this.loggingService.info('OnDeviceChange triggered');
                this.enumerateMediaDevices();
            };
        }

        combineLatest([this.mediaDevices$, this.startMedia$])
            .pipe(
                filter(([, startMedia]) => startMedia),
                withLatestFrom(
                    this.inputDevices$,
                    this.audioOutputDevice$,
                    this.startMedia$
                )
            )
            .subscribe(
                ([
                    [mediaDevices],
                    { videoInputDevice, audioInputDevice },
                    audioOutputDevice
                ]) => {
                    // videoInputDevices
                    const videoInputDevices = mediaDevices.filter(
                        mediaDevice =>
                            mediaDevice.kind === MediaDeviceKindEnum.videoinput
                    );

                    if (videoInputDevice && videoInputDevices.length > 0) {
                        this.loggingService.info(
                            'videoInputDevice',
                            videoInputDevice,
                            mediaDevices
                        );
                        const currentVideoInputDevice = mediaDevices.filter(
                            mediaDevice =>
                                mediaDevice.deviceId ===
                                    videoInputDevice.deviceId &&
                                mediaDevice.kind === videoInputDevice.kind
                        );

                        if (
                            currentVideoInputDevice.length > 0 &&
                            currentVideoInputDevice[0].label !==
                                videoInputDevice.label
                        ) {
                            this.loggingService.info(
                                `Set video input device labels not match`,
                                currentVideoInputDevice[0],
                                videoInputDevice
                            );
                            this.setVideoInputDevice(
                                currentVideoInputDevice[0]
                            );
                        }
                    }

                    // audioInputDevices
                    const audioInputDevices = mediaDevices.filter(
                        mediaDevice =>
                            mediaDevice.kind === MediaDeviceKindEnum.audioinput
                    );

                    if (audioInputDevice && audioInputDevices.length > 0) {
                        const currentAudioInputDevice = mediaDevices.filter(
                            mediaDevice =>
                                mediaDevice.deviceId ===
                                    audioInputDevice.deviceId &&
                                mediaDevice.kind === audioInputDevice.kind
                        );

                        if (
                            currentAudioInputDevice.length > 0 &&
                            currentAudioInputDevice[0].label !==
                                audioInputDevice.label
                        ) {
                            this.setAudioInputDevice(
                                currentAudioInputDevice[0]
                            );
                        }
                    }

                    // audioOutputDevices
                    const audioOutputDevices = mediaDevices.filter(
                        mediaDevice =>
                            mediaDevice.kind === MediaDeviceKindEnum.audiooutput
                    );

                    if (audioOutputDevice && audioOutputDevices.length > 0) {
                        const currentAudioOutputDevice = mediaDevices.filter(
                            mediaDevice =>
                                mediaDevice.deviceId ===
                                    audioOutputDevice.deviceId &&
                                mediaDevice.kind === audioOutputDevice.kind
                        );

                        if (
                            currentAudioOutputDevice.length > 0 &&
                            currentAudioOutputDevice[0].label !==
                                audioOutputDevice.label
                        ) {
                            this.setAudioOutputDevice(
                                currentAudioOutputDevice[0]
                            );
                        }
                    }

                    if (!this.getUserMediaSubscription) {
                        this.createGetUserMediaObservable();
                    }
                }
            );

        this.deviceList = {
            [MediaDeviceKindEnum.videoinput]: {
                device$: this.videoInputDevice$,
                devices$: this.videoInputDevices$,
                setMethod: this.setVideoInputDevice.bind(this),
                kind: MediaDeviceKindEnum.videoinput,
                class: 'videoInputDeviceNone',
                icon: 'video',
                headline: 'SETTINGS_DIALOG.CHANGE_CAMERA',
                aria: 'ARIA.VIDEO_INPUT'
            },
            [MediaDeviceKindEnum.audioinput]: {
                device$: this.audioInputDevice$,
                devices$: this.audioInputDevices$,
                setMethod: this.setAudioInputDevice.bind(this),
                kind: MediaDeviceKindEnum.audioinput,
                class: 'audioInputDeviceNone',
                icon: 'mic',
                headline: 'SETTINGS_DIALOG.CHANGE_MICROPHONE',
                aria: 'ARIA.AUDIO_INPUT'
            },
            [MediaDeviceKindEnum.audiooutput]: {
                device$: this.audioOutputDevice$,
                devices$: this.audioOutputDevices$,
                setMethod: this.setAudioOutputDevice.bind(this),
                kind: MediaDeviceKindEnum.audiooutput,
                class: 'audiooutputDeviceNone',
                icon: 'volume-2',
                headline: 'SETTINGS_DIALOG.CHANGE_SPEAKER',
                aria: 'ARIA.AUDIO_OUTPUT'
            }
        };

        this.storageService.loaded$.pipe(take(1)).subscribe(() => {
            if (this.shouldLoadWebBlur) {
                this.webBlur = new VideoProcessing(
                    this.storageService,
                    this.loggingService
                );
            }
            if (
                window.AudioContext &&
                this.storageService.applicationSettingsProxy.micSampling
            ) {
                this.initAudioContext();
            }
        });

        if (this.platformService.isElectron()) {
            this.storageService.userSettingsStorage.listeners.desktopBackgroundFile$.subscribe(
                desktopBackgroundFile => {
                    this.webBlur?.handleFileChanged(desktopBackgroundFile);
                }
            );
        }
    }

    initAudioContext() {
        this.audioContext = new window.AudioContext();
        this.analyser = this.audioContext.createAnalyser();
        const fftSize = 1024;
        const smoothingTimeConstant = 0.5;
        this.analyser.fftSize = fftSize;
        this.analyser.smoothingTimeConstant = smoothingTimeConstant;
    }

    releaseAudioContext() {
        if (this.analyser) {
            this.analyser.disconnect();
            this.analyser = undefined;
        }

        if (this.audioContext) {
            this.audioContext.close();
            this.audioContext = undefined;
        }
    }

    // initially created after the first enumerateDevices finish
    createGetUserMediaObservable() {
        this.logSupportedConstraints();
        this.getUserMediaSubscription = combineLatest([
            // filter when only label changes, happens sometime after enumerate
            this.inputDevices$.pipe(
                distinctUntilChanged((a, b) => {
                    return (
                        (a.audioInputDevice && b.audioInputDevice
                            ? a.audioInputDevice.deviceId ===
                              b.audioInputDevice.deviceId
                            : false) &&
                        (a.videoInputDevice && b.videoInputDevice
                            ? a.videoInputDevice.deviceId ===
                              b.videoInputDevice.deviceId
                            : false)
                    );
                })
            ),
            this.storageService.userSettingsStorage.callType$, // home page media type request
            this.mediaDeviceFacade.mediaTypeRequest$,
            this.storageService.userSettingsStorage.listeners.bandwidth$,
            this.storageService.userSettingsStorage.listeners.enableFecc$
        ]) // in conference meida type request
            .pipe(
                withLatestFrom(
                    this.streamConstraints$,
                    this.mediaDeviceFacade.mutedByUser$
                ),
                filter(
                    ([
                        [
                            { videoInputDevice, audioInputDevice },
                            callType,
                            mediaTypeRequest,
                            bandwidth,
                            feccSetting
                        ],
                        streamConstraints
                    ]) => {
                        if (
                            this.latestMediaConstraints ||
                            this.gettingUserMedia
                        ) {
                            // when there is an ongoing getUserMedia, the streamConstraints is not updated yet,
                            // so could just update the current queued getUserMedia request to the latest request
                            this.loggingService.info(
                                'When there is an ongoing getUserMedia'
                            );
                            return true;
                        } else if (
                            !this.userMediaStream &&
                            streamConstraints.callType !== 'none'
                        ) {
                            // when there is error for getUserMedia, the current userMediaStream has been released
                            // so need to get again the former userMediaStream(at least works for home page)
                            this.loggingService.info(
                                'When there is error for getUserMedia'
                            );
                            return true;
                        } else {
                            if (
                                mediaTypeRequest &&
                                mediaTypeRequest !== streamConstraints.callType
                            ) {
                                const { video, audio } = this.getConstraints(
                                    videoInputDevice,
                                    audioInputDevice,
                                    mediaTypeRequest
                                );
                                if (!video && !audio) {
                                    this.noneEscalate$.next(mediaTypeRequest);
                                }
                            }

                            const videoChanged =
                                videoInputDevice &&
                                videoInputDevice.deviceId !==
                                    streamConstraints.videoDeviceID &&
                                (mediaTypeRequest === 'video' ||
                                    callType === 'video');
                            const audioChanged =
                                audioInputDevice &&
                                audioInputDevice.deviceId !==
                                    streamConstraints.audioDeviceID &&
                                (mediaTypeRequest !== 'none' ||
                                    callType !== 'none');
                            const mediaTypeChanged =
                                mediaTypeRequest &&
                                mediaTypeRequest !== streamConstraints.callType;
                            const callTypeChanged =
                                !mediaTypeRequest &&
                                callType !== streamConstraints.callType;
                            const bandwidthChanged =
                                bandwidth !== this.previousBandwidth &&
                                (bandwidth === '2464' ||
                                    this.previousBandwidth === '2464');
                            const feccSettingChanged =
                                feccSetting !== this.prevFeccSetting;
                            this.prevFeccSetting = feccSetting;

                            this.loggingService.info(
                                'Video changed',
                                videoInputDevice,
                                streamConstraints,
                                mediaTypeRequest,
                                callType
                            );

                            this.loggingService.info(
                                'What changed',
                                videoChanged,
                                audioChanged,
                                mediaTypeChanged,
                                callTypeChanged,
                                bandwidthChanged,
                                videoInputDevice,
                                streamConstraints,
                                audioInputDevice,
                                mediaTypeRequest,
                                callType
                            );
                            return !!(
                                videoChanged ||
                                audioChanged ||
                                mediaTypeChanged ||
                                callTypeChanged ||
                                bandwidthChanged ||
                                feccSettingChanged
                            );
                        }
                    }
                )
            )
            .subscribe(
                ([
                    [
                        { videoInputDevice, audioInputDevice },
                        callType,
                        mediaTypeRequest
                    ],
                    _streamConstraints
                ]) => {
                    const type = mediaTypeRequest || callType;
                    const constraints = this.getConstraints(
                        videoInputDevice,
                        audioInputDevice,
                        type
                    );
                    this.loggingService.info(
                        'GetUserMedia call triggered by getUserMediaSubscription observable'
                    );
                    this.getUserMedia(constraints);
                }
            );
    }

    // tslint:disable-next-line:cyclomatic-complexity
    getConstraints(
        videoInputDevice: MediaDeviceInfo,
        audioInputDevice: MediaDeviceInfo,
        callType: string
    ): MediaStreamConstraints {
        // Default constraints always use audio and video
        const constraints: MediaStreamConstraints = {};
        if (callType === 'none') {
            return constraints;
        }
        const chromeVersionCheck = 57;

        // special case, for chrome version less than 57, to get the correct video ratio,
        // the constraints syntax is different
        // FIXME: could remove the electron check here once electron package gets updated
        if (
            this.platformService.isWeb() &&
            this.platformService.browserType === 'chrome' &&
            this.platformService.browserMajorVersion < chromeVersionCheck
        ) {
            constraints.video = {
                optional: [
                    {
                        minWidth: 320
                    },
                    {
                        minWidth: 640
                    },
                    {
                        minWidth: 1024
                    },
                    {
                        minWidth: 1280
                    }
                ]
            } as MediaTrackConstraints;
        } else if (this.storageService.userSettingsProxy.videoConstraints) {
            constraints.video = {
                width: 1280,
                height: 720
            } as MediaTrackConstraints;
        } else {
            constraints.video = true;
        }

        constraints.audio = true;

        // Set facingMode for android front/back cameras
        if (
            videoInputDevice &&
            (videoInputDevice.deviceId === 'user' ||
                videoInputDevice.deviceId === 'environment')
        ) {
            constraints.video = {
                facingMode: videoInputDevice.deviceId
            } as MediaTrackConstraints;

            if (this.storageService.userSettingsProxy.videoConstraints) {
                constraints.video = {
                    ...constraints.video,
                    width: 1280,
                    height: 720
                };
            }
        }
        // Use specific deviceId if specified
        else if (videoInputDevice && videoInputDevice.deviceId) {
            // special case, for chrome version less than 57, to get the correct video ratio,
            // the constraints syntax is different
            // FIXME: could remove the electron check here once electron package gets updated
            if (
                this.platformService.isWeb() &&
                this.platformService.browserType === 'chrome' &&
                this.platformService.browserMajorVersion < chromeVersionCheck
            ) {
                constraints.video = {
                    mandatory: {
                        sourceId: videoInputDevice.deviceId
                    },
                    optional: [
                        {
                            minWidth: 320
                        },
                        {
                            minWidth: 640
                        },
                        {
                            minWidth: 1024
                        },
                        {
                            minWidth: 1280
                        }
                    ]
                } as MediaTrackConstraints;
            } else if (this.storageService.userSettingsProxy.videoConstraints) {
                constraints.video = {
                    deviceId: { exact: videoInputDevice.deviceId },
                    width: 1280,
                    height: 720
                };
            } else {
                constraints.video = {
                    deviceId: { exact: videoInputDevice.deviceId }
                };
                if (this.platformService.isAndroid()) {
                    if (
                        !this.mediaDevices.find(
                            device =>
                                device.deviceId === videoInputDevice.deviceId
                        )
                    ) {
                        const deviceWithMatchingLabel = this.mediaDevices.find(
                            device => device.label === videoInputDevice.label
                        );
                        if (deviceWithMatchingLabel) {
                            constraints.video.deviceId = {
                                exact: deviceWithMatchingLabel.deviceId
                            };
                        }
                    }
                }
            }
        }

        if (
            this.bandwidth >= '2464' &&
            constraints.video &&
            typeof constraints.video === 'object' &&
            constraints.video.width === 1280 &&
            constraints.video.height === 720
        ) {
            constraints.video.width = { ideal: 1920 };
            constraints.video.height = { ideal: 1080 };
        }

        if (audioInputDevice && audioInputDevice.deviceId) {
            constraints.audio = {
                deviceId: { exact: audioInputDevice.deviceId }
            };
            if (this.platformService.isAndroid()) {
                if (
                    !this.mediaDevices.find(
                        device => device.deviceId === audioInputDevice.deviceId
                    )
                ) {
                    const deviceWithMatchingLabel = this.mediaDevices.find(
                        device => device.label === audioInputDevice.label
                    );
                    if (deviceWithMatchingLabel) {
                        constraints.audio.deviceId = {
                            exact: deviceWithMatchingLabel.deviceId
                        };
                    }
                }
            }
        }

        if (
            constraints.video &&
            typeof constraints.video === 'object' &&
            this.storageService.userSettingsProxy.enableFecc
        ) {
            const supports = this.getSupportedConstraints();
            addPtzToVideoConstraints(supports, constraints.video);
        }

        if (
            callType === 'audioonly' ||
            !videoInputDevice ||
            videoInputDevice.deviceId === 'none'
        ) {
            constraints.video = false;
        }

        if (!audioInputDevice || audioInputDevice.deviceId === 'none') {
            constraints.audio = false;
        }

        return constraints;
    }

    private getSupportedConstraints() {
        let supportedConstraints: MediaTrackSupportedConstraints = {};
        if (this.hasSupportedConstraints) {
            supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
        }

        return supportedConstraints;
    }

    private get hasSupportedConstraints() {
        return (
            navigator.mediaDevices &&
            navigator.mediaDevices.getSupportedConstraints
        );
    }

    setBandwidth(bandwidth: string) {
        this.storageService.userSettingsProxy.bandwidth = bandwidth;
        this.previousBandwidth = bandwidth;
    }

    setMediaInputDevices(
        videoInputDevice: MediaDeviceInfo,
        audioInputDevice: MediaDeviceInfo,
        mutedByUser?: boolean,
        store = true
    ) {
        this.mediaDeviceFacade.setMediaInputDevices(
            videoInputDevice,
            audioInputDevice,
            mutedByUser,
            store
        );
    }

    setVideoInputDevice(
        videoInputDevice: MediaDeviceInfo,
        mutedByUser?: boolean,
        store = true
    ) {
        this.mediaDeviceFacade.setVideoInputDevice(
            videoInputDevice,
            mutedByUser,
            store
        );
    }

    setAudioInputDevice(
        audioInputDevice: MediaDeviceInfo,
        mutedByUser?: boolean,
        store = true
    ) {
        this.mediaDeviceFacade.setAudioInputDevice(
            audioInputDevice,
            mutedByUser,
            store
        );
    }

    setAudioOutputDevice(audioOutputDevice: MediaDeviceInfo) {
        this.mediaDeviceFacade.setAudioOutputDevice(audioOutputDevice);
    }
    setAudioOutputDeviceAndPlayTestSound(audioOutputDevice: MediaDeviceInfo) {
        this.setAudioOutputDevice(audioOutputDevice);
        this.shouldPlaySound = true;
    }

    setShouldPlaySound(shouldPlaySound: boolean) {
        this.shouldPlaySound = shouldPlaySound;
    }

    setMuteCamera(muteCamera: boolean) {
        this.storageService.userSettingsProxy.muteCamera = muteCamera;
    }

    setMuteMicrophone(muteMicrophone: boolean) {
        if (
            this.storageService.userSettingsProxy.muteMicrophone !==
            muteMicrophone
        ) {
            this.storageService.userSettingsProxy.muteMicrophone = muteMicrophone;
        }
    }

    enumerateMediaDevices = async () => {
        if (window.pexBranding) {
            this.brandingEnumerateMediaDevices();
            return;
        }
        if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
            this.loggingService.info('xxx ENUMERATE MEDIA DEVICES');
            const mediaDevices = await navigator.mediaDevices.enumerateDevices();
            combineLatest([
                this.mediaDeviceFacade.isNoneCamera$,
                this.mediaDeviceFacade.isNoneMicrophone$,
                this.mediaDeviceFacade.mutedByUser$,
                this.mediaDeviceFacade.inputDevices$
            ])
                .pipe(take(1))
                .subscribe(
                    ([
                        isNoneCamera,
                        isNoneMicrophone,
                        mutedByUser,
                        inputDevices
                    ]) => {
                        this.handleMediaDevices({
                            mediaDevices,
                            isNoneCamera,
                            isNoneMicrophone,
                            mutedByUser
                        });

                        const resetVideoInput =
                            inputDevices.videoInputDevice.deviceId &&
                            !this.mediaDevices.some(
                                device =>
                                    device.deviceId ===
                                    inputDevices.videoInputDevice.deviceId
                            );
                        const resetAudioInput =
                            inputDevices.audioInputDevice.deviceId &&
                            !this.mediaDevices.some(
                                device =>
                                    device.deviceId ===
                                    inputDevices.audioInputDevice.deviceId
                            );

                        if (resetVideoInput && resetAudioInput) {
                            this.loggingService.info(
                                'enumerateDevices: stored deviceID not found, reseting video and audio inputs'
                            );
                            this.mediaDeviceFacade.setMediaInputDevices(
                                __CONSTANTS__.DEFAULT_VIDEO_INPUT_DEVICE,
                                __CONSTANTS__.DEFAULT_AUDIO_INPUT_DEVICE
                            );
                        } else if (resetVideoInput) {
                            this.loggingService.info(
                                'enumerateDevices: stored deviceID not found, reseting video input'
                            );
                            this.mediaDeviceFacade.setVideoInputDevice(
                                __CONSTANTS__.DEFAULT_VIDEO_INPUT_DEVICE
                            );
                        } else if (resetAudioInput) {
                            this.loggingService.info(
                                'enumerateDevices: stored deviceID not found, reseting audio input'
                            );
                            this.mediaDeviceFacade.setAudioInputDevice(
                                __CONSTANTS__.DEFAULT_AUDIO_INPUT_DEVICE
                            );
                        }
                    }
                );
        } else {
            this.loggingService.warn(
                'No mediaDevices API found, setting audio and video to none'
            );
            this.mediaDevices$.next([
                __CONSTANTS__.NONE_VIDEO_INPUT_DEVICE,
                __CONSTANTS__.NONE_AUDIO_INPUT_DEVICE
            ]);
            this.setAudioInputDevice(
                __CONSTANTS__.NONE_AUDIO_INPUT_DEVICE,
                undefined,
                false
            );
            this.setVideoInputDevice(
                __CONSTANTS__.NONE_VIDEO_INPUT_DEVICE,
                undefined,
                false
            );
        }
    };

    private handleMediaDevices = ({
        mediaDevices,
        isNoneCamera,
        isNoneMicrophone,
        mutedByUser
    }: {
        mediaDevices: MediaDeviceInfo[];
        isNoneCamera: boolean;
        isNoneMicrophone: boolean;
        mutedByUser: MediaDeviceState['mutedByUser'];
    }) => {
        // tslint:disable-next-line:cyclomatic-complexity
        this.ngZone.run(() => {
            this.loggingService.info(
                'Enumerate devices, found devices: ',
                mediaDevices
            );
            const realFoundDevices = [...mediaDevices];
            const supportedConstraints = this.getSupportedConstraints();

            // Filter out browser defined 'default' devices
            mediaDevices = mediaDevices.filter(
                mediaDevice =>
                    mediaDevice.deviceId !== 'default' &&
                    !mediaDevice.label.startsWith('default')
            );

            // iOS uses iosrtc plugin, so always has these devices
            if (this.platformService.isIOS()) {
                const {
                    DEFAULT_VIDEO_INPUT_DEVICE,
                    FRONT_CAMERA_VIDEO_INPUT_DEVICE,
                    BACK_CAMERA_VIDEO_INPUT_DEVICE,
                    DEFAULT_AUDIO_INPUT_DEVICE
                } = this.platformService.constants;

                mediaDevices = [
                    DEFAULT_VIDEO_INPUT_DEVICE,
                    FRONT_CAMERA_VIDEO_INPUT_DEVICE,
                    BACK_CAMERA_VIDEO_INPUT_DEVICE,

                    DEFAULT_AUDIO_INPUT_DEVICE
                ];
            }

            // Android always has headset/speaker handled by cordova for output. If supported, front/back camera.
            if (this.platformService.isAndroid()) {
                const {
                    FRONT_CAMERA_VIDEO_INPUT_DEVICE,
                    BACK_CAMERA_VIDEO_INPUT_DEVICE,
                    HEADSET_AUDIO_OUTPUT_DEVICE,
                    SPEAKER_AUDIO_OUTPUT_DEVICE,
                    NONE_VIDEO_INPUT_DEVICE,
                    NONE_AUDIO_INPUT_DEVICE,
                    DEFAULT_AUDIO_INPUT_DEVICE,
                    DEFAULT_VIDEO_INPUT_DEVICE
                } = this.platformService.constants;

                if (supportedConstraints.facingMode) {
                    mediaDevices.unshift(
                        FRONT_CAMERA_VIDEO_INPUT_DEVICE,
                        BACK_CAMERA_VIDEO_INPUT_DEVICE
                    );
                }

                mediaDevices.unshift(
                    DEFAULT_AUDIO_INPUT_DEVICE,
                    DEFAULT_VIDEO_INPUT_DEVICE
                );
                mediaDevices.push(
                    NONE_AUDIO_INPUT_DEVICE,
                    NONE_VIDEO_INPUT_DEVICE
                );
                mediaDevices = mediaDevices.filter(
                    mediaDevice => mediaDevice.kind !== 'audiooutput'
                );

                mediaDevices.push(
                    HEADSET_AUDIO_OUTPUT_DEVICE,
                    SPEAKER_AUDIO_OUTPUT_DEVICE
                );
            }

            // Web/desktop
            if (!this.platformService.isMobileApp()) {
                if (
                    this.platformService.isMobileBrowser() &&
                    supportedConstraints.facingMode
                ) {
                    const {
                        FRONT_CAMERA_VIDEO_INPUT_DEVICE,
                        BACK_CAMERA_VIDEO_INPUT_DEVICE
                    } = new PexConstantsMobile();
                    mediaDevices.unshift(
                        FRONT_CAMERA_VIDEO_INPUT_DEVICE,
                        BACK_CAMERA_VIDEO_INPUT_DEVICE
                    );
                }

                const {
                    DEFAULT_VIDEO_INPUT_DEVICE,
                    DEFAULT_AUDIO_INPUT_DEVICE,
                    DEFAULT_AUDIO_OUTPUT_DEVICE,
                    NONE_AUDIO_INPUT_DEVICE,
                    NONE_VIDEO_INPUT_DEVICE
                } = this.platformService.constants;

                mediaDevices.unshift(
                    DEFAULT_VIDEO_INPUT_DEVICE,
                    DEFAULT_AUDIO_INPUT_DEVICE,
                    DEFAULT_AUDIO_OUTPUT_DEVICE
                );
                mediaDevices.push(
                    NONE_AUDIO_INPUT_DEVICE,
                    NONE_VIDEO_INPUT_DEVICE
                );
            }

            // Add indexed labels for unnamed devices
            const indexes = {
                videoinput: 0,
                audioinput: 0,
                audiooutput: 0
            };
            mediaDevices = mediaDevices.map(value => {
                if (value.label) {
                    return value;
                } else {
                    indexes[value.kind] += 1;
                    return Object.assign({}, {
                        deviceId: value.deviceId,
                        groupId: value.groupId,
                        kind: value.kind,
                        label: this.translate.instant(value.kind, {
                            n: indexes[value.kind]
                        })
                    } as MediaDeviceInfo);
                }
            });

            if (
                !this.platformService.isIOS() &&
                this.injector.get(Router).url.startsWith('/home')
            ) {
                let newAudio = false;
                let newVideo = false;

                const audioInput = this.getDevice(
                    realFoundDevices,
                    'audioinput'
                );

                if (
                    !!audioInput &&
                    isNoneMicrophone &&
                    !mutedByUser.audioinput
                ) {
                    this.loggingService.info(
                        'New audio device, set to',
                        audioInput
                    );
                    newAudio = true;
                }

                const videoInput = this.getDevice(
                    realFoundDevices,
                    'videoinput'
                );

                if (!!videoInput && isNoneCamera && !mutedByUser.videoinput) {
                    this.loggingService.info(
                        'New video device, set to',
                        videoInput
                    );
                    newVideo = true;
                }

                if (newAudio && newAudio) {
                    this.setMediaInputDevices(videoInput, audioInput);
                } else if (newAudio) {
                    this.setAudioInputDevice(audioInput);
                } else if (newVideo) {
                    this.setVideoInputDevice(videoInput);
                }
            }

            this.mediaDevices = mediaDevices;
            this.mediaDevices$.next(mediaDevices);
        });
    };

    private checkIfDevicesExist(
        mediaDevices: MediaDeviceInfo[],
        kind: MediaDeviceKind
    ) {
        const doesDeviceByKindExist = this.doesDeviceExist(kind);
        return mediaDevices.some(d => doesDeviceByKindExist(d));
    }

    private getDevice(mediaDevices: MediaDeviceInfo[], kind: MediaDeviceKind) {
        const doesDeviceByKindExist = this.doesDeviceExist(kind);
        return mediaDevices.find(d => doesDeviceByKindExist(d));
    }

    private doesDeviceExist(kind: MediaDeviceKind) {
        return (d: MediaDeviceInfo) =>
            d.kind === kind &&
            (d.deviceId !== null || d.label === 'DEFAULT') &&
            d.deviceId !== 'none';
    }

    async getUserMedia(constraints: MediaStreamConstraints, force = false) {
        this.loggingService.info(
            'GetUserMedia Internal - called with:',
            constraints
        );

        if (!force) {
            const {
                constraints: previousConstraints
            } = await this.streamConstraints$.pipe(take(1)).toPromise();
            if (previousConstraints === Logger.safeStringify(constraints)) {
                this.loggingService.info(
                    'GetUserMedia Internal - skipped because current stream has identical constraints'
                );
                return;
            }
        }

        if (!this.shouldGetMediaStream) return;

        if (window.pexBranding) {
            this.brandingGetUserMedia(constraints);
            return;
        }

        if (!constraints.video && !constraints.audio) {
            this.releaseUserMedia(this.userMediaStream);
            this.mediaDeviceFacade.setLocalMediaStream({
                stream: null,
                audioDeviceID: null,
                videoDeviceID: null,
                streamType: 'none',
                constraints: null
            });
            this.previousMediaConstraints = null;
            return;
        }

        if (this.platformService.isAndroid()) {
            const permissions = cordova.plugins.permissions;
            try {
                const gotPermissions = await this.platformService.getPermissions(
                    [permissions.CAMERA, permissions.RECORD_AUDIO]
                );
                if (!gotPermissions)
                    return this.handleGetUserMediaError(
                        'AndroidGetDeviceDenied'
                    );
            } catch (e) {
                this.loggingService.error(
                    'Error getting camera and mic permissions:',
                    e
                );
            }
        }

        if (navigator.mediaDevices.getUserMedia) {
            this.loggingService.info(
                'GetUserMedia Internal: mediaDevices.getUserMedia exists'
            );
            if (!this.gettingUserMedia) {
                if (this.platformService.isAndroid()) {
                    await window.plugins.ManageAudio.getUserMediaChange(
                        'start'
                    );
                }
                this.loggingService.info(
                    'GetUserMedia Internal: No current GUM request to wait for'
                );
                // tslint:disable-next-line:cyclomatic-complexity
                this.ngZone.runOutsideAngular(async () => {
                    this.gettingUserMedia = true;
                    this.loggingService.info(
                        'GetUserMedia Internal: gettingUserMedia',
                        this.userMediaStream,
                        this.pexrtcUserMediaStream
                    );
                    if (
                        this.userMediaStream !== this.pexrtcUserMediaStream ||
                        (this.platformService.isWinElectron() &&
                            constraints.video) ||
                        this.platformService.isMobile() ||
                        (this.platformService.isWeb() &&
                            this.platformService.browserType === 'firefox')
                    ) {
                        this.loggingService.info(
                            'GetUserMedia Internal: Might release'
                        );
                        if (this.userMediaStream) {
                            this.loggingService.info(
                                'GetUserMedia Internal: Tries releasing media'
                            );
                            this.releaseUserMedia(this.userMediaStream);
                        }
                        if (this.platformService.isAndroid()) {
                            await window.plugins.ManageAudio.getUserMediaChange(
                                'released'
                            );
                        }
                    }

                    let result;

                    try {
                        this.mediaDeviceFacade.setSlowGUMCall(false);
                        // If the user neither allows nor denies permission open a popup to prompt
                        if (this.gumTimeout) {
                            window.clearTimeout(this.gumTimeout);
                            this.gumTimeout = undefined;
                        }
                        this.gumTimeout = window.setTimeout(() => {
                            if (this.platformService.isWeb()) {
                                this.openPermissionDialog('timeout');
                            } else {
                                this.mediaDeviceFacade.setSlowGUMCall(true);
                            }
                        }, this.GET_USER_MEDIA_TIMEOUT);

                        result = await navigator.mediaDevices.getUserMedia(
                            constraints
                        );
                        this.mediaDeviceFacade.setSlowGUMCall(false);
                        window.clearTimeout(this.gumTimeout);

                        // for the chorme 62 or possible some other browsers, when try to get a video stream,
                        // even the camera is blocked , only the audio output device has the permission
                        // still will have a black video mediaStream result without any error
                        // same situation with no camera as well seems
                        // TODO: maybe could just show a warning here, so could be possible to join/keep as a video
                        // participant with a black self video
                        if (
                            constraints.video &&
                            result &&
                            (!result.getVideoTracks() ||
                                result.getVideoTracks().length === 0)
                        ) {
                            throw 'LackCameraAccessError';
                        }

                        if (
                            result &&
                            !this.shouldGetMediaStream &&
                            this.platformService.platform === 'electron'
                        ) {
                            this.releaseUserMedia(result);
                            this.gettingUserMedia = false;
                            return;
                        }

                        this.failedMediaConstraints = null;
                        this.previousMediaConstraints = constraints;

                        if (
                            result &&
                            result.getAudioTracks().length > 0 &&
                            window.AudioContext &&
                            this.audioContext &&
                            this.audioContext.createMediaStreamDestination &&
                            this.storageService.applicationSettingsProxy
                                .micSampling
                        ) {
                            result = this.connectAudioNodes(result);
                        }

                        if (
                            result &&
                            this.platformService.isWeb() &&
                            !this.platformService.isMobileBrowser()
                        ) {
                            try {
                                await this.setFeccSupported(result);
                            } catch (error) {
                                this.loggingService.error(
                                    'Error in setFeccSupported() function',
                                    error
                                );
                            }
                        }

                        if (this.shouldDoWebBlur(result)) {
                            result = await this.doWebBlur(result);
                        }
                        this.userMediaStream = result;
                        this.loggingService.info(
                            'GetUserMedia Internal - result:',
                            this.userMediaStream
                        );
                    } catch (error) {
                        this.ngZone.run(() => {
                            window.clearTimeout(this.gumTimeout);
                            if (this.platformService.isAndroid()) {
                                window.plugins.ManageAudio.getUserMediaChange(
                                    'end'
                                );
                            }
                            let errorString = '';
                            if (error !== null && typeof error === 'object') {
                                for (const el in error)
                                    errorString += error[el];
                            }
                            errorString +=
                                JSON.stringify(error) + error.toString();
                            this.loggingService.error(
                                'GetUserMedia Internal - error:',
                                error,
                                errorString
                            );
                            this.failedMediaConstraints = constraints;
                            this.gettingUserMedia = false;
                            this.latestMediaConstraints = null;
                            this.handleGetUserMediaError(errorString);
                        });
                    }

                    if (!this.failedMediaConstraints) {
                        if (
                            window.AudioContext &&
                            this.storageService.applicationSettingsProxy
                                .micSampling
                        ) {
                            this.audioInputVolume$ = this.mediaStreamAudioLevel$(
                                this.userMediaStream
                            ).pipe(
                                filter(volume => {
                                    const difference = Math.abs(
                                        this.lastVolume - volume
                                    );
                                    const differenceRange = 0.01;

                                    if (
                                        difference !== 0 &&
                                        difference > differenceRange
                                    ) {
                                        this.lastVolume = volume;
                                        return true;
                                    }
                                }),
                                map(volume => Math.min(100, volume)),
                                share()
                            );
                        }

                        let videoDeviceID =
                            this.getTrackConstraint(
                                constraints.video,
                                'deviceId'
                            ) || (constraints.video ? null : 'none');

                        if (
                            this.platformService.isMobile() &&
                            constraints.video &&
                            constraints.video['facingMode']
                        ) {
                            videoDeviceID =
                                this.getTrackConstraint(
                                    constraints.video,
                                    'facingMode'
                                ) || null;
                        }

                        const audioDeviceID =
                            this.getTrackConstraint(
                                constraints.audio,
                                'deviceId'
                            ) || (constraints.audio ? null : 'none');
                        const streamType = constraints.video
                            ? 'video'
                            : 'audioonly';

                        const payload = {
                            stream: this.userMediaStream,
                            videoDeviceID,
                            audioDeviceID,
                            streamType,
                            constraints: Logger.safeStringify(constraints)
                        };

                        if (this.userMediaStream) {
                            this.userMediaStream.getTracks().forEach(track => {
                                track.onended = () => {
                                    this.loggingService.warn(
                                        'GetUserMedia - MediaStreamTrack ended',
                                        track
                                    );
                                    this.trackEnded$.next();
                                };
                                track.onmute = () => {
                                    this.loggingService.info(
                                        'GetUserMedia - MediaStreamTrack muted',
                                        track
                                    );
                                    this.trackMuted$.next();
                                };
                                track.onunmute = () => {
                                    this.loggingService.info(
                                        'GetUserMedia - MediaStreamTrack unmuted',
                                        track
                                    );
                                };
                            });
                        }

                        this.ngZone.run(() => {
                            this.mediaDeviceFacade.setLocalMediaStream(payload);
                        });
                        if (this.platformService.isAndroid()) {
                            window.plugins.ManageAudio.getUserMediaChange(
                                'end'
                            );
                        }
                        this.gettingUserMedia = false;
                        const currentMediaConstraints = this
                            .latestMediaConstraints;
                        this.latestMediaConstraints = null;
                        if (currentMediaConstraints) {
                            this.loggingService.info(
                                'GetUserMedia call triggered by queued latestMediaConstraints'
                            );
                            this.getUserMedia(currentMediaConstraints);
                        }
                    }
                });
            } else {
                this.loggingService.info(
                    'GetUserMedia Internal: Waiting for a GUM result, updating latestMediaConstraints'
                );
                this.latestMediaConstraints = constraints;
            }
        } else {
            // console.log('webrtc not support');
        }
    }

    private async setFeccSupported(mediaStream: MediaStream) {
        const panTiltZoomPermissionStatus = await getPtzPermissionStatus();
        const cameraHasPtzCapabilities = isVideoTrackWithPtzCapabilities(
            mediaStream
        );

        this.mediaDeviceFacade.setFeccSupported(
            panTiltZoomPermissionStatus &&
                panTiltZoomPermissionStatus.state === 'granted' &&
                this.storageService.userSettingsProxy.enableFecc &&
                cameraHasPtzCapabilities
        );
    }

    private handleGetUserMediaError(errorString: string) {
        this.gumError$.next(errorString);
        if (this.injector.get(Router).url.startsWith('/conference')) {
            // in conference, user media maybe have been released
            // or 'firefox', 'safari', 'edge' once camera permission is denied, mic permission is blocked as well
            // so reset to api participant
            if (
                !this.pexrtcUserMediaStream ||
                (this.pexrtcUserMediaStream &&
                    !this.pexrtcUserMediaStream.active) ||
                (this.platformService.isWeb() &&
                    ['firefox', 'safari', 'edge'].indexOf(
                        this.platformService.browserType
                    ) > -1)
            ) {
                this.userMediaStream = null;
                this.mediaDeviceFacade.setLocalMediaStream({
                    stream: null,
                    audioDeviceID: null,
                    videoDeviceID: null,
                    streamType: 'none',
                    constraints: null
                });
                this.loggingService.info('xxx RESET MEDIATYPE');
                this.mediaDeviceFacade.setMediaTypeRequest('none');
            } else {
                // TODO: test and make sure
                // for chorme, since the current stream is still valid and mic/cam has different permission
                // restore mediaTypeRequest value to the last successful one
                const currentMediaType = !this.previousMediaConstraints
                    ? 'none'
                    : this.previousMediaConstraints['video']
                    ? 'video'
                    : 'audioonly';
                this.mediaDeviceFacade.setMediaTypeRequest(currentMediaType);
            }
        }

        const permType = this.getPermissionDialogType(errorString);
        if (permType) {
            this.ngZone.run(() => {
                if (this.showPermissionDialog || permType === 'other') {
                    if (permType !== 'other') {
                        this.showPermissionDialog = false;
                    }
                    this.openPermissionDialog(permType);
                }
            });
        }

        if (this.injector.get(Router).url.startsWith('/home')) {
            //Back to DEFAULT devices if overconstrained/can't use deviceId
            if (
                errorString.indexOf('Invalid constraintdeviceId') > -1 || //safari
                errorString.indexOf('OverconstrainedError') > -1
            ) {
                if (this.failedMediaConstraints) {
                    if (
                        this.failedMediaConstraints.video &&
                        this.failedMediaConstraints.audio
                    ) {
                        this.setMediaInputDevices(
                            __CONSTANTS__.DEFAULT_VIDEO_INPUT_DEVICE,
                            __CONSTANTS__.DEFAULT_AUDIO_INPUT_DEVICE
                        );
                    } else {
                        if (this.failedMediaConstraints.video) {
                            this.setVideoInputDevice(
                                __CONSTANTS__.DEFAULT_VIDEO_INPUT_DEVICE
                            );
                        }
                        if (this.failedMediaConstraints.audio) {
                            this.setAudioInputDevice(
                                __CONSTANTS__.DEFAULT_AUDIO_INPUT_DEVICE
                            );
                        }
                    }
                }
            } else {
                if (
                    this.failedMediaConstraints &&
                    this.failedMediaConstraints.video &&
                    !['blocked', 'camera_blocked'].includes(permType)
                ) {
                    //Try without video if we asked for video and failed
                    /*
                        Only do this if the perms werent explicitly blocked, as
                        requesting audio only screws up Chrome perm UI in the URL bar
                        otherwise, making it hard to find out how to allow camera
                    */
                    this.setVideoInputDevice(
                        __CONSTANTS__.NONE_VIDEO_INPUT_DEVICE,
                        undefined,
                        false
                    );
                } else {
                    //Otherwise assume no media if we failed without request video/request was blocked
                    this.setMediaInputDevices(
                        __CONSTANTS__.NONE_VIDEO_INPUT_DEVICE,
                        __CONSTANTS__.NONE_AUDIO_INPUT_DEVICE,
                        undefined,
                        false
                    );
                }
            }
        }
    }

    private getPermissionDialogType(errorString: string) {
        if (this.platformService.platform === 'web') {
            // permission errors
            if (
                errorString.indexOf('NotAllowedError') > -1 || // firefox, safari
                errorString.indexOf('PermissionDeniedError') > -1
            ) {
                return 'blocked';
            }

            // when getUserMedia promise hangs, we throw this, see above;
            if (errorString.indexOf('GetUserMediaTimeout') > -1) {
                return 'timeout';
            }
        }

        if (errorString.indexOf('AndroidGetDeviceDenied') > -1) {
            return 'android_blocked';
        }

        // when webcam or mic but they’re already in use(mostly windows os)
        if (
            errorString.indexOf('NotReadableError') > -1 || // firefox
            errorString.indexOf('TrackStartError') > -1 || // chrome
            errorString.indexOf('InternalError: Starting video failed') > -1 || // FF,ubuntu
            // when no webcam or mic/sound card. or device is disabled from the OS
            errorString.indexOf('NotFoundError') > -1 || // firefox
            errorString.indexOf('DevicesNotFoundError') > -1
        ) {
            return 'other';
        }

        // when mic has seprate permission but camera is blocked in chrome
        // we throw this, see above
        if (errorString.indexOf('LackCameraAccessError') > -1) {
            return 'camera_blocked';
        }

        return null;
    }

    private connectAudioNodes(userMedia: MediaStream) {
        if (this.rawMediaStream) {
            const tracks = this.rawMediaStream.getTracks();
            for (const track of tracks) {
                track.stop();
            }
        }

        if (this.mediaStreamAudio) {
            this.mediaStreamAudio.disconnect();
        }

        if (this.mediaStreamAudioSource) {
            this.mediaStreamAudioSource.disconnect();
        }

        if (this.analyser) {
            this.analyser.disconnect();
        }

        this.rawMediaStream = userMedia;
        this.mediaStreamAudio = this.audioContext?.createMediaStreamDestination();
        this.mediaStreamAudioSource = this.audioContext?.createMediaStreamSource(
            this.rawMediaStream
        );
        this.mediaStreamAudioSource
            .connect(this.analyser)
            .connect(this.mediaStreamAudio);

        userMedia
            .getVideoTracks()
            .forEach(track => this.mediaStreamAudio.stream.addTrack(track));

        return this.mediaStreamAudio.stream;
    }

    // reset home page mic & camera status
    private resetCallType(callType: string) {
        if (callType === 'video') {
            this.homeFacade.setMicrophoneMuted(false);
            this.homeFacade.setCameraMuted(false);
        } else if (callType === 'audioonly') {
            this.homeFacade.setMicrophoneMuted(false);
            this.homeFacade.setCameraMuted(true);
        } else {
            this.homeFacade.setMicrophoneMuted(true);
            this.homeFacade.setCameraMuted(true);
        }
    }

    async openPermissionDialog(reason: string) {
        if (
            !this.dialogService.isDialogContentOpen(
                DevicePermissionDialogComponent
            )
        ) {
            await this.dialogService.open(
                DevicePermissionDialogComponent,
                reason
            );
        }
    }

    getUserMediaFromCallType(callType: string) {
        combineLatest([this.videoInputDevice$, this.audioInputDevice$])
            .pipe(take(1))
            .subscribe(([videoInputDevice, audioInputDevice]) =>
                this.getUserMedia(
                    this.getConstraints(
                        videoInputDevice,
                        audioInputDevice,
                        callType
                    )
                )
            );
    }

    getUserMediaWithCurrentConstraints(force = false) {
        combineLatest([
            this.videoInputDevice$,
            this.audioInputDevice$,
            this.mediaDeviceFacade.mediaTypeRequest$,
            this.storageService.userSettingsStorage.callType$
        ])
            .pipe(take(1))
            .subscribe(
                ([
                    videoInputDevice,
                    audioInputDevice,
                    mediaTypeRequest,
                    callType
                ]) => {
                    const type = mediaTypeRequest || callType;
                    const constraints = this.getConstraints(
                        videoInputDevice,
                        audioInputDevice,
                        type
                    );
                    this.getUserMedia(constraints, force);
                }
            );
    }

    getUserMediaNoConstraints() {
        combineLatest([
            this.videoInputDevice$,
            this.audioInputDevice$,
            this.startMedia$
        ])
            .pipe(
                take(1),
                filter(([, , startMedia]) => startMedia)
            )
            .subscribe(([videoInputDevice, audioInputDevice, _startMedia]) => {
                if (this.callType !== null) {
                    this.getUserMedia(
                        this.getConstraints(
                            videoInputDevice,
                            audioInputDevice,
                            this.callType
                        )
                    );
                }
            });
    }

    releaseUserMedia(mediaStream: MediaStream) {
        if (window.pexBranding) {
            this.brandingReleaseUserMedia();
            return;
        }

        if (this.webBlur) {
            this.webBlur.cleanup();
        }

        if (mediaStream) {
            this.loggingService.info('Releasing media stream: ', mediaStream);
            if (this.volumeSubscriber) {
                this.volumeSubscriber.complete();
                this.volumeSubscriber = null;
            }
            const tracks = mediaStream.getTracks();
            for (const track of tracks) {
                track.stop();
            }
            if (mediaStream === this.userMediaStream) {
                this.userMediaStream = null;
                if (this.audioContext && this.rawMediaStream) {
                    const tracks = this.rawMediaStream.getTracks();
                    for (const track of tracks) {
                        track.stop();
                    }
                }
            }
        }
    }

    mediaStreamAudioLevel$(mediaStream: MediaStream) {
        return new Observable<number>(subscriber => {
            this.volumeSubscriber = subscriber;
            if (
                mediaStream !== null &&
                mediaStream.getAudioTracks().length > 0
            ) {
                const timerInterval = 100;
                const timer = interval(timerInterval)
                    .pipe(
                        map(() => {
                            if (this.audioContext?.state === 'suspended') {
                                this.audioContext?.resume();
                            }
                            const array = new Uint8Array(
                                this.analyser.frequencyBinCount
                            );
                            this.analyser.getByteFrequencyData(array);

                            const total = array.reduce(
                                (acc, val) => acc + val,
                                0
                            );
                            subscriber.next(total / array.length);
                        })
                    )
                    .subscribe();

                // Clean up
                return () => {
                    timer.unsubscribe();
                };
            }
        }).pipe(share());
    }

    calcCallType(cameraMuted: boolean, micMuted: boolean) {
        if (!cameraMuted) return 'video';
        if (cameraMuted && !micMuted) return 'audioonly';
        return 'none';
    }

    compareMediaDeviceInfo(item1: MediaDeviceInfo, item2: MediaDeviceInfo) {
        return item1 && item2
            ? item1.deviceId === item2.deviceId
            : item1 === item2;
    }

    private brandingEnumerateMediaDevices() {
        const brandingMediaDevices: MediaDeviceInfo[] = [
            __CONSTANTS__.DEFAULT_AUDIO_INPUT_DEVICE,
            __CONSTANTS__.DEFAULT_AUDIO_OUTPUT_DEVICE,
            __CONSTANTS__.DEFAULT_VIDEO_INPUT_DEVICE
        ];
        this.mediaDevices$.next(brandingMediaDevices);
    }

    private brandingGetUserMedia(constraints: MediaStreamConstraints) {
        let stream: any; //tslint:disable-line
        let streamType: string;

        if (!constraints['video'] && !constraints['audio']) {
            stream = null;
            streamType = 'none';
        } else if (constraints['video'] && !constraints['audio']) {
            stream = {
                active: true,
                id: 'branding-mock-stream',
                getVideoTracks: () => {
                    return [
                        {
                            id: 'video1',
                            enabled: true
                        }
                    ];
                },
                getAudioTracks: (): MediaStreamTrack[] => {
                    return [];
                },
                getTracks: () => {
                    return [
                        {
                            id: 'video1',
                            enabled: true
                        }
                    ];
                }
            };
            streamType = 'video';
        } else if (constraints['video'] && constraints['audio']) {
            stream = {
                active: true,
                id: 'branding-mock-stream',
                getVideoTracks: () => {
                    return [
                        {
                            id: 'video1',
                            enabled: true
                        }
                    ];
                },
                getAudioTracks: () => {
                    return [
                        {
                            id: 'audio1',
                            enabled: true
                        }
                    ];
                },
                getTracks: () => {
                    return [
                        {
                            id: 'video1',
                            enabled: true
                        },
                        {
                            id: 'audio1',
                            enabled: true
                        }
                    ];
                }
            };
            streamType = 'video';
        } else if (!constraints['video'] && constraints['audio']) {
            stream = {
                active: true,
                id: 'branding-mock-stream',
                getVideoTracks: (): MediaStreamTrack[] => {
                    return [];
                },
                getAudioTracks: () => {
                    return [
                        {
                            id: 'audio1',
                            enabled: true
                        }
                    ];
                },
                getTracks: () => {
                    return [
                        {
                            id: 'audio1',
                            enabled: true
                        }
                    ];
                }
            };
            streamType = 'audioonly';
        }

        this.mediaDeviceFacade.setLocalMediaStream({
            stream,
            audioDeviceID: null,
            videoDeviceID: null,
            streamType,
            constraints: null
        });
    }

    private brandingReleaseUserMedia() {}

    private getTrackConstraint<K extends keyof MediaTrackConstraints>(
        constraints: boolean | MediaTrackConstraints,
        key: K
    ) {
        if (typeof constraints === 'object') {
            const mandatory = constraints.mandatory;
            if (
                key === 'deviceId' &&
                mandatory &&
                !Array.isArray(mandatory) &&
                mandatory.sourceId
            ) {
                return mandatory.sourceId;
            }

            const constraint = constraints[key];
            if (
                constraint === null ||
                constraint === undefined ||
                Array.isArray(constraint) ||
                typeof constraint !== 'object'
            ) {
                return constraint;
            } else {
                const castedConstraint = constraint as ConstrainRangeOrParams;
                if (castedConstraint.exact !== undefined)
                    return castedConstraint.exact;
                if (castedConstraint.ideal !== undefined)
                    return castedConstraint.ideal;
            }
        }
        return null;
    }

    private logSupportedConstraints() {
        if (!this.hasSupportedConstraints) {
            return;
        }

        const supportedConstraints = this.getSupportedConstraints();
        let supported = '';
        for (const constraint in supportedConstraints) {
            supported += constraint + ' ';
        }
        this.loggingService.info(`supported: ${supported}`);
    }

    private get shouldLoadWebBlur() {
        return (
            !this.webBlur &&
            this.backgroundEffectsService.areBackgroundEffectsSupported
        );
    }

    private shouldDoWebBlur(stream: MediaStream) {
        return (
            this.backgroundEffectsService.isWebGLEnabled &&
            stream &&
            stream.getVideoTracks().length > 0 &&
            this.webBlur &&
            !this.webBlur.hasError
        );
    }

    private async doWebBlur(stream: MediaStream) {
        this.rawMediaStream = stream;
        return this.webBlur.connectBlur(stream);
    }

    getInputDeviceChangeRace() {
        return race(
            this.mediaDeviceFacade.inputDevices$.pipe(
                skip(1),
                map(() => {
                    this.loggingService.info('InputDevice won race');
                    return false;
                })
            ),
            timer(1000).pipe(
                map(() => {
                    this.loggingService.info('InputDevice lost race');
                    return true;
                })
            )
        );
    }
}
