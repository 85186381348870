export class PexConstants {
    // Time constants
    readonly SECOND_MS = 1000;
    readonly MINUTE_MS = 60000;

    readonly MINUTE_S = 60;
    readonly HOUR_S = 3600;

    // HTTP result codes
    readonly HTTP_NOT_FOUND = 404;

    // Presentation
    readonly PRESENTATION_FETCH_TIMEOUT = 15000;

    // App constants
    readonly MAX_NAME_LENGTH = 20;
    readonly MAX_BODY_LENGTH = 100;
    readonly MAX_ALIAS_LENGTH = 30;
    readonly WIDTH_BREAKPOINT = 768;
    readonly MAX_MESSAGE_LENGTH = 250;
    readonly EVENT_BUFFER_DURATION = 1000;

    // Media Device constants
    readonly NONE_AUDIO_INPUT_DEVICE = {
        deviceId: 'none',
        groupId: null,
        kind: 'audioinput',
        label: 'NONE'
    } as MediaDeviceInfo;

    readonly NONE_VIDEO_INPUT_DEVICE = {
        deviceId: 'none',
        groupId: null,
        kind: 'videoinput',
        label: 'NONE'
    } as MediaDeviceInfo;

    readonly DEFAULT_VIDEO_INPUT_DEVICE = {
        deviceId: null,
        groupId: null,
        kind: 'videoinput',
        label: 'DEFAULT'
    } as MediaDeviceInfo;

    readonly DEFAULT_AUDIO_INPUT_DEVICE = {
        deviceId: null,
        groupId: null,
        kind: 'audioinput',
        label: 'DEFAULT'
    } as MediaDeviceInfo;

    readonly DEFAULT_AUDIO_OUTPUT_DEVICE = {
        deviceId: null,
        groupId: null,
        kind: 'audiooutput',
        label: 'DEFAULT'
    } as MediaDeviceInfo;

    readonly VIDEOPLAY_TIMEOUT = 200;
}

export class PexConstantsMobile extends PexConstants {
    readonly FRONT_CAMERA_VIDEO_INPUT_DEVICE = {
        deviceId: 'user',
        groupId: null,
        kind: 'videoinput',
        label: 'FRONT_CAMERA'
    } as MediaDeviceInfo;

    readonly BACK_CAMERA_VIDEO_INPUT_DEVICE = {
        deviceId: 'environment',
        groupId: null,
        kind: 'videoinput',
        label: 'BACK_CAMERA'
    } as MediaDeviceInfo;

    readonly HEADSET_AUDIO_OUTPUT_DEVICE = {
        deviceId: 'earpiece',
        groupId: null,
        kind: 'audiooutput',
        label: 'HEADSET'
    } as MediaDeviceInfo;

    readonly SPEAKER_AUDIO_OUTPUT_DEVICE = {
        deviceId: 'speaker',
        groupId: null,
        kind: 'audiooutput',
        label: 'SPEAKER'
    } as MediaDeviceInfo;

    readonly DEFAULT_AUDIO_OUTPUT_DEVICE: MediaDeviceInfo = this
        .SPEAKER_AUDIO_OUTPUT_DEVICE;
}

export class PexConstantsAndroid extends PexConstantsMobile {}

export class PexConstantsIOS extends PexConstantsMobile {}

export class PexConstantsElectron extends PexConstants {}

export class PexConstantsWeb extends PexConstants {}

export class PexConstantsE2E extends PexConstants {
    readonly DEFAULT_AUDIO_OUTPUT_DEVICE = {
        deviceId: 'default',
        groupId: null,
        kind: 'audiooutput',
        label: 'DEFAULT'
    } as MediaDeviceInfo;
}
