import { createReducer, on } from '@ngrx/store';
import { LayoutActions, LAYOUT_ACTIONS } from './layout.actions';
import { PresentationSlotCoordinates, ViewState } from './layout.model';

export interface LayoutState {
    participants: [];
    pres_slot_coords: PresentationSlotCoordinates;
    view: ViewState;
    requested_layout: ViewState;
}

export interface State extends LayoutState {}

const initialLayoutState: LayoutState = {
    participants: [],
    pres_slot_coords: [
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0]
    ],
    view: '5:7',
    requested_layout: '5:7'
};

export const initialState: State = { ...initialLayoutState };

export function reducer(state = initialState, action: LayoutActions) {
    return layoutReducer(state, action);
}

const layoutReducer = createReducer(
    initialState,
    on(LAYOUT_ACTIONS.presSlotCoordsChangedAction, (state, action) => ({
        ...state,
        pres_slot_coords: action.payload
    })),
    on(LAYOUT_ACTIONS.viewChangedAction, (state, action) => ({
        ...state,
        view: action.payload
    })),
    on(LAYOUT_ACTIONS.requestedLayoutChangedAction, (state, action) => ({
        ...state,
        requested_layout: action.payload
    }))
);
