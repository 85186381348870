import { Pipe, PipeTransform } from '@angular/core';
const linkifyStr = require('linkifyjs/html');

@Pipe({
    name: 'linkify'
})
export class LinkifyPipe implements PipeTransform {
    transform(str: string): string {
        return linkifyStr(str);
    }
}
