import { createAction, props } from '@ngrx/store';
import {
    IncomingCallDetails,
    TokenResponse
} from '../services/registration/registration.model';

type ErrorResponse = any; //tslint:disable-line:no-any

export const REGISTRATION_ACTIONS = {
    setUsername: createAction(
        '[Registration] Set username',
        props<{ username: string }>()
    ),
    setPassword: createAction(
        '[Registration] Set password',
        props<{ password: string }>()
    ),
    initPassword: createAction(
        '[Registration] Init password',
        props<{ password: string }>()
    ),
    setAlias: createAction(
        '[Registration] Set alias',
        props<{ alias: string }>()
    ),
    setHost: createAction('[Registration] Set host', props<{ host: string }>()),

    setADFSFederationServiceName: createAction(
        '[Registration] Set ADFS federation service name',
        props<{ adfsFederationServiceName: string }>()
    ),
    setADFSResource: createAction(
        '[Registration] Set ADFS resource',
        props<{ adfsResource: string }>()
    ),
    setADFSClientID: createAction(
        '[Registration] Set ADFS client ID',
        props<{ adfsClientID: string }>()
    ),
    setADFSRedirectURI: createAction(
        '[Registration] Set ADFS redirect URI',
        props<{ adfsRedirectURI: string }>()
    ),
    setADFSToken: createAction(
        '[Registration] Set ADFS token',
        props<{ adfsToken: string }>()
    ),
    setADFSRefreshToken: createAction(
        '[Registration] Set ADFS refresh token',
        props<{ adfsRefreshToken: string }>()
    ),
    setADFSStateToken: createAction(
        '[Registration] Set ADFS state token',
        props<{ adfsStateToken: string }>()
    ),
    setADFSTokenExpiry: createAction(
        '[Registration] Set ADFS token expiry',
        props<{ adfsTokenExpiry: number }>()
    ),

    register: createAction('[Registration] Register'),
    registerFail: createAction(
        '[Registration] Registration fail',
        props<{ error: ErrorResponse }>()
    ),
    registerFinish: createAction(
        '[Registration] Registration finish',
        props<{ closeSourceFn: () => void }>()
    ),
    unregisterStart: createAction('[Registration] Unregister start'),
    unregisterFinish: createAction('[Registration] Unregister finish'),
    cancel: createAction('[Registration] cancel'),

    srvLookupStart: createAction('[Registration] SRV lookup start'),
    srvLookupFinish: createAction(
        '[Registration] SRV lookup finish',
        props<{ resolvedHosts: string[] }>()
    ),
    srvLookupFail: createAction(
        '[Registration] SRV lookup fail',
        props<{ error: ErrorResponse }>()
    ),

    requestTokenStart: createAction(
        '[Registration] Request token start',
        props<{ server: string }>()
    ),
    requestTokenFinish: createAction(
        '[Registration] Request token finish',
        props<{ registeredHost: string; tokenResponse: TokenResponse }>()
    ),
    requestTokenFail: createAction(
        '[Registration] Request token fail',
        props<{ error: ErrorResponse }>()
    ),

    refreshTokenStart: createAction('[Registration] Refresh token start'),
    refreshTokenFinish: createAction(
        '[Registration] Refresh token finish',
        props<{ tokenResponse: TokenResponse }>()
    ),
    refreshTokenFail: createAction(
        '[Registration] Refresh token fail',
        props<{ error: ErrorResponse }>()
    ),
    clearDetails: createAction('[Registration] Clear details'),

    openADFSURL: createAction('[Registration] Open ADFS URL'),
    adfsTokenRefresh: createAction('[Registration] ADFS token refresh'),
    adfsTokenRequest: createAction(
        '[Registration] ADFS token request',
        props<{ code: string }>()
    ),

    openEventSourceStart: createAction(
        '[Registration] Open event source start'
    ),
    openEventSourceFinish: createAction(
        '[Registration] Open event source finish'
    ),
    incomingCall: createAction(
        '[Registration] Incoming call',
        props<{ call: IncomingCallDetails }>()
    ),
    incomingCallCancelled: createAction(
        '[Registration] Incoming call cancelled',
        props<{ call: IncomingCallDetails }>()
    ),
    incomingCallDialogOpened: createAction(
        '[Registration] Incoming call dialog opened',
        props<{ token: string; id: string }>()
    ),
    incomingCallDialogClosed: createAction(
        '[Registration] Incoming call dialog closed',
        props<{ token: string }>()
    )
};
