import { EventEmitter, Injectable } from '@angular/core';
import { ToastMessage } from './toast-message';

@Injectable({ providedIn: 'root' })
export class ToastService {
    addToast = new EventEmitter();
    removeToast = new EventEmitter();

    constructor() {}

    add(message: ToastMessage) {
        this.addToast.emit(message);
    }

    remove(message: ToastMessage) {
        this.removeToast.emit(message);
    }
}
