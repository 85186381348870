import { ActionCreator } from '@ngrx/store';
import { CONFERENCE_ACTIONS } from 'src/core/conference/conference.actions';
import { EVENTS_ACTIONS } from 'src/core/conference/events/events.actions';
import { HOME_ACTIONS } from 'src/core/home/home.actions';
import { MEDIA_DEVICE_ACTIONS } from 'src/core/media-device/media-device.actions';
import { REGISTRATION_ACTIONS } from 'src/core/registration/registration.actions';
import { GENERAL_ACTIONS } from 'src/core/shared/general/general.actions';

const actionCreators = [
    ...Object.entries(CONFERENCE_ACTIONS),
    ...Object.entries(EVENTS_ACTIONS),
    ...Object.entries(HOME_ACTIONS),
    ...Object.entries(GENERAL_ACTIONS),
    ...Object.entries(MEDIA_DEVICE_ACTIONS),
    ...Object.entries(REGISTRATION_ACTIONS)
].reduce<Record<string, ActionCreator>>((acc, [, action]) => {
    acc[action.type] = action;
    return acc;
}, {});

export function getActionCreator(type: string) {
    return actionCreators[type];
}
