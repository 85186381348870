import {
    Action,
    createFeatureSelector,
    createReducer,
    createSelector,
    on
} from '@ngrx/store';

import { DevicesSetupState } from '../media-device/media-devices/devices-setup-state.type';
import { HOME_ACTIONS } from './home.actions';
import {
    ScreenState,
    ScreenStateEnum
} from './offset-overlay/screen-state.type';
import {
    SettingsState,
    SettingsStateEnum
} from './setting-overlay/settings-state.type';

export interface HomeState {
    screen: ScreenState;
    settings: SettingsState;
    devicesSetup: DevicesSetupState;
    microphoneMuted: boolean;
    cameraMuted: boolean;
}

const userSettings = JSON.parse(localStorage.getItem('pexUserSettingsValues'));

export const initialState: HomeState = {
    screen: ScreenStateEnum.HOME,
    settings: SettingsStateEnum.MAIN,
    devicesSetup: null,
    microphoneMuted: (userSettings && userSettings.muteMicrophone) || false,
    cameraMuted: (userSettings && userSettings.muteCamera) || false
};

const homeReducer = createReducer(
    initialState,
    on(HOME_ACTIONS.setScreenAction, (state, { screen }) => ({
        ...state,
        screen
    })),
    on(HOME_ACTIONS.settingsNavigateAction, (state, { screen }) => ({
        ...state,
        settings: screen
    })),
    on(HOME_ACTIONS.devicesSetupNavigateAction, (state, { screen }) => ({
        ...state,
        settings: SettingsStateEnum.DEVICES_SETUP,
        devicesSetup: screen
    })),
    on(HOME_ACTIONS.cameraMuteChangeAction, (state, { muted }) => ({
        ...state,
        cameraMuted: muted
    })),
    on(HOME_ACTIONS.microphoneMuteChangeAction, (state, { muted }) => ({
        ...state,
        microphoneMuted: muted
    }))
);

export function reducer(state: HomeState, action: Action) {
    return homeReducer(state, action);
}

export const getHomeState = createFeatureSelector<HomeState>('home');

export const getScreenState = createSelector(
    getHomeState,
    (state: HomeState) => state.screen
);
export const getIsHome = createSelector(
    getHomeState,
    (state: HomeState) => state.screen === ScreenStateEnum.HOME
);
export const getSettingsState = createSelector(
    getHomeState,
    (state: HomeState) => state.settings
);
export const getDevicesSetupState = createSelector(
    getHomeState,
    (state: HomeState) => state.devicesSetup
);
export const getMicrophoneMuted = createSelector(
    getHomeState,
    (state: HomeState) => state.microphoneMuted
);
export const getCameraMuted = createSelector(
    getHomeState,
    (state: HomeState) => state.cameraMuted
);
