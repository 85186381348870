import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

import { AddParticipantDialogComponent } from '../conference/add-participant-dialog/add-participant-dialog.component';
import { ConferenceFacade } from '../conference/conference.facade';
import { DialogService } from '../dialog/dialog.service';
import { PlatformService } from '../platform.service';
import { HOT_KEYS_LIST } from './hotkey-list';
interface HotkeyPattern {
    ctrl: boolean;
    meta: boolean;
    alt: boolean;
    pattern: string;
}

interface Hotkey {
    patterns: HotkeyPattern[];
    subject?: Subject<HotkeyPattern>;
}

@Injectable()
export class HotkeyService {
    private activeHotkeys: Hotkey[] = [];
    private currentChain: string[] = [];

    private readonly SPECIAL_KEYS = ['Meta', 'Control', 'Shift', 'Alt'];

    constructor(
        private conferenceFacade: ConferenceFacade,
        private dialogService: DialogService,
        private platformService: PlatformService
    ) {
        window.addEventListener('blur', () => this.blurHandler());
        window.addEventListener('keydown', event => this.keydownHandler(event));
        window.addEventListener('keyup', event => this.keyupHandler(event));
    }

    subscribeToConferenceHotkeys(
        takeUntilDestroyed$: <T>(source: Observable<T>) => Observable<T>
    ) {
        this.getListener(HOT_KEYS_LIST.TOGGLE_MIC.hotKey)
            .pipe(
                takeUntilDestroyed$,
                withLatestFrom(
                    this.conferenceFacade.isConnected$,
                    this.conferenceFacade.mediaType$,
                    this.conferenceFacade.isMicrophoneMuted$
                )
            )
            .subscribe(([_, connected, mediaType, micMuted]) => {
                if (connected && mediaType !== 'none') {
                    if (micMuted) {
                        this.conferenceFacade.setUnmuteMicrophone();
                    } else {
                        this.conferenceFacade.setMuteMicrophone();
                    }
                }
            });
        this.getListener(HOT_KEYS_LIST.TOGGLE_CAMERA.hotKey)
            .pipe(
                takeUntilDestroyed$,
                withLatestFrom(
                    this.conferenceFacade.isConnected$,
                    this.conferenceFacade.mediaType$,
                    this.conferenceFacade.isCameraMuted$
                )
            )
            .subscribe(([_, connected, mediaType, cameraMuted]) => {
                if (connected && mediaType === 'video') {
                    if (cameraMuted) {
                        this.conferenceFacade.setUnmuteCamera();
                    } else {
                        this.conferenceFacade.setMuteCamera();
                    }
                }
            });

        this.getListener(HOT_KEYS_LIST.ADD_PARTICIPANT.hotKey)
            .pipe(
                takeUntilDestroyed$,
                withLatestFrom(
                    this.conferenceFacade.isConnected$,
                    this.conferenceFacade.selfParticipant$
                )
            )
            .subscribe(([_, connected, selfParticipant]) => {
                if (
                    connected &&
                    selfParticipant &&
                    selfParticipant.canControl &&
                    this.dialogService.getDialogRefs(
                        AddParticipantDialogComponent
                    ).length === 0
                ) {
                    this.conferenceFacade.openAddParticipantDialog({
                        isHost: true
                    });
                }
            });

        this.getListener(HOT_KEYS_LIST.TOGGLE_SIDEBAR.hotKey)
            .pipe(
                takeUntilDestroyed$,
                withLatestFrom(this.conferenceFacade.isConnected$)
            )
            .subscribe(([_, connected]) => {
                if (connected) {
                    this.conferenceFacade.toggleSidebar();
                }
            });

        this.getListener(HOT_KEYS_LIST.TOGGLE_EVENTS_ROSTER.hotKey)
            .pipe(
                takeUntilDestroyed$,
                withLatestFrom(
                    this.conferenceFacade.isConnected$,
                    this.conferenceFacade.sidebarState$
                )
            )
            .subscribe(([_, connected, sidebarState]) => {
                if (connected) {
                    this.conferenceFacade.setShowConferenceSidebar(true);
                    if (sidebarState === 'EVENTS') {
                        this.conferenceFacade.setSidebarState('ROSTER');
                    } else if (sidebarState === 'ROSTER') {
                        this.conferenceFacade.setSidebarState('EVENTS');
                    }
                }
            });

        this.getListener(HOT_KEYS_LIST.TOGGLE_VIDEO_PRESENTATION.hotKey)
            .pipe(
                takeUntilDestroyed$,
                withLatestFrom(
                    this.conferenceFacade.isPresentationLive$,
                    this.conferenceFacade.isPresentationMaximized$,
                    this.conferenceFacade.isPresentationPoppedOut$
                )
            )
            .subscribe(
                ([
                    _,
                    isPresentationLive,
                    isPresentationMaximized,
                    isPresentationPoppedOut
                ]) => {
                    if (isPresentationLive && !isPresentationPoppedOut) {
                        // this.isDragging = false;
                        if (isPresentationMaximized) {
                            this.conferenceFacade.setPresentationMaximized(
                                false
                            );
                        } else {
                            this.conferenceFacade.setPresentationMaximized(
                                true
                            );
                        }
                    }
                }
            );

        if (!this.platformService.isCitrixApp()) {
            this.getListener(HOT_KEYS_LIST.POPUP_PRESENTATION.hotKey)
                .pipe(
                    takeUntilDestroyed$,
                    withLatestFrom(
                        this.conferenceFacade.isPresentationLive$,
                        this.conferenceFacade.isPresentationPoppedOut$
                    )
                )
                .subscribe(
                    ([_, isPresentationLive, isPresentationPoppedOut]) => {
                        if (isPresentationLive) {
                            if (isPresentationPoppedOut) {
                                this.conferenceFacade.setPresentationPoppedOut(
                                    false
                                );
                            } else {
                                this.conferenceFacade.setPresentationPoppedOut(
                                    true
                                );
                            }
                        }
                    }
                );
        }

        this.getListener(HOT_KEYS_LIST.TOGGLE_INCOMING_AUDIO.hotKey)
            .pipe(
                takeUntilDestroyed$,
                withLatestFrom(
                    this.conferenceFacade.isConnected$,
                    this.conferenceFacade.mediaType$,
                    this.conferenceFacade.isVolumeMuted$
                )
            )
            .subscribe(([_, connected, mediaType, volumeMuted]) => {
                if (connected && mediaType !== 'none') {
                    if (volumeMuted) {
                        this.conferenceFacade.setUnmuteVolume();
                    } else {
                        this.conferenceFacade.setMuteVolume();
                    }
                }
            });
    }

    getListener(pattern: HotkeyPattern | string | (HotkeyPattern | string)[]) {
        const hotkey: Hotkey = { patterns: [] };

        if (!Array.isArray(pattern)) {
            pattern = [pattern];
        }

        const patterns = pattern.map(p => {
            if (typeof p === 'string') {
                return { pattern: p, ctrl: false, alt: false, meta: false };
            }
            return p;
        });
        hotkey.patterns = patterns;

        const subject = new Subject<HotkeyPattern>();
        hotkey.subject = subject;
        this.activeHotkeys.push(hotkey);
        return subject;
    }

    private blurHandler() {
        this.currentChain = [];
    }

    private keyupHandler(event: KeyboardEvent) {
        this.handleKeyChange(event, false);
    }

    private keydownHandler(event: KeyboardEvent) {
        if (event.repeat) return;
        this.handleKeyChange(event, true);
    }

    private handleKeyChange(event: KeyboardEvent, down: boolean) {
        let { key } = event;
        if (key === 'CapsLock') return;

        if (event.getModifierState) {
            const isCaps = event.getModifierState('CapsLock');
            const isShift = event.getModifierState('Shift');
            if (isCaps && !isShift) {
                key = key.toLowerCase();
            }
        }

        const activeElement = document.activeElement;
        if (activeElement && activeElement.tagName === 'INPUT') {
            this.currentChain = [];
            return;
        }
        if (this.isSpecialKey(key)) {
            if (down) return;
            if (
                !event.altKey &&
                !event.ctrlKey &&
                !event.shiftKey &&
                !event.metaKey
            ) {
                this.currentChain = [];
            }
        }

        const charIndex = this.currentChain.indexOf(key);

        if (charIndex > -1 && !down) {
            return this.currentChain.splice(charIndex, 1);
        }

        if (charIndex === -1 && down) {
            this.currentChain.push(key);
            for (const hotkey of this.activeHotkeys) {
                for (const pattern of hotkey.patterns) {
                    if (this.checkHotkeyPattern(pattern, event)) {
                        hotkey.subject.next(pattern);
                        break;
                    }
                }
            }
        }
    }

    private isSpecialKey(key: string) {
        const specialKeyIndex = this.SPECIAL_KEYS.indexOf(key);
        if (specialKeyIndex > -1) {
            return this.SPECIAL_KEYS[specialKeyIndex].toLowerCase();
        }
        return null;
    }

    private checkHotkeyPattern(hotkey: HotkeyPattern, event: KeyboardEvent) {
        if (hotkey.pattern.length !== this.currentChain.length) return false;

        if (hotkey.alt !== event.altKey) return false;
        if (hotkey.ctrl !== event.ctrlKey) return false;
        if (hotkey.meta !== event.metaKey) return false;

        for (const char of this.currentChain) {
            if (!hotkey.pattern.includes(char)) return false;
        }
        return true;
    }
}
