import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';

import ResizeObserver from 'resize-observer-polyfill';
import { fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    TakeUntilDestroy,
    untilDestroyed
} from './take-until-destory.decorator';
@TakeUntilDestroy()
@Directive({
    selector: '[pexDraggable]'
})
export class DraggableDirective implements OnInit, OnDestroy {
    private topStart: number;
    private leftStart: number;
    private _allowDrag = true;
    private _handle: HTMLElement;
    private _isDragging = false;
    private md: boolean;
    private lastPosition = { x: 0, y: 0 };
    private containerObserver: ResizeObserver;

    @Input() container?: HTMLElement;
    private containerSize?: { width: number; height: number };

    @Output()
    isDragging = new EventEmitter<boolean>();

    constructor(public element: ElementRef, private ngZone: NgZone) {}

    ngOnInit() {
        this.containerObserver = new ResizeObserver(() => {
            this.onContainerResize();
        });
        if (this.container) {
            this.containerObserver.observe(this.container);
        } else {
            this.containerObserver.observe(document.body);
        }
    }

    ngOnDestroy() {
        if (this.containerObserver) {
            this.containerObserver.disconnect();
        }
    }

    @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent) {
        if (
            event.button === 2 ||
            (this._handle !== undefined && event.target !== this._handle)
        )
            return; // prevents right click drag, remove this if you want it

        this.dragStart(event.clientX, event.clientY);
        this.ngZone.runOutsideAngular(() => {
            fromEvent(document, 'mousemove')
                .pipe(
                    takeUntil(fromEvent(document, 'mouseup')),
                    takeUntil(fromEvent(document, 'mouseleave')),
                    untilDestroyed(this)
                )
                .subscribe(
                    (e: MouseEvent) => {
                        this.onMouseMove(e);
                    },
                    undefined,
                    () => {
                        this.dragStop();
                    }
                );
        });
    }

    private isMoving(event: MouseEvent) {
        if (
            event.clientX !== this.lastPosition.x ||
            event.clientY !== this.lastPosition.y
        ) {
            return true;
        } else {
            return false;
        }
    }

    onMouseMove(event: MouseEvent) {
        if (this.isMoving(event) && this.md && this._allowDrag) {
            this.dragMove(event.clientX, event.clientY);
        }
    }

    @HostListener('touchstart', ['$event'])
    onTouchStart(event: PexTouchEvent) {
        this.dragStart(
            event.changedTouches[0].clientX,
            event.changedTouches[0].clientY
        );
        event.stopPropagation();

        this.ngZone.runOutsideAngular(() => {
            fromEvent(document, 'touchmove')
                .pipe(
                    takeUntil(fromEvent(document, 'touchend')),
                    untilDestroyed(this)
                )
                .subscribe(
                    (e: PexTouchEvent) => {
                        this.onTouchMove(e);
                    },
                    undefined,
                    () => {
                        this.dragStop();
                    }
                );
        });
    }

    onTouchMove(event: PexTouchEvent) {
        if (this.md && this._allowDrag) {
            this.dragMove(
                event.changedTouches[0].clientX,
                event.changedTouches[0].clientY
            );
        }
        event.stopPropagation();
    }

    @Input('pexDraggable')
    set allowDrag(value: boolean) {
        this._allowDrag = value;
        if (this._allowDrag)
            this.element.nativeElement.className += ' cursor-draggable';
        else
            this.element.nativeElement.className = this.element.nativeElement.className.replace(
                ' cursor-draggable',
                ''
            );
    }

    @Input()
    set handle(handle: HTMLElement) {
        this._handle = handle;
    }

    private dragStart(x: number, y: number) {
        this.md = true;
        this._isDragging = false;
        this.isDragging.next(false);

        this.lastPosition.x = x;
        this.lastPosition.y = y;

        const { y: offsetTop, x: offsetLeft } = this.getCurrentPosition();

        this.topStart = y - offsetTop;
        this.leftStart = x - offsetLeft;

        if (this._allowDrag) {
            if (this.container) {
                this.element.nativeElement.style.position = 'absolute';
            } else {
                this.element.nativeElement.style.position = 'fixed';
            }
        }

        this.updateContainerSize();
    }

    private dragMove(x: number, y: number) {
        if (!this._isDragging) {
            this._isDragging = true;
            this.isDragging.next(true);
        }

        this.lastPosition.x = x;
        this.lastPosition.y = y;

        this.element.nativeElement.style.top =
            Math.max(
                Math.min(
                    y - this.topStart,
                    this.containerSize.height -
                        this.element.nativeElement.clientHeight
                ),
                0
            ) + 'px';
        this.element.nativeElement.style.left =
            Math.max(
                Math.min(
                    x - this.leftStart,
                    this.containerSize.width -
                        this.element.nativeElement.clientWidth
                ),
                0
            ) + 'px';
    }

    private dragStop() {
        this.md = false;
    }

    private onContainerResize() {
        this.updateContainerSize();
        const maxWidth =
            this.containerSize.width - this.element.nativeElement.clientWidth;
        const maxHeight =
            this.containerSize.height - this.element.nativeElement.clientHeight;
        const { x, y } = this.getCurrentPosition();
        if (this._allowDrag) {
            this.element.nativeElement.style.top =
                Math.max(Math.min(y, maxHeight), 0) + 'px';
            this.element.nativeElement.style.left =
                Math.max(Math.min(x, maxWidth), 0) + 'px';
        }
    }

    private getCurrentPosition() {
        const element = this.element.nativeElement;
        return {
            y: element.style.top
                ? element.style.top.replace('px', '')
                : element.offsetTop,
            x: element.style.left
                ? element.style.left.replace('px', '')
                : element.offsetLeft
        };
    }

    private updateContainerSize() {
        if (this.container) {
            this.containerSize = {
                width: this.container.clientWidth,
                height: this.container.clientHeight
            };
        } else {
            this.containerSize = {
                width: window.innerWidth,
                height: window.innerHeight
            };
        }
    }
}
