import { BehaviorSubject } from 'rxjs/';

import { Favorite } from '../favorites.service';
import { PexStorage } from './storage';

export class FavoritesStorage extends PexStorage<FavoritesModel> {
    readonly storageString = 'pexFavorites';
    favorites$: BehaviorSubject<FavoritesModel>;

    constructor(values: FavoritesModel) {
        super(new FavoritesModel(), values);
        this.favorites$ = new BehaviorSubject(this.values);
    }

    reset() {
        super.reset();
        if (localStorage.getItem(this.storageString)) {
            localStorage.removeItem(this.storageString);
        }
        this.favorites$.next({ favorites: [] });
    }
}

export class FavoritesModel {
    favorites: Favorite[] = [];
}
