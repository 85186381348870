export enum MediaDeviceKindEnum {
    videoinput = 'videoinput',
    audioinput = 'audioinput',
    audiooutput = 'audiooutput'
}

export type MediaDeviceKind =
    | MediaDeviceKindEnum.audioinput
    | MediaDeviceKindEnum.audiooutput
    | MediaDeviceKindEnum.videoinput;
