import { Component } from '@angular/core';

import { Logger } from '../../shared/logger';
import { BaseDialogContentComponent } from '../base-dialog-content.component';

@Component({
    templateUrl: './error-dialog.component.html',
    styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent extends BaseDialogContentComponent<
    boolean,
    string | object
> {
    close() {
        super.close(true);
    }

    copy() {
        if (window.document.execCommand) {
            window.document.execCommand('copy');
        }
    }

    get errorText() {
        return typeof this.config === 'object'
            ? Logger.safeStringify(this.config)
            : this.config;
    }
}
