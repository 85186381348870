import { Injectable } from '@angular/core';

import { LoggingService } from './logging.service';
import { BrandingService } from './services/branding.service';
import { StorageService } from './services/storage/storage.service';
import { GeneralFacade } from './shared/general/general.facade';

@Injectable({ providedIn: 'root' })
export abstract class InitService {
    constructor(
        protected generalFacade: GeneralFacade,
        protected storageService: StorageService,
        protected loggingService: LoggingService,
        protected brandingService: BrandingService
    ) {}
    init() {
        this.initImpl();
        window.addEventListener('online', () =>
            this.generalFacade.setOnline(true)
        );
        window.addEventListener('offline', () =>
            this.generalFacade.setOnline(false)
        );

        this.generalFacade.initFinished();
    }

    abstract initImpl(): void;
}
