import { Params } from '@angular/router';

export class PexURL {
    protocol: string;
    alias: string;
    params: Params;

    constructor(public url: string) {
        if (url.indexOf(':') > -1) {
            this.protocol = url.split(':')[0];
        } else {
            throw new Error('failed to find a valid URL');
        }
        const { protocol, alias, params } = PexURL.getBitsFromUrl(url);
        this.protocol = protocol;
        this.alias = alias;

        if (!this.protocol) {
            // failed to parse out a valid alias
            throw new Error('failed to parse valid alias');
        }

        if (params) {
            this.params = this.normalizeQueryParams(params);
        }
    }

    static getBitsFromUrl(url: string) {
        const questionMarkIndex = url.indexOf('?');
        let aliasPart: string;
        let queryParamPart: string;

        if (questionMarkIndex > -1) {
            queryParamPart = url.substr(questionMarkIndex);
            aliasPart = url.substr(0, questionMarkIndex);
        } else {
            aliasPart = url;
        }
        const matches = aliasPart.match(
            /^(pexip|pexip-provision|pexip-auth):\/?\/?([^\/]*)/
        );
        if (matches) {
            return {
                protocol: matches[1],
                alias: matches[2],
                params: queryParamPart
            };
        } else {
            // console.log('no match for', url);
            return {
                protocol: null,
                alias: null,
                params: null
            };
        }
    }

    private normalizeQueryParams(params: string) {
        const queryParams = {};
        const it = new URLSearchParams(params)['entries']();
        let entry;
        while (!(entry = it.next()).done) {
            queryParams[entry.value[0]] = entry.value[1];
        }

        return queryParams;
    }
}
