import { Component } from '@angular/core';

import { BaseDialogContentComponent } from './base-dialog-content.component';

export interface TemplateDialogConfig {
    title?: string;
    body?: string;
    escapeAction?: string;
    isCloseDisabled?: boolean;
}

@Component({
    templateUrl: './template-dialog.component.html',
    styleUrls: ['./template-dialog.component.scss']
})
export class TemplateDialogComponent extends BaseDialogContentComponent<
    string,
    TemplateDialogConfig
> {
    clickOutside(_e: MouseEvent) {
        if (this.safeConfig.isCloseDisabled) return;
        this.close();
    }

    escapePressed(_e: KeyboardEvent) {
        if (this.safeConfig.isCloseDisabled) return;
        this.close(this.safeConfig.escapeAction);
    }
}
