export interface LanguageModel {
    id?: string;
    locale: string;
    label: string;
}
export class ApplicationSettingsModel {
    dialOutProtocol = 'auto';
    languages = [
        {
            id: 'en',
            label: 'English',
            locale: 'en'
        }
    ] as LanguageModel[];
    bandwidths = ['', '256', '576', '1264', '2464', '6144'];
    turnServer:
        | Partial<RTCIceServer & { url?: string }>
        | Partial<RTCIceServer & { url?: string }>[]
        | undefined = undefined;
    serverAddress: string | undefined = undefined;
    registrationEnabled = true;
    h264Enabled = false;
    disconnectDestination = '/home';
    defaultToMuted = false;
    showTimeline = false;
    showSidebar = true;
    micSampling = false;
    raiseHandInVMR = false;
    presentationInMix = true;
    sortAttendeesAlphabetically = false;
    backgroundEffects = true;
}

export enum BackgroundEffects {
    blur = 'blur',
    replacement = 'replacement'
}

export class UserSettingsModel {
    displayName: string | undefined = undefined;
    language = 'en';
    screenshareFrameRate = 15;
    promptDisconnect = true;
    viewFullMotionPresentation = true;
    sendStats = true;
    bandwidth = '';
    showConferenceSidebar = false;
    highContrast = false;
    startInBackground = false;
    playRingtone = true;
    muteMicrophone = false;
    muteCamera = false;
    videoConstraints = true;
    devicePairing = true;
    desktopBackgroundFile: string | undefined =
        'assets/default-replacement.jpg';
    activeEffect = BackgroundEffects.blur;
    enableBackgroundEffects = false;
    enableFecc = false;
    enablePushToTalk = true;
}

export class MobileApplicationSettingsModel extends ApplicationSettingsModel {
    languages = [
        {
            id: 'en',
            label: 'English',
            locale: 'en'
        },
        {
            id: 'ja',
            label: '日本語',
            locale: 'ja'
        },
        {
            id: 'zh-cn',
            label: '简体中文',
            locale: 'zh-cn'
        },
        {
            id: 'zh-tw',
            label: '繁體中文',
            locale: 'zh-tw'
        }
    ] as LanguageModel[];
}

export class MobileUserSettingsModel extends UserSettingsModel {
    devicePairing = false;
    videoConstraints = false;
}

export class PluginSettingsModel {
    id: string;
    srcURL: string;
    enabled = false;
}

export interface SettingsModel {
    applicationSettings: ApplicationSettingsModel;
    defaultUserSettings: UserSettingsModel;
    plugins: PluginSettingsModel[];
}

export interface BrandedSettingsModel {
    applicationSettings?: Partial<ApplicationSettingsModel>;
    defaultUserSettings?: Partial<UserSettingsModel>;
    plugins?: PluginSettingsModel[];
}
