import { HttpClient } from '@angular/common/http';
import {
    Compiler,
    ComponentFactory,
    Injectable,
    Injector,
    NgZone
} from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ConferenceFacade } from './../../conference/conference.facade';
import { DialogService, ExtractDialog } from './../../dialog/dialog.service';

import { PlatformService } from '../../platform.service';

import { CONFERENCE_ACTIONS } from '../../conference/conference.actions';

import { State } from '../../app.reducer';

import { LoggingService } from '../../logging.service';
import { ParticipantsFacade } from './../../participant/participants.facade';

import { PluginSettingsModel } from '../storage/settings.model';

import { PARTICIPANT_ACTIONS } from 'src/core/participant/participant.actions';
import { EVENTS_ACTIONS } from '../../conference/events/events.actions';
import { BaseDialogContainerComponent } from '../../dialog/base-dialog-container.component';
import { BaseDialogContentComponent } from '../../dialog/base-dialog-content.component';
import { DialogType } from '../../dialog/dialog.service';
import { TemplateDialogConfig } from '../../dialog/template-dialog.component';
import { HOME_ACTIONS } from '../../home/home.actions';
import { ScreenStateEnum } from '../../home/offset-overlay/screen-state.type';
import { StorageService } from '../storage/storage.service';
import { getActionCreator } from './action-wrapper';
import { MenuItem, MenuItemJSON, Plugin, PluginMetaData } from './plugin.model';

@Injectable({
    providedIn: 'root'
})
export class PluginService {
    pluginMetaDataList: { [id: string]: PluginMetaData } = {};
    participantContextMenuItems: MenuItem[] = [];
    conferenceSettingsMenuItems: MenuItem[] = [];
    cmdLineEntries: MenuItem[] = [];
    toolbarButtons: MenuItem[] = [];
    private pluginHttpRequests: Subscription[] = [];

    screenState$: BehaviorSubject<string> = new BehaviorSubject('HOME');
    private get pluginResources() {
        return this.storageService.brandingProxy.cachedPluginResources;
    }

    constructor(
        private actions$: Actions,
        private store$: Store<State>,
        private storageService: StorageService,
        private dialogService: DialogService,
        private httpClient: HttpClient,
        public platformService: PlatformService,
        public injector: Injector,
        private loggingService: LoggingService,
        private ngZone: NgZone,
        private compiler: Compiler,
        private conferenceFacade: ConferenceFacade,
        private participantsFacade: ParticipantsFacade
    ) {
        const patchedActions$: Actions & { ofType?: Function } = this.actions$;
        patchedActions$.ofType = (...actions: string[]) =>
            this.actions$.pipe(ofType(...actions));
        window.PEX = {
            pluginAPI: {
                registerPlugin: (plugin: Plugin) => {
                    plugin.load(
                        this.participantsFacade.rosterParticipants$,
                        this.conferenceFacade.conferenceDetails$
                    );
                    this.pluginMetaDataList[plugin.id].plugin = plugin;
                },
                createNewState: (initialValue: {
                    icon?: string;
                    label?: string;
                }) => {
                    return new BehaviorSubject(initialValue);
                },
                openTemplateDialog: (
                    config: TemplateDialogConfig = {},
                    type?: DialogType,
                    priority?: number,
                    containerConfig?: unknown
                ) => {
                    return this.addLegacyDialogAPISupport(
                        this.dialogService.openTemplateDialog(
                            config,
                            type,
                            priority,
                            containerConfig
                        )
                    );
                },
                //FIXME: linter complains if you annotate, but also if you remove the annotation???
                //tslint:disable-next-line:no-inferrable-types
                navigateToTestConference: (conferenceName: string = 'test') => {
                    this.ngZone.run(() => {
                        this.injector
                            .get(Router)
                            .navigate([`/conference/${conferenceName}`]);
                    });
                },
                navigateToInvitationCard: (
                    //FIXME: linter complains if you annotate, but also if you remove the annotation???
                    //tslint:disable-next-line:no-inferrable-types
                    conference: string = 'test',
                    pin?: number
                ) => {
                    const queryParams: {
                        conference: string;
                        pin?: number;
                    } = {
                        conference
                    };
                    if (pin) {
                        queryParams.pin = pin;
                    }
                    this.ngZone.run(() => {
                        this.injector
                            .get(Router)
                            .navigate(['/home/'], { queryParams });
                    });
                },
                navigateToHome: () => {
                    this.ngZone.run(() => {
                        this.store$.dispatch(
                            HOME_ACTIONS.setScreenAction({
                                screen: ScreenStateEnum.HOME
                            })
                        );
                        this.injector.get(Router).navigate(['/home']);
                    });
                },
                navigateToHomeSearch: () => {
                    this.ngZone.run(() => {
                        this.store$.dispatch(
                            HOME_ACTIONS.setScreenAction({
                                screen: ScreenStateEnum.SEARCH
                            })
                        );
                        this.injector.get(Router).navigate(['/home']);
                    });
                },
                navigateToHomeSettings: () => {
                    this.ngZone.run(() => {
                        this.store$.dispatch(
                            HOME_ACTIONS.setScreenAction({
                                screen: ScreenStateEnum.SETTINGS
                            })
                        );
                        this.injector.get(Router).navigate(['/home']);
                    });
                },
                navigateToOnBoardingScreen: () => {
                    this.ngZone.run(() => {
                        this.store$.dispatch(
                            HOME_ACTIONS.setScreenAction({
                                screen: ScreenStateEnum.WELCOME
                            })
                        );
                        this.injector.get(Router).navigate(['/home']);
                    });
                },
                navigateToIncomingCall: () => {
                    this.ngZone.run(() => {
                        this.injector.get(Router).navigate(['/home'], {
                            queryParams: { conference: 'test' }
                        });
                    });
                },
                getPluginMenuItem: (pluginId: string, menuItemId: string) =>
                    this.getPluginMenuItem(pluginId, menuItemId)
            },
            actions: {
                SEND_CHAT_MESSAGE: CONFERENCE_ACTIONS.sendChatMessage.type,
                RECEIVE_CHAT_MESSAGE:
                    CONFERENCE_ACTIONS.receiveChatMessage.type,

                SELECT_EVENT: EVENTS_ACTIONS.setSelectedEvent.type,

                PARTICIPANT_CONNECT_SUCCESS:
                    PARTICIPANT_ACTIONS.connectSuccessAction.type,
                PARTICIPANT_DISCONNECT_SUCCESS:
                    PARTICIPANT_ACTIONS.disconnectSuccessAction.type
            },
            actions$: patchedActions$,
            getActionCreator,
            dispatchAction: action => {
                this.store$.dispatch(action);
            },
            screenState$: this.screenState$,
            rtc: {
                options: {}
            }
        };
    }

    get plugins() {
        return Object.values(this.pluginMetaDataList);
    }

    init() {
        this.loggingService.info('plugin-service: init');
        for (const plugin of this.storageService.pluginsProxy) {
            this.addPluginMetaData(plugin);
        }
    }

    addPluginResource(resourcePath: string, resourceContent: string) {
        this.storageService.brandingProxy.cachedPluginResources = {
            ...this.pluginResources,
            [resourcePath]: resourceContent
        };
    }

    addPluginMetaData(plugin: PluginSettingsModel) {
        if (!plugin) {
            this.loggingService.warn('Tried to load a null plugin');
            return;
        }

        if (
            this.platformService.isElectron() &&
            !plugin.srcURL.startsWith('https://')
        ) {
            if (!plugin.srcURL.startsWith('plugins/')) {
                return this.loggingService.warn(
                    'Electron plugin path is not in plugins dir',
                    plugin.srcURL
                );
            }

            const fileContent = this.pluginResources[plugin.srcURL];

            if (!fileContent) {
                return this.loggingService.warn(
                    'Could not find electron manifest',
                    plugin.srcURL
                );
            }

            try {
                const pluginMetaData: PluginMetaData = JSON.parse(fileContent);
                pluginMetaData.pluginBaseURL = plugin.srcURL
                    .split('/')
                    .splice(0, 2)
                    .join('/');
                this.pluginMetaDataList[pluginMetaData.id] = pluginMetaData;

                if (plugin.enabled) {
                    this.loadPlugin(pluginMetaData);
                }
                return;
            } catch (e) {
                this.loggingService.error(
                    'Error while parsing plugin manifest',
                    plugin.srcURL,
                    e
                );
            }
        }

        const metadataUrl = plugin.srcURL.startsWith('https://')
            ? plugin.srcURL
            : this.storageService.brandingProxy.brandingURL +
              '/' +
              plugin.srcURL;
        const load = plugin.enabled;
        this.loggingService.info(
            'plugin-service: Loading metadata from',
            metadataUrl
        );
        this.pluginHttpRequests.push(
            this.httpClient
                .get(metadataUrl)
                .pipe(
                    catchError(() => {
                        this.loggingService.warn(
                            `${metadataUrl} plugin not available`
                        );
                        return of({
                            id: plugin.id,
                            name: plugin.id,
                            description: 'Failed to load ' + plugin.id,
                            version: '',
                            srcURL: plugin.srcURL,
                            allowUnload: true,
                            platforms: [],
                            participantRoles: [],
                            scriptElement: null,
                            plugin: null,
                            failedToLoad: true
                        });
                    })
                )
                .subscribe((pluginMetaData: PluginMetaData) => {
                    if (pluginMetaData.failedToLoad) {
                        this.pluginMetaDataList[
                            pluginMetaData.id
                        ] = pluginMetaData;
                    } else if (plugin.id !== pluginMetaData.id) {
                        this.loggingService.warn(
                            'plugin-service: plugin id mismatch',
                            plugin,
                            pluginMetaData
                        );
                    } else if (
                        pluginMetaData.platforms &&
                        pluginMetaData.platforms.indexOf(
                            this.platformService.platform
                        ) > -1
                    ) {
                        // FIXME: this is fragile - get it working first
                        const url = metadataUrl.split('/');
                        url.pop();
                        pluginMetaData.pluginBaseURL = url.join('/');
                        this.pluginMetaDataList[
                            pluginMetaData.id
                        ] = pluginMetaData;
                        if (load) {
                            this.loadPlugin(pluginMetaData);
                        }
                    }
                })
        );
    }

    // addPluginFromURL(metadataUrl: string) {
    //     console.log('Loading metadata from ' + metadataUrl);
    //     this.httpClient.get(metadataUrl)
    //         .subscribe((pluginMetaData: PluginMetaData) => {

    //             if (pluginMetaData.platforms && pluginMetaData.platforms.indexOf(this.platformService.platform) > -1) {
    //                 this.pluginMetaDataList[pluginMetaData.id] = pluginMetaData;
    //             }
    //         });
    // }

    loadPlugin(pluginMetaData: PluginMetaData) {
        // FIXME: make checks for all required fields and also errors on loading etc
        // FIXME: pluginBaseURL fragility here too
        this.pluginHttpRequests.push(
            this.loadPluginScript(
                pluginMetaData.pluginBaseURL + '/' + pluginMetaData.srcURL
            ).subscribe(scriptElement => {
                if (!scriptElement) return;
                pluginMetaData.scriptElement = scriptElement;

                const plugin = this.storageService.pluginsProxy.find(
                    plugin => plugin.id === pluginMetaData.id
                );

                if (!plugin) {
                    this.loggingService.warn(
                        'plugin-service: plugin not found - possible id mismatch'
                    );
                    this.unLoadPlugin(pluginMetaData);
                    return;
                }
                if (!plugin.enabled) {
                    plugin.enabled = true;
                }

                // load any context menu items:
                if (pluginMetaData.menuItems) {
                    for (const item of pluginMetaData.menuItems.participants ||
                        []) {
                        this.loggingService.info(
                            'plugin-service: loading context item:',
                            item
                        );
                        this.participantContextMenuItems.push(
                            new MenuItem(
                                pluginMetaData,
                                item.label,
                                item.action,
                                this.getIconURL(item, pluginMetaData),
                                item.id
                            )
                        );
                    }

                    for (const item of pluginMetaData.menuItems.conference ||
                        []) {
                        this.loggingService.info(
                            'plugin-service: loading conference settings item:',
                            item
                        );
                        this.conferenceSettingsMenuItems.push(
                            new MenuItem(
                                pluginMetaData,
                                item.label,
                                item.action,
                                this.getIconURL(item, pluginMetaData),
                                item.id
                            )
                        );
                    }

                    for (const item of pluginMetaData.menuItems.toolbar || []) {
                        this.loggingService.info(
                            'plugin-service: loading toolbar button:',
                            item
                        );
                        this.toolbarButtons.push(
                            new MenuItem(
                                pluginMetaData,
                                item.label,
                                item.action,
                                this.getIconURL(item, pluginMetaData),
                                item.id
                            )
                        );
                    }

                    for (const item of pluginMetaData.menuItems.cmdLine || []) {
                        this.loggingService.info(
                            'plugin-service: loading cmd line entries:',
                            item
                        );
                        this.cmdLineEntries.push(
                            new MenuItem(
                                pluginMetaData,
                                item.label,
                                item.action,
                                undefined,
                                item.id
                            )
                        );
                    }
                }
            })
        );
    }

    private getIconURL(item: MenuItemJSON, pluginMetaData: PluginMetaData) {
        const mapIconToURL = (icon: string) => {
            let iconPath = `${pluginMetaData.pluginBaseURL}/${icon}`;
            if (!this.platformService.isElectron()) {
                return iconPath;
            }
            let iconContent = this.pluginResources[iconPath];
            let fragment = '';
            const fragmentArr = icon.split('#');
            if (!iconContent && fragmentArr.length === 2) {
                iconPath = `${pluginMetaData.pluginBaseURL}/${fragmentArr[0]}`;
                iconContent = this.pluginResources[iconPath];
                fragment = `#${fragmentArr[1]}`;
            }
            if (iconContent) {
                return `${URL.createObjectURL(
                    new Blob([iconContent], { type: 'image/svg+xml' })
                )}${fragment}`;
            }
            return icon;
        };

        if (item.icon) {
            return of(item.icon).pipe(map(mapIconToURL));
        } else {
            return pluginMetaData.plugin.state$.pipe(
                map(({ icon }) => mapIconToURL(icon))
            );
        }
    }

    private loadPluginScript(src: string) {
        this.loggingService.info('plugin-service: loading plugin script', src);
        if (this.platformService.isElectron() && !src.startsWith('https://')) {
            const pluginScript = this.pluginResources[src];
            if (!pluginScript) {
                this.loggingService.warn(
                    'Electron plugin script path is not in plugins dir',
                    src
                );
                return of(null);
            }

            return of(pluginScript).pipe(
                map(pluginScript => {
                    this.loggingService.info(
                        'plugin-service: script loaded',
                        src
                    );
                    const script = document.createElement('script');
                    script.text = pluginScript;
                    document.body.appendChild(script);
                    return script;
                })
            );
        }
        return this.httpClient
            .get(src, { responseType: 'text' })
            .pipe(
                catchError(error => {
                    this.loggingService.warn(
                        'plugin-service: failed to load plugin src',
                        src,
                        error
                    );
                    return of(null);
                })
            )
            .pipe(
                map(scriptText => {
                    this.loggingService.info(
                        'plugin-service: script loaded',
                        src
                    );
                    const script = document.createElement('script');
                    script.text = scriptText;
                    document.body.appendChild(script);
                    return script;
                })
            );
    }

    // removePlugin(pluginMetaData: PluginMetaData) {
    //     delete this.pluginMetaDataList[pluginMetaData.id];
    // }

    unLoadPlugin(pluginMetaData: PluginMetaData) {
        // FIXME: check for errors unloading etc
        this.loggingService.info(
            'plugin-service: unloading plugin',
            pluginMetaData
        );
        pluginMetaData.plugin.unload();
        pluginMetaData.plugin = null;

        this.participantContextMenuItems = this.participantContextMenuItems.filter(
            m => m.pluginId !== pluginMetaData.id
        );

        this.conferenceSettingsMenuItems = this.conferenceSettingsMenuItems.filter(
            m => m.pluginId !== pluginMetaData.id
        );

        this.toolbarButtons = this.toolbarButtons.filter(
            m => m.pluginId !== pluginMetaData.id
        );

        this.cmdLineEntries = this.cmdLineEntries.filter(
            m => m.pluginId !== pluginMetaData.id
        );

        // remove the plugin script
        document.body.removeChild(pluginMetaData.scriptElement);
        pluginMetaData.scriptElement = null;
        const plugin = this.storageService.pluginsProxy.find(
            plugin => plugin.id === pluginMetaData.id
        );
        if (plugin) {
            plugin.enabled = false;
        }
    }

    resetPlugins() {
        this.loggingService.info('plugin-service: resetting plugins');
        this.pluginHttpRequests.forEach(request => request.unsubscribe());
        for (const pluginMetaData of Object.values(this.pluginMetaDataList)) {
            if (pluginMetaData.plugin) {
                this.unLoadPlugin(pluginMetaData);
            }
        }

        this.pluginMetaDataList = {};
        this.pluginHttpRequests = [];
        this.participantContextMenuItems = [];
        this.conferenceSettingsMenuItems = [];
        this.toolbarButtons = [];
    }

    private addLegacyDialogAPISupport(
        dialogPromise: Promise<
            BaseDialogContainerComponent<BaseDialogContentComponent>
        >
    ) {
        dialogPromise = dialogPromise.then(dialogRef => {
            dialogRef['viewInit$'] = {
                subscribe: (fn: Function) => fn()
            };
            return dialogRef;
        });
        dialogPromise['subscribe'] = dialogPromise.then;
        return dialogPromise;
    }

    addConferencePluginApiActions() {
        this.screenState$.next('CONFERENCE');
        window.PEX.pluginAPI = {
            ...window.PEX.pluginAPI,
            spotlightOnParticipant: (uuid: string) => {
                this.conferenceFacade.spotlightParticipant(uuid, true);
            },
            spotlightOffParticipant: (uuid: string) => {
                this.conferenceFacade.spotlightParticipant(uuid, false);
            },
            dialOut: (
                alias: string,
                protocol: string,
                role: string,
                cb: (msg: object) => void,
                params: string | object
            ) => {
                this.conferenceFacade.dialOut(
                    alias,
                    protocol,
                    role,
                    cb,
                    params
                );
            },
            sendRequest: (
                request: string,
                params: object,
                cb: (msg: object) => void,
                reqMethod: string,
                retries: number
            ) => {
                this.conferenceFacade.sendRequest(
                    request,
                    params,
                    cb,
                    reqMethod,
                    retries
                );
            },
            disconnectParticipant: (uuid: string) => {
                this.conferenceFacade.disconnectParticipant(uuid);
            },
            changeConferenceLayout: (params: object) => {
                this.conferenceFacade.changeConferenceLayout(params);
            },
            transformConferenceLayout: (params: object) => {
                this.conferenceFacade.transformConferenceLayout(params);
            },
            sendChatMessage: (message: string) => {
                if (message) {
                    this.conferenceFacade.sendChatMessage(message);
                }
            }
        };
    }

    removeConferencePluginApiActions() {
        window.PEX.pluginAPI = Object.assign({}, window.PEX.pluginAPI, {
            spotlightOnParticipant: (_uuid: string) => {},
            spotlightOffParticipant: (_uuid: string) => {},
            dialOut: (
                _alias: string,
                _protocol: string,
                _role: string,
                _cb: Function,
                _params: string | object
            ) => {},
            disconnectParticipant: (_uuid: string) => {},
            changeConferenceLayout: (_params: object) => {},
            transformConferenceLayout: (_params: object) => {},
            sendChatMessage: (_params: string) => {}
        });
    }

    getPluginMenuItem(pluginId: string, menuItemId: string) {
        return [
            ...this.participantContextMenuItems,
            ...this.conferenceSettingsMenuItems,
            ...this.toolbarButtons,
            ...this.cmdLineEntries
        ].find(item => item.pluginId === pluginId && item.id === menuItemId);
    }
}
