import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IncomingCallDetails } from '../services/registration/registration.model';
import { REGISTRATION_ACTIONS } from './registration.actions';
import {
    areFormFieldsDisabled,
    getCloseSourceFn,
    getErrorLabel,
    getRegistrationDetails,
    getRegistrationState,
    getRegistrationType,
    isRetrying,
    RegistrationState
} from './registration.reducer';

@Injectable({
    providedIn: 'root'
})
export class RegistrationFacade {
    state$: Observable<RegistrationState>;
    state: RegistrationState;

    details$: Observable<{
        host: string;
        alias: string;
        username: string;
        password: string;
    }>;
    areFormFieldsDisabled$: Observable<boolean>;

    constructor(private store: Store<RegistrationState>) {
        this.state$ = this.store.select(getRegistrationState);
        this.state$.subscribe(state => (this.state = state));
        this.details$ = this.store.select(getRegistrationDetails);
        this.areFormFieldsDisabled$ = this.store.select(areFormFieldsDisabled);
    }

    setAlias(alias: string) {
        this.store.dispatch(REGISTRATION_ACTIONS.setAlias({ alias }));
    }

    setHost(host: string) {
        this.store.dispatch(REGISTRATION_ACTIONS.setHost({ host }));
    }

    setPassword(password: string) {
        this.store.dispatch(REGISTRATION_ACTIONS.setPassword({ password }));
    }

    setUsername(username: string) {
        this.store.dispatch(REGISTRATION_ACTIONS.setUsername({ username }));
    }

    setADFSFederationServiceName(adfsFederationServiceName: string) {
        this.store.dispatch(
            REGISTRATION_ACTIONS.setADFSFederationServiceName({
                adfsFederationServiceName
            })
        );
    }
    setADFSResource(adfsResource: string) {
        this.store.dispatch(
            REGISTRATION_ACTIONS.setADFSResource({ adfsResource })
        );
    }

    setADFSClientID(adfsClientID: string) {
        this.store.dispatch(
            REGISTRATION_ACTIONS.setADFSClientID({ adfsClientID })
        );
    }

    setADFSRedirectURI(adfsRedirectURI: string) {
        this.store.dispatch(
            REGISTRATION_ACTIONS.setADFSRedirectURI({ adfsRedirectURI })
        );
    }

    setADFSToken(adfsToken: string) {
        this.store.dispatch(REGISTRATION_ACTIONS.setADFSToken({ adfsToken }));
    }

    setADFSRefreshToken(adfsRefreshToken: string) {
        this.store.dispatch(
            REGISTRATION_ACTIONS.setADFSRefreshToken({ adfsRefreshToken })
        );
    }

    setADFSStateToken(adfsStateToken: string) {
        this.store.dispatch(
            REGISTRATION_ACTIONS.setADFSStateToken({ adfsStateToken })
        );
    }

    setADFSTokenExpiry(adfsTokenExpiry: number) {
        this.store.dispatch(
            REGISTRATION_ACTIONS.setADFSTokenExpiry({ adfsTokenExpiry })
        );
    }

    register() {
        this.store.dispatch(REGISTRATION_ACTIONS.register());
    }

    unregister() {
        this.store.dispatch(REGISTRATION_ACTIONS.unregisterStart());
    }

    cancel() {
        this.store.dispatch(REGISTRATION_ACTIONS.cancel());
    }

    clearDetails() {
        this.store.dispatch(REGISTRATION_ACTIONS.clearDetails());
    }

    adfsTokenRequest(code: string) {
        this.store.dispatch(REGISTRATION_ACTIONS.adfsTokenRequest({ code }));
    }

    incomingCall(call: IncomingCallDetails) {
        this.store.dispatch(REGISTRATION_ACTIONS.incomingCall({ call }));
    }

    incomingCallCancelled(token: string) {
        const call = this.state.incomingCalls[token];
        this.store.dispatch(
            REGISTRATION_ACTIONS.incomingCallCancelled({ call })
        );
    }

    // incomingCallDialogOpened(id: string) {
    //     this.store.dispatch(REGISTRATION_ACTIONS.incomingCallDialogOpened({id}));
    // }

    getRegistrationType() {
        return this.store.select(getRegistrationType);
    }

    isRetrying() {
        return this.store.select(isRetrying);
    }

    getCloseSourceFn() {
        return this.store.select(getCloseSourceFn);
    }

    getErrorLabel() {
        return this.store.select(getErrorLabel);
    }
}
