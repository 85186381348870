import { Injectable, Injector } from '@angular/core';

import { PlatformService } from '../platform.service';

@Injectable({
    providedIn: 'root'
})
export class ClipboardService {
    select = require('select');

    constructor(private injector: Injector) {}

    copy(text: string) {
        if (this.injector.get(PlatformService).platform === 'ios') {
            cordova.plugins.clipboard.copy(text);
        } else {
            const selBox = document.createElement('textarea');

            selBox.style.position = 'fixed';
            selBox.style.left = '0';
            selBox.style.top = '0';
            selBox.style.opacity = '0';
            selBox.value = text;
            selBox.id = 'selBox';

            document.body.appendChild(selBox);

            this.select(selBox);
            document.execCommand('copy');
            document.body.removeChild(selBox);
        }
    }
}
