<div
    class="card-dialog-container"
    [class.active]="isOpen"
    (transitionend)="transitionEnded()"
>
    <ng-template #content></ng-template>
    <div class="card-dialog__close" (click)="this.close$.next()" pexTabbable>
        <svg><use xlink:href="icons.svg#x" /></svg>
        <span>{{ 'DISMISS' | translate }} </span>
    </div>
</div>
