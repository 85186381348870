import { PluginSettingsModel, UserSettingsModel } from './settings.model';
import { PexStorage } from './storage';

export class BrandingStorage extends PexStorage<BrandingModel> {
    readonly storageString = 'pexBranding';

    constructor(values: BrandingModel) {
        super(new BrandingModel(), values);
    }
}

export interface BrandingManifest {
    brandingID?: string;
    isSettingsBranded?: boolean;
    isWatermarkBranded?: boolean;
    isStylesBranded?: boolean;
    isCustomBackgroundBranded?: boolean;
}

export class BrandingModel {
    brandingURL = 'custom_configuration';
    cachedStyle: string;
    cachedImages: { [id: string]: string };
    cachedLanguages: { [id: string]: string } = {};
    cachedPluginResources: { [path: string]: string } = {};
    cachedManifest: BrandingManifest | undefined = undefined;
    defaultUserSettings: UserSettingsModel;
    plugins: PluginSettingsModel[];
}
