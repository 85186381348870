import { Injectable } from '@angular/core';

import Fuse from 'fuse.js';

import { Conference, ConnectionDetails } from '../conference/conference.model';
import { StorageService } from './storage';

@Injectable({
    providedIn: 'root'
})
export class HistoryDatabaseService {
    get recents() {
        return this.storageService.historyProxy.recents;
    }

    get recentsLength() {
        return Object.keys(this.recents).length;
    }

    constructor(private storageService: StorageService) {}

    addConference(connectionDetails: ConnectionDetails) {
        this.storageService.historyProxy.recents = Object.assign(
            {},
            this.recents
        );
        this.recents[connectionDetails.alias] = {
            connectionDetails,
            timestamp: Date.now()
        };
        this.storageService.historyProxy.recents = Object.assign(
            {},
            this.recents
        );
    }

    // FIXME: the numer could be tuned later
    getRecentConferences(
        searchTerm: string,
        options: Fuse.IFuseOptions<Conference> = {
            keys: ['connectionDetails.alias'],
            threshold: 0.4,
            distance: 500
        }
    ) {
        const recents = Object.values(this.recents);

        const results =
            searchTerm && recents.length > 0
                ? new Fuse(recents, options)
                      .search(searchTerm.trim())
                      .map(result => result.item)
                : recents;
        return results.sort(
            (conference1, conference2) =>
                conference2.timestamp - conference1.timestamp
        );
    }

    clearRecents() {
        this.storageService.historyProxy.recents = null;
    }

    // getLastConferences(limit: number) {
    //     return Observable.fromPromise(
    //         this.recents
    //             .toArray())
    //         // for locate some problem, could remove later
    //         .do((recents: any[]) => console.log('getLastConferences', recents))
    //         .map((recents: any[]) => recents.filter(recent => recent).map(recent => recent.conferenceId))
    //         .switchMap(conferenceIds =>
    //             this.conferences
    //                 .orderBy('timestamp')
    //                 .filter((conference) => conferenceIds.indexOf(conference.id) > -1)
    //                 .reverse()
    //                 .limit(limit)
    //                 .toArray()
    //         );
    // }
}
