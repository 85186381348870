import {
    ApplicationRef,
    ChangeDetectorRef,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewContainerRef
} from '@angular/core';

import { TooltipComponent } from './tooltip.component';

import { PlatformService } from '../../platform.service';

interface TooltipInfo {
    message: string;
    position: string;
    opaque?: string;
    fixed?: string;
}

@Directive({
    selector: '[pexTooltip]'
})
export class TooltipDirective implements OnChanges, OnDestroy {
    private message: string;
    private position: string;
    private opaque: string;
    private factory: ComponentFactory<TooltipComponent>;
    private componentRef: ComponentRef<TooltipComponent>;
    private fixed: string;

    @Input()
    set pexTooltip(value: TooltipInfo) {
        this.message = value.message;
        this.position = value.position;
        this.opaque = value.opaque ?? 'false';
        this.fixed = value.fixed ?? 'false';
    }

    constructor(
        private viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private platformService: PlatformService,
        private elementRef: ElementRef,
        private applicationRef: ApplicationRef,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        if (!this.platformService.isMobile()) {
            this.elementRef?.nativeElement?.addEventListener('mouseenter', () =>
                this.show()
            );
            this.elementRef?.nativeElement?.addEventListener('mouseleave', () =>
                this.hide()
            );
            this.elementRef?.nativeElement?.addEventListener('click', () =>
                this.hide()
            );
        }
        this.elementRef?.nativeElement?.addEventListener('focus', () => {
            this.show();
        });
        this.elementRef?.nativeElement?.addEventListener('blur', () => {
            this.hide();
        });
    }

    ngOnChanges({ pexTooltip }: SimpleChanges) {
        if (
            pexTooltip &&
            pexTooltip.previousValue &&
            pexTooltip.currentValue &&
            pexTooltip.currentValue.message !== pexTooltip.previousValue.message
        ) {
            if (this.componentRef) {
                this.componentRef.instance.message =
                    pexTooltip.currentValue.message;
            }
        }
    }

    ngOnDestroy() {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    }

    show() {
        if (this.componentRef) return;
        this.viewContainerRef.clear();
        this.factory = this.componentFactoryResolver.resolveComponentFactory(
            TooltipComponent
        );
        const rootComponent = this.applicationRef.components[0].instance;
        const viewContainerRef =
            this.fixed === 'true' && rootComponent.viewContainerRef
                ? rootComponent.viewContainerRef
                : this.viewContainerRef;
        this.componentRef = viewContainerRef.createComponent(this.factory);
        this.componentRef.instance.message = this.message;
        if (this.position === 'left') {
            this.componentRef.instance.left = true;
        } else if (this.position === 'right') {
            this.componentRef.instance.right = true;
        } else if (this.position === 'top') {
            this.componentRef.instance.top = true;
        } else if (this.position === 'topHigher') {
            this.componentRef.instance.topHigher = true;
        } else {
            this.componentRef.instance.bottom = true;
        }

        this.componentRef.instance.opaque = this.opaque === 'true';

        if (this.fixed === 'true') {
            const {
                top,
                left,
                width,
                height
            } = this.elementRef.nativeElement.getBoundingClientRect();
            const offset = 7;
            this.componentRef.instance.fixed = true;

            if (this.position === 'bottom') {
                this.componentRef.instance.topPX = top + height + offset;
                this.componentRef.instance.leftPX = left + width / 2;
            } else if (this.position === 'left') {
                this.componentRef.instance.topPX = top + height / 2;
                this.componentRef.instance.rightPX =
                    window.innerWidth - left + offset + '';
            } else if (this.position === 'top') {
                this.componentRef.instance.bottomPX =
                    window.innerHeight - top + offset + '';
                this.componentRef.instance.leftPX = left + width / 2;
            } else if (this.position === 'right') {
                this.componentRef.instance.topPX = top + height / 2;
                this.componentRef.instance.leftPX = left + width + offset + '';
            }

            this.componentRef.changeDetectorRef.detectChanges();
        }
    }

    hide() {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
        this.componentRef = null;

        if (this.fixed === 'true') {
            this.changeDetectorRef.detectChanges();
        }
    }
}
