import { Injectable } from '@angular/core';

import { StorageService } from './storage';

export interface Space {
    name: string;
    alias: string;
    showPresentation: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class MySpaceDatabaseService {
    spaces: Space[] = [];

    isAddSpacesFormOpen = false;
    isSpaceEditing = false;
    spaceEditAlias: string;

    isSpacesOpen = false;
    activeSpace = '';
    defaultDevice = 'This Device';

    constructor(private storageService: StorageService) {
        this.storageService.spacesStorage.spaces$.subscribe(spacesData => {
            this.spaces = spacesData.spaces;
            this.activeSpace = spacesData.activeSpace;
        });
    }

    get activeSpaceName() {
        return this.spaces.reduce((acc, curr) => {
            if (curr.alias === this.activeSpace) {
                acc = curr.name;
            }
            return acc;
        }, '');
    }

    get activeSpaceShowPresentation() {
        return this.spaces.reduce((acc, curr) => {
            if (curr.alias === this.activeSpace) {
                acc = curr.showPresentation;
            }
            return acc;
        }, Boolean(true));
    }

    addSpace(space: Space) {
        this.spaces = [space, ...this.spaces];
        this.storageService.spacesStorage.setItem('spaces', this.spaces);
    }

    addBefore(space: Space, beforeAlias: string) {
        const index = this.spaces.findIndex(a => a.alias === beforeAlias);
        this.spaces.splice(index, 0, space);
        this.storageService.spacesStorage.setItem('spaces', this.spaces);
    }

    addAfter(space: Space, afterAlias: string) {
        const index = this.spaces.findIndex(a => a.alias === afterAlias);
        this.spaces.splice(index + 1, 0, space);
        this.storageService.spacesStorage.setItem('spaces', this.spaces);
    }

    removeSpace(space: Space) {
        this.spaces = this.spaces.filter(a => a.alias !== space.alias);
        this.storageService.spacesStorage.setItem('spaces', this.spaces);
        this.setActiveSpace(null);
    }

    editSpace(alias: string, newValue: Space) {
        this.spaces[this.spaces.findIndex(a => a.alias === alias)] = newValue;
        this.storageService.spacesStorage.setItem('spaces', this.spaces);
    }

    clearSpaces() {
        this.spaces = [];
        this.storageService.spacesStorage.deleteItem('spaces');
    }

    setActiveSpace(spaceAlias: string) {
        this.activeSpace = spaceAlias;
        this.storageService.spacesStorage.setItem('activeSpace', spaceAlias);
    }

    getSpace(spaceAlias: string) {
        return this.spaces.find(s => s.alias === spaceAlias);
    }
}
