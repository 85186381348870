import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { MediaDevicesListComponent } from '../media-device/media-devices-list/media-devices-list.component';
import { MediaDevicesComponent } from '../media-device/media-devices/media-devices.component';
import { HotkeyService } from '../services/hotkey.service';
import { AutoFocusDirective } from './auto-focus.directive';
import { ContextMenuModule } from './context-menu';
import { PexDatePipe } from './date.pipe';
import { DraggableDirective } from './draggable.directive';
import { EmbedDirective } from './embed.directive';
import { FileDropDirective } from './file-drop.directive';
import { GeneralFacade } from './general/general.facade';
import { IdleDirective } from './idle.directive';
import { LinkifyPipe } from './linkify.pipe';
import { NavigateBtnComponent } from './navigate-btn/navigate-btn.component';
import { OrientationDirective } from './orientation.directive';
import { RegistrationProvisionDialogComponent } from './registration-provision-dialog/registration-provision-dialog.component';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { SanitizeStylePipe } from './sanitize-style.pipe';
import { SanitizeUrlPipe } from './sanitize-url.pipe';
import { SelfviewComponent } from './selfview';
import { BackgroundEffectIndicatorComponent } from './selfview/background-effect-indicator/background-effect-indicator.component';
import { TalkingWhenMutedIndicatorComponent } from './selfview/talking-when-muted/talking-when-muted-indicator/talking-when-muted-indicator.component';
import { TalkingWhenMutedComponent } from './selfview/talking-when-muted/talking-when-muted.component';
import { SinkIdDirective } from './sinkid.directive';
import { SplitPipe } from './split.pipe';
import { TabbableDirective } from './tabbable.directive';
import { TabLockDirective } from './tablock.directive';
import { ToastOverlayComponent } from './toast/toast-overlay.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { TooltipDirective } from './tooltip/tooltip.directive';
import { TruncateDisplayNamePipe } from './truncate-display-name.pipe';
import { TruncatePipe } from './truncate.pipe';
import { WillChangeSourceDirective } from './will-change/will-change-source.directive';
import { WillChangeTargetDirective } from './will-change/will-change-target.directive';
import { WillChangeService } from './will-change/will-change.service';

@NgModule({
    imports: [CommonModule, ContextMenuModule, TranslateModule],
    providers: [
        TruncatePipe,
        TruncateDisplayNamePipe,
        SanitizeHtmlPipe,
        SanitizeStylePipe,
        SanitizeUrlPipe,
        SplitPipe,
        LinkifyPipe,
        HotkeyService,
        GeneralFacade,
        WillChangeService
    ],
    declarations: [
        SinkIdDirective,
        IdleDirective,
        OrientationDirective,
        SelfviewComponent,
        BackgroundEffectIndicatorComponent,
        TalkingWhenMutedComponent,
        TalkingWhenMutedIndicatorComponent,
        ToastOverlayComponent,

        TooltipDirective,

        TooltipComponent,

        FileDropDirective,
        AutoFocusDirective,

        TruncatePipe,
        TruncateDisplayNamePipe,
        SanitizeHtmlPipe,
        SanitizeStylePipe,
        SanitizeUrlPipe,
        SplitPipe,
        LinkifyPipe,

        EmbedDirective,
        DraggableDirective,
        PexDatePipe,
        NavigateBtnComponent,

        MediaDevicesComponent,
        MediaDevicesListComponent,
        WillChangeSourceDirective,
        WillChangeTargetDirective,

        RegistrationProvisionDialogComponent,
        TabbableDirective,
        TabLockDirective
    ],
    exports: [
        ContextMenuModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,

        SinkIdDirective,
        IdleDirective,
        OrientationDirective,
        SelfviewComponent,
        BackgroundEffectIndicatorComponent,
        TalkingWhenMutedComponent,
        TalkingWhenMutedIndicatorComponent,
        ToastOverlayComponent,

        TooltipDirective,
        TooltipComponent,
        FileDropDirective,
        AutoFocusDirective,

        TruncatePipe,
        TruncateDisplayNamePipe,
        SanitizeHtmlPipe,
        SanitizeStylePipe,
        SanitizeUrlPipe,
        SplitPipe,
        LinkifyPipe,

        EmbedDirective,
        DraggableDirective,
        PexDatePipe,
        NavigateBtnComponent,

        MediaDevicesComponent,
        MediaDevicesListComponent,
        WillChangeSourceDirective,
        WillChangeTargetDirective,

        RegistrationProvisionDialogComponent,
        TabbableDirective,
        TabLockDirective
    ]
})
export class SharedModule {}
