import { ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent {
    @Input() top: boolean;
    @Input() topHigher: boolean;
    @Input() left: boolean;
    @Input() right: boolean;
    @Input() bottom: boolean;
    @Input() opaque: boolean;

    @Input() fixed: boolean;
    @Input() topPX: string;
    @Input() bottomPX: string;
    @Input() leftPX: string;
    @Input() rightPX: string;

    private _message: string;
    @Input()
    set message(_message: string) {
        if (this._message !== _message) {
            this._message = _message;
            this.changeDetectorRef.detectChanges();
        }
    }

    get message() {
        return this._message;
    }

    constructor(private changeDetectorRef: ChangeDetectorRef) {}
}
