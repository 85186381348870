import { Injectable } from '@angular/core';

export interface CalendarMeeting {
    uri: string;
    startDate: number;
    pin?: string;
    title?: string;
    description?: string;
}

@Injectable()
export abstract class CalendarService {
    upcomingMeetings: CalendarMeeting[] = [];

    abstract init(): void;

    protected getDateRange() {
        const startTime = new Date();
        startTime.setHours(startTime.getHours() - 1);
        const endTime = new Date();
        const range = 6;
        endTime.setHours(endTime.getHours() + range);
        return [startTime, endTime];
    }
}
