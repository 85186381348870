import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import { State } from './participants.reducer';
import {
    getParticipants,
    getRoster,
    getRosterParticipants,
    getStageParticipants
} from './participants.selectors';

@Injectable({ providedIn: 'root' })
export class ParticipantsFacade {
    participants$ = this.store.select(getParticipants);
    roster$ = this.store.select(getRoster);
    rosterParticipants$ = this.store.select(getRosterParticipants);
    alphabeticalRosterParticipants$ = this.rosterParticipants$.pipe(
        tap(participants => {
            return participants.sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });
        })
    );
    stageParticipants$ = this.store.select(getStageParticipants);

    constructor(private store: Store<State>) {}
}
