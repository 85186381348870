import { Injectable } from '@angular/core';

import { StorageService } from './storage';

//tslint:disable-next-line
const Keen = require('../../vendor/keen-tracking-1.4.0.min.js');
const keenClient = new Keen({
    projectId: '59e46381c9e77c00011564e3',
    writeKey:
        '6C55BB954FDB1B4443F066D6877FAB12BD737FE35627440B1A3613A7E888BBB99EF932B23098193C6C178E57C14E4017CAF044117AD6B769F34544C680D5400D21A55B315705577A6E85314AB21568710F72C512741D78273CADFB84EB008A59'
});

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    public managerEnabled: boolean;

    constructor(private storageService: StorageService) {
        this.managerEnabled = false;
    }

    private get analyticsEnabled() {
        return (
            this.storageService.userSettingsProxy.sendStats &&
            this.managerEnabled
        );
    }

    recordEvent(collection: string, metadata?: {}) {
        if (this.analyticsEnabled) {
            const startEvent = {
                user_agent: '${keen.user_agent}',
                usedWidth: screen.availWidth,
                usedHeight: screen.availHeight,
                width: screen.width,
                height: screen.height,
                colorDepth: screen.colorDepth,
                pixelDepth: screen.pixelDepth,
                language: navigator.language,
                keen: {
                    timestamp: new Date().toISOString(),
                    addons: [
                        {
                            name: 'keen:ua_parser',
                            input: {
                                ua_string: 'user_agent'
                            },
                            output: 'parsed_vendor'
                        }
                    ]
                }
            };
            if (metadata) {
                const fullEvent = { ...startEvent, metadata };
                keenClient.recordEvent(collection, fullEvent);
            } else {
                keenClient.recordEvent(collection, startEvent);
                console.debug('keenClient', keenClient);
            }
        }
    }
}
