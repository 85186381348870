import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
    transform(value: string, maxLength?: number, symbol?: string): string {
        if (maxLength === -1) {
            return value;
        } else if (!value) {
            return '';
        } else {
            const defaultMaxLength = 20;
            const limit = maxLength ? maxLength : defaultMaxLength;
            const trail = symbol ? symbol : '...';

            return value.length > limit + trail.length
                ? value.substring(0, limit) + trail
                : value;
        }
    }
}
