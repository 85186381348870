import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    ViewChild
} from '@angular/core';

@Component({
    selector: 'pex-selfview',
    templateUrl: './selfview.component.html',
    styleUrls: ['./selfview.component.scss']
})
export class SelfviewComponent implements OnDestroy {
    userMediaStream: MediaStream;
    pexBranding = window['pexBranding'];
    shouldShowVideoElem = false;

    @ViewChild('videoElement', { static: false }) videoElement: ElementRef;

    @Input() cameraMuted: boolean;
    @Input() microphoneMuted: boolean;
    @Input() mirror: boolean;
    @Input() mobilePortrait: boolean;
    @Input() shouldShowIndicatorWhenMuted = false;
    @Input() inMeeting = false;
    @Input() shouldShowMicMutedIcon = true;

    @Input()
    set localMediaStream(value: MediaStream) {
        if (value) {
            this.userMediaStream = value;
            if (this.userMediaStream.getVideoTracks().length > 0) {
                this.shouldShowVideoElem = true;
            } else {
                this.shouldShowVideoElem = false;
            }
        } else {
            this.userMediaStream = null;
            this.shouldShowVideoElem = false;
        }
        if (this.videoElement)
            setTimeout(() => {
                this.play();
            }, __CONSTANTS__.VIDEOPLAY_TIMEOUT);
    }

    ngOnDestroy() {
        this.userMediaStream = null;
        if (this.videoElement && this.videoElement.nativeElement) {
            this.videoElement.nativeElement.srcObject = null;
        }
    }

    async pause() {
        if (this.videoElement?.nativeElement?.srcObject) {
            try {
                await this.videoElement.nativeElement.pause();
            } catch (e) {}
        }
    }

    async play() {
        if (this.videoElement?.nativeElement?.srcObject) {
            try {
                this.videoElement.nativeElement.muted = true;
                await this.videoElement.nativeElement.play();
            } catch (e) {}
        }
    }
}
