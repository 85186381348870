import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import type { AndroidPlatformService } from '../android/android.platform.service';
import type { ElectronPlatformService } from '../electron/renderer/electron.platform.service';
import type { IOSPlatformService } from '../ios/ios.platform.service';
import type { WebPlatformService } from '../web/web.platform.service';

import { PexConstants, PexConstantsMobile } from './shared/constants';

export const HAS_WEBGL = (() => {
    const WEBGL_ATTRIBUTES = {
        alpha: false,
        antialias: false,
        premultipliedAlpha: false,
        preserveDrawingBuffer: false,
        depth: false,
        stencil: false,
        failIfMajorPerformanceCaveat: true
    };
    try {
        const canvas = document.createElement('canvas');
        return !!(
            window.WebGLRenderingContext &&
            (canvas.getContext('webgl', WEBGL_ATTRIBUTES) ||
                canvas.getContext('experimental-webgl', WEBGL_ATTRIBUTES))
        );
    } catch (e) {
        return false;
    }
})();

@Injectable()
export abstract class PlatformService {
    abstract readonly platform: string;
    public appVersion = APP_VERSION;
    public preBaked = PREBAKED;
    public mcuVersion = {};
    public useWorker: boolean;
    public orientationChange$ = new BehaviorSubject<string>('');

    abstract get constants(): PexConstants;

    isElectron(): this is ElectronPlatformService {
        return this.platform === 'electron';
    }

    isWinElectron() {
        return this.isElectron() && this.hostPlatform === 'Win32';
    }

    isAndroid(): this is AndroidPlatformService {
        return this.platform === 'android';
    }

    isIOS(): this is IOSPlatformService {
        return this.platform === 'ios';
    }

    isWeb(): this is WebPlatformService {
        return this.platform === 'web';
    }

    isMobileApp(): this is MobilePlatformService {
        return ['ios', 'android'].indexOf(this.platform) > -1 || false;
    }

    isMobile() {
        return this.isMobileApp() || this.isMobileBrowser() || false;
    }

    isMobileBrowser(): this is WebPlatformService {
        return (this.isWeb() && this.mobileBrowser) || false;
    }

    isSafariIOS() {
        return this.isMobileBrowser() && this.browserType === 'safari';
    }

    isCitrixApp() {
        return this.isElectron() && this.isCitrix;
    }
}

@Injectable()
export abstract class MobilePlatformService extends PlatformService {
    get constants() {
        return __CONSTANTS__ as PexConstantsMobile;
    }
}
