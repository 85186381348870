import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'split'
})
export class SplitPipe implements PipeTransform {
    transform(input: string, sep: string, idx: number): string {
        return input.split(sep)[idx];
    }
}
