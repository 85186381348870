import { Injectable } from '@angular/core';

export interface UserMessage {
    message: string;
    code: string;
}

@Injectable({
    providedIn: 'root'
})
export class UserMessageService {
    private errorMessages: { [message: string]: UserMessage } = {
        'Call Failed: Invalid role': {
            message: 'ERROR_MESSAGES.PEX100',
            code: '#pex100'
        },
        'Call Failed: Invalid PIN': {
            message: 'ERROR_MESSAGES.PEX101',
            code: '#pex101'
        },
        'Call Failed: Out of proxying resource': {
            message: 'ERROR_MESSAGES.PEX109',
            code: '#pex109'
        },
        'Call Failed: System in maintenance mode': {
            message: 'ERROR_MESSAGES.PEX110',
            code: '#pex110'
        },
        'Call Failed: 502 Bad Gateway': {
            message: 'ERROR_MESSAGES.PEX111',
            code: '#pex111'
        },
        'Call Failed: 503 Service Unavailable': {
            message: 'ERROR_MESSAGES.PEX112',
            code: '#pex112'
        },
        'Call Failed: Invalid token': {
            message: 'ERROR_MESSAGES.PEX113',
            code: '#pex113'
        },
        'Call Failed: Out of resource': {
            message: 'ERROR_MESSAGES.PEX114',
            code: '#pex114'
        },
        'Call Failed: Out of transcoding resource': {
            message: 'ERROR_MESSAGES.PEX114',
            code: '#pex114'
        },
        'transfer failed': {
            message: 'ERROR_MESSAGES.PEX115',
            code: '#pex115'
        },
        'Call Failed: ': {
            message: 'ERROR_MESSAGES.PEX117',
            code: '#pex117'
        },
        'Could not join localhost:8080': {
            message: 'ERROR_MESSAGES.PEX118',
            code: '#pex118'
        },
        'Call Failed: Failed to forward request': {
            message: 'ERROR_MESSAGES.PEX119',
            code: '#pex119'
        },
        'Conference host ended the conference with a DTMF command': {
            message: 'ERROR_MESSAGES.PEX120',
            code: '#pex120'
        },
        'API initiated conference termination': {
            message: 'ERROR_MESSAGES.PEX121',
            code: '#pex121'
        },
        'API initiated participant disconnect': {
            message: 'ERROR_MESSAGES.PEX122',
            code: '#pex122'
        },
        'Conference terminated by an administrator': {
            message: 'ERROR_MESSAGES.PEX122',
            code: '#pex122'
        },
        'Disconnected by an administrator': {
            message: 'ERROR_MESSAGES.PEX123',
            code: '#pex123'
        },
        'Disconnected by another participant': {
            message: 'ERROR_MESSAGES.PEX124',
            code: '#pex124'
        },
        'Conference terminated by another participant': {
            message: 'ERROR_MESSAGES.PEX125',
            code: '#pex125'
        },
        'Timeout waiting for conference host to join or permit access to locked conference': {
            message: 'ERROR_MESSAGES.PEX126',
            code: '#pex126'
        },
        'Call Failed: Disabled': {
            message: 'ERROR_MESSAGES.PEX127',
            code: '#pex127'
        },
        'Call Failed: Failed to establish media to server. Ensure required firewall ports are permitted.': {
            message: 'ERROR_MESSAGES.PEX128',
            code: '#pex128'
        },
        'Call Failed: Failed to establish media. Ensure required firewall ports are permitted.': {
            message: 'ERROR_MESSAGES.PEX128',
            code: '#pex128'
        },
        'Signaling node disconnected': {
            message: 'ERROR_MESSAGES.PEX129',
            code: '#pex129'
        },
        'Media process disconnected': {
            message: 'ERROR_MESSAGES.PEX130',
            code: '#pex130'
        },
        'Call Failed: Invalid call': {
            message: 'ERROR_MESSAGES.PEX130',
            code: '#pex130'
        },
        'Media node disconnected': {
            message: 'ERROR_MESSAGES.PEX131',
            code: '#pex131'
        },
        'Proxied participant disconnected': {
            message: 'ERROR_MESSAGES.PEX132',
            code: '#pex132'
        },
        'No participants can keep conference alive': {
            message: 'ERROR_MESSAGES.PEX140',
            code: '#pex140'
        },
        'All conference hosts departed hosted conference': {
            message: 'ERROR_MESSAGES.PEX141',
            code: '#pex141'
        },
        'Last remaining participant removed from conference after timeout': {
            message: 'ERROR_MESSAGES.PEX142',
            code: '#pex142'
        },
        'Test call finished': {
            message: 'ERROR_MESSAGES.PEX143',
            code: '#pex143'
        },
        'Call rejected': {
            message: 'ERROR_MESSAGES.PEX150',
            code: '#pex150'
        },
        'Call disconnected': {
            message: 'ERROR_MESSAGES.PEX151',
            code: '#pex151'
        },
        'Gateway dial out failed': {
            message: 'ERROR_MESSAGES.PEX152',
            code: '#pex152'
        },
        'invalid gateway routing rule transform': {
            message: 'ERROR_MESSAGES.PEX153',
            code: '#pex153'
        },
        'Call Failed: Neither conference nor gateway found': {
            message: 'ERROR_MESSAGES.PEX154',
            code: '#pex154'
        },
        'Could not join ': {
            message: 'ERROR_MESSAGES.PEX155',
            code: '#pex155'
        },
        'Call failed: No direct route between Edge and Transcoding': {
            message: 'ERROR_MESSAGES.PEX156',
            code: '#pex156'
        },
        'Call Failed: 404 Not Found': {
            message: 'ERROR_MESSAGES.PEX157',
            code: '#pex157'
        },
        'Failed to gather IP addresses.': {
            message: 'ERROR_MESSAGES.PEX170',
            code: '#pex170'
        },
        'Call Failed: Error: Could not get access to camera/microphone': {
            message: 'ERROR_MESSAGES.PEX171',
            code: '#pex171'
        },
        'Call Failed: Error: Could not get access to camera/microphone.': {
            message: 'ERROR_MESSAGES.PEX171',
            code: '#pex171'
        },
        'Presentation ended': {
            message: 'ERROR_MESSAGES.PEX180',
            code: '#pex180'
        },
        'Presentation stream remotely disconnected': {
            message: 'ERROR_MESSAGES.PEX181',
            code: '#pex181'
        },
        'Presentation stream unavailable': {
            message: 'ERROR_MESSAGES.PEX182',
            code: '#pex182'
        },
        'Screenshare cancelled': {
            message: 'ERROR_MESSAGES.PEX183',
            code: '#pex183'
        },
        'Screenshare error': {
            message: 'ERROR_MESSAGES.PEX184',
            code: '#pex184'
        },
        'Screenshare remotely disconnected': {
            message: 'ERROR_MESSAGES.PEX185',
            code: '#pex185'
        },
        'Error connecting to conference': {
            message: 'ERROR_MESSAGES.PEX190',
            code: '#pex190'
        },
        'Call Failed: Error connecting to conference': {
            message: 'ERROR_MESSAGES.PEX190',
            code: '#pex190'
        },
        'Call Failed: Resource unavailable': {
            message: 'ERROR_MESSAGES.PEX191',
            code: '#pex191'
        },
        'Participant exceeded PIN entry retries': {
            message: 'ERROR_MESSAGES.PEX192',
            code: '#pex192'
        },
        'Invalid license': {
            message: 'ERROR_MESSAGES.PEX193',
            code: '#pex193'
        },
        'Call Failed: Participant limit reached': {
            message: 'ERROR_MESSAGES.PEX194',
            code: '#pex194'
        },
        'Insufficient licenses': {
            message: 'ERROR_MESSAGES.PEX195',
            code: '#pex195'
        },
        'Ice failure': {
            message: 'ERROR_MESSAGES.PEX196',
            code: '#pex196'
        },
        'Telehealth Call failure': {
            message: 'ERROR_MESSAGES.PEX197',
            code: '#pex197'
        },
        'Out of resource': {
            message: 'ERROR_MESSAGES.PEX114',
            code: '#pex114'
        },
        'Resource unavailable': {
            message: 'ERROR_MESSAGES.PEX114',
            code: '#pex114'
        },
        'Timer expired awaiting token refresh': {
            message: 'ERROR_MESSAGES.PEX113',
            code: '#pex113'
        },
        'Call Failed: SSO Authentication Failed': {
            message: 'ERROR_MESSAGES.PEX200',
            code: '#pex200'
        },
        'Call Failed: SSO enabled but no Identity Providers configured': {
            message: 'ERROR_MESSAGES.PEX201',
            code: '#pex201'
        },
        'Unable to open window for SSO authentication. This may have been prevented by a pop-up blocker': {
            message: 'ERROR_MESSAGES.PEX202',
            code: '#pex202'
        },
        'Call Failed: SSO Authentication Failed. The system is in Maintenance mode': {
            message: 'ERROR_MESSAGES.PEX203',
            code: '#pex203'
        },
        'Call Failed: SSO Authentication Failed. SSO is not available from this domain': {
            message: 'ERROR_MESSAGES.PEX204',
            code: '#pex204'
        },
        'Failed to transfer into a multi-party conference': {
            message: 'ERROR_MESSAGES.PEX210',
            code: '#pex210'
        },
        'Failed to transfer into a one-to-one conference': {
            message: 'ERROR_MESSAGES.PEX211',
            code: '#pex211'
        }
    };

    translateErrorMessage(message: string): UserMessage {
        if (!!message.trim().match(/^Call Failed\: \d+$/i)) {
            return this.errorMessages['Call Failed: '];
        } else if (message.indexOf('Could not join localhost:8080') > -1) {
            return this.errorMessages[message];
        } else if (!!message.match(/^Could not join /i)) {
            return this.errorMessages['Could not join '];
        } else if (
            message.indexOf(
                'Call Failed: Error: Could not get access to camera/microphone'
            ) > -1
        ) {
            return this.errorMessages[
                'Call Failed: Error: Could not get access to camera/microphone.'
            ];
        } else {
            return this.errorMessages[message]
                ? this.errorMessages[message]
                : { message, code: 'Missing code' };
        }
    }
}
