import {
    Directive,
    ElementRef,
    HostBinding,
    HostListener
} from '@angular/core';

@Directive({
    selector: '[pexTabbable]'
})
export class TabbableDirective {
    constructor(private elementRef: ElementRef) {}

    @HostListener('keyup.enter')
    @HostListener('keyup.space')
    activateHandler() {
        this.elementRef?.nativeElement?.click();
    }

    @HostBinding('attr.tabindex') tabindex = 0;
}
