import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { Participant } from '../participant/participant.model';
import { CONFERENCE_ACTIONS } from './conference.actions';
import { SidebarState, SplashScreen } from './conference.model';
import { State } from './conference.reducer';
import * as selectors from './conference.selectors';
import { DTMFDialogConfig } from './dtmf-dialog/dtmf-dialog.component';
import { FECCDialogConfig } from './fecc-dialog/fecc-dialog.component';
import { LoginError } from './idp-popup-login-dialog/idp-login.service';

@Injectable({ providedIn: 'root' })
export class ConferenceFacade {
    deviceSetupState$ = this.store$.select(
        selectors.getConferenceDevicesSetupState
    );
    conferenceDetails$ = this.store$.select(selectors.getConferenceDetails);
    isConnected$ = this.store$.select(selectors.getConnected);
    conferenceState$ = this.store$.select(selectors.getConferenceState);
    mediaType$ = this.store$.select(selectors.getMediaType);
    isVideoMediaType$ = this.store$.select(selectors.getIsVideoMediaType);
    isAudioOnlyMediaType$ = this.store$.select(
        selectors.getIsAudioOnlyMediaType
    );
    isNoneMediaType$ = this.store$.select(selectors.getIsNoneMediaType);
    currentSlide$ = this.store$.select(selectors.getCurrentSlide);
    presentationImgSrc$ = this.store$.select(selectors.getPresentationImgSrc);
    presentationVideo$ = this.store$.select(selectors.getPresentationVideo);
    isPresentationImgSrc$ = this.store$.select(
        selectors.getIsPresentationImgSrc
    );
    isPresentationVideo$ = this.store$.select(selectors.getIsPresentationVideo);
    presentingSlides$ = this.store$.select(selectors.getPresentingSlides);
    presentationDetails$ = this.store$.select(selectors.getPresentationDetails);
    presentingMode$ = this.store$.select(selectors.getPresentingMode);
    isPresentationActive$ = this.store$.select(selectors.isPresentationActive);
    isPresentationLive$ = this.store$.select(selectors.getIsPresentationLive);
    isOutgoingPresentationActive$ = this.store$.select(
        selectors.isOutgoingPresentationActive
    );
    isCameraMuted$ = this.store$.select(selectors.getCameraMuted);
    isMicrophoneMuted$ = this.store$.select(selectors.getMicrophoneMuted);
    isVolumeMuted$ = this.store$.select(selectors.getVolumeMuted);
    remoteMediaStream$ = this.store$.select(selectors.getRemoteMediaStream);
    selfUUID$ = this.store$.select(selectors.getSelfUUID);
    sidebarState$ = this.store$.select(selectors.getSidebar);
    slides$ = this.store$.select(selectors.getSlides);
    isVideoLoaded$ = this.store$.select(selectors.getIsVideoLoaded);
    isLoading$ = this.store$.select(selectors.getIsLoading);
    isPinOnTop$ = this.store$.select(selectors.getIsPinOnTop);
    isIdle$ = this.store$.select(selectors.getIsIdle);
    isPresentationMaximized$ = this.store$.select(
        selectors.getIsPresentationMaximized
    );
    isPresentationPoppedOut$ = this.store$.select(
        selectors.getIsPresentationPoppedOut
    );
    presentingSlidesCount$ = this.store$.select(
        selectors.getPresentingSlidesCount
    );
    isVideoMainStage$ = this.store$.select(selectors.getIsVideoMainStage);
    isScreenPresentingMode$ = this.store$.select(
        selectors.getIsScreenPresentingMode
    );
    isScreenHttpPresentingMode$ = this.store$.select(
        selectors.getIsScreenHttpPresentingMode
    );
    shouldShowAudioOnlyScreen$ = this.store$.select(
        selectors.getShouldShowAudioOnlyScreen
    );
    remoteCallType$ = this.store$.select(selectors.getRemoteCallType);
    isAudioRemoteCallType$ = this.store$.select(selectors.getIsAudioRemoteType);
    selfParticipant$ = this.store$.select(selectors.getSelfParticipant);
    gwParticipant$ = this.store$.select(selectors.getGwParticipant);
    isGwParticipantFeccSupported$ = this.store$.select(
        selectors.getIsGwParticipantFeccSupported
    );
    canSelfParticipantControl$ = this.store$.select(
        selectors.getCanSelfParticipantControl
    );
    isSelfParticipantBuzzed$ = this.store$.select(
        selectors.getIsSelfParticipantBuzzed
    );
    isSelfParticipantHost$ = this.store$.select(
        selectors.getIsSelfParticipantHost
    );
    isSelfPartipantSilentVideo$ = this.store$.select(
        selectors.getIsSelfParticipantSilentVideo
    );
    openDTMFDialogs$ = this.store$.select(selectors.getOpenDTMFDialogs);
    openFECCDialogs$ = this.store$.select(selectors.getOpenFECCDialogs);
    openInfoDialogs$ = this.store$.select(selectors.getOpenInfoDialogs);

    isSidebarOpen$ = this.store$.select(selectors.getIsSidebarOpen);
    isSidebarExpanded$ = this.store$.select(selectors.getIsSidebarExpanded);

    isPresentationInMix$ = this.store$.select(selectors.getIsPresentationInMix);
    isPresentationIncoming$ = this.store$.select(
        selectors.getIsPresentationIncoming
    );
    getCurrentPresenterUuid$ = this.store$.select(
        selectors.getCurrentPresenter
    );
    isLiveCaptionsEnabled$ = this.store$.select(
        selectors.getLiveCaptionsEnabled
    );
    splashScreen$ = this.store$.select(selectors.getSplashScreen);

    isDirectMedia$ = this.store$.select(selectors.getIsDirectMedia);

    constructor(private store$: Store<State>) {}

    setMuteCamera() {
        this.store$.dispatch(CONFERENCE_ACTIONS.muteCamera());
    }

    setUnmuteCamera() {
        this.store$.dispatch(CONFERENCE_ACTIONS.unmuteCamera());
    }

    setMuteMicrophone() {
        this.store$.dispatch(CONFERENCE_ACTIONS.muteMicrophone());
    }

    setUnmuteMicrophone() {
        this.store$.dispatch(CONFERENCE_ACTIONS.unmuteMicrophone());
    }

    setMuteVolume() {
        this.store$.dispatch(CONFERENCE_ACTIONS.muteVolume());
    }

    setUnmuteVolume() {
        this.store$.dispatch(CONFERENCE_ACTIONS.unmuteVolume());
    }

    setVideoLoaded(isVideoLoaded: boolean) {
        this.store$.dispatch(CONFERENCE_ACTIONS.setVideoLoaded(isVideoLoaded));
    }

    setPinOnTop(isPinOnTop: boolean) {
        this.isPinOnTop$.pipe(take(1)).subscribe(currentIsPinOnTop => {
            if (currentIsPinOnTop !== isPinOnTop) {
                this.store$.dispatch(
                    CONFERENCE_ACTIONS.setPinOnTop(isPinOnTop)
                );
            }
        });
    }

    setIdle(isIdle: boolean) {
        this.store$.dispatch(CONFERENCE_ACTIONS.setIdle(isIdle));
    }

    setPresentationMaximized(isPresentationMaximized: boolean) {
        this.store$.dispatch(
            CONFERENCE_ACTIONS.setPresentationMaximized(isPresentationMaximized)
        );
    }

    setPresentationPoppedOut(isPresentationPoppedOut: boolean) {
        this.store$.dispatch(
            CONFERENCE_ACTIONS.setPresentationPoppedOut(isPresentationPoppedOut)
        );
    }

    setPresentationInMix(isPresentationInMix: boolean) {
        this.store$.dispatch(
            CONFERENCE_ACTIONS.setPresentationInMix(isPresentationInMix)
        );
    }

    startPresenting(presentingMode: PresentingMode) {
        this.store$.dispatch(
            CONFERENCE_ACTIONS.startPresenting(presentingMode)
        );
    }

    startSlideUpload() {
        this.store$.dispatch(CONFERENCE_ACTIONS.startSlideUpload());
    }

    stopPresenting() {
        this.store$.dispatch(CONFERENCE_ACTIONS.stopPresenting());
    }

    startPresentationVideo() {
        this.store$.dispatch(CONFERENCE_ACTIONS.startPresentationVideo());
    }

    stopPresentationVideo() {
        this.store$.dispatch(CONFERENCE_ACTIONS.stopPresentationVideo());
    }

    openDialpad(dialogId: string, uuid?: string) {
        this.store$.dispatch(
            CONFERENCE_ACTIONS.openDialpad({ dialogId, uuid })
        );
    }

    addDTMFDialog(dtmfdialog: DTMFDialogConfig) {
        this.store$.dispatch(CONFERENCE_ACTIONS.addDTMFDialog(dtmfdialog));
    }

    removeDTMFDialog(dtmfDialogName: string) {
        this.store$.dispatch(
            CONFERENCE_ACTIONS.removeDTMFDialog(dtmfDialogName)
        );
    }

    openFECCpad(dialogId: string, uuid?: string, participant?: Participant) {
        this.store$.dispatch(
            CONFERENCE_ACTIONS.openFECCpad({ dialogId, uuid, participant })
        );
    }

    addFECCDialog(feccDialog: FECCDialogConfig) {
        this.store$.dispatch(CONFERENCE_ACTIONS.addFECCDialog(feccDialog));
    }

    removeFECCDialog(feccDialogName: string) {
        this.store$.dispatch(
            CONFERENCE_ACTIONS.removeFECCDialog(feccDialogName)
        );
    }

    buzzOn() {
        this.store$.dispatch(CONFERENCE_ACTIONS.buzzOn());
    }

    buzzOff(uuid?: string) {
        this.store$.dispatch(CONFERENCE_ACTIONS.buzzOff(uuid));
    }

    openHostPinDialog() {
        this.store$.dispatch(CONFERENCE_ACTIONS.openHostPinDialog());
    }

    openIdPSelectionDialog() {
        this.store$.dispatch(CONFERENCE_ACTIONS.openIdPSelectionDialog());
    }

    openIdPLoginDialog() {
        this.store$.dispatch(CONFERENCE_ACTIONS.openIdpLoginDialog());
    }

    openStatsDialog() {
        this.store$.dispatch(CONFERENCE_ACTIONS.openStatsDialog());
    }

    openAddParticipantDialog(participantOptions: {
        previousURI?: string;
        isHost: boolean;
    }) {
        this.store$.dispatch(
            CONFERENCE_ACTIONS.openAddParticipantDialog(participantOptions)
        );
    }

    lock() {
        this.store$.dispatch(CONFERENCE_ACTIONS.lock());
    }

    unlock() {
        this.store$.dispatch(CONFERENCE_ACTIONS.unlock());
    }

    muteAll() {
        this.store$.dispatch(CONFERENCE_ACTIONS.muteAll());
    }

    unmuteAll() {
        this.store$.dispatch(CONFERENCE_ACTIONS.unmuteAll());
    }

    disconnect(shouldShowDisconnectDialog?: boolean) {
        this.store$.dispatch(
            CONFERENCE_ACTIONS.disconnect(shouldShowDisconnectDialog)
        );
    }

    disconnectAll() {
        this.store$.dispatch(CONFERENCE_ACTIONS.disconnectAll());
    }

    filterRosterBy(type: string) {
        this.store$.dispatch(CONFERENCE_ACTIONS.filterRosterBy(type));
    }

    startConference() {
        this.store$.dispatch(CONFERENCE_ACTIONS.startConference());
    }

    sendChatMessage(message: string) {
        this.store$.dispatch(CONFERENCE_ACTIONS.sendChatMessage(message));
    }

    openParticipantInfoPad(dialogId: string, participant: Participant) {
        this.store$.dispatch(
            CONFERENCE_ACTIONS.openParticipantInfoPad({ dialogId, participant })
        );
    }

    addParticipantInfoDialog(dialogId: string, participant: Participant) {
        this.store$.dispatch(
            CONFERENCE_ACTIONS.addParticipantInfoDialog({
                dialogId,
                participant
            })
        );
    }

    removeParticipantInfoDialog(feccDialogName: string) {
        this.store$.dispatch(
            CONFERENCE_ACTIONS.removeParticipantInfoDialog(feccDialogName)
        );
    }

    spotlightParticipant(uuid: string, shouldSpotlight: boolean) {
        this.store$.dispatch(
            CONFERENCE_ACTIONS.spotlightParticipant({ uuid, shouldSpotlight })
        );
    }

    changeParticipantRole(uuid: string, role: string) {
        this.store$.dispatch(
            CONFERENCE_ACTIONS.changeParticipantRole({ uuid, role })
        );
    }

    setParticipantMuted(uuid: string, shouldMute: boolean) {
        this.store$.dispatch(
            CONFERENCE_ACTIONS.setParticipantMuted({ uuid, shouldMute })
        );
    }

    setParticipantRxPres(uuid: string, allowRxPres: boolean) {
        this.store$.dispatch(
            CONFERENCE_ACTIONS.setParticipantRxPres({ uuid, allowRxPres })
        );
    }

    nextSlide() {
        this.store$.dispatch(CONFERENCE_ACTIONS.nextSlide());
    }

    prevSlide() {
        this.store$.dispatch(CONFERENCE_ACTIONS.prevSlide());
    }

    updatePresentationWindow() {
        this.store$.dispatch(CONFERENCE_ACTIONS.updatePresentationWindow());
    }

    toggleSidebar() {
        this.store$.dispatch(CONFERENCE_ACTIONS.toggleSidebar());
    }

    setShowConferenceSidebar(shouldShowConferenceSidebar: boolean) {
        this.store$.dispatch(
            CONFERENCE_ACTIONS.setShowConferenceSidebar(
                shouldShowConferenceSidebar
            )
        );
    }

    setSidebarState(sidebarState: SidebarState) {
        this.store$.dispatch(CONFERENCE_ACTIONS.setSidebarAction(sidebarState));
    }

    dialOut(
        alias: string,
        protocol: string,
        role: string,
        cb: (msg: object) => void,
        params: string | object
    ) {
        this.store$.dispatch(
            CONFERENCE_ACTIONS.dialOut({
                alias,
                protocol,
                role,
                cb,
                params
            })
        );
    }

    sendRequest(
        request: string,
        params: object,
        cb: (msg: object) => void,
        reqMethod: string,
        retries: number
    ) {
        this.store$.dispatch(
            CONFERENCE_ACTIONS.sendRequest({
                request,
                params,
                cb,
                reqMethod,
                retries
            })
        );
    }

    disconnectParticipantDialog(participant: Participant) {
        this.store$.dispatch(
            CONFERENCE_ACTIONS.disconnectParticipantDialog(participant)
        );
    }

    transferParticipantDialog(participant: Participant) {
        this.store$.dispatch(
            CONFERENCE_ACTIONS.transferParticipantDialog(participant)
        );
    }

    disconnectParticipant(uuid: string) {
        this.store$.dispatch(CONFERENCE_ACTIONS.disconnectParticipant(uuid));
    }

    changeConferenceLayout(params: object) {
        this.store$.dispatch(CONFERENCE_ACTIONS.changeConferenceLayout(params));
    }

    transformConferenceLayout(params: object) {
        this.store$.dispatch(
            CONFERENCE_ACTIONS.transformConferenceLayout(params)
        );
    }

    localMediaChange(userMedia: MediaStream) {
        this.store$.dispatch(
            CONFERENCE_ACTIONS.localMediaChange({ payload: userMedia })
        );
    }

    handleEscalation([mediaType, newMediaType]: [string, CallType]) {
        this.store$.dispatch(
            CONFERENCE_ACTIONS.handleEscalation([mediaType, newMediaType])
        );
    }

    setSidebarExpanded(shouldSidebarExpand: boolean) {
        this.store$.dispatch(
            CONFERENCE_ACTIONS.setSidebarExpanded(shouldSidebarExpand)
        );
    }

    setActive() {
        this.store$.dispatch(CONFERENCE_ACTIONS.setActive());
    }

    openLoginUrl(redirectUrl: string) {
        this.store$.dispatch(CONFERENCE_ACTIONS.openLoginUrl(redirectUrl));
    }

    submitSsoToken(ssoToken: string) {
        this.store$.dispatch(CONFERENCE_ACTIONS.submitSsoToken(ssoToken));
    }

    samlLoginError(loginError: LoginError) {
        this.store$.dispatch(CONFERENCE_ACTIONS.samlLoginError(loginError));
    }

    setSplashScreen(splashScreen: SplashScreen) {
        this.store$.dispatch(CONFERENCE_ACTIONS.setSplashScreen(splashScreen));
    }

    setLiveCaptionsEnabled(enabled: boolean) {
        this.store$.dispatch(
            CONFERENCE_ACTIONS.setLiveCaptionsEnabled(enabled)
        );
    }
}
