import { Component } from '@angular/core';

import { BaseDialogContainerComponent } from '../base-dialog-container.component';
import { BaseDialogContentComponent } from '../base-dialog-content.component';

@Component({
    selector: 'pex-dialog-container',
    templateUrl: './fullscreen-dialog-container.component.html',
    styleUrls: ['./fullscreen-dialog-container.component.scss']
})
export class FullscreenDialogContainerComponent<
    S extends BaseDialogContentComponent
> extends BaseDialogContainerComponent<S> {
    readonly type = 'fullscreen';
}
