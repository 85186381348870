import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Participant } from './participant.model';
import * as fromParticipants from './participants.reducer';

export const getParticipantsState = createFeatureSelector<fromParticipants.State>(
    'participants'
);

export const getParticipants = createSelector(
    getParticipantsState,
    (state: fromParticipants.State) => state.participants
);
export const getRoster = createSelector(
    getParticipantsState,
    (state: fromParticipants.State) => state.roster
);
export const getRosterParticipants = createSelector(
    getParticipantsState,
    (state: fromParticipants.State) => {
        const participants = state.participants;
        const roster = state.roster;
        return roster.map(uuid => participants[uuid]);
    }
);

export const getStageParticipants = createSelector(
    getRosterParticipants,
    (participants: Participant[]) => {
        return participants
            .filter(
                ({ stageIndex, callType }) =>
                    stageIndex !== undefined && callType !== 'api'
            )
            .sort((p1, p2) => p1.stageIndex - p2.stageIndex);
    }
);
