<div id="pex-blur-button" *ngIf="areBackgroundEffectsSupported">
    <button
        *ngIf="isBackgroundEffectEnabled"
        type="button"
        class="pex-button"
        [attr.aria-label]="'TOOLTIP.BLUR_ON_ACTION' | translate"
        [pexTooltip]="{
            message: isBlurActiveEffect
                ? 'TOOLTIP.BLUR_ON_ACTION'
                : 'TOOLTIP.REPLACEMENT_ON_ACTION',
            position: 'left'
        }"
        (click)="setEnableBackgroundEffects($event, false)"
    >
        <svg
            class="button-icon icons"
            id="home-blur-on-icon"
            viewBox="0 0 37 38"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
        >
            <g fill="none" fill-rule="evenodd">
                <mask id="blur.mask" fill="#fff">
                    <path d="M0 0h37v27.754H0z" />
                </mask>
                <g
                    mask="url(#blur.mask)"
                    stroke="#FFF"
                    stroke-linecap="round"
                    stroke-width="3"
                >
                    <path
                        d="m17.848-13.59 25.505 25.37M12.996-8.132 38.5 17.238M8.174-2.098l25.504 25.37M3.291 3.907l25.504 25.37M-1.561 9.366l25.504 25.37M-6.383 15.4l25.504 25.369"
                    />
                </g>
                <g
                    transform="translate(7.289 7.039)"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3.2"
                >
                    <path
                        d="M24.262 28.961v-3.218c0-3.554-2.896-6.436-6.47-6.436H6.47C2.897 19.307 0 22.19 0 25.743v3.218"
                    />
                    <ellipse
                        fill="#FFF"
                        cx="12.131"
                        cy="6.436"
                        rx="6.47"
                        ry="6.436"
                    />
                </g>
            </g>
        </svg>
    </button>
    <button
        *ngIf="!isBackgroundEffectEnabled"
        type="button"
        class="pex-button"
        [attr.aria-label]="'TOOLTIP.BLUR_OFF_ACTION' | translate"
        [pexTooltip]="{
            message: isBlurActiveEffect
                ? 'TOOLTIP.BLUR_OFF_ACTION'
                : 'TOOLTIP.REPLACEMENT_OFF_ACTION',
            position: 'left'
        }"
        (click)="setEnableBackgroundEffects($event, true)"
    >
        <svg
            class="button-icon icons"
            id="home-blur-on-icon"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 37 38"
        >
            <g fill="none" fill-rule="evenodd">
                <mask id="blur-off.mask" fill="#fff">
                    <path d="M0 0h37v27.754H0z" />
                </mask>
                <g
                    mask="url(#blur-off.mask)"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-width="3"
                >
                    <path
                        d="m17.848-13.59 25.504 25.37M12.996-8.131 38.5 17.239M8.174-2.097l25.504 25.37M3.291 3.908l25.504 25.37M-1.562 9.366l25.505 25.37M-6.384 15.4l25.505 25.37"
                    />
                </g>
                <g
                    transform="translate(7.289 7.039)"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3.2"
                >
                    <path
                        d="M24.262 28.961v-3.218c0-3.554-2.896-6.436-6.47-6.436H6.47C2.897 19.307 0 22.19 0 25.743v3.218"
                    />
                    <ellipse
                        fill="#FFF"
                        cx="12.131"
                        cy="6.436"
                        rx="6.47"
                        ry="6.436"
                    />
                </g>
            </g>
        </svg>
    </button>
</div>
