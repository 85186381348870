import { Injectable } from '@angular/core';
import { LoggingService } from './logging.service';

@Injectable()
export abstract class SRVService {
    abstract resolveSrv(name: string): Promise<SRVRecord[]>;

    constructor(private loggingService: LoggingService) {}

    resolvePexAppGenerator(host: string) {
        return this.resolvePexApp(host).then(hosts =>
            (function* () {
                for (const host of hosts) {
                    yield host;
                }
            })()
        );
    }

    resolvePexApp(host: string): Promise<string[]> {
        const ipRe = /^\d+\.\d+\.\d+\.\d+$/;
        if (host && host.match(ipRe)) {
            // If host is an ip address, return it as the address
            return new Promise((resolve, _reject) => {
                resolve([host]);
            });
        } else {
            // Try to resolve records for _pexapp._tcp.<host>
            return (
                this.resolveSrv('_pexapp._tcp.' + host)
                    .then(
                        records => {
                            return records && records.length > 0
                                ? this.weightSort(records)
                                      // Reduce to '<record.name>:<record.port>' strings
                                      .map(
                                          record =>
                                              record.name + ':' + record.port
                                      )
                                : [];
                        },
                        error => {
                            this.loggingService.warn(
                                'SRV lookup failed',
                                error
                            );
                            return [];
                        }
                    )
                    // Add <host> as final server to try (e.g. if no records found, or lookup fails)
                    .then(records => [...records, host])
            );
        }
    }

    private weightSort(records: SRVRecord[]) {
        const sortedPriorityArrs = this.splitPriorities(records);
        const weightSortedRecords: SRVRecord[] = [];

        for (const priorityArr of sortedPriorityArrs) {
            this.shuffle(priorityArr);
            priorityArr.sort((a, b) => {
                if (a.weight !== 0 || b.weight === 0) return 0;
                return -1;
            });

            let weightSum = records.reduce(
                (sum, record) => sum + record.weight,
                0
            );
            const newOrder: SRVRecord[] = [];

            while (priorityArr.length > 0) {
                const nextRecord = this.getNextRecord(priorityArr, weightSum);
                weightSum -= nextRecord.weight;
                newOrder.push(nextRecord);
            }

            weightSortedRecords.push(...newOrder);
        }

        return weightSortedRecords;
    }

    private getNextRecord(records: SRVRecord[], weightSum: number) {
        let runningSum = 0;
        const randomWeight = Math.floor(Math.random() * (weightSum + 1));
        const index = records.findIndex(record => {
            runningSum += record.weight;
            return runningSum >= randomWeight;
        });
        return records.splice(index, 1)[0];
    }

    private splitPriorities(records: SRVRecord[]): SRVRecord[][] {
        const splitRecords: { [priority: number]: SRVRecord[] } = {};

        for (const record of records) {
            if (!splitRecords[record.priority]) {
                splitRecords[record.priority] = [];
            }

            if (!record.weight || isNaN(record.weight)) record.weight = 0;
            else record.weight = Number(record.weight);

            splitRecords[record.priority].push(record);
        }

        return Object.keys(splitRecords)
            .sort((a, b) => Number(a) - Number(b))
            .map(priority => splitRecords[priority]);
    }

    private shuffle(arr: unknown[]) {
        for (let i = arr.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [arr[i], arr[j]] = [arr[j], arr[i]];
        }
        return arr;
    }
}
