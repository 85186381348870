<div class="pex-dialog-close">
    <button
        type="button"
        (click)="close()"
        class="circle-close-button"
        [attr.aria-label]="'CLOSE' | translate"
    >
        <svg class="circle-close-button-icon">
            <use xlink:href="icons.svg#x" />
        </svg>
    </button>
</div>

<div class="pex-dialog-content-inner">
    <div class="pex-dialog-content-title">
        {{ 'INCOMINGCALL_DIALOG.TITLE' | translate }}
    </div>

    <div class="pex-dialog-content-message">
        {{ remoteDisplayName || remoteAlias }}<br />
    </div>

    <div class="pex-dialog-content-button">
        <button (click)="close()" class="dialog-button red-action-button">
            {{ 'INCOMINGCALL_DIALOG.DECLINE' | translate }}
        </button>
        <button (click)="accept()" class="dialog-button green-action-button">
            {{ 'INCOMINGCALL_DIALOG.ACCEPT' | translate }}
        </button>
    </div>
    <audio
        *ngIf="this.config?.playRingtone"
        src="assets/ping.ogg"
        autoplay
        loop
    ></audio>
</div>
