import { Injectable } from '@angular/core';
import { DialogService } from 'src/core/dialog/dialog.service';
import { HAS_WEBGL, PlatformService } from 'src/core/platform.service';
import { BackgroundEffects } from 'src/core/services/storage/settings.model';
import { StorageService } from 'src/core/services/storage/storage.service';

const MIN_RESOLUTION_WIDTH = 352;
const MIN_RESOLUTION_HEIGHT = 240;
const MAX_RESOLUTION_WIDTH = 4000;
const MAX_RESOLUTION_HEIGHT = 2550;

enum uploadImageError {
    INVALID_FILE_TYPE = 'INVALID_FILE_TYPE',
    INVALID_IMAGE_RESOLUTION = 'INVALID_IMAGE_RESOLUTION',
    READ_IMAGE_ERROR = 'READ_IMAGE_ERROR'
}

@Injectable({
    providedIn: 'root'
})
export class BackgroundEffectsService {
    constructor(
        public platformService: PlatformService,
        private storageService: StorageService,
        private dialogService: DialogService
    ) {}

    get areBackgroundEffectsSupported() {
        return (
            (this.platformService.isElectron() ||
                (this.platformService.isWeb() &&
                    ['chrome'].includes(this.platformService.browserType))) &&
            !this.platformService.isCitrixApp() &&
            !this.platformService.isMobileBrowser() &&
            this.storageService.applicationSettingsProxy.backgroundEffects &&
            this.isWebGLEnabled
        );
    }

    get isWebGLEnabled() {
        return HAS_WEBGL;
    }

    get isBackgroundEffectEnabled() {
        return this.storageService.userSettingsProxy.enableBackgroundEffects;
    }

    get isBlurActiveEffect() {
        return (
            this.storageService.userSettingsProxy.activeEffect ===
            BackgroundEffects.blur
        );
    }

    get isReplacementActiveEffect() {
        return (
            this.storageService.userSettingsProxy.activeEffect ===
            BackgroundEffects.replacement
        );
    }

    updateActiveBackgroundEffect(nextEffect: BackgroundEffects) {
        const currentEffect = this.storageService.userSettingsProxy
            .activeEffect;
        if (!this.storageService.userSettingsProxy.enableBackgroundEffects) {
            this.storageService.userSettingsProxy.enableBackgroundEffects = true;
        } else if (
            this.storageService.userSettingsProxy.activeEffect === nextEffect &&
            this.storageService.userSettingsProxy.enableBackgroundEffects
        ) {
            this.storageService.userSettingsProxy.enableBackgroundEffects = false;
        }

        if (currentEffect !== nextEffect) {
            this.storageService.userSettingsProxy.activeEffect = nextEffect;
        }
    }

    setEnableBackgroundEffects(enable: boolean) {
        this.storageService.userSettingsProxy.enableBackgroundEffects = enable;
    }

    validateAndUploadBackgroundImage(image: File) {
        if (!this.isImageTypeValid) {
            this.handleInvalidImage(uploadImageError.INVALID_FILE_TYPE);
        }

        // Read Image file to string
        const reader = new FileReader();
        try {
            reader.readAsDataURL(image);
        } catch (error) {
            this.handleInvalidImage(uploadImageError.READ_IMAGE_ERROR);
        }
        return new Promise<void>(resolve => {
            reader.onload = _event => {
                const imageUrl = String(reader.result);
                const image = new Image();
                image.src = imageUrl;
                image.onload = async () => {
                    if (
                        this.isImageResolutionValid(image.height, image.width)
                    ) {
                        await this.setBackgroundImage(imageUrl);
                    } else {
                        await this.handleInvalidImage(
                            uploadImageError.INVALID_IMAGE_RESOLUTION
                        );
                    }
                    resolve();
                };
            };
        });
    }

    private isImageTypeValid(image: File) {
        const validFileTypes = [
            'image/jpeg',
            'image/jpg',
            'image/png',
            'image/bmp'
        ];
        return validFileTypes.some(validFile => validFile === image.type);
    }

    private isImageResolutionValid(height: number, width: number) {
        return (
            height >= MIN_RESOLUTION_HEIGHT &&
            height <= MAX_RESOLUTION_HEIGHT &&
            width >= MIN_RESOLUTION_WIDTH &&
            width <= MAX_RESOLUTION_WIDTH
        );
    }

    /**
     * @param error - key string must exist in the language files
     */
    private async handleInvalidImage(error: uploadImageError) {
        return await this.dialogService.openActionDialog({
            title: `${error}.TITLE`,
            message: `${error}.BODY`,
            actions: ['OK']
        });
    }

    private async setBackgroundImage(imgString: string) {
        await window.pexBridge.storeBackgroundImg(imgString);
        this.storageService.userSettingsProxy.desktopBackgroundFile = imgString;
    }

    resetBackgroundImage() {
        this.storageService.userSettingsProxy.desktopBackgroundFile =
            'assets/default-replacement.jpg';
        window.pexBridge.deleteBackgroundImg();
    }
}
