interface HotKeyMeta {
    hotKey: string;
    description: string;
    hotKeyDisplay?: string;
}
export type HotKeys =
    | 'TOGGLE_MIC'
    | 'TOGGLE_CAMERA'
    | 'ADD_PARTICIPANT'
    | 'TOGGLE_SIDEBAR'
    | 'TOGGLE_EVENTS_ROSTER'
    | 'TOGGLE_VIDEO_PRESENTATION'
    | 'POPUP_PRESENTATION'
    | 'PUSH_TO_TALK'
    | 'TOGGLE_INCOMING_AUDIO';
export const HOT_KEYS_LIST: { readonly [T in HotKeys]: HotKeyMeta } = {
    TOGGLE_MIC: {
        hotKey: 'm',
        description: 'HOT_KEYS.TOGGLE_MIC'
    },
    TOGGLE_CAMERA: {
        hotKey: 'c',
        description: 'HOT_KEYS.TOGGLE_CAMERA'
    },
    PUSH_TO_TALK: {
        hotKey: 'space',
        hotKeyDisplay: 'space',
        description: 'HOT_KEYS.PUSH_TO_TALK'
    },
    TOGGLE_INCOMING_AUDIO: {
        hotKey: 'v',
        hotKeyDisplay: 'v',
        description: 'HOT_KEYS.TOGGLE_INCOMING_AUDIO'
    },
    ADD_PARTICIPANT: {
        hotKey: 'a',
        description: 'HOT_KEYS.ADD_PARTICIPANT'
    },
    TOGGLE_SIDEBAR: {
        hotKey: 's',
        description: 'HOT_KEYS.TOGGLE_SIDEBAR'
    },
    TOGGLE_EVENTS_ROSTER: {
        hotKey: 'S',
        hotKeyDisplay: 'Shift+s',
        description: 'HOT_KEYS.TOGGLE_EVENTS_ROSTER'
    },
    TOGGLE_VIDEO_PRESENTATION: {
        hotKey: 'p',
        description: 'HOT_KEYS.TOGGLE_VIDEO_PRESENTATION'
    },
    POPUP_PRESENTATION: {
        hotKey: 'P',
        hotKeyDisplay: 'Shift+p',
        description: 'HOT_KEYS.POPUP_PRESENTATION'
    }
};
