import { Component } from '@angular/core';

import { BaseDialogContentComponent } from '../base-dialog-content.component';

interface IncomingCallConfig {
    remote_display_name: string;
    remote_alias: string;
    playRingtone: boolean;
    token: string;
}

@Component({
    templateUrl: './incoming-call-dialog.component.html'
})
export class IncomingCallDialogComponent extends BaseDialogContentComponent<
    string,
    IncomingCallConfig
> {
    remoteDisplayName: string;
    remoteAlias: string;

    afterInit() {
        this.remoteDisplayName = this.config.remote_display_name;
        this.remoteAlias = this.config.remote_alias;
    }

    accept() {
        this.close('ACCEPT');
    }
}
