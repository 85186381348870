import {
    Directive,
    HostBinding,
    HostListener,
    NgZone,
    OnInit
} from '@angular/core';
import { PlatformService } from '../platform.service';

@Directive({
    selector: '[pexOrientation]'
})
export class OrientationDirective implements OnInit {
    @HostBinding('attr.orientation') orientation = 'false';

    @HostListener('window:orientationchange')
    onOrientationChange() {
        let orientation: string;

        if (window.screen && window.screen.orientation) {
            orientation = window.screen.orientation.type.split('-')[0];
        } else if (window.orientation) {
            const portraitDegrees = 90;
            orientation =
                Math.abs(Number(window.orientation)) === portraitDegrees
                    ? 'landscape'
                    : 'portrait';
        }

        this.ngZone.run(() => {
            this.orientation = orientation;
            this.platformService.orientationChange$.next(orientation);
        });
    }

    constructor(
        private ngZone: NgZone,
        private platformService: PlatformService
    ) {}

    ngOnInit() {
        if (window.screen.orientation) {
            this.onOrientationChange();
        }
    }
}
