<div class="pex-video-container">
    <video
        id="selfviewVideo"
        playsInline
        [class.pex-video--mirror]="mirror"
        autoplay
        muted
        onloadedmetadata="this.muted = true"
        [attr.srcobjecttrigger]="userMediaStream"
        [srcObject]="pexBranding ? null : userMediaStream"
        [class.hide-selfview-video]="
            !userMediaStream ||
            !userMediaStream.active ||
            pexBranding ||
            !shouldShowVideoElem
        "
        poster="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        #videoElement
    ></video>
    <img
        src="assets/Offical_Pexip_Stina.jpg"
        class="selfview__placeholder"
        *ngIf="userMediaStream && pexBranding"
    />
    <img
        src="assets/camera-off.svg"
        class="selfview__camera-off"
        *ngIf="cameraMuted && !microphoneMuted"
        alt="{{ 'TOOLTIP.VIDEO_OFF' | translate }}"
    />
    <img
        src="assets/mic-off-portrait.svg"
        class="selfview__camera-off-portrait"
        *ngIf="
            shouldShowMicMutedIcon &&
            microphoneMuted &&
            !cameraMuted &&
            mobilePortrait
        "
        alt="{{ 'TOOLTIP.MICROPHONE_OFF' | translate }}"
    />
    <img
        src="assets/mic-off.svg"
        class="selfview__mic-off"
        *ngIf="
            shouldShowMicMutedIcon &&
            microphoneMuted &&
            !cameraMuted &&
            !mobilePortrait
        "
        alt="{{ 'TOOLTIP.MICROPHONE_OFF' | translate }}"
    />
    <img
        src="assets/camera-mic-off.svg"
        class="selfview__camera-mic-off"
        *ngIf="microphoneMuted && cameraMuted && !mobilePortrait"
        alt="{{ 'TOOLTIP.VIDEO_AND_MICROPHONE_OFF' | translate }}"
    />
    <img
        src="assets/camera-mic-off-portrait.svg"
        class="selfview__camera-mic-off-portrait"
        *ngIf="
            shouldShowMicMutedIcon &&
            microphoneMuted &&
            cameraMuted &&
            mobilePortrait
        "
        alt="{{ 'TOOLTIP.VIDEO_AND_MICROPHONE_OFF' | translate }}"
    />
    <pex-talking-when-muted
        *ngIf="shouldShowIndicatorWhenMuted"
    ></pex-talking-when-muted>

    <pex-background-effect-indicator
        *ngIf="!cameraMuted"
        class="pex-button--blur"
        [ngClass]="{ 'in-meeting': inMeeting }"
    ></pex-background-effect-indicator>
</div>
