import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { MediaDeviceService } from '../../media-device/media-device.service';
import { CustomValidators } from '../../shared/custom-validators';
import { BaseDialogContentComponent } from '../base-dialog-content.component';

@Component({
    templateUrl: './display-name-dialog.component.html'
})
export class DisplayNameDialogComponent extends BaseDialogContentComponent<
    string,
    string
> {
    displayNameForm: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        private mediaDeviceService: MediaDeviceService
    ) {
        super();
    }

    afterInit() {
        this.buildForm();
    }

    buildForm(): void {
        this.displayNameForm = this.formBuilder.group({
            displayName: [this.config, CustomValidators.required]
        });
    }

    onSubmit() {
        this.mediaDeviceService.startMedia$.next(true);
        this.close(this.displayNameForm.value.displayName);
    }

    clickOutside() {}

    escapePressed() {}
}
