import { Action, ActionReducer } from '@ngrx/store';

import { PARTICIPANT_ACTIONS } from '../participant/participant.actions';
import { Logger } from './logger';

export const BATCH_ACTION = '[Batch] batch action';
export class BatchContainerAction implements Action {
    readonly type = BATCH_ACTION;
    constructor(public payload: BatchAction[]) {}
}

export interface BatchAction extends Action {
    useNewObjects: boolean;
    isBatch: boolean;
}

export function batch<T>(reducer: ActionReducer<T>) {
    return (state: T, action: Action) => {
        switch (action.type) {
            case BATCH_ACTION:
                const containerAction = action as BatchContainerAction;
                Logger.info(
                    `Batch action containing ${containerAction.payload.length} actions`
                );
                if (containerAction.payload.length < 20) {
                    Logger.info('Batch actions:', containerAction.payload);
                }

                let newState = state;
                const batchedActionTypes: {
                    [actionType: string]: boolean;
                } = {};
                for (const batchedAction of containerAction.payload) {
                    batchedAction.useNewObjects = !batchedActionTypes[
                        batchedAction.type
                    ];
                    batchedAction.isBatch = true;
                    newState = reducer(newState, batchedAction);
                    if (!batchedActionTypes[batchedAction.type]) {
                        batchedActionTypes[batchedAction.type] = true;
                    }
                }

                return newState;

            default:
                return reducer(state, action);
        }
    };
}

export function isBatch(action: Action): action is BatchAction {
    return ([
        PARTICIPANT_ACTIONS.connectSuccessAction.type,
        PARTICIPANT_ACTIONS.connectingAction.type,
        PARTICIPANT_ACTIONS.disconnectSuccessAction.type
    ] as string[]).includes(action.type);
}
