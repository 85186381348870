import { Injectable } from '@angular/core';
import { PlatformService } from 'src/core/platform.service';
import { ConferenceFacade } from '../conference.facade';

export enum ErrorCode {
    SERVICE_UNAVAILABLE = 503
}

export enum LoginError {
    DEFAULT = 'IDP_SELECTION_DIALOG.ERROR.DEFAULT',
    POPUP_BLOCKER = 'IDP_SELECTION_DIALOG.ERROR.POPUP_BLOCKER',
    USER_INTERRUPT = 'IDP_SELECTION_DIALOG.ERROR.USER_INTERRUPT',
    MAINTENANCE = 'IDP_SELECTION_DIALOG.ERROR.MAINTENANCE',
    NO_IDENTITY_PROVIDERS = 'IDP_SELECTION_DIALOG.ERROR.NO_IDENTITY_PROVIDERS'
}

export const isClientLoginError = (loginError: LoginError) => {
    if (!loginError) {
        return false;
    }
    const clientLoginErrors = [
        LoginError.POPUP_BLOCKER,
        LoginError.USER_INTERRUPT
    ];
    return clientLoginErrors.includes(loginError);
};

@Injectable({
    providedIn: 'root'
})
export class IdpLoginService {
    private authPopupRef: Window | null;
    private pollingIdRef: number;

    constructor(
        private platformService: PlatformService,
        private conferenceFacade: ConferenceFacade
    ) {}

    public openPopupInWeb(): void {
        if (this.platformService.isWeb()) {
            this.authPopupRef = window.open(
                'idpRedirectWaitingPage.html',
                '_blank',
                'width=800,height=900'
            );
        }
    }

    public redirectToLogin(redirectUrl: string) {
        if (this.platformService.isMobileApp()) {
            this.loginOnMobileApp(redirectUrl);
        } else if (this.platformService.isElectron()) {
            this.loginOnElectron(redirectUrl);
        } else {
            this.loginOnWeb(redirectUrl);
        }
    }

    private loginOnMobileApp(redirectUrl: string) {
        this.authPopupRef = cordova.InAppBrowser.open(redirectUrl, '_system');
    }

    private loginOnElectron(redirectUrl: string) {
        window.open(redirectUrl, '_blank', 'width=800,height=900');
    }

    private loginOnWeb(redirectUrl: string) {
        window.addEventListener('message', this.handleMessage, false);

        // pop-up should already be opened in 'openPopupInWeb()' to avoid pop-up blockers
        this.authPopupRef
            ? (this.authPopupRef.location.href = redirectUrl)
            : this.loginFailed(LoginError.POPUP_BLOCKER);

        const timeoutMs = 1000;
        this.pollingIdRef = window.setInterval(() => {
            if (this.authPopupRef?.closed) {
                this.loginFailed(LoginError.USER_INTERRUPT);
            }
        }, timeoutMs);
    }

    private handleMessage = (event: MessageEvent) => {
        if (event.source !== this.authPopupRef) {
            this.loginFailed(LoginError.DEFAULT);
            return;
        }

        this.authPopupRef?.close();

        window.removeEventListener('message', this.handleMessage);

        if (event.data.result === 'success') {
            this.loginSuccess(event.data.token);
        } else if (event.data.code === ErrorCode.SERVICE_UNAVAILABLE) {
            this.loginFailed(LoginError.MAINTENANCE);
        } else {
            this.loginFailed(LoginError.DEFAULT);
        }
    };

    private loginSuccess(ssoToken: string) {
        this.cleanPopup();
        this.conferenceFacade.submitSsoToken(ssoToken);
    }

    private loginFailed(loginError: LoginError) {
        this.cleanPopup();
        this.conferenceFacade.samlLoginError(loginError);
    }

    public cleanPopup() {
        clearInterval(this.pollingIdRef);
        this.pollingIdRef = undefined;
        this.authPopupRef?.close();
        this.authPopupRef = undefined;
        window.removeEventListener('message', this.handleMessage);
    }
}
