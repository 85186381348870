import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
// import { storeFreeze } from 'ngrx-store-freeze';
import { localStorageSync } from 'ngrx-store-localstorage';

import * as fromConference from './conference/conference.reducer';
import * as fromEvents from './conference/events/events.reducer';
import * as fromLayout from './conference/layout/layout.reducer';
import * as fromHome from './home/home.reducer';
import * as fromMediaDevices from './media-device/media-device.reducer';
import * as fromParticipants from './participant/participants.reducer';
import * as fromRegistration from './registration/registration.reducer';
import { batch, isBatch } from './shared/batch-ngrx';
import * as fromGeneral from './shared/general/general.reducer';
import { Logger } from './shared/logger';

export interface State {
    conference: fromConference.State;
    participants: fromParticipants.State;
    mediaDevices: fromMediaDevices.MediaDeviceState;
    events: fromEvents.State;
    home: fromHome.HomeState;
    general: fromGeneral.GeneralState;
    registration: fromRegistration.RegistrationState;
    layout: fromLayout.LayoutState;
}

export const initialState = {
    conference: fromConference.initialState,
    participants: fromParticipants.initialState,
    mediaDevices: fromMediaDevices.initialState,
    events: fromEvents.initialState,
    home: fromHome.initialState,
    general: fromGeneral.initialState,
    registration: fromRegistration.initialState,
    layout: fromLayout.initialState
};

export const reducer: ActionReducerMap<State> = {
    conference: fromConference.reducer,
    participants: fromParticipants.reducer,
    mediaDevices: fromMediaDevices.reducer,
    events: fromEvents.reducer,
    home: fromHome.reducer,
    general: fromGeneral.reducer,
    registration: fromRegistration.reducer,
    layout: fromLayout.reducer
};

export function persist(reducer: ActionReducer<State>) {
    return localStorageSync({
        keys: [{ registration: fromRegistration.storedRegistrationKeys }],
        rehydrate: true,
        storageKeySerializer: key => {
            return `pex${key.charAt(0).toUpperCase() + key.slice(1)}`;
        }
    })(reducer);
}

export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
    return (state, action) => {
        const nextState = reducer(state, action);
        const prefix = `State transition: ${action.type}`;
        if (!(isBatch(action) && action.isBatch)) {
            Logger.info(`${prefix}: Action`, action);
            if (ENV === 'development') {
                const from = {};
                const to = {};
                for (const prop in nextState) {
                    if (nextState.hasOwnProperty(prop)) {
                        if (state && state[prop] !== nextState[prop]) {
                            from[prop] = state[prop];
                            to[prop] = nextState[prop];
                        }
                    }
                }
                Logger.info(`${prefix}: From`, from);
                Logger.info(`${prefix}: To`, to);
            }
        }

        return nextState;
    };
}

export const metaReducers: MetaReducer<State>[] = [
    batch,
    persist,
    logger,
    ...(ENV === 'production'
        ? []
        : [
              /*storeFreeze FIXME: storeFreeze breaks dev build because we are mutating state that should be immutable*/
          ])
];
