import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getParticipants } from '../participant/participants.selectors';
import { State } from './conference.reducer';

export const getConferenceState = createFeatureSelector<State>('conference');

export const isOutgoingPresentationActive = createSelector(
    getConferenceState,
    (state: State) => state.outgoingPresentation
);

export const isPresentationActive = createSelector(
    getConferenceState,
    (state: State) => state.incomingPresentation || state.outgoingPresentation
);

export const getIsPresentationLive = createSelector(
    getConferenceState,
    (state: State) =>
        !!(
            (state.incomingPresentation || state.outgoingPresentation) &&
            (state.presentationImgSrc || state.presentationVideo)
        )
);

export const getConferenceDetails = createSelector(
    getConferenceState,
    (state: State) => {
        return {
            locked: state.locked,
            guestsMuted: state.guestsMuted,
            chatEnabled: state.chatEnabled,
            liveCaptionsAvailable: state.liveCaptionsAvailable,
            mediaType: state.mediaType,
            started: state.started,
            isDirectMedia: state.isDirectMedia
        };
    }
);
export const getRemoteMediaStream = createSelector(
    getConferenceState,
    (state: State) => state.remoteMediaStream
);
export const getCameraMuted = createSelector(
    getConferenceState,
    (state: State) => state.cameraMuted
);
export const getMicrophoneMuted = createSelector(
    getConferenceState,
    (state: State) => state.microphoneMuted
);
export const getVolumeMuted = createSelector(
    getConferenceState,
    (state: State) => state.volumeMuted
);
export const getPresentingMode = createSelector(
    getConferenceState,
    (state: State) => state.presentingMode
);
export const getPresentationImgSrc = createSelector(
    getConferenceState,
    (state: State) => state.presentationImgSrc
);
export const getPresentationVideo = createSelector(
    getConferenceState,
    (state: State) => state.presentationVideo
);
export const getIsPresentationImgSrc = createSelector(
    getConferenceState,
    (state: State) => !!state.presentationImgSrc
);
export const getIsPresentationVideo = createSelector(
    getConferenceState,
    (state: State) => !!state.presentationVideo
);
export const getSlides = createSelector(
    getConferenceState,
    (state: State) => state.slides
);
export const getPresentingSlides = createSelector(
    getConferenceState,
    (state: State) => state.presentingSlides
);
export const getCurrentSlide = createSelector(
    getConferenceState,
    (state: State) => state.currentSlide
);
export const getSelfUUID = createSelector(
    getConferenceState,
    (state: State) => state.selfUUID
);
export const getConnected = createSelector(
    getConferenceState,
    (state: State) => state.connected
);
export const getMediaType = createSelector(
    getConferenceState,
    (state: State) => state.mediaType
);

export const getIsVideoMediaType = createSelector(
    getConferenceState,
    (state: State) => state.mediaType === 'video'
);

export const getIsAudioOnlyMediaType = createSelector(
    getConferenceState,
    (state: State) => state.mediaType === 'audioonly'
);

export const getIsNoneMediaType = createSelector(
    getConferenceState,
    (state: State) => state.mediaType === 'none'
);

export const getPresentationDetails = createSelector(
    getConferenceState,
    (state: State) => {
        return {
            incomingPresentation: state.incomingPresentation,
            outgoingPresentation: state.outgoingPresentation,
            presentationImgSrc: state.presentationImgSrc,
            presentationVideo: state.presentationVideo,
            presentingMode: state.presentingMode,
            slides: state.slides,
            currentSlide: state.currentSlide,
            presentingSlides: state.presentingSlides
        };
    }
);
export const getIsPresentationIncoming = createSelector(
    getConferenceState,
    (state: State) => state.incomingPresentation
);
export const getSidebar = createSelector(
    getConferenceState,
    (state: State) => state.sidebar
);
export const getConferenceDevicesSetupState = createSelector(
    getConferenceState,
    (state: State) => state.devicesSetup
);
export const getIsPinOnTop = createSelector(
    getConferenceState,
    (state: State) => state.pinOnTop
);
export const getIsVideoLoaded = createSelector(
    getConferenceState,
    (state: State) => state.videoLoaded
);

export const getSplashScreen = createSelector(
    getConferenceState,
    (state: State) => state.splashScreen
);

export const getIsLoading = createSelector(
    getConnected,
    getIsVideoMediaType,
    getIsVideoLoaded,
    getSplashScreen,
    (isConnected, isVideoMediaType, isVideoLoaded, splashScreen) =>
        !isConnected || (isVideoMediaType && !isVideoLoaded && !splashScreen)
);

export const getIsIdle = createSelector(
    getConferenceState,
    (state: State) => state.idle
);

export const getIsPresentationMaximized = createSelector(
    getConferenceState,
    (state: State) => state.presentationMaximized
);

export const getIsPresentationPoppedOut = createSelector(
    getConferenceState,
    (state: State) => state.presentationPoppedOut
);

export const getIsPresentationInMix = createSelector(
    getConferenceState,
    (state: State) => state.presentationInMix
);

export const getIsVideoMainStage = createSelector(
    getIsPresentationLive,
    getIsPresentationMaximized,
    getIsPresentationPoppedOut,
    (isPresentationLive, isPresentationMaximized, isPresentationPoppedOut) =>
        !isPresentationLive ||
        !isPresentationMaximized ||
        isPresentationPoppedOut
);

export const getShouldShowAudioOnlyScreen = createSelector(
    getIsAudioOnlyMediaType,
    getIsNoneMediaType,
    getIsPinOnTop,
    getIsPresentationLive,
    getIsPresentationPoppedOut,
    (
        isAudioOnlyMediaType,
        isNoneMediaType,
        isPinOnTop,
        isPresentationLive,
        isPresentationPoppedOut
    ) =>
        (isAudioOnlyMediaType || (isNoneMediaType && isPinOnTop)) &&
        (!isPresentationLive || isPresentationPoppedOut)
);

export const getIsScreenPresentingMode = createSelector(
    getConferenceState,
    (state: State) => state.presentingMode === 'screen'
);

export const getIsScreenHttpPresentingMode = createSelector(
    getConferenceState,
    (state: State) => state.presentingMode === 'screen_http'
);

export const getPresentingSlidesCount = createSelector(
    getPresentingSlides,
    getIsScreenHttpPresentingMode,
    (presentingSlides, isScreenHttpPresentingMode) =>
        isScreenHttpPresentingMode ? presentingSlides.length : 0
);

export const getRemoteCallType = createSelector(
    getConferenceState,
    (state: State) => state.remoteCallType
);

export const getIsAudioRemoteType = createSelector(
    getRemoteCallType,
    (remoteCallType: State['remoteCallType']) => remoteCallType === 'audio'
);

export const getSelfParticipant = createSelector(
    getSelfUUID,
    getParticipants,
    (selfUUUID, participants) => participants[selfUUUID]
);

export const getGwParticipant = createSelector(
    getSelfUUID,
    getParticipants,
    (selfUUUID, participants) =>
        Object.values(participants).find(participant => {
            const part = participant.uuid !== selfUUUID;
            return part;
        })
);

export const getIsGwParticipantFeccSupported = createSelector(
    getGwParticipant,
    gwParticipant => (!gwParticipant ? false : gwParticipant.feccSupported)
);

export const getCanSelfParticipantControl = createSelector(
    getSelfParticipant,
    selfParticipant => (!selfParticipant ? false : selfParticipant.canControl)
);
export const getIsSelfParticipantBuzzed = createSelector(
    getSelfParticipant,
    selfParticipant => (!selfParticipant ? false : selfParticipant.buzzTime > 0)
);
export const getIsSelfParticipantHost = createSelector(
    getSelfParticipant,
    selfParticipant =>
        !selfParticipant ? false : selfParticipant.role === 'chair'
);

export const getIsSelfParticipantSilentVideo = createSelector(
    getSelfParticipant,
    selfParticipant =>
        !selfParticipant ? false : Boolean(selfParticipant.isVideoSilent)
);

export const getOpenDTMFDialogs = createSelector(
    getConferenceState,
    (state: State) => state.openDtmfDialogs
);
export const getOpenFECCDialogs = createSelector(
    getConferenceState,
    (state: State) => state.openFeccDialogs
);
export const getOpenInfoDialogs = createSelector(
    getConferenceState,
    (state: State) => state.openInfoDialogs
);
export const getIsSidebarOpen = createSelector(
    getConferenceState,
    (state: State) => state.showConferenceSidebar
);
export const getIsSidebarExpanded = createSelector(
    getConferenceState,
    (state: State) => state.sidebarExpanded
);

export const getCurrentPresenter = createSelector(
    getConferenceState,
    (state: State) => state.currentPresenterUuid
);

export const getLiveCaptionsEnabled = createSelector(
    getConferenceState,
    (state: State) => state.liveCaptionsEnabled
);

export const getIsDirectMedia = createSelector(
    getConferenceState,
    (state: State) => {
        return state.isDirectMedia;
    }
);
