import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { DeviceList } from './device-list.interface';
import { DevicesSetupState } from './devices-setup-state.type';

import { Store } from '@ngrx/store';

import { State } from '../../app.reducer';

import { MediaDeviceService } from '../media-device.service';

import { CONFERENCE_ACTIONS } from '../../conference/conference.actions';
import { HOME_ACTIONS } from '../../home/home.actions';
import { PlatformService } from '../../platform.service';

@Component({
    selector: 'pex-media-devices',
    templateUrl: './media-devices.component.html',
    styleUrls: ['./media-devices.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaDevicesComponent {
    mediaDevices$: Observable<MediaDeviceInfo[]>;
    videoInputDevices$: Observable<MediaDeviceInfo[]>;
    audioInputDevices$: Observable<MediaDeviceInfo[]>;
    audioOutputDevices$: Observable<MediaDeviceInfo[]>;

    videoInputDevice$: Observable<MediaDeviceInfo>;
    audioInputDevice$: Observable<MediaDeviceInfo>;
    audioOutputDevice$: Observable<MediaDeviceInfo>;

    bandwidths: string[];
    bandwidthLabelsSelected: { [value: string]: string };
    bandwidthLabelsOptions: { [value: string]: string };

    deviceList: DeviceList[];

    @Input() showBandwidth: boolean;
    @Input() inConference = false;
    @Input() showInDialog = true;

    constructor(
        public mediaDeviceService: MediaDeviceService,
        private store$: Store<State>,
        public platformService: PlatformService
    ) {
        this.videoInputDevice$ = mediaDeviceService.videoInputDevice$;
        this.audioInputDevice$ = mediaDeviceService.audioInputDevice$;
        this.audioOutputDevice$ = mediaDeviceService.audioOutputDevice$;

        this.bandwidths = mediaDeviceService.bandwidths;
        this.bandwidthLabelsSelected =
            mediaDeviceService.bandwidthLabelsSelected;
        this.bandwidthLabelsOptions = mediaDeviceService.bandwidthLabelsOptions;

        this.mediaDevices$ = mediaDeviceService.mediaDevices$;

        this.videoInputDevices$ = mediaDeviceService.videoInputDevices$;
        this.audioInputDevices$ = mediaDeviceService.audioInputDevices$;
        this.audioOutputDevices$ = mediaDeviceService.audioOutputDevices$;

        this.deviceList = Object.values(mediaDeviceService.deviceList);
    }

    setCurrentDevice(devicesSetup: DevicesSetupState) {
        if (!devicesSetup) return;

        if (devicesSetup === 'bandwidth') {
            this.mediaDeviceService.currentDeviceList =
                'SETTINGS_DIALOG.CHANGE_BANDWIDTH';
        } else {
            this.mediaDeviceService.currentDeviceList = this.mediaDeviceService.deviceList[
                devicesSetup
            ].headline;
        }
    }

    navigateToDeviceSetup(kind: DevicesSetupState, event: Event) {
        this.setCurrentDevice(kind);

        if (this.inConference) {
            this.store$.dispatch(
                CONFERENCE_ACTIONS.devicesSetupNavigateAction(kind)
            );
        } else {
            this.store$.dispatch(
                HOME_ACTIONS.devicesSetupNavigateAction({ screen: kind })
            );
        }
        event.stopPropagation();
    }
}
