import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export function TakeUntilDestroy() {
    // tslint:disable-next-line:no-any
    return <T extends { new (...args: any[]): {} }>(wrappedComponent: T) => {
        const originalDestroy = wrappedComponent.prototype.ngOnDestroy;
        if (typeof originalDestroy !== 'function') {
            console.warn(`${wrappedComponent.name} is using @TakeUntilDestroy
                but does not implement ngOnDestroy`);
        }

        wrappedComponent.prototype.destroyed$ = new Subject();

        wrappedComponent.prototype.ngOnDestroy = function () {
            typeof originalDestroy === 'function' &&
                originalDestroy.apply(this);
            wrappedComponent.prototype.destroyed$.next(true);
            wrappedComponent.prototype.destroyed$.complete();
            wrappedComponent.prototype.destroyed$ = new Subject();
        };
    };
}

// tslint:disable-next-line:no-any
export const untilDestroyed = (that: any) => <T>(source: Observable<T>) => {
    if (!('destroyed$' in that)) {
        console.warn(`'destroyed$' property does not exist
        on ${that.constructor.name}. Did you decorate the class with '@TakeUntilDestroy()'?`);
        return source;
    }

    return source.pipe(takeUntil<T>(that.destroyed$));
};
