import { APP_BASE_HREF } from '@angular/common';
import {
    Compiler,
    CompilerFactory,
    COMPILER_OPTIONS,
    Injector,
    NgModule
} from '@angular/core';
import { JitCompilerFactory } from '@angular/platform-browser-dynamic';

import {
    AppComponent,
    CoreModule,
    InitService,
    LoggingService,
    LongPressService
} from '../core';
import { PlatformService } from '../core/platform.service';
import { SRVService } from '../core/srv.service';

import { setAppInjector } from '../app-injector';
import { CalendarService } from '../core/services';
import {
    ApplicationSettingsModel,
    UserSettingsModel
} from '../core/services/storage/settings.model';
import { AppRoutingModule } from './app-routing.module';
import { WebCalendarService } from './web.calendar.service';
import { WebInitService } from './web.init.service';
import { WebLoggingService } from './web.logging.service';
import { WebPlatformService } from './web.platform.service';
import { WebSRVService } from './web.srv.service';

export function _webkitURL() {
    return URL || window.webkitURL;
}

export function BaseHrefFactory() {
    if (ENV === 'development') return '/';
    const cookiePath = `; ${document.cookie}`
        .split('; path=')
        .pop()
        .split(';')[0];

    return cookiePath || `/${window.location.pathname.split('/')[1] || ''}`;
}

export function createCompiler(fn: CompilerFactory): Compiler {
    return fn.createCompiler();
}

@NgModule({
    imports: [AppRoutingModule, CoreModule],
    declarations: [AppComponent],
    providers: [
        { provide: APP_BASE_HREF, useFactory: BaseHrefFactory },
        { provide: LongPressService, useValue: {} },
        { provide: PlatformService, useClass: WebPlatformService },
        { provide: SRVService, useClass: WebSRVService },
        { provide: InitService, useClass: WebInitService },
        { provide: LoggingService, useClass: WebLoggingService },
        { provide: CalendarService, useClass: WebCalendarService },
        {
            provide: 'UserSettingsModelFactory',
            useFactory: () => UserSettingsModel
        },
        {
            provide: 'ApplicationSettingsModelFactory',
            useFactory: () => ApplicationSettingsModel
        },
        {
            provide: COMPILER_OPTIONS,
            useValue: {},
            multi: true
        },
        {
            provide: CompilerFactory,
            useClass: JitCompilerFactory,
            deps: [COMPILER_OPTIONS]
        },
        {
            provide: Compiler,
            useFactory: createCompiler,
            deps: [CompilerFactory]
        }
    ],
    exports: [AppRoutingModule],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(injector: Injector) {
        setAppInjector(injector);
    }
}
