import { Injectable } from '@angular/core';
import { SRVService } from '../core/srv.service';

@Injectable()
export class WebSRVService extends SRVService {
    resolveSrv(_record: string) {
        // Srv lookups are not performed on web platforms
        return new Promise<SRVRecord[]>(resolve => resolve([]));
    }
}
