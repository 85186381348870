import { Conference } from '../../conference';
import { PexStorage } from './storage';

export class HistoryStorage extends PexStorage<HistoryModel> {
    readonly storageString = 'pexHistory';

    constructor(values: HistoryModel) {
        super(new HistoryModel(), values);
    }
}

export class HistoryModel {
    recents: { [alias: string]: Conference } = {};
}
