import { createAction, props } from '@ngrx/store';
import { MediaDeviceState } from './media-device.reducer';

export const MEDIA_DEVICE_ACTIONS = {
    setMediaInputDevicesAction: createAction(
        '[Media Device] Set Media Input Devices',
        props<{
            audio: MediaDeviceInfo;
            video: MediaDeviceInfo;
            mutedByUser?: boolean;
            store?: boolean;
        }>()
    ),
    setVideoInputDeviceAction: createAction(
        '[Media Device] Set Video Input Device',
        props<{
            device: MediaDeviceInfo;
            mutedByUser?: boolean;
            store?: boolean;
        }>()
    ),
    setAudioInputDeviceAction: createAction(
        '[Media Device] Set Audio Input Device',
        props<{
            device: MediaDeviceInfo;
            mutedByUser?: boolean;
            store?: boolean;
        }>()
    ),
    setAudioOutputDeviceAction: createAction(
        '[Media Device] Set Audio Output Device',
        props<{ device: MediaDeviceInfo }>()
    ),
    audioOutputDeviceChangedAction: createAction(
        '[Media Device] Audio Output Device Changed',
        props<{ device: MediaDeviceInfo }>()
    ),
    setLocalMediaStreamAction: createAction(
        '[Media Device] Set Local Media Stream',
        props<{
            stream: MediaStream;
            audioDeviceID: MediaDeviceInfo;
            videoDeviceID: MediaDeviceInfo;
            streamType: string;
            constraints: string;
        }>()
    ),
    setStreamConstraintsAction: createAction(
        '[Media Device] Set Stream Constraints',
        props<{
            audioDeviceID: MediaDeviceInfo;
            videoDeviceID: MediaDeviceInfo;
            callType: string;
        }>()
    ),
    setCurrentConferenceLocalMediaStream: createAction(
        '[Media Device] Set Current Conference Local Media Stream',
        props<{ stream: MediaStream }>()
    ),
    setMediaTypeRequest: createAction(
        '[Media Device] Set Media Type Request',
        props<{ callType: CallType }>()
    ),
    setFeccSupported: createAction(
        '[Media Device] Set FECC Supported',
        props<{ feccSupported: boolean }>()
    ),
    setSlowGUMCall: createAction(
        '[Media Device] Set Slow GUM Call',
        props<{ isSlow: boolean }>()
    )
};
