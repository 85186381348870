<div
    [pexDraggable]="config.type === 'floating'"
    [class.floating-dialog]="config.type === 'floating'"
    [class.modal-dialog]="config.type === 'modal'"
    [style.z-index]="priority + 1000"
    #divElement
>
    <div
        [class.opaque]="config.style === 'opaque'"
        [class.transparent]="config.style === 'transparent'"
        class="pex-dialog-content"
        (click)="$event.stopPropagation()"
    >
        <ng-template #content></ng-template>
    </div>
</div>
