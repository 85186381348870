<div
    class="pex-tooltip"
    [class.pex-tooltip--top]="top"
    [class.pex-tooltip--top-higher]="topHigher"
    [class.pex-tooltip--bottom]="bottom"
    [class.pex-tooltip--left]="left"
    [class.pex-tooltip--right]="right"
    [class.pex-tooltip--opaque]="opaque"
    [class.pex-tooltip--fixed]="fixed"
    [style.top.px]="topPX ? topPX : ''"
    [style.left.px]="leftPX ? leftPX : ''"
    [style.right.px]="rightPX ? rightPX : ''"
    [style.bottom.px]="bottomPX ? bottomPX : ''"
>
    {{ message | translate }}
</div>
