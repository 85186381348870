import { Component } from '@angular/core';

import { BaseDialogContainerComponent } from './base-dialog-container.component';
import { BaseDialogContentComponent } from './base-dialog-content.component';

@Component({
    selector: 'pex-dialog-container',
    templateUrl: './dialog-container.component.html',
    styleUrls: ['./dialog-container.component.scss']
})
export class DialogContainerComponent<
    S extends BaseDialogContentComponent
> extends BaseDialogContainerComponent<S> {
    readonly type = 'static';
}
