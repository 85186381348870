<div class="pex-dialog-close">
    <button
        type="button"
        class="circle-close-button"
        (click)="close()"
        [attr.aria-label]="'CLOSE' | translate"
    >
        <svg class="circle-close-button-icon">
            <use xlink:href="icons.svg#x" />
        </svg>
    </button>
</div>
<div class="pex-dialog-content-inner">
    <div class="pex-dialog-content-title">
        <span [innerHTML]="'ERROR_DIALOG.TITLE' | translate">Oops</span>
    </div>
    <div class="pex-dialog-content-message" style="max-width: 500px">
        <span [innerHTML]="'ERROR_DIALOG.MESSAGE' | translate"
            >Looks like something went wrong. Please check your console logs or
            contact your support representative.<br />You'll need to reload to
            get it working again.</span
        >
        <br />
        <br />
        <textarea
            *ngIf="this.config"
            (click)="errorArea.select(); copy()"
            class="error-area"
            readonly
            #errorArea
        >
            {{ errorText }}</textarea
        >
    </div>

    <div class="pex-dialog-content-button">
        <button
            (click)="close()"
            class="dialog-button blue-action-button"
            [innerHTML]="'OK' | translate"
        >
            OK
        </button>
    </div>
</div>
