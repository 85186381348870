import { createAction, props } from '@ngrx/store';

export const GENERAL_ACTIONS = {
    setWindowActiveAction: createAction(
        '[General] Set Window Active',
        props<{ active: boolean }>()
    ),
    setWindowMinimizedAction: createAction(
        '[General] Set Window Minimized',
        props<{ minimised: boolean }>()
    ),
    setWindowClosedAction: createAction(
        '[General] Set Window Closed',
        props<{ closed: boolean }>()
    ),
    initFinished: createAction('[General] Init finished'),
    setOnlineAction: createAction(
        '[General] Set Online',
        props<{ online: boolean }>()
    )
};
