import { createReducer, on } from '@ngrx/store';
import {
    ParticipantActions,
    PARTICIPANT_ACTIONS
} from '../../participant/participant.actions';
import { ConferenceActions, CONFERENCE_ACTIONS } from '../conference.actions';
import { Frame, PexLock, PexPresentation, TimelineEvent } from './event.model';
import { EventsActions, EVENTS_ACTIONS } from './events.actions';

export interface State {
    time0: number;
    events: TimelineEvent[];
    selectedEvent: TimelineEvent;
    locks: PexLock[];
    presentations: PexPresentation[];
    orphanedFrames: Frame[];
    selfUUID: string;
}

export const initialState: State = {
    time0: Date.now(),
    events: [],
    selectedEvent: null,
    locks: [],
    presentations: [],
    orphanedFrames: [],
    selfUUID: ''
};

export function reducer(
    state: State,
    action: ConferenceActions | ParticipantActions | EventsActions
) {
    return eventsReducer(state, action);
}

const eventsReducer = createReducer(
    initialState,
    on(
        CONFERENCE_ACTIONS.connectSuccess,
        CONFERENCE_ACTIONS.transferSuccess,
        CONFERENCE_ACTIONS.mutedChanged,
        CONFERENCE_ACTIONS.receiveChatMessage,
        CONFERENCE_ACTIONS.sendChatMessage,
        PARTICIPANT_ACTIONS.disconnectSuccessAction,
        (state, action) => ({
            ...state,
            events: [
                ...state.events,
                new TimelineEvent(action, isPresentationOngoing(state))
            ]
        })
    ),

    on(PARTICIPANT_ACTIONS.connectSuccessAction, (state, action) => {
        const event = new TimelineEvent(action, isPresentationOngoing(state));
        event.hide =
            action.payload.startTime * __CONSTANTS__.SECOND_MS < state.time0;
        return {
            ...state,
            events: [...state.events, event]
        };
    }),

    on(CONFERENCE_ACTIONS.lockedChanged, (state, action) => {
        let locks: PexLock[];
        if (!action.payload) {
            locks = state.locks.map(lock => {
                if (lock.unfinished) return new PexLock(lock.start, Date.now());
                else return lock;
            });
        } else {
            locks = [...state.locks, new PexLock()];
        }

        return {
            ...state,
            ...{
                events: [
                    ...state.events,
                    new TimelineEvent(action, isPresentationOngoing(state))
                ],
                locks
            }
        };
    }),

    on(CONFERENCE_ACTIONS.newPresentationFrameAction, (state, action) => {
        const presentations = [...state.presentations];
        const presentationIndex = presentations.findIndex(
            presentation => presentation.unfinished
        );
        let presentation = presentations[presentationIndex];
        const frame = { time: Date.now(), img: action.payload };
        if (!presentation) {
            return {
                ...state,
                orphanedFrames: [...state.orphanedFrames, frame]
            };
        }
        presentation = new PexPresentation(
            presentation.presenterUUID,
            presentation.isIncoming,
            presentation.start,
            presentation.end,
            [...presentation.frames, frame]
        );
        presentations[presentationIndex] = presentation;
        return { ...state, presentations };
    }),

    on(
        CONFERENCE_ACTIONS.incomingPresentationChangedAction,
        (state, action) => {
            if (
                !action.isActive &&
                !state.presentations.some(
                    presentation =>
                        presentation.unfinished &&
                        presentation.presenterUUID !== state.selfUUID
                )
            ) {
                return state;
            }
            const presentations = state.presentations.map(presentation => {
                if (
                    presentation.unfinished &&
                    presentation.presenterUUID !== state.selfUUID
                ) {
                    return new PexPresentation(
                        presentation.presenterUUID,
                        presentation.isIncoming,
                        presentation.start,
                        Date.now(),
                        presentation.frames
                    );
                } else return presentation;
            });

            if (action.isActive) {
                presentations.push(new PexPresentation(action.uuid, true));
            }

            return {
                ...state,
                ...{
                    events: [
                        ...state.events,
                        new TimelineEvent(action, isPresentationOngoing(state))
                    ],
                    presentations
                }
            };
        }
    ),

    on(
        CONFERENCE_ACTIONS.slideShareStarted,
        CONFERENCE_ACTIONS.screenShareStarted,
        (state, action) => {
            return {
                ...state,
                ...{
                    events: [
                        ...state.events,
                        new TimelineEvent(action, isPresentationOngoing(state))
                    ],
                    presentations: [
                        ...state.presentations,
                        new PexPresentation(state.selfUUID)
                    ]
                }
            };
        }
    ),

    on(CONFERENCE_ACTIONS.presentingStopped, (state, action) => {
        const presentations = state.presentations.map(presentation => {
            if (
                presentation.unfinished &&
                presentation.presenterUUID === state.selfUUID
            ) {
                return new PexPresentation(
                    presentation.presenterUUID,
                    presentation.isIncoming,
                    presentation.start,
                    Date.now(),
                    presentation.frames
                );
            } else return presentation;
        });

        return {
            ...state,
            ...{
                events: [
                    ...state.events,
                    new TimelineEvent(action, isPresentationOngoing(state))
                ],
                presentations
            }
        };
    }),

    on(CONFERENCE_ACTIONS.setSelfUUID, (state, action) => ({
        ...state,
        selfUUID: action.payload
    })),

    on(EVENTS_ACTIONS.clear, state => ({
        ...state,
        time0: Date.now(),
        events: [],
        locks: [],
        presentations: [],
        selfUUID: ''
    })),

    on(EVENTS_ACTIONS.setSelectedEvent, (state, action) => ({
        ...state,
        selectedEvent: action.payload
    })),

    on(EVENTS_ACTIONS.setTime0Action, (state, action) => ({
        ...state,
        time0: action.time
    }))
);

export const getEvents = (state: State) => state.events;
export const getLocks = (state: State) => state.locks;
export const getPresentations = (state: State) => state.presentations;
export const isPresentationOngoing = (state: State) =>
    state.presentations.some(presentation => presentation.unfinished);
