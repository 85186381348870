import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'pex-base',
    template: ``,
    styles: [``]
})
export class BaseComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
