import { BehaviorSubject, Observable } from 'rxjs';

type GetListenerName<T> = T extends string ? `${T}$` : never;
type GetListenersType<T> = {
    [K in keyof T as GetListenerName<K>]: Observable<T[K]>;
};

export abstract class PexStorageBase<T> {
    abstract storageString: string;
    abstract values: Readonly<T>;
    protected abstract currentValues: Partial<T>;
    protected _listeners: Record<string, BehaviorSubject<unknown>>;

    get listeners(): GetListenersType<T> {
        return (this._listeners as unknown) as GetListenersType<T>;
    }

    constructor(protected model: Required<T>, currentValues?: Partial<T>) {
        const values = { ...model, ...currentValues };
        this._listeners = Object.keys(values).reduce((acc, curr) => {
            acc[`${curr}$`] = new BehaviorSubject(values[curr]);
            return acc;
        }, {});
    }

    setItem<K extends keyof T>(key: K, value: T[K]) {
        const prevValue = this.values[key];
        this.currentValues[key] = value;
        localStorage.setItem(
            this.storageString,
            JSON.stringify(this.currentValues)
        );
        if (value !== prevValue) {
            this.getListener(key)?.next(value);
        }
    }

    deleteItem<K extends keyof T>(key: K) {
        const prevValue = this.values[key];
        delete this.currentValues[key];
        localStorage.setItem(
            this.storageString,
            JSON.stringify(this.currentValues)
        );
        if (prevValue !== this.values[key]) {
            this.getListener(key)?.next(this.values[key]);
        }
    }

    protected getListener<K extends keyof T>(key: K) {
        return this._listeners[`${String(key)}$`];
    }
}

export abstract class PexStorage<T> extends PexStorageBase<T> {
    defaultValues: T;

    get values() {
        return Object.assign({}, this.defaultValues, this.currentValues);
    }

    constructor(model: Required<T>, public currentValues: Partial<T>) {
        super(model, currentValues);

        this.defaultValues = { ...this.model };
        if (!currentValues) {
            this.currentValues = {};
        }
    }

    reset(keysToReset?: Partial<T>) {
        if (keysToReset) {
            for (const key of Object.keys(keysToReset)) {
                this.deleteItem(<keyof T>key);
            }
        } else {
            const prevValues = this.currentValues;
            this.currentValues = {};
            if (localStorage.getItem(this.storageString)) {
                localStorage.removeItem(this.storageString);
            }
            for (const [key, prevValue] of Object.entries(prevValues)) {
                if (this.defaultValues[key] !== prevValue) {
                    this.getListener(key as keyof T)?.next(
                        this.defaultValues[key]
                    );
                }
            }
        }
    }
}

export abstract class PexDefaultStorage<T> extends PexStorageBase<T> {
    get storageStringValues() {
        return `${this._storageString}Values`;
    }
    get storageStringDefaults() {
        return `${this._storageString}Defaults`;
    }

    get storageString() {
        return this.storageStringValues;
    }

    defaultValues: T;
    get values() {
        return Object.assign(
            {},
            this.defaultValues,
            this.storedDefaultValues,
            this.currentValues
        );
    }

    constructor(
        model: Required<T>,
        public currentValues: Partial<T>,
        public storedDefaultValues: Partial<T>,
        private _storageString: string
    ) {
        super(model, { ...storedDefaultValues, ...currentValues });

        this.defaultValues = { ...this.model };
        if (!this.currentValues) {
            this.currentValues = {};
        }
        if (!this.storedDefaultValues) {
            this.storedDefaultValues = {};
        }
    }

    resetAll() {
        const prevValues = this.values;
        this.resetValues(undefined, false);
        this.resetDefaults(false);
        const newValues = this.values;
        for (const [key, prevValue] of Object.entries(prevValues)) {
            if (newValues[key] !== prevValue) {
                this.getListener(key as keyof T)?.next(newValues[key]);
            }
        }
    }

    resetValues(keysToReset?: Partial<T>, updateListeners = true) {
        if (keysToReset) {
            for (const key of Object.keys(keysToReset)) {
                this.deleteItem(<keyof T>key);
            }
        } else {
            const prevValues = this.currentValues;
            this.currentValues = {};
            localStorage.removeItem(this.storageStringValues);
            if (updateListeners) {
                for (const [key, prevValue] of Object.entries(prevValues)) {
                    if (this.defaultValues[key] !== prevValue) {
                        this.getListener(key as keyof T)?.next(
                            this.defaultValues[key]
                        );
                    }
                }
            }
        }
    }

    resetDefaults(updateListeners = true) {
        const prevValues = this.values;
        this.storedDefaultValues = {};
        localStorage.removeItem(this.storageStringDefaults);
        const newValues = this.values;
        if (updateListeners) {
            for (const [key, prevValue] of Object.entries(prevValues)) {
                if (prevValue !== newValues[key]) {
                    this.getListener(key as keyof T)?.next(newValues[key]);
                }
            }
        }
    }

    updateDefaults(newDefaults: Partial<T>) {
        const oldValues = this.values;
        this.storedDefaultValues = newDefaults;
        localStorage.setItem(
            this.storageStringDefaults,
            JSON.stringify(newDefaults)
        );
        const newValues = this.values;
        Object.entries(newValues).forEach(([key, newValue]) => {
            if (newValue !== oldValues[key]) {
                this.getListener(key as keyof T)?.next(newValue);
            }
        });
    }
}
